import { Image } from '../Image';
import { Link } from '../Link';

export function MenuItem({
  handleMenuDrawerClose,
  handleMenuDrawerStayOpen,
  menuDrawerContent,
  menuDrawerPosition,
  isActive,
}) {
  const { submenus, imageLinks } = {
    ...menuDrawerContent,
  };
  const hasContent = imageLinks?.length > 0 || submenus?.length > 0;
  const hasOnlyImage = imageLinks?.length > 0 && !submenus?.length;
  if (!hasContent) return null;
  const columns = hasContent
    ? (submenus?.filter((item) => item.submenuLinks)?.length ?? 0) +
      (imageLinks?.length ?? 0)
    : 0;
  const customPosition = columns > 1 ? 'auto' : `${menuDrawerPosition}px`;

  // Limit the amount of columns to 10
  // CTA columns take priority over Menu columns
  const submenuLimit = 10 - (imageLinks?.length ?? 0);
  return (
    <div
      id="header-menu-drawer"
      role="navigation"
      className={`absolute top-[100%] mt-[1px] hidden origin-top transition duration-200 lg:block
        ${isActive ? 'scale-y-100' : 'scale-y-0'}

        ${columns > 1 ? 'left-0 w-full bg-grey1' : `w-auto`}`}
    >
      {hasContent && (
        <div
          className={`flex justify-center bg-grey1 ${
            !hasOnlyImage ? 'pt-2' : 'max-w-[60rem] py-0'
          }
            ${
              columns > 1
                ? `mx-auto w-full ${
                    hasOnlyImage ? 'max-w-[60rem]' : 'max-w-[123rem]'
                  } flex flex-wrap px-4 md:px-6`
                : `w-[250px] px-[3rem] ${hasOnlyImage ? '' : 'pb-[1.75rem]'}`
            }`}
          style={{ marginLeft: `${customPosition}` }}
        >
          {/* These are the submenu columns */}
          {submenus
            ?.slice(0, submenuLimit)
            .map(({ submenuLinks, sublink_title }, index) => {
              return (
                <div
                  className={`header-column-links p-4 ${
                    columns > 1 ? 'w-1/5' : 'w-full'
                  }`}
                  key={`header-column-links-${index}`}
                >
                  {sublink_title ? (
                    <h2 className="mb-4 text-sh-upper-lg font-bold">
                      {sublink_title}
                    </h2>
                  ) : null}

                  <ul
                    className="my-0 flex list-none flex-col gap-2 px-0"
                    key={index}
                  >
                    {submenuLinks?.map(
                      ({ sublink, icon, blankIcon, textBold }, index1) => {
                        return (
                          <li key={index1} className="my-0">
                            <Link
                              aria-hidden={!hasContent}
                              aria-label={sublink?.text}
                              className={`flex cursor-pointer items-start gap-2.5 text-[1rem] leading-[1.5] text-black no-underline hover:text-darkLava${
                                textBold ? ' font-bold' : ''
                              }`}
                              href={sublink?.url}
                              newTab={sublink?.newTab}
                              onClick={handleMenuDrawerClose}
                              tabIndex={hasContent ? '0' : '-1'}
                              onFocus={handleMenuDrawerStayOpen}
                            >
                              {(icon?.src || blankIcon) && (
                                <span className="relative mt-0.5 block aspect-square w-[21px] flex-shrink-0">
                                  {icon?.src && !blankIcon && (
                                    <Image
                                      alt={sublink?.text}
                                      fill
                                      sizes="50vw"
                                      src={icon.src}
                                    />
                                  )}
                                </span>
                              )}
                              <span
                                className={
                                  sublink?.text?.includes('Shop All') &&
                                  !icon?.src &&
                                  !blankIcon
                                    ? 'underline underline-offset-4'
                                    : null
                                }
                              >
                                {sublink?.text}
                              </span>
                            </Link>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              );
            })}

          {/* These are the CTA columns */}
          {imageLinks
            ?.slice(0, 10)
            .map(({ image, alt, title, description, link }, index) => {
              return (
                <div
                  key={index}
                  className={`header-column-cta p-6
                  ${link?.text?.includes('Shop All') ? 'bg-grey2' : ''}
                  ${columns > 1 ? 'w-1/5' : 'w-full'}`}
                >
                  <Link
                    aria-hidden={!hasContent}
                    aria-label={title}
                    href={link?.url ? link.url : 'javascript:void(0);'}
                    newTab={link?.newTab}
                    onClick={handleMenuDrawerClose}
                    tabIndex={hasContent ? '0' : '-1'}
                    className="no-underline"
                    onFocus={handleMenuDrawerStayOpen}
                  >
                    <div
                      className={`relative aspect-[4/3.328] w-full ${
                        hasOnlyImage ? 'mx-auto max-w-[7rem]' : ''
                      }`}
                    >
                      {image?.src && (
                        <Image alt={alt} fill sizes="50vw" src={image.src} />
                      )}
                    </div>

                    {link?.text && link?.url ? (
                      <p className="focus-link mb-2 mt-3 block text-center text-sh-upper-sm leading-normal no-underline">
                        {link?.text}
                      </p>
                    ) : null}
                    {description ? (
                      <p className="mb-0 block text-center text-sm font-normal text-black no-underline">
                        {description}
                      </p>
                    ) : null}
                  </Link>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

MenuItem.displayName = 'MenuItem';
