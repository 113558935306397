import { Image } from '../Image';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function MenuNestedSidebar({
  handleCloseSidebar,
  nestedSidebarContent,
  handleNestedSidebar,
  nestedSidebarIndex,
}) {
  const { submenus, imageLinks } = { ...nestedSidebarContent };
  const submenuLimit = 10 - (imageLinks?.length ?? 0);
  const hasContent = imageLinks?.length > 0 || submenus?.length > 0;

  return (
    <div
      className={`scrollbar-hide visible left-0 top-0 z-[1] w-full bg-background transition-all duration-150
        ${hasContent ? 'h-full' : 'hidden'}`}
    >
      <div className="scrollbar-hide h-full w-full overflow-y-auto">
        <div className="bg-grey1 pb-8 pt-6">
          {submenus
            ?.slice(0, submenuLimit)
            .map(({ submenuLinks, sublink_title }, index) => {
              return (
                <div
                  className={`header-column-links mx-12 ${
                    index > 0 ? 'border-t-[2px] border-t-white pt-6' : ''
                  }`}
                  key={`submenu-${nestedSidebarIndex}-${index}`}
                >
                  {sublink_title ? (
                    <h2 className="mb-4 text-sh-upper-lg font-bold">
                      {' '}
                      {sublink_title}
                    </h2>
                  ) : null}

                  <ul
                    className={`my-0 flex list-none flex-col  px-0
                  ${(submenus.length < 2 && !imageLinks?.length) ? 'pb-0' : 'pb-8'}`}
                  >
                    {submenuLinks?.map(
                      ({ sublink, icon, blankIcon, textBold }, index1) => {
                        return (
                          <li
                            key={`submenu-${nestedSidebarIndex}-${index}-${index1}`}
                            className={`${
                              icon?.src || blankIcon ? 'mt-4' : 'mt-2'
                            } first:mt-0`}
                          >
                            <Link
                              aria-hidden={!hasContent}
                              aria-label={sublink?.text}
                              className={`flex cursor-pointer items-start gap-2.5 text-[1rem] leading-[1.5] text-black no-underline hover:text-darkLava ${
                                textBold ? ' font-bold' : ''
                              }`}
                              href={sublink?.url}
                              newTab={sublink?.newTab}
                              onClick={handleCloseSidebar}
                            >
                              {(icon?.src || blankIcon) && (
                                <span className="relative mt-0.5 block aspect-square w-[21px] flex-shrink-0">
                                  {icon?.src && !blankIcon && (
                                    <Image
                                      alt={sublink?.text}
                                      fill
                                      sizes="50vw"
                                      src={icon.src}
                                    />
                                  )}
                                </span>
                              )}
                              <span
                                className={`text-[1rem] leading-[1.5] text-grey5 ${
                                  sublink?.text.includes('Shop All') &&
                                  !icon?.src &&
                                  !blankIcon
                                    ? 'underline underline-offset-4'
                                    : ''
                                }`}
                              >
                                {sublink?.text}
                              </span>
                            </Link>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              );
            })}

          {imageLinks
            ?.slice(0, 10)
            .map(({ image, alt, title, description, link }, index) => {
              return (
                <div
                  key={`submenu-image-${nestedSidebarIndex}-${index}`}
                  className="header-column-cta w-full border-t-[2px] border-t-white first:mt-[-1.5rem] last:mb-[-2rem]"
                >
                  <Link
                    aria-hidden={!hasContent}
                    aria-label={title}
                    href={link?.url ? link.url : 'javascript:void(0);'}
                    newTab={link?.newTab}
                    onClick={handleCloseSidebar}
                    // tabIndex={hasContent ? '0' : '-1'}
                    className={`flex no-underline ${
                      link?.text && link.text.includes('Shop All')
                        ? 'bg-grey2'
                        : ''
                    }`}
                  >
                    <div className="relative aspect-[6/5] w-[42%] max-w-[9.75rem]">
                      {image?.src && (
                        <Image alt={alt} fill sizes="50vw" src={image.src} />
                      )}
                    </div>

                    <div className="flex w-[57%] flex-col justify-center px-4 py-4">
                      {link?.text && link?.url ? (
                        <p className="focus-link mb-2 mt-4 block text-left text-sh-upper-sm no-underline">
                          {link?.text}
                        </p>
                      ) : null}
                      {description ? (
                        <p className="mb-0 block text-left text-sm font-normal text-black no-underline">
                          {description}
                        </p>
                      ) : null}
                    </div>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

MenuNestedSidebar.displayName = 'MenuNestedSidebar';
