import { useInView } from 'react-intersection-observer';

import { Link, Svg, Image } from '@/snippets';

export function CollectionContentTile({ tile }) {
  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const { title, url, seo } = tile;
  const image = seo?.image;

  return (
    <Link
      aria-label={title}
      className="w-full text-black no-underline"
      href={url}
      ref={ref}
    >
      <div>
        <div
          className="mb-3 flex items-center justify-center bg-grey3 p-5"
          style={{
            aspectRatio: 'var(--product-image-aspect-ratio)',
          }}
        >
          {image ? (
            <Image
              alt={title}
              className="mx-auto max-w-full bg-transparent object-contain"
              height="117"
              src={image}
              width="117"
            />
          ) : (
            <Svg
              className="h-full w-full text-white"
              src="/svgs/logo-grey.svg#logo-grey"
              title={title}
              viewBox="0 0 39 48"
            />
          )}
        </div>
        {title && (
          <h3 className="text-bold m-0 text-sh-upper-sm capitalize md:text-sh-lg">
            {title}
          </h3>
        )}
      </div>
    </Link>
  );
}

CollectionContentTile.displayName = 'CollectionContentTile';
