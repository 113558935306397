export function Schema() {
  return {
    category: 'FourSixty',
    label: 'FourSixty',
    key: 'foursixty',
    fields: [
      {
        label: 'Heading',
        name: 'instaHandle',
        component: 'html',
      },
      {
        label: 'CTA Link',
        name: 'instaLink',
        component: 'link',
      },
      {
        component: 'number',
        name: 'mobilePadding',
        label: 'Section Bottom Margin - Mobile',
        defaultValue: 24,
      },
      {
        component: 'number',
        name: 'desktopPadding',
        label: 'Section Bottom Margin - Desktop',
        defaultValue: 48,
      },
    ],
  };
}
