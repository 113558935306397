import { useState, useEffect } from 'react';
import { useCustomerDetails } from '../../hooks';

function SwellPointBalance({ customer }) {
  const [points, setPoints] = useState(0);
  const { customerDetails } = useCustomerDetails({
    email: customer?.email,
  });
  useEffect(() => {
    setPoints(customerDetails?.points_balance);
  }, [customerDetails?.points_balance]);

  return (
    <>
      {customer && (
        <div
          id="swell-customer-identification"
          data-authenticated="true"
          data-email={customer?.email}
          data-id={customer?.id?.replace('gid://shopify/Customer/', '')}
          data-tags='"[]"'
          style={{ display: 'none' }}
        />
      )}
      <span className="text-sh-sm text-white font-bold bg-lava px-2 py-1 rounded-[40px]">{points} points</span>
    </>
  );
}

export default SwellPointBalance;
