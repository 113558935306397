import { Markdown, Image, Link } from '../../snippets';
import { Schema } from './IconRow.schema';

export function IconRow({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { heading, icons, section, subtext, buttonLink } = cms;
  const styles = { backgroundColor: section?.bgColor, color: 'var(--black)' };
  let buttonStyle = 'button--orion';

  switch (section?.bgColor) {
    case 'var(--orion)':
    case 'var(--dark-loden)':
    case 'var(--dark-orion)':
      styles.color = 'var(--white)';
      buttonStyle = 'button--light';
      break;
    default:
      styles.color = 'var(--black)';
      buttonStyle = 'button--orion';
  }

  return (
    <div
      id={moduleID}
      className="icon-row-section-container px-contained py-contained"
      style={styles}
    >
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div className="mx-auto text-center">
        {heading && (
          <h2
            className="h3 mb-8"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}

        {subtext && (
          <div className="mx-auto mb-8 max-w-[30rem] [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <div dangerouslySetInnerHTML={{ __html: subtext }} />
          </div>
        )}

        {icons?.length > 0 && (
          <ul className="mb-0 mt-0 flex list-none flex-wrap justify-center gap-x-2 gap-y-6 pl-0 lg:gap-x-4">
            {icons.map(({ icon, label, description }, index) => {
              return (
                <li
                  key={index}
                  className="mt-0 flex max-w-[10rem] grow basis-1/2 flex-col text-center md:basis-1/6 lg:max-w-[12rem]"
                >
                  {icon?.src && (
                    <div className="relative mx-auto h-[3.75rem] w-[3.75rem] lg:h-[5.625rem] lg:w-[5.625rem]">
                      <Image
                        alt={`${label} icon`}
                        fill
                        sizes="(min-width: 768px) 25vw, 50vw"
                        src={icon.src}
                      />
                    </div>
                  )}

                  {label && (
                    <h3 className="h4 mb-0 mt-2 flex flex-1 justify-center text-sh-upper-lg uppercase lg:mt-4">
                      {label}
                    </h3>
                  )}

                  {description && (
                    <p className="mt-2 flex-1 lg:mt-4">{description}</p>
                  )}
                </li>
              );
            })}
          </ul>
        )}

        {buttonLink?.text && buttonLink?.url && (
          <Link
            aria-label={buttonLink?.text}
            className={`button mt-10 inline-block no-underline lg:mt-12 ${buttonStyle}`}
            href={buttonLink?.url}
            newTab={buttonLink?.newTab}
          >
            {buttonLink?.text}
          </Link>
        )}
      </div>
    </div>
  );
}

IconRow.displayName = 'IconRow';
IconRow.Schema = Schema;
