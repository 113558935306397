import { useCallback, useEffect, useRef } from 'react';
import {
  useProductByHandle,
  useProductInventoryByHandle,
  useRouter,
} from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

import { UpsellOptions } from './UpsellOptions';
import { useSelectedVariant } from './useSelectedVariant';
import { Link } from '../Link';
import { ProductItemMedia } from '../ProductItem/ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from '../ProductItem/ProductItemPrice';
import { ProductStars } from '../ProductStars';
import { AddToCart } from '../AddToCart';

export function UpsellItem({
  enabledColorSelector,
  enabledQuickShop,
  enabledStarRating,
  handle,
  index,
  isSearchPage,
  onClick,
  product: passedProduct,
  isRebuyPanel,
  rulesetid,
  setOosProducts,
  rebuyProductId,
}) {
  const { ref, inView } = useInView({
    rootMargin: '1200px',
    triggerOnce: true,
  });
  const { product: fetchedFullProduct, finished } = useProductByHandle({
    handle,
    fetchOnMount: inView,
  });
  const initialProduct =
    fetchedFullProduct || (passedProduct?.loaded ? passedProduct : null);

  const router = useRouter();
  const emptyRef = useRef(null);

  const selectedProduct = initialProduct;
  const { selectedVariant, setSelectedOptions, selectedOptions } =
    useSelectedVariant(selectedProduct);

  const productUrl = selectedProduct
    ? `/products/${selectedProduct.handle}${
        selectedVariant?.legacyResourceId
          ? `?variant=${selectedVariant.legacyResourceId}`
          : ''
      }`
    : '';
  const title = selectedProduct?.title;

  const hideOptions =
    selectedProduct?.variants?.length === 1 &&
    selectedProduct?.variants?.[0].title === 'Default Title';

  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: handle || 'null',
    withInventory: false,
  });

  const handleClick = useCallback(
    (evt) => {
      if (isRebuyPanel) {
        evt.preventDefault();
        if (/\?/.test(productUrl)) {
          router.push(`${productUrl}&_source=rebuy&rulesetid=${rulesetid}`);
        } else {
          router.push(`${productUrl}?_source=rebuy&rulesetid=${rulesetid}`);
        }
      }
      PubSub.publish(
        isSearchPage ? 'CLICK_SEARCH_ITEM' : 'CLICK_COLLECTION_ITEM',
        {
          ...selectedVariant,
          image: selectedProduct?.images?.[0],
          index,
        }
      );
      if (typeof onClick === 'function') onClick();
    },
    [index, selectedProduct?.id, selectedVariant?.id, isRebuyPanel]
  );

  useEffect(() => {
    if (
      inView &&
      selectedProduct &&
      !inventory?.availableForSale &&
      (inventoryStatus?.success || inventoryStatus?.finished)
    ) {
      setOosProducts((prev) => [...prev, selectedProduct.id]);
    }
  }, [index, inView, selectedProduct, inventory]);

  useEffect(() => {
    if (emptyRef?.current) {
      setOosProducts((prev) => [...prev, rebuyProductId]);
    }
  }, [emptyRef?.current]);

  if (
    inView &&
    selectedProduct &&
    !inventory?.availableForSale &&
    (inventoryStatus?.success || inventoryStatus?.finished)
  ) {
    return <div ref={emptyRef} />;
  }
  if (!selectedProduct && finished) {
    return <div ref={emptyRef} />;
  }

  return (
    <div
      className={`group flex h-full flex-col justify-between bg-grey1 p-4 pb-2.5 pl-0 ${
        !inventory?.availableForSale &&
        (inventoryStatus?.success || inventoryStatus?.finished)
          ? 'oos'
          : ''
      }`}
      ref={ref}
    >
      <div className="grid grid-cols-[1fr_auto_min-content] items-start">
        <Link
          aria-label={title}
          className="row-span-2 pr-4 text-black no-underline md:pr-5"
          href={productUrl}
          onClick={handleClick}
        >
          <div className="w-[80px] bg-grey1 pl-2 md:w-[100px]">
            <ProductItemMedia
              selectedProduct={selectedProduct}
              selectedVariant={selectedVariant}
              enabledQuickShop={enabledQuickShop}
              enabledColorSelector={enabledColorSelector}
            />
          </div>
        </Link>
        <div className="p-0">
          {title && (
            <Link
              aria-label={title}
              className="-mb-2 block w-full text-black no-underline"
              href={productUrl}
              onClick={handleClick}
            >
              <h3 className="text-bold m-0 text-sh-upper-sm uppercase md:text-sh-lg">
                {title}
              </h3>
            </Link>
          )}
          {enabledStarRating && (
            <div className="mb-2">
              <ProductStars
                legacyResourceId={initialProduct?.legacyResourceId}
              />
            </div>
          )}
          <ProductItemPrice selectedVariant={selectedVariant} />
        </div>
        <div className="pl-3 md:pl-4">
          {selectedVariant && (
            <AddToCart
              addToCartText="Add"
              className="button--dark-outline !h-auto min-h-[40px] px-4 py-2 text-sh-upper text-black md:px-6"
              isPdp={false}
              rulesetid={rulesetid}
              selectedVariant={selectedVariant}
            />
          )}
        </div>
        <div className="col-span-2 col-start-2 pt-3">
          {!hideOptions && (
            <UpsellOptions
              product={selectedProduct}
              selectedOptions={selectedOptions}
              selectedVariant={selectedVariant}
              setSelectedOptions={setSelectedOptions}
            />
          )}
        </div>
      </div>
    </div>
  );
}

UpsellItem.displayName = 'UpsellItem';
UpsellItem.propTypes = {
  activeColorFilters: PropTypes.array,
  enabledColorNameOnHover: PropTypes.bool,
  enabledColorSelector: PropTypes.bool,
  enabledQuickShop: PropTypes.bool,
  enabledStarRating: PropTypes.bool,
  handle: PropTypes.string,
  index: PropTypes.number,
  isSearchPage: PropTypes.bool,
  onClick: PropTypes.func,
  swatchesMap: PropTypes.object,
};
