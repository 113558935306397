import { useMemo } from 'react';

import { Image } from '../../Image';
import { Svg } from '../../Svg';

export function CollectionFilterOption({
  activeFilters,
  addFilter,
  count,
  isColor,
  name,
  removeFilter,
  showCount,
  swatchesMap,
  value,
}) {
  const isActive = useMemo(() => {
    return Object.entries(activeFilters).some(([key, values]) => {
      return key === name && values.some((v) => v === value);
    });
  }, [activeFilters, value]);

  let swatch = null;
  let hasImage = false;

  if (isColor) {
    swatch = swatchesMap[value.toLowerCase().trim()];
    hasImage = swatch?.startsWith('http');
  }

  const checkmarkColor = useMemo(() => {
    if (!isColor) return `text-white`;
    if (!swatch) return `text-black`;
    const hexArr = swatch?.split('#').pop().split('');
    const isLight = [hexArr[0], hexArr[2], hexArr[4]].every(
      (char) => char === 'F' || char === 'E'
    );
    return isLight ? `text-black` : `text-white`;
  }, [isColor, swatch]);

  const colorBackground =
    isActive && swatch === '#FFFFFF'
      ? 'var(--white)'
      : swatch || 'var(--white)';
  const nonColorBackground = isActive ? 'var(--black)' : 'var(--white)';

  return (
    <button
      aria-label={`Add ${value} to filters`}
      className={`flex gap-2 text-left text-base text-black ${
        isActive ? '' : ''
      }`}
      onClick={() => {
        if (isActive) {
          removeFilter({ key: name, value });
        } else {
          addFilter({ key: name, value });
        }
      }}
      type="button"
    >
      <div
        className="relative top-1 h-4 w-4 flex-shrink-0 cursor-pointer appearance-none rounded-md- border border-black bg-white transition-all"
        style={{
          backgroundColor: isColor ? colorBackground : nonColorBackground,
        }}
      >
        {hasImage && (
          <Image
            alt={`Add ${value} to filters`}
            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
            height="24"
            width="24"
            src={swatch}
          />
        )}

        {isActive && (
          <Svg
            src="/svgs/checkmark.svg#checkmark"
            title="Checkmark"
            viewBox="0 0 24 24"
            className={`pointer-events-none absolute left-1/2 top-1/2 w-3.5 -translate-x-1/2 -translate-y-1/2 ${checkmarkColor}`}
          />
        )}
      </div>

      <p className="m-0 flex-1">
        {value}{' '}
        <span className={`text-sm ${showCount ? 'inline' : 'hidden'}`}>
          ({count})
        </span>
      </p>
    </button>
  );
}

CollectionFilterOption.displayName = 'CollectionFilterOption';
