import { useEffect } from 'react';

export function InjectScript({ src, id }) {
  if( !src ) return null;
  const asyncLoad = () => {
    const e = document.createElement('script');
    e.type = 'text/javascript',
    e.async = true,
    e.src = src,
    e.id = id,
    e.setAttribute('id', id);
    const t = document.getElementsByTagName('script')[0];
    const b = document.getElementById(id);
    if (!b) {
      t.parentNode.insertBefore(e, t);
    }
  }

  const removeScripts = () => {
    if( !id ) return;
    const b = document.getElementById(id);
    if (b) {
      t.parentNode.removeChild(b);
    }
  }

  useEffect(() => {
    if( src ){
      asyncLoad(src);
    }
    //return removeScripts;
  }, []);

  return null;
}

InjectScript.displayName = 'InjectScript';
