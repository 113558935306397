import { Image } from '../../snippets';

export function ShopByDesignMedia({ media }) {
  const { image } = { ...media };
  return (
    <div className="relative h-full">
      <div className="relative h-full w-full overflow-hidden md:hidden">
        {image?.imageMobile?.src && (
          <Image
            alt={image.alt}
            sizes="100vw"
            src={image.imageMobile.src}
            className="w-full"
          />
        )}
      </div>

      <div className="relative hidden h-full w-full overflow-hidden md:block">
        {image?.imageDesktop?.src && (
          <Image
            alt={image.alt}
            sizes="50vw"
            src={image.imageDesktop.src}
            className="w-full h-full object-cover"
          />
        )}
      </div>
    </div>
  );
}

ShopByDesignMedia.displayName = 'ShopByDesignMedia';
