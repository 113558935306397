import React, { useState } from 'react';

import { Link } from '../Link';
import { Svg } from '../Svg';

export function MobileMenuItem({ item }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-b-grey5 py-6 px-4">
      <button
        aria-label={
          isOpen ? `Close ${item.title} menu` : `Open ${item.title} menu`
        }
        className="flex w-full items-center justify-between focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h3 className="mb-0 text-sh-upper uppercase">{item.title}</h3>

        <Svg
          className={`w-5 ${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down-wide.svg#chevron-down-wide"
          title={`Chevron ${isOpen ? 'Up' : 'Down'}`}
          viewBox="0 0 20 8"
        />
      </button>

      <ul
        className={`my-0 flex-col items-start gap-3 px-4 pt-4 ${
          isOpen ? 'flex' : 'hidden'
        }`}
      >
        {item.links?.map(({ link }, linkIndex) => {
          return (
            <li key={linkIndex} className="mt-0 list-none text-xs">
              <Link
                aria-label={link?.text}
                className="text-gray no-underline hover:text-medDesert"
                href={link?.url}
                newTab={link?.newTab}
              >
                {link?.text}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

MobileMenuItem.displayName = 'MobileMenuItem';
