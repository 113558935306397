export function Schema({ product }) {
  if (!product) return null;

  return {
    category: 'Product',
    label: 'Product Fit and Fabric',
    key: 'product-fit-and-fabric',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/product-fit-and-fabric-preview.jpg?v=1682972863',
    fields: [
      {
        label: 'Section Heading',
        name: 'headingText',
        component: 'text',
        defaultValue: 'Fit & Fabric',
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'markdown',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}
