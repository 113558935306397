import { useSettings } from '@backpackjs/storefront';

import { CollectionDropdownFilter } from './CollectionDropdownFilter';
import { CollectionFiltersSummary } from './CollectionFiltersSummary';
import { CollectionMobileFilters } from './CollectionMobileFilters';
import { Spinner } from '../../Spinner';
import { useCollectionFilters } from '../useCollectionFilters';

export function CollectionFilters({
  collectionCount,
  collectionFiltersData,
  swatchesMap,
  filteredProducts,
  enabledFilters,
  handle,
  productsReady,
}) {
  const settings = useSettings();
  const showCount = settings?.collection?.filtering?.showCount;
  const isSticky = settings?.collection?.filtering?.sticky;
  const stickyPromobar =
    settings?.header?.promobar?.enabled &&
    !settings?.header?.promobar?.autohide;
  const stickyTopClass = stickyPromobar
    ? 'md:top-[calc(var(--header-height)+var(--promobar-height)+1.5rem)]'
    : 'md:top-[calc(var(--header-height)+1.5rem)]';
  const {
    state: { activeFilters, filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters },
  } = collectionFiltersData;

  const newcollectionFiltersData = useCollectionFilters({
    enabledFilters,
    handle,
    products: filteredProducts,
    productsReady,
  });
  const {
    state: { filters: newFilters, filtersMap: newfiltersMap },
  } = newcollectionFiltersData;

  const filteredFilters = Object.keys(activeFilters)?.length
    ? newFilters
    : filters;

  if (filteredProducts?.length) {
    let firstActiveFilter;
    let firstfiltersMap;
    let firstfilters;

    if (Object.keys(activeFilters)?.length > 0) {
      firstActiveFilter = Object.keys(activeFilters).shift();
    }
    if (firstActiveFilter && Object.values(activeFilters).length === 1) {
      firstfilters = (filters || []).find((f) => f?.name === firstActiveFilter);
      firstfiltersMap = (Object.values(filtersMap) || []).find(
        (f) => f?.name === firstActiveFilter
      );
      newFilters.forEach((f, i) => {
        if (f?.name === firstActiveFilter && firstfilters) {
          newFilters[i] = firstfilters;
        }
      });
      Object.values(newfiltersMap).forEach((f) => {
        if (f?.name === firstActiveFilter) {
          newfiltersMap[`${firstActiveFilter}`] = firstfiltersMap;
        }
      });
    }
  }

  return (
    <div
      className={`flex flex-col gap-4 md:sticky ${
        isSticky ? stickyTopClass : ''
      }`}
    >
      {/* desktop */}
      <div className="hidden md:block">
        <CollectionFiltersSummary
          activeFilters={activeFilters}
          clearFilters={clearFilters}
          filtersMap={filtersMap}
          removeFilter={removeFilter}
        />
      </div>
      <ul className="m-0 list-none p-0 max-md:hidden [&_li]:m-0">
        {filteredFilters.map((filter, index) => {
          if (
            !filter.values.length ||
            (filter.values.length === 1 &&
              filter.values[0].count === collectionCount)
          )
            return null;

          return (
            <li key={index} className="border-t border-grey2">
              <CollectionDropdownFilter
                activeFilters={activeFilters}
                addFilter={addFilter}
                defaultOpen={filter.defaultOpen}
                filter={filter}
                removeFilter={removeFilter}
                showCount={showCount}
                swatchesMap={swatchesMap}
              />
            </li>
          );
        })}

        {!filters.length && (
          <div className="relative h-[20rem]">
            <Spinner
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white"
              color="#D0D0D1"
              width="24"
            />
          </div>
        )}
      </ul>

      {/* mobile */}
      <div className="md:hidden">
        <CollectionMobileFilters
          activeFilters={activeFilters}
          addFilter={addFilter}
          clearFilters={clearFilters}
          collectionCount={collectionCount}
          filters={filteredFilters}
          filtersMap={filtersMap}
          removeFilter={removeFilter}
          showCount={showCount}
          swatchesMap={swatchesMap}
        />
      </div>
    </div>
  );
}

CollectionFilters.displayName = 'CollectionFilters';
