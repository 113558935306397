import Head from 'next/head';

export function PageTitle({ title }) {
  
  return title && (
    <Head>
      <title>{title}</title>
    </Head>
  );
}

PageTitle.displayName = 'PageTitle';