import Head from 'next/head';
import { useSettings } from '@backpackjs/storefront';

export function MainSchemaMarkup({ pageUrl }) {
  const logo = useSettings()?.header?.logo;
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
            url: pageUrl,
            logo: logo
              ? logo.src
              : process.env.NEXT_PUBLIC_SITE_LOGO ||
                `${process.env.SITE_URL}/svgs/logo.svg`,
          }),
        }}
      />
    </Head>
  );
}

MainSchemaMarkup.displayName = 'MainSchemaMarkup';
