import { Link, Markdown } from '../../snippets';
import { Schema } from './TextBlock.schema';

export function TextBlock({ cms }) {
  const { buttons, heading, section, subtext } = cms;
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';

  return (
    <div
      id={moduleID}
      className="px-contained py-contained"
      style={{ backgroundColor: section?.bgColor, color: section?.textColor }}
    >
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div
        className={`mx-auto flex flex-col items-center gap-4 md:gap-6 ${maxWidthClass} text-center`}
      >
        {heading &&
          (section?.aboveTheFold ? (
            <h1 className="text-title-h2 mx-auto max-w-[46rem]" dangerouslySetInnerHTML={{__html: heading}} />
          ) : (
            <h2 className="text-title-h2 mx-auto max-w-[46rem]" dangerouslySetInnerHTML={{__html: heading}} />
          ))}

        {subtext && (
          <div className="mx-auto max-w-[46rem] [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <div
              dangerouslySetInnerHTML={{__html: subtext}}
            />
          </div>
        )}

        {buttons?.length > 0 && (
          <ul className="mb-0 mt-4 flex list-none flex-col justify-center gap-4 p-0 xs:flex-row">
            {buttons.slice(0, 2).map(({ link, style }, index) => {
              return (
                link?.url &&
                link?.text && (
                  <li key={index}>
                    <Link
                      aria-label={link?.text}
                      className={`${
                        style !== 'button--underline' ? 'button ' : ''
                      }${style} ${
                        (section?.bgColor === 'var(--white)' ||
                          section?.bgColor === 'transparent') &&
                        style === 'button--light-outline'
                          ? 'text-black'
                          : ''
                      } text-center max-md:px-6`}
                      href={link?.url}
                      newTab={link?.newTab}
                    >
                      {link?.text}
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

TextBlock.displayName = 'TextBlock';
TextBlock.Schema = Schema;
