import { useMemo } from 'react';
import { Schema } from './KickflipCustomizer.schema';
import { Customize } from '@/snippets';

export function KickflipCustomizer({ cms }) {
  const { product, section, customizerProduct } = cms;
  const moduleID = `${Schema({ product })?.key}-${
    cms.id || cms.cmsId || cms.tinaId
  }`;

  const customizer = useMemo(() => {
    if (!customizerProduct?.length) return null;
    return customizerProduct.find((item) => {
      return item?.productEnable?.handle === product?.handle;
    });
  }, [customizerProduct]);

  if (!customizer || !customizer?.startingPointId) return null;

  return (
    <div id={moduleID}>
      <Customize product={product} customizer={customizer} cms={cms} />
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
    </div>
  );
}

KickflipCustomizer.displayName = 'Kickflip Customizer';
KickflipCustomizer.Schema = Schema;
