import { useCallback, useState } from 'react';
import { useCustomer, useSettings } from '@backpackjs/storefront';

import { useGlobalContext } from '../../contexts';
import { Svg } from '../Svg';
import { Spinner } from '../Spinner';

export function BackInStockModal({ selectedVariant }) {
  const customer = useCustomer();
  const settings = useSettings();
  const {
    actions: { closeModal },
  } = useGlobalContext();

  const [email, setEmail] = useState(customer?.email || '');
  const { heading, subtext, submitText } = {
    ...settings?.product?.backInStock,
  };
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState(null);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();
    setSubmitting(true);
    // back in stock integration here
    const endpoint = '/api/klaviyo';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'postBackInStock',
        email: e.target.querySelector('[name="email"]').value,
        variant: selectedVariant.legacyResourceId
      })
    };

    const response = await fetch(endpoint, options);
    const body = await response.json();
    setResult(body);
    setSubmitting(false);
    setTimeout(() => {
      if (body?.success) {
        closeModal();
      } else {
        setResult(null);
      }
    }, 3000);
  }, []);

  return (
    <div className="flex flex-col items-center gap-8 text-center">
      <div>
        <h2 className="text-title-h3 uppercase mb-4 md:mb-6">{heading}</h2>
        {subtext && <p className="mt-2">{subtext}</p>}
      </div>

      <div>
        <h5 className="uppercase mb-2">{selectedVariant.product.title}</h5>
        <p>{selectedVariant.title}</p>
      </div>

      <form
        className="flex w-full flex-col items-center"
        onSubmit={handleSubmit}
      >
        <input
          className="input-text text-text md:max-w-[30rem]"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email..."
          required
          type="email"
          value={email}
        />

        {!result &&
          <button
            aria-label="Notify Me"
            className="button button--orion mt-4 max-md:w-full min-w-[10rem]"
            type="submit"
            disabled={submitting}
          >
            {submitting ? (
              <Spinner className="mx-auto" width="20" />
            ) : (
              <>{submitText}</>
            )}
          </button>
        }
        {result &&
          <div className='mt-4 md:max-w-[30rem] flex items-start justify-center text-left mx-auto'>
            {result?.success && <>
              <Svg
                className={`w-6 text-success mr-2`}
                src="/svgs/checkmark.svg#checkmark"
                viewBox="0 0 24 24"
              />
              <p className='text-success mb-0'>You're in! We'll let you know when it's back.</p>
            </>}
            {result?.success === false && <>
              <Svg
                className={`w-6 mr-2`}
                src="/svgs/red-error.svg#red-error"
                viewBox="0 0 14 16"
              />
              <p className='text-error mb-0'>{result?.errors?.map(error => <>{error}<br/></>)}</p>
            </>}
            {result?.status == 400 && <>
              <Svg
                className={`w-6 mr-2`}
                src="/svgs/red-error.svg#red-error"
                viewBox="0 0 14 16"
              />
              <p className='text-error mb-0'>{result?.message}</p>
            </>}
          </div>
        }
      </form>
    </div>
  );
}

BackInStockModal.displayName = 'BackInStockModal';
