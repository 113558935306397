import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useCountdown } from '../../hooks';

function DateTimeDisplay({ value, type, isDanger, className }) {
  return (
    <div
      className={`${
        isDanger ? 'countdown danger' : 'countdown'
      } flex flex-col ${className}`}
    >
      {value && <span>{value < 10 && value > 0 ? `0${value}` : value}</span>}
      <span className="text-[5px] leading-none">{type}</span>
    </div>
  );
}

function ExpiredNotice() {
  return null;
}

function ShowCountDown({ days, hours, minutes, seconds, clockColor }) {
  const [second, setSecond] = useState(null);
  useEffect(() => {
    setSecond(seconds);
  }, [seconds]);
  return (
    <div
      className="coundown-timer flex items-center justify-center gap-2 font-bold uppercase max-lg:pt-1 lg:pl-4"
      style={{ color: clockColor || 'var(--white)' }}
    >
      <DateTimeDisplay
        value={days}
        type="Days"
        isDanger={days <= 3}
        className="border-r border-currentColor pr-2"
      />
      <DateTimeDisplay
        value={hours}
        type="Hrs"
        isDanger={false}
        className="border-r border-currentColor pr-2"
      />
      <DateTimeDisplay
        value={minutes}
        type="Min"
        isDanger={false}
        className="border-r border-currentColor pr-2"
      />
      <DateTimeDisplay
        value={second}
        type="Sec"
        isDanger={false}
        className=""
      />
    </div>
  );
}

export function CountdownTimer({ countdown }) {
  const { clockColor, targetTime, timezone } = countdown || {};
  const targetDate = targetTime;
  const targetDateZone = DateTime.fromFormat(
    `${targetDate} ${timezone || 'America/Los_Angeles'}`,
    'MM-dd-yyyy tt z'
  );
  const [days, hours, minutes, seconds] = useCountdown(targetDateZone);
  if (!targetDateZone.isValid) return null;

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  }
  return (
    <ShowCountDown
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      clockColor={clockColor}
    />
  );
}
