export default {
  label: 'Free Product Scenarios',
  name: 'freeProducts',
  component: 'group',
  fields: [
    {
      label: 'Gift Type',
      name: 'giftType',
      component: 'select',
      description:
        'Select the type of gift you would like to offer. "Spend X Get Y".',
      options: [
        {
          label: 'None (disabled)',
          value: 'disabled',
        },
        {
          label: 'Spend X Get Y',
          value: 'gwp',
        },
      ],
    },
    {
      label: 'GWP (Spend X Get Y) Settings',
      name: 'gwpSettings',
      component: 'group',
      fields: [
        {
          name: 'gwpProduct',
          label: 'Gift With Purchase Product',
          component: 'productSearch',
        },
        {
          label: 'Cart Spend To Qualify For Gift (USD)',
          name: 'gwpCartMinimum',
          component: 'number',
        },
        {
          component: 'text',
          name: 'discountDescription',
          label: 'Discount Description',
          defaultValue: 'Spent $100, get free Old Glory Hat',
        },
        {
          component: 'text',
          name: 'code',
          label: 'Discount Code',
          defaultValue: 'TREAT',
        },
        {
          label: 'Add item to cart label',
          name: 'gwpAddBack',
          component: 'text',
          default: 'Add free gift',
          description:
            'Label for the button that the customer uses to add the items back to their cart if they have opted out',
        },
      ],
    },
  ],
};
