'use client';

import Script from 'next/script';

export function OpensendTracking() {
  return (
    <Script
      strategy="beforeInteractive"
      id="opensend-tracking"
      src="https://cdn.aggle.net/oir/oir.min.js"
      oirtyp="6311ae17"
      oirid="46F03B55"
      onReady={() => {
        !(function (e) {
          const t = {};
          (e._oirtrk = e._oirtrk || []).push(['track', 'on-site', t]);
        })(window);
      }}
    />
  );
}
