import { useMemo } from 'react';
import equal from 'fast-deep-equal';

export function UpsellOptionValue({
  inventory,
  isLoading,
  isSelected,
  name,
  product,
  selectedOptions,
  setSelectedOptions,
  value,
}) {
  const newSelectedOptions = useMemo(() => {
    return selectedOptions
      ? {
          ...selectedOptions,
          [name]: value,
        }
      : null;
  }, [name, selectedOptions, value]);

  const variantFromOptionValue = useMemo(() => {
    if (!newSelectedOptions) return null;
    return product.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
  }, [newSelectedOptions, product.variants]);

  const optionValueIsAvailable =
    inventory?.variants?.[variantFromOptionValue?.id]?.availableForSale ||
    false;
  const disabled = isLoading;

  return (
    <button
      aria-label={value}
      className={`h-10 min-w-[2.5rem] rounded-md- border px-3 text-sh-upper-sm font-bold uppercase transition hover:text-black ${
        !disabled ? 'md:hover:border-darkOrion' : ''
      } ${
        isSelected
          ? 'border-darkOrion bg-grey1 text-black'
          : 'border-grey2 text-grey2'
      } ${!optionValueIsAvailable ? 'hidden' : ''}`}
      disabled={disabled}
      onClick={() => {
        if (isSelected) return;
        setSelectedOptions(newSelectedOptions);
      }}
      style={
        !optionValueIsAvailable && !disabled
          ? {
              background:
                'linear-gradient(to top left, transparent 0%, transparent calc(50% - 1px), currentColor 50%, transparent calc(50% + 1px), transparent 100%)',
            }
          : {}
      }
      type="button"
    >
      <span
        className={`${
          optionValueIsAvailable && !disabled ? 'text-black' : 'text-grey4'
        }`}
      >
        {value}
      </span>
    </button>
  );
}

UpsellOptionValue.displayName = 'UpsellOptionValue';
