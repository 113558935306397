import { Image } from '../../snippets';

export function FiftyFiftyMedia({ media }) {
  const { image } = { ...media };
  return (
    <div className="absolute inset-0 h-full w-full">
      <div className="relative h-full w-full overflow-hidden md:hidden">
        {image?.imageMobile?.src && (
          <Image
            alt={image.alt}
            className={`${image.positionMobile}`}
            fill
            sizes="100vw"
            src={image.imageMobile.src}
          />
        )}
      </div>

      <div className="relative hidden h-full w-full overflow-hidden md:block">
        {image?.imageDesktop?.src && (
          <Image
            alt={image.alt}
            className={`${image.positionDesktop}`}
            fill
            sizes="50vw"
            src={image.imageDesktop.src}
          />
        )}
      </div>
    </div>
  );
}

FiftyFiftyMedia.displayName = 'FiftyFiftyMedia';
