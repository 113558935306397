import { useState, useMemo, useCallback } from 'react';
import {
  useCart,
  useCartAddDiscount,
  useSettings,
  useProductByHandle,
  useCartAddItem,
  useCartRemoveItem,
} from '@backpackjs/storefront';
import Cookies from 'js-cookie';

export const useGwp = () => {
  const cart = useCart();
  const siteSettings = useSettings();
  const { cartAddItem } = useCartAddItem();
  const { cartAddDiscount } = useCartAddDiscount();
  const { cartRemoveItem } = useCartRemoveItem();
  const [isRemovingItem, setIsRemovingItem] = useState(false);

  const giftType = siteSettings?.freeProducts?.giftType || 'disabled';
  const { gwpProduct, gwpCartMinimum, discountDescription, code, gwpAddBack } =
    {
      ...siteSettings?.freeProducts?.[`${giftType}Settings`],
    };
  const { product } = useProductByHandle({ handle: gwpProduct?.handle });

  const gwpLine = useMemo(() => {
    return cart?.lines.find((line) => {
      const hasGiftProperty = line?.attributes?.find(
        (attr) => attr?.key === '_gwp' && attr?.value === 'true'
      );
      return (
        hasGiftProperty && line?.variant?.product?.handle === product?.handle
      );
    });
  }, [cart?.lines, product?.handle]);

  const cartTotal = useMemo(() => {
    if (gwpLine?.id && cart?.estimatedCost?.subtotalAmount?.amount) {
      return (
        Number(cart.estimatedCost.subtotalAmount.amount) -
        Number(gwpLine.estimatedCost.totalAmount.amount)
      );
    }
    return parseFloat(cart?.estimatedCost?.subtotalAmount?.amount || 0);
  }, [product?.handle, gwpLine?.id, cart?.lines]);

  const addGwp = useCallback(async () => {
    if (product?.variants?.[0]?.id) {
      await cartAddItem({
        merchandiseId: product?.variants?.[0]?.id,
        quantity: 1,
        attributes: [{ key: '_gwp', value: 'true' }],
      });
    }
    if (
      code &&
      !cart?.discountCodes?.some((discountCode) => discountCode.code === code)
    ) {
      await cartAddDiscount({ discountCode: code });
    }
  }, [product?.handle]);

  const removeGwp = useCallback(async () => {
    setIsRemovingItem(true);
    if (gwpLine?.id) {
      await cartRemoveItem({ lineId: gwpLine?.id });
    }
    setIsRemovingItem(false);
  }, [gwpLine?.id]);

  const qualifiedFreeGift = useMemo(() => {
    if (
      product &&
      giftType === 'gwp' &&
      gwpCartMinimum &&
      cartTotal >= gwpCartMinimum
    ) {
      return true;
    }
    return false;
  }, [siteSettings?.freeProducts, product?.handle, cart?.lines]);

  const gwpPrice = useMemo(() => {
    if (!gwpLine?.id) {
      return {
        price: null,
        compareAtPrice: null,
      };
    }
    const { discountAllocations, estimatedCost, quantity, variant } = gwpLine;
    const totalAmount = parseFloat(estimatedCost?.totalAmount?.amount || '0');

    // compare at price from variant
    const variantAmount = parseFloat(variant?.priceV2?.amount || '0');
    const variantCompareAtAmount = parseFloat(
      variant?.compareAtPriceV2?.amount || '0'
    );
    const compareAtPriceFromVariant =
      variantCompareAtAmount > variantAmount ? variantCompareAtAmount : 0;

    // compare at price from discount
    const subtotalAmount = parseFloat(
      estimatedCost?.subtotalAmount?.amount || '0'
    );
    const totalDiscount = parseFloat(
      discountAllocations?.[0]?.discountedAmount?.amount || '0'
    );
    const compareAtPriceFromDiscount = totalDiscount
      ? subtotalAmount / quantity
      : 0;

    // prices
    const price = totalAmount / quantity;
    const compareAtPrice =
      compareAtPriceFromVariant || compareAtPriceFromDiscount;

    return {
      price: `$${price.toFixed(2).replace(/.00/, '')}`,
      compareAtPrice: compareAtPrice
        ? `$${compareAtPrice.toFixed(2).replace(/.00/, '')}`
        : null,
    };
  }, [!!gwpLine?.id]);

  return {
    gwpLine,
    gwpPrice,
    gwpProductToAdd: product,
    qualifiedFreeGift,
    gwpInCart: !!gwpLine?.id,
    discountDescription,
    addGwp,
    removeGwp,
    isRemovingItem,
    gwpAddBack,
  };
};
