export function Schema({ product }) {
  if (!product) return null;

  return {
    category: 'Product',
    label: 'Product Description',
    key: 'product-description',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/product-description-preview.jpg?v=1682969041',
    fields: [
      {
        label: 'Product Description Heading',
        name: 'headingText',
        component: 'text',
        defaultValue: 'Our #1 Selling Hat',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}
