export function Schema({ page }) {
  if (page?.handle !== 'search') return null;

  return {
    category: 'Search',
    label: 'Search Results',
    key: 'search-results',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/search-results-preview.jpg?v=1675732833',
    fields: [
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}
