import { useCallback } from 'react';
import { useStartFinishErrors } from '@backpackjs/storefront';

export const useOrder = () => {
  const {
    setStarted,
    setFinished,
    setSuccess,
    errors,
    setErrors,
    reset,
    ...status
  } = useStartFinishErrors();

  const getOrder = useCallback(async ({ orderId }) => {
    try {
      reset();
      setStarted(true);
      const url = `/api/order`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getOrder',
          orderId,
        }),
      };
      const response = await fetch(url, options);

      if (!response.ok) {
        setErrors(['Error with REST API']);
        console.error(`Response back from API not successful:`, response);
        return null;
      }
      const body = await response.json();

      if (body?.errors) {
        setErrors([body.error]);
        return body;
      }

      setSuccess(true);
      setFinished(true);
      return body;
    } catch (error) {
      setErrors(['Error with REST API.']);
      console.error(`Error with REST API: ${error}`);
    }
    return {
      error: 'Unknown',
    };
  }, []);

  const refundCalculates = useCallback(async ({ orderId, refund }) => {
    if (!orderId || !refund) {
      return null;
    }

    try {
      reset();
      setStarted(true);
      const url = `/api/order`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'refundCalculates',
          orderId,
          refund,
        }),
      };
      const response = await fetch(url, options);

      if (!response.ok) {
        setErrors(['Error with REST API']);
        console.error(`Response back from API not successful:`, response);
        return null;
      }
      const body = await response.json();

      if (body?.error) {
        setErrors([body.error]);
        return body;
      }

      setSuccess(true);
      setFinished(true);
      return body;
    } catch (error) {
      setErrors(['Error with REST API.']);
      console.error(`Error with REST API: ${error}`);
    }
    return {
      error: 'Unknown',
    };
  }, []);

  const cancelOrder = useCallback(async ({ orderId, data }) => {
    if (!orderId) {
      return null;
    }

    try {
      reset();
      setStarted(true);
      const url = `/api/order`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'cancelOrder',
          orderId,
          data,
        }),
      };
      const response = await fetch(url, options);

      if (!response.ok) {
        setErrors(['Error with REST API']);
        console.error(`Response back from API not successful:`, response);
        return null;
      }
      const body = await response.json();

      if (body?.error) {
        setErrors([body.error]);
        return body;
      }

      setSuccess(true);
      setFinished(true);
      return body;
    } catch (error) {
      setErrors(['Error with REST API.']);
      console.error(`Error with REST API: ${error}`);
    }
    return {
      error: 'Unknown',
    };
  }, []);

  const updateOrder = useCallback(async ({ orderId, data }) => {
    if (!orderId || !data) {
      return null;
    }

    try {
      reset();
      setStarted(true);
      const url = `/api/order`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'updateOrder',
          orderId,
          data,
        }),
      };
      const response = await fetch(url, options);

      if (!response.ok) {
        setErrors(['Error with REST API']);
        console.error(`Response back from API not successful:`, response);
        return null;
      }
      const body = await response.json();

      if (body?.errors) {
        setErrors([body?.errors]);
        return null;
      }

      setSuccess(true);
      setFinished(true);
      return body;
    } catch (error) {
      setErrors(['Error with REST API.']);
      console.error(`Error with REST API: ${error}`);
    }
    return {
      error: 'Unknown',
    };
  }, []);

  return {
    getOrder,
    refundCalculates,
    cancelOrder,
    updateOrder,
    errors,
    setErrors,
    reset,
    ...status,
  };
};
