import { useMemo } from 'react';

import { useRouter } from 'next/router';
import { Link } from '../../snippets';

export function HeroContent({ aboveTheFold, isFirstSlide, slide }) {
  const router = useRouter();
  const { button, content, text } = slide;
  const { color, colorMobile, heading, subheading, superheading } = { ...text };
  const {
    alignmentMobile,
    alignmentDesktop,
    darkOverlay,
    bgColor,
    bgColorMobile,
    maxWidthMobile,
    maxWidthDesktop,
    positionMobile,
    positionDesktop,
  } = {
    ...content,
  };
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
  const positionClasses = `${positionMobile} ${positionDesktop}`;
  const maxWidthContentClasses = `${maxWidthMobile} ${maxWidthDesktop}`;
  const darkOverlayClass = darkOverlay ? 'content-bg-overlay-active' : '';

  const userSettings = useMemo(() => {
    const obj = {};
    switch (color) {
      case 'var(--black)':
        obj.desktopTextColor = 'lg:text-black';
        obj.desktopSuperheadingColor = 'lg:text-darkLava';
        break;
      case 'var(--white)':
        obj.desktopTextColor = 'lg:text-white';
        obj.desktopSuperheadingColor =
          bgColor === 'var(--med-desert)'
            ? 'lg:text-white'
            : 'lg:text-medDesert';
        break;
      default:
        obj.desktopTextColor = 'lg:text-white';
        obj.desktopSuperheadingColor = 'lg:text-white';
    }

    switch (colorMobile) {
      case 'var(--black)':
        obj.mobileTextColor = 'max-lg:text-black';
        obj.mobileSuperheadingColor = 'max-lg:text-darkLava';
        break;
      case 'var(--white)':
        obj.mobileTextColor = 'max-lg:text-white';
        obj.mobileSuperheadingColor =
          bgColorMobile === 'var(--med-desert)'
            ? 'max-lg:text-white'
            : 'max-lg:text-medDesert';
        break;
      default:
        obj.mobileTextColor = 'max-lg:text-white';
        obj.mobileSuperheadingColor =
          bgColorMobile === 'var(--med-desert)'
            ? 'max-lg:text-white'
            : 'max-lg:text-medDesert';
    }
    switch (bgColor) {
      case 'var(--dark-loden)':
        obj.overlayBg = 'lg:bg-darkLoden';
        obj.desktopBgOverlay = 'bg-darkLoden';
        break;
      case 'var(--dark-orion)':
        obj.overlayBg = 'lg:bg-darkOrion';
        obj.desktopBgOverlay = 'bg-darkOrion';
        break;
      case 'var(--orion)':
        obj.overlayBg = 'lg:bg-orion';
        obj.desktopBgOverlay = 'bg-orion';
        break;
      case 'var(--med-desert)':
        obj.overlayBg = 'lg:bg-medDesert';
        obj.desktopBgOverlay = 'bg-medDesert';
        break;
      default:
        obj.overlayBg = 'lg:bg-transparent';
        obj.desktopBgOverlay = 'lg:bg-transparent';
    }
    switch (bgColorMobile) {
      case 'var(--dark-loden)':
        obj.overlayBgMobile = 'bg-darkLoden';
        break;
      case 'var(--dark-orion)':
        obj.overlayBgMobile = 'bg-darkOrion';
        break;
      case 'var(--orion)':
        obj.overlayBgMobile = 'bg-orion';
        break;
      case 'var(--med-desert)':
        obj.overlayBgMobile = 'bg-medDesert';
        break;
      default:
        obj.overlayBgMobile = 'bg-transparent';
    }
    switch (positionDesktop) {
      case 'md:justify-start md:items-start':
        obj.desktopInnerPosition = 'md:justify-start';
        break;
      case 'md:justify-start md:items-center':
        obj.desktopInnerPosition = 'md:!items-start md:justify-center';
        break;
      case 'md:justify-start md:items-end':
        obj.desktopInnerPosition = 'md:!items-start md:justify-end';
        break;
      case 'md:justify-center md:items-start':
        obj.desktopInnerPosition = 'md:!items-start md:justify-start';
        break;
      case 'md:justify-center md:items-center':
        obj.desktopInnerPosition = 'md:!items-start md:justify-center';
        break;
      case 'md:justify-center md:items-end':
        obj.desktopInnerPosition = 'md:!items-start md:!justify-end';
        break;
      case 'md:justify-end md:items-start':
        obj.desktopInnerPosition = 'md:!items-start md:!justify-start';
        break;
      case 'md:justify-end md:items-center':
        obj.desktopInnerPosition = 'md:!items-start md:justify-center';
        break;
      case 'md:justify-end md:items-end':
        obj.desktopInnerPosition = 'md:!items-start md:justify-end';
        break;
      default:
        obj.desktopInnerPosition = 'md:justify-start';
    }
    switch (positionMobile) {
      case 'justify-start items-start':
        obj.mobileInnerPosition = 'justify-start';
        break;
      case 'justify-start items-center':
        obj.mobileInnerPosition = '!items-start justify-center';
        break;
      case 'justify-start items-end':
        obj.mobileInnerPosition = '!items-start justify-end';
        break;
      case 'justify-center items-start':
        obj.mobileInnerPosition = '!items-start justify-start';
        break;
      case 'justify-center items-center':
        obj.mobileInnerPosition = '!items-start justify-center';
        break;
      case 'justify-center items-end':
        obj.mobileInnerPosition = '!items-start !justify-end';
        break;
      case 'justify-end items-start':
        obj.mobileInnerPosition = '!items-start !justify-start';
        break;
      case 'justify-end items-center':
        obj.mobileInnerPosition = '!items-start justify-center';
        break;
      case 'justify-end items-end':
        obj.mobileInnerPosition = '!items-start justify-end';
        break;
      default:
        obj.mobileInnerPosition = 'justify-center';
    }

    return obj;
  }, [
    color,
    colorMobile,
    bgColor,
    bgColorMobile,
    positionDesktop,
    positionMobile,
  ]);

  const {
    desktopTextColor,
    mobileTextColor,
    desktopSuperheadingColor,
    mobileSuperheadingColor,
    overlayBg,
    desktopBgOverlay,
    overlayBgMobile,
    desktopInnerPosition,
    mobileInnerPosition,
  } = userSettings;

  return (
    <>
      <div
        className={`hero-text-content-container pointer-events-none absolute inset-0 h-full w-full ${overlayBgMobile} md:bg-transparent`}
      >
        <div
          className={`mx-auto flex h-full w-full max-w-[123rem] items-center justify-center overflow-hidden px-4 md:px-6 ${positionDesktop}`}
        >
          <div
            className={`hero-inner-text-content-container z-[3] flex h-full flex-col p-4 md:p-8 xl:p-12 ${positionClasses} ${mobileInnerPosition} ${desktopInnerPosition} ${overlayBg} desktop-${desktopBgOverlay} ${darkOverlayClass} ${alignmentClasses} ${maxWidthContentClasses} ${mobileTextColor} ${desktopTextColor}`}
          >
            {superheading && (
              <p
                className={`text-superheading text-sh-upper-sm font-semibold uppercase md:text-sh-upper lg:text-sh-upper-lg ${desktopSuperheadingColor} ${mobileSuperheadingColor} mb-4`}
              >
                {superheading}
              </p>
            )}

            {aboveTheFold && isFirstSlide ? (
              <h1
                className="mb-0 font-sans uppercase"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            ) : (
              <h2
                className="mb-0 font-sans uppercase"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            )}

            {subheading && (
              <p
                className="mb-6 mt-4 font-sans"
                dangerouslySetInnerHTML={{ __html: subheading }}
              />
            )}

            {button?.buttons?.length > 0 && (
              <ul className="hero-buttons-container mb-0 mt-0 flex flex-col justify-center pl-0">
                {button?.buttons?.slice(0, 2).map(({ link, style }, index) => {
                  return (
                    link?.url &&
                    link?.text && (
                      <li
                        key={`hero-cta-${index}`}
                        className="mb-6 mt-0 list-none"
                      >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        {button.clickableSlide &&
                        !button.buttons?.[0]?.link?.url?.includes(
                          '#hsForm_'
                        ) ? (
                          <button
                            aria-label={link?.text}
                            className={`hero-cta-link !pointer-events-auto inline cursor-pointer ${style}`}
                            type="button"
                            onClick={() => {
                              if (button.buttons?.[0]?.link?.url) {
                                router.push(button.buttons?.[0]?.link?.url);
                              }
                            }}
                          >
                            {link?.text}
                          </button>
                        ) : (
                          <div>
                            <Link
                              aria-label={link?.text}
                              className={`hero-cta-link !pointer-events-auto inline-block cursor-pointer ${style}`}
                              href={link?.url}
                              newTab={link?.newTab}
                            >
                              <span>{link?.text}</span>
                            </Link>
                          </div>
                        )}
                      </li>
                    )
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
      {button?.clickableSlide && button.buttons?.[0]?.link?.url && (
        <Link
          aria-label={button.buttons?.[0]?.link?.text}
          className="pointer-events-auto absolute inset-0 z-[1] h-full w-full"
          href={button.buttons?.[0]?.link?.url}
        />
      )}
    </>
  );
}

HeroContent.displayName = 'HeroContent';
