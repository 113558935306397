import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Mousewheel, Scrollbar, Manipulation } from 'swiper';

import { Spinner, Link, Markdown } from '../../snippets';
import { VisualCategoriesTile } from './VisualCategoriesTile';
import { Schema } from './VisualCategories.schema';

export function VisualCategories({ cms }) {
  const {
    content,
    heading,
    headingSize,
    headingSizeM,
    headingAlign,
    description,
    section,
    tiles,
  } = cms;

  const [swiper, setSwiper] = useState(null);

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  let descClasses = '';
  if (headingAlign === 'text-left') {
    descClasses = 'ml-0';
  } else if (headingAlign === 'text-right') {
    descClasses = 'mr-0';
  }

  return (
    <div
      className="visual-categories"
      data-client-id={cms.id || cms.cmsId || cms.tinaId || cms.clientId}
      id={moduleID}
    >
      <style>{`
        #${moduleID}{ margin-bottom: ${section.mobilePadding || 0}px; }
        @media(min-width: 1024px) {
          #${moduleID}{ margin-bottom: ${section.desktopPadding || 0}px; }
        }
        ${
          headingSizeM
            ? `@media(max-width: 768px) {
            #${moduleID} .heading{ font-size: ${headingSizeM}px; }
          }`
            : ''
        }
        ${
          headingSize
            ? `@media(min-width: 769px) {
          #${moduleID} .heading{ font-size: ${headingSize}px; }
        }`
            : ''
        }
      `}</style>
      <div className="lg-px-contained">
        <div className={`mx-auto ${maxWidthClass}`}>
          {(heading || description) && (
            <div className="mb-5 px-4 lg:px-0">
              {heading && (
                <h2
                  className={`heading h4 mb-2 uppercase ${headingAlign}`}
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              )}
              {description && (
                <div
                  className={`mx-auto my-6 max-w-[51.5rem] text-grey5 xl:mb-8 [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base ${descClasses} ${headingAlign}`}
                >
                  <Markdown>{description}</Markdown>
                </div>
              )}
            </div>
          )}

          {tiles?.length > 0 && (
            <>
              {/* mobile/tablet */}
              <div
                className={`relative xl:hidden ${
                  !swiper ? 'min-h-[25rem]' : ''
                }`}
              >
                <Swiper
                  grabCursor
                  onSwiper={setSwiper}
                  modules={[A11y, Scrollbar, Mousewheel, Manipulation]}
                  navigation={false}
                  scrollbar={{
                    hide: false,
                    draggable: true,
                  }}
                  slidesOffsetAfter={16}
                  slidesOffsetBefore={16}
                  slidesPerView={2.4}
                  spaceBetween={6}
                  breakpoints={{
                    768: {
                      slidesPerView: tiles.length < 3 ? 1.4 : 3.4,
                      spaceBetween: 6,
                    },
                  }}
                  className="pb-4"
                >
                  {swiper &&
                    tiles.slice(0, 8).map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <VisualCategoriesTile
                            content={content}
                            item={item}
                            extraClass="aspect-[16/13]"
                            mobile
                          />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>

                {!swiper && (
                  <Spinner
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                    width="32"
                  />
                )}
              </div>

              {/* desktop */}
              <div
                className={`hidden ${
                  tiles?.length > 5
                    ? 'grid-cols-[repeat(auto-fit,_minmax(0,_1fr))] xl:grid'
                    : 'justify-center xl:flex'
                } gap-2`}
              >
                {tiles.slice(0, 8).map((item, index) => {
                  return (
                    <div
                      className={`relative${tiles?.length > 5 ? '' : ' w-1/5'}`}
                      key={index}
                    >
                      <VisualCategoriesTile
                        content={content}
                        item={item}
                        extraClass="aspect-[16/13]"
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

VisualCategories.displayName = 'Visual Categories';
VisualCategories.Schema = Schema;
