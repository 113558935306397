import { useRef } from 'react';

import { Badges } from '../../Badges';
import { Image } from '../../Image';
import { ProductItemVideo } from './ProductItemVideo';
import { Spinner } from '../../Spinner';
import { useProductItemMedia } from './useProductItemMedia';
import { QuickShop } from '../QuickShop';

export function ProductItemMedia({
  selectedProduct,
  selectedVariant,
  enabledQuickShop,
  enabledColorSelector,
}) {
  const hoverVideoRef = useRef(null);

  const { primaryMedia, hoverMedia } = useProductItemMedia({
    selectedProduct,
    selectedVariant,
  });

  const alt = selectedVariant?.product?.title || selectedProduct?.title;

  return (
    <div
      className="group/media relative aspect-[var(--product-image-aspect-ratio)] bg-grey1"
      onMouseEnter={() => {
        if (hoverMedia?.mediaContentType !== 'VIDEO') return;
        hoverVideoRef.current.play();
      }}
      onMouseLeave={() => {
        if (hoverMedia?.mediaContentType !== 'VIDEO') return;
        hoverVideoRef.current.pause();
      }}
    >
      {/* media w/o hover element */}
      {primaryMedia &&
        !hoverMedia &&
        (primaryMedia.mediaContentType === 'VIDEO' ? (
          <ProductItemVideo autoPlay media={primaryMedia} />
        ) : (
          <Image
            alt={alt}
            fill
            sizes="(min-width: 768px) 33vw, 50vw"
            src={primaryMedia.image.src}
            srcSetSizes={[480, 960]}
          />
        ))}

      {/* media w/ hover element */}
      {primaryMedia && hoverMedia && (
        <>
          <div className="opacity-100 transition duration-300 md:group-hover/media:opacity-0">
            {primaryMedia.mediaContentType === 'VIDEO' ? (
              <ProductItemVideo autoPlay media={primaryMedia} />
            ) : (
              <Image
                alt={alt}
                fill
                sizes="(min-width: 768px) 33vw, 50vw"
                src={primaryMedia.image.src}
                srcSetSizes={[480, 960]}
              />
            )}
          </div>

          <div className="hidden opacity-0 transition duration-300 md:block md:group-hover/media:opacity-100">
            {hoverMedia.mediaContentType === 'VIDEO' ? (
              <ProductItemVideo
                autoPlay={false}
                media={hoverMedia}
                ref={hoverVideoRef}
              />
            ) : (
              <Image
                alt={alt}
                fill
                sizes="(min-width: 768px) 33vw, 50vw"
                src={hoverMedia.image.src}
                srcSetSizes={[480, 960]}
              />
            )}
          </div>
        </>
      )}

      {/* loader */}
      {!primaryMedia && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-gray">
          <Spinner width="20" />
        </div>
      )}

      <div className="pointer-events-none absolute left-1 top-1 z-[1] md:left-2 md:top-2">
        <Badges
          selectedVariant={selectedVariant}
          className="gap-2 !text-[11px] text-sh-sm font-bold xs:gap-2 [&_div]:rounded-none"
          isDraft={selectedProduct?.status === 'DRAFT'}
          tags={selectedVariant?.product?.tags}
        />
      </div>

      {enabledQuickShop && (
        <QuickShop
          enabledColorSelector={enabledColorSelector}
          selectedProduct={selectedProduct}
          selectedVariant={selectedVariant}
        />
      )}
    </div>
  );
}

ProductItemMedia.displayName = 'ProductItemMedia';
