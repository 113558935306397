import { useRef, useEffect } from 'react';
import { Svg } from '../Svg';
import { Link } from '../Link';

export function CartHeader({ closeCart, heading, cartItemsLength, cartOpen }) {
  const closeBtnRef = useRef(null);
  useEffect(() => {
    if (cartOpen && closeBtnRef?.current) {
      closeBtnRef?.current.focus();
    }
    return () => {
      if (!cartOpen && closeBtnRef?.current) {
        closeBtnRef?.current.blur();
      }
    };
  }, []);

  return (
    <div className="relative flex items-center border-b border-b-black p-4 md:p-6">
      <h5
        className={`mb-0 px-4 text-center uppercase text-darkOrion md:pl-0 ${
          cartItemsLength ? 'md:text-left' : ''
        }`}
      >
        {heading || 'Cart'}
      </h5>
      <Link
        aria-label="Go to cart page"
        className={`focus-link hidden text-sm ${
          cartItemsLength ? 'md:block' : ''
        }`}
        href="/cart"
        onClick={closeCart}
      >
        View Full Cart
      </Link>

      <button
        aria-label="Close Side cart"
        className="absolute right-4 top-1/2 -translate-y-1/2 transform md:right-6"
        onClick={closeCart}
        type="button"
        ref={closeBtnRef}
      >
        <Svg
          className="w-6 text-black"
          src="/svgs/close.svg#close"
          title="Close"
          viewBox="0 0 24 24"
        />
      </button>
    </div>
  );
}

CartHeader.displayName = 'CartHeader';
