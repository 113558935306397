import { useMemo } from 'react';
import equal from 'fast-deep-equal';
import { useSettings } from '@backpackjs/storefront';

import { Image, Link } from '@/snippets';

function ColorOptionValue({
  disabled,
  isSelected,
  swatch,
  value,
  optionValueIsAvailable,
}) {
  return (
    <div
      className={`relative flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border transition hover:text-black md:hover:border-darkOrion ${
        isSelected ? 'border-darkOrion text-black' : 'border-grey2 text-grey2'
      }`}
      style={
        !optionValueIsAvailable && !disabled
          ? {
              background:
                'linear-gradient(to top left, transparent 0%, transparent calc(50% - 1px), currentColor 50%, transparent calc(50% + 1px), transparent 100%)',
            }
          : {}
      }
    >
      <div
        className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-full border-4 border-white transition-[border-width] duration-100 ${
          isSelected ? '' : ''
        }`}
        style={{
          backgroundColor:
            isSelected && swatch?.color === '#FFFFFF'
              ? 'var(--off-white)'
              : swatch?.color,
        }}
      >
        {swatch?.image?.src && (
          <Image
            alt={swatch?.imageAlt || value}
            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-full object-cover"
            height="32"
            src={swatch.image.src}
            width="32"
          />
        )}
      </div>
    </div>
  );
}

export function UpsellColorOptionValue({
  groupingProductsMapByColor,
  inventory,
  isLoading,
  isSelected,
  name,
  product,
  selectedOptions,
  setSelectedOptions,
  value,
}) {
  const settings = useSettings();
  const swatches = settings?.product?.colors?.swatches;
  const isFromGrouping = product.grouping?.isTransformed;

  const newSelectedOptions = useMemo(() => {
    return selectedOptions
      ? {
          ...selectedOptions,
          [name]: value,
        }
      : null;
  }, [name, selectedOptions, value]);

  const swatch = useMemo(() => {
    if (!swatches) return null;
    return (
      swatches.find(
        ({ name: swatchName }) =>
          swatchName?.trim().toLowerCase() === value.toLowerCase()
      ) || null
    );
  }, [swatches, value]);

  const url = useMemo(() => {
    if (!isFromGrouping || !newSelectedOptions || !groupingProductsMapByColor)
      return null;

    const selectedVariantFromOptions = groupingProductsMapByColor[
      newSelectedOptions.Color
    ]?.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
    if (!selectedVariantFromOptions) return null;

    let params = '';
    if (typeof window !== 'undefined') {
      const { search } = window.location;
      params = new URLSearchParams(search);
      params.set('variant', selectedVariantFromOptions.legacyResourceId);
    }

    return `/products/${selectedVariantFromOptions.product.handle}?${params}`;
  }, [groupingProductsMapByColor, isFromGrouping, newSelectedOptions]);

  const variantFromOptionValue = useMemo(() => {
    return product.variants?.find(({ selectedOptionsMap }) => {
      return equal(newSelectedOptions, selectedOptionsMap);
    });
  }, [newSelectedOptions, product.variants]);

  const optionValueIsAvailable =
    inventory?.variants?.[variantFromOptionValue?.id]?.availableForSale ||
    false;
  const disabled = isLoading;

  if (!optionValueIsAvailable) return null;

  return isFromGrouping ? (
    <Link
      aria-label={value}
      href={url}
      onClick={(e) => {
        if (isSelected) e.preventDefault();
      }}
      scroll={false}
    >
      <ColorOptionValue
        disabled={disabled}
        isSelected={isSelected}
        swatch={swatch}
        value={value}
        optionValueIsAvailable={optionValueIsAvailable}
      />
    </Link>
  ) : (
    <button
      aria-label={value}
      onClick={() => {
        if (isSelected) return;
        setSelectedOptions(newSelectedOptions);
      }}
      type="button"
      className="block"
    >
      <ColorOptionValue
        disabled={disabled}
        isSelected={isSelected}
        swatch={swatch}
        value={value}
        optionValueIsAvailable={optionValueIsAvailable}
      />
    </button>
  );
}

UpsellColorOptionValue.displayName = 'UpsellColorOptionValue';
