'use client';

import Script from 'next/script';
import { useEffect, useState } from 'react';
import { Schema } from './FourSixty.schema';
import { Link } from '../../snippets';

export function FourSixty({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { instaHandle, instaLink, desktopPadding, mobilePadding } = cms;
  const [loaded, setLoaded] = useState(false);
  const [foursixty, setFoursixty] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);
  useEffect(() => {
    setFoursixty(true);
    if (
      typeof window.Foursixty !== 'undefined' &&
      document.querySelector(`#${moduleID} .foursixty_gallery`) &&
      foursixty &&
      !document.querySelector(`#${moduleID} .fs-slider-container`)
    ) {
      window.Foursixty.Slider.create({
        targetSelector: `#${moduleID} .foursixty_gallery`,
      }).init();
    }
    return () => {
      setFoursixty(false);
    };
  }, [loaded]);

  return (
    loaded && (
      <div id={moduleID} className="foursixty-section-container">
        {(instaHandle || instaLink) && (
          <div className="mb-6 flex items-baseline px-4 md:px-8 lg:mb-8 xl:px-12">
            {instaHandle && (
              <h2
                className="h4 mb-0 text-sh-upper-sm font-semibold uppercase md:text-sh-upper lg:text-sh-upper-lg [&_*]:m-0"
                dangerouslySetInnerHTML={{ __html: instaHandle }}
              />
            )}
            {instaLink && instaLink?.url && instaLink?.text && (
              <Link
                aria-label={instaLink.text}
                className="focus-link ml-1 text-sh-upper-sm md:text-sh-upper lg:text-sh-upper-lg"
                href={instaLink.url}
                newTab={instaLink.newTab}
              >
                {instaLink.text}
                {instaLink?.newTab && (
                  <span className="sr-only">(opens in a new tab)</span>
                )}
              </Link>
            )}
          </div>
        )}
        <style>{`
        #${moduleID}{ margin-bottom: ${mobilePadding || 0}px; }
        @media(min-width: 1024px) {
          #${moduleID}{ margin-bottom: ${desktopPadding || 0}px; }
        }
        .fs-has-links::after {
          padding: 5px 7.5px;
          background-color: #ffffff;
          color: rgba(0, 0, 0, 0.8);
          content: "SHOP IT";
        }
        .fs-wrapper {
            height: auto;
        }
        .fs-wrapper div.fs-text-container .fs-entry-title,
        div.fs-detail-title {
            font-family: Times New Roman, serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
        }
        div.fs-text-container .fs-entry-date,
        div.fs-detail-container .fs-post-info,
        div.fs-wrapper div.fs-has-links::after,
        .fs-text-product,
        .fs-overlink-text {
            font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
        }
        .fs-wrapper div.fs-text-container * {
            color: #ffffff;
        }
        .fs-wrapper div.fs-text-container {
            background-color: rgba(0, 0, 0, 0.8);
            margin: 0px;
        }
        div.fs-entry-date {
            display: none;
        }
        div.fs-entry-title {
            display: none;
        }
        .fs-slider_v2_5 .fs-wrapper div.fs-timeline-entry {
            margin: 4px;
        }

        .fs-timeline-entry {
            transition: opacity 0.64s;
        }

        .fs-wrapper div.fs-text-container {
            background-color: rgb(32, 37, 53, .84);
        }
        .fs-service-icon {
          padding: 5px 5px 0px 5px;
        }

        .fs-wrapper .fs-fa-instagram:before {
          content: '';
          background-image: url("https://cdn.shopify.com/s/files/1/1616/9825/files/white-instagram.svg?v=1682627414");
          display: block;
          width: 24px;
          height: 24px;
        }
        div.fs-wrapper div.fs-has-links::after, .fs-text-product, .fs-overlink-text {
          font-family: Inter, sans-serif;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-color: white;
          background-color: white;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          height: 2.5rem;
          cursor: pointer;
          border-radius: 3px;
          border-width: 1px;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          line-height: 1.5;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          color: var(--black);
          transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 200ms;
        }

        .fs-timeline-detail {
          background-color: #353B3E;
          background: rgba(53,59,62,1);
        }

        .fs-overlink-text {
          height: auto;
        }

        .fs-shopify-options .fs-view + div {
          display: none;
        }

        .fs-wrapper div.fs-text-container .fs-entry-title,
         div.fs-detail-title,
        .fs-product-price,
        .fs-product-name,
        .fs-detail-title,
        .fs-service-username,
        .fs-slashes,
        .fs-detail-date,
        .fs-post-info a {
          font-family: Inter, sans-serif;
        }

        .fs-product-price {
          color: #191919;
          font-weight: 400;
          font-size: 14px;
          margin-bottom: 8px;
        }

        .fs-product-name {
          font-weight: 700;
          font-size: 14px;
        }

        div.fs-detail-title {
          font-weight: 400;
          font-size: 13px;
          color: #353B3E;
        }

        .fs-service-username,
        .fs-slashes,
        .fs-detail-date,
        .fs-post-info a {
          font-weight: 700;
          font-size: 9.5px;
          line-height: 11px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: #353B3E !important;
        }

        @media screen and (max-width: 768px) {
          .fs-wrapper  .fs-entry-container {
            width: 45% !important;
            padding-top: 45% !important;
          }
        }

       .fs-detail-nav-button .fs-arrow {
          stroke-width: 3px;
      }

      `}</style>
        <div
          className="foursixty_gallery"
          data-open-links-in-same-page="true"
          data-url-related="true"
          data-cell-size="25%"
          data-feed-id="branded-bills"
          data-theme="slider_v2_6"
        />
        {foursixty && (
          <Script
            src="https://foursixty.com/media/scripts/fs.combined.v2.6.js"
            onLoad={() => {
              if (typeof window.Foursixty !== 'undefined') {
                window.Foursixty.Slider.create({
                  targetSelector: `#${moduleID} .foursixty_gallery`,
                }).init();
              }
            }}
          />
        )}
      </div>
    )
  );
}

FourSixty.displayName = 'FourSixty';
FourSixty.Schema = Schema;
