export default {
  label: 'Order Cancellation and Shipping Address',
  name: 'orderCancellation',
  component: 'group',
  fields: [
    {
      label: 'Enabled Order Cancellation',
      name: 'enabledOrderCancellation',
      component: 'toggle',
      description: 'Enabled Order Cancellation feature',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Enabled Edit Shipping Address',
      name: 'enabledEditShippingAdd',
      component: 'toggle',
      description: 'Enabled Edit Shipping Address feature',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: false,
    },
    {
      label: 'Order Cancelling',
      name: 'orderCancelling',
      component: 'group',
      fields: [
        {
          component: 'text',
          name: 'heading',
          label: 'Heading',
          defaultValue: 'Do you want to cancel your order?',
        },
        {
          name: 'description',
          label: 'Description',
          component: 'html',
          defaultValue:
            '<p>Mi eget mauris pharetra et. Duis at tellus at urna. Et ligula ullamcorper malesuada proin libero nunc consequat interdum varius. Maecenas accumsan lacus vel facilisis volutpat est velit.</p>',
        },
        {
          label: 'Reason for cancelling',
          name: 'reason',
          component: 'group-list',
          itemProps: {
            label: '{{item.label}}',
          },
          defaultItem: () => ({
            name: 'New Label',
          }),
          fields: [
            {
              label: 'Label',
              name: 'label',
              component: 'text',
            },
            {
              label: 'Value',
              name: 'value',
              component: 'text',
            },
          ],
          defaultValue: [
            {
              label: 'Ordered wrong size or color',
              value: 'Ordered wrong size or color',
            },
            {
              label: 'Ordered incorrect quantity',
              value: 'Ordered incorrect quantity',
            },
            {
              label: 'Need to change shipping method',
              value: 'Need to change shipping method',
            },
            {
              label: ' Need to change payment method',
              value: ' Need to change payment method',
            },
            {
              label: 'Just changed my mind',
              value: 'Just changed my mind',
            },
          ],
        },
        {
          label: 'Submit Button Text',
          name: 'submitButtonText',
          component: 'text',
          defaultValue: 'YES, Cancel my Order',
        },
        {
          label: 'Cancel Button Text',
          name: 'cancelButtonText',
          component: 'text',
          defaultValue: 'No, Keep my order',
        },
      ],
    },
    {
      label: 'Order Cancelled',
      name: 'orderCancelled',
      component: 'group',
      fields: [
        {
          component: 'text',
          name: 'heading',
          label: 'Heading',
          defaultValue: 'Your order has been cancelled.',
        },
        {
          name: 'description',
          label: 'Description',
          component: 'html',
          defaultValue:
            '<p>Mi eget mauris pharetra et. Duis at tellus at urna. Et ligula ullamcorper malesuada proin libero nunc consequat interdum varius. Maecenas accumsan lacus vel facilisis volutpat est velit.</p>',
        },
        {
          label: 'Button Text',
          name: 'buttonText',
          component: 'text',
          defaultValue: 'Close',
        },
      ],
    },
  ],
};