const BUTTON_COLOR_FIELDS = [
  {
    label: 'Background Color',
    name: 'bgColor',
    component: 'color',
  },
  {
    label: 'Border Color',
    name: 'borderColor',
    component: 'color',
  },
  {
    label: 'Text Color',
    name: 'textColor',
    component: 'color',
  },
  {
    label: 'Hover Background Color',
    name: 'hoverBgColor',
    component: 'color',
  },
  {
    label: 'Hover Border Color',
    name: 'hoverBorderColor',
    component: 'color',
  },
  {
    label: 'Hover Text Color',
    name: 'hoverTextColor',
    component: 'color',
  },
];

export default {
  label: 'Theme',
  name: 'theme',
  component: 'group',
  description: 'Theme colors, button colors',
  fields: [
    {
      label: 'Colors',
      name: 'colors',
      component: 'group',
      fields: [
        {
          label: 'Black',
          name: 'black',
          component: 'color',
        },
        {
          label: 'White',
          name: 'white',
          component: 'color',
        },
        {
          label: 'Loden',
          name: 'loden',
          component: 'color',
        },
        {
          label: 'Dark Loden',
          name: 'darkLoden',
          component: 'color',
        },
        {
          label: 'Orion',
          name: 'orion',
          component: 'color',
        },
        {
          label: 'Dark Orion',
          name: 'darkOrion',
          component: 'color',
        },
        {
          label: 'Dark Desert',
          name: 'darkDesert',
          component: 'color',
        },
        {
          label: 'Medium Desert',
          name: 'medDesert',
          component: 'color',
        },
        {
          label: 'Light Desert',
          name: 'lightDesert',
          component: 'color',
        },
        {
          label: 'Lava',
          name: 'lava',
          component: 'color',
        },
        {
          label: 'Dark Lava',
          name: 'darkLava',
          component: 'color',
        },
        {
          label: 'Grey 1',
          name: 'grey1',
          component: 'color',
        },
        {
          label: 'Grey 2',
          name: 'grey2',
          component: 'color',
        },
        {
          label: 'Grey 3',
          name: 'grey3',
          component: 'color',
        },
        {
          label: 'Grey 4',
          name: 'grey4',
          component: 'color',
        },
        {
          label: 'Grey 5',
          name: 'grey5',
          component: 'color',
        },
        {
          label: 'Error',
          name: 'error',
          component: 'color',
        },
        {
          label: 'Success',
          name: 'success',
          component: 'color',
        },
      ],
      defaultValue: {
        background: '#FFFFFF',
        text: '#191919',
        border: '#E8E8E8',
        primary: '#008464',
        secondary: '#8164BF',
        accent1: '#189CC5',
        accent2: '#4A69D4',
        black: '#191919',
        offBlack: '#101010',
        offWhite: '#F9F9F9',
        white: '#FFFFFF',
        loden: '#6D7356',
        darkLoden: '#484B39',
        orion: '#3E4F5C',
        darkOrion: '#202535',
        darkDesert: '#C3B091',
        medDesert: '#EFE8DB',
        lightDesert: '#FAF7F2',
        lava: '#C53C1D',
        darkLava: '#B22F12',
        grey1: '#F5F5F5',
        grey2: '#E8E8E9',
        grey3: '#D0D0D1',
        grey4: '#74757B',
        grey5: '#353B3E',
        error: '#CC1E1E',
        success: '#4B7A2C'
      },
    },
  ],
};