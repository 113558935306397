const COLORS = [
  { label: 'Dark Loden', value: 'var(--dark-loden)' },
  { label: 'Dark Orion', value: 'var(--dark-orion)' },
  { label: 'Orion', value: 'var(--orion)' },
  { label: 'Light Desert', value: 'var(--light-desert)' },
];

export function Schema() {
  return {
    category: 'Yotpo',
    label: 'Yotpo Reviews Carousel 3.0',
    key: 'yotpo-review-carousel',
    fields: [
      {
        label: 'Yotpo Instance Id',
        name: 'yotpoInstanceId',
        component: 'number',
        description: 'Yotpo Instance Id',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Background color, spacing bottom (mobile and desktop)',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          bgColor: 'var(--light-desert)',
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
}