import { Fragment, useMemo, useState, useEffect } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CollectionPromoTile } from './CollectionPromoTile';
import { ProductItem } from '../ProductItem';
import { CollectionContentTile } from './CollectionContentTile';
import { CollectionFiltersSummary } from './CollectionFilters/CollectionFiltersSummary';

export function CollectionGrid({
  activeFilters,
  collectionProductsData,
  enabledFilters,
  isSearchPage,
  promoTiles,
  swatchesMap,
  collectionFiltersData,
  hideFilters,
  setFilteredProducts,
}) {
  const settings = useSettings();
  const { pagination, productItem } = { ...settings?.collection };
  const mappedColorNames =
    settings?.collection?.filtering?.colorsMapping?.color;

  const {
    state: {
      filteredProducts,
      isInfiniteScroll,
      productsLimit,
      resultsPerPage,
    },
    actions: { loadMoreProducts },
    refs: { loadMoreRef },
  } = collectionProductsData;

  const hasActiveFilters = Object.keys(activeFilters).length > 0;
  const hasMoreProducts = filteredProducts?.length > productsLimit;

  const {
    state: { filters, filtersMap },
    actions: { addFilter, removeFilter, clearFilters },
  } = collectionFiltersData;

  const activeFiltersList = useMemo(() => {
    const filterEntries = Object.entries(activeFilters);
    if (!filterEntries.length) return [];

    return filterEntries.reduce((acc, [filterKey, filterValues]) => {
      return [
        ...acc,
        ...filterValues.map((value) => {
          return {
            key: filterKey,
            value,
          };
        }),
      ];
    }, []);
  }, [activeFilters]);

  const colorFilterNameMap = useMemo(() => {
    return mappedColorNames?.reduce((mapAcc, { mapping, colorList }) => {
      const colorMap = [mapping, ...colorList.split(',')].reduce(
        (colorAcc, color) => {
          const colorKey = color?.toLowerCase()?.trim();
          if (!colorKey) return colorAcc;
          return {
            ...colorAcc,
            [colorKey]: true,
          };
        },
        []
      );
      return { ...mapAcc, [mapping.toLowerCase()]: colorMap };
    }, {});
  }, [mappedColorNames]);

  useEffect(() => {
    setFilteredProducts(filteredProducts);
  }, [filteredProducts]);

  return (
    <>
      <div className="-mt-4 block md:hidden">
        <CollectionFiltersSummary
          activeFilters={activeFilters}
          filtersMap={filtersMap}
          removeFilter={removeFilter}
          clearFilters={clearFilters}
        />
      </div>

      {filteredProducts?.length > 0 && (
        <ul
          className={`mx-auto grid grid-cols-2 gap-x-4 gap-y-12 lg:gap-x-6 lg:gap-y-16 ${
            enabledFilters && !hideFilters
              ? 'md:grid-cols-2 lg:grid-cols-3'
              : 'md:grid-cols-4'
          } m-0 list-none p-0`}
        >
          {filteredProducts.slice(0, productsLimit).map((product, index) => {
            if (['article', 'page'].includes(product?.itemType)) {
              return (
                <Fragment key={`content-tile-${index}`}>
                  <li className="m-0">
                    <CollectionContentTile tile={product} />
                  </li>
                </Fragment>
              );
            }
            const promoTile = promoTiles?.find(
              ({ position }) => position === index + 1
            );
            const key = product?.id || product?.handle || index;
            return (
              <Fragment
                key={`${promoTile ? 'promo' : 'collection'}-tile-${key}`}
              >
                {promoTile && (
                  <li
                    className={`m-0 ${
                      promoTile?.gridWidth !== 'normal' ? 'col-span-2' : ''
                    }`}
                  >
                    <CollectionPromoTile tile={promoTile} />
                  </li>
                )}
                <li className="m-0">
                  <ProductItem
                    activeColorFilters={activeFilters?.['option.Color']}
                    colorFilterNameMap={colorFilterNameMap}
                    enabledColorNameOnHover={
                      productItem?.enabledColorNameOnHover
                    }
                    enabledColorSelector
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    handle={product?.handle}
                    index={index}
                    isSearchPage={isSearchPage}
                    product={product}
                    swatchesMap={swatchesMap}
                  />
                </li>
              </Fragment>
            );
          })}
        </ul>
      )}

      {hasMoreProducts && !isInfiniteScroll && (
        <div className="mb-[4.5rem] mt-12 flex flex-col items-center justify-center gap-4 md:mb-24 lg:mt-16">
          <p className="m-0 text-sh-upper font-bold uppercase text-grey5">
            Showing 1-{productsLimit} of {filteredProducts?.length} total
          </p>
          <button
            className={`${pagination?.buttonStyle} button w-[320px] max-w-full`}
            onClick={loadMoreProducts}
            type="button"
          >
            {pagination?.loadText}
          </button>
        </div>
      )}

      {hasMoreProducts && isInfiniteScroll && (
        <div
          ref={loadMoreRef}
          className="mb-[4.5rem] mt-12 flex justify-center md:mb-24 lg:mt-16"
        >
          <p>{pagination?.loadText}</p>
        </div>
      )}

      {!filteredProducts?.length && hasActiveFilters && (
        <div className="flex min-h-[12rem] items-center justify-center">
          <p className="m-0 text-sh font-bold uppercase">
            No products found matching these filters.
          </p>
        </div>
      )}
    </>
  );
}

CollectionGrid.displayName = 'CollectionGrid';
