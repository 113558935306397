import { useEffect } from 'react';
import { useCartItems, useSettings } from '@backpackjs/storefront';

import { Svg } from '../Svg';
import { CartEmpty } from './CartEmpty';
import { CartHeader } from './CartHeader';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { RebuyUpsells } from '../RebuyUpsells';
import { FreeShippingMeter } from './FreeShippingMeter';
import { GiftWithPurchase } from './GiftWithPurchase';
import { useGlobalContext } from '../../contexts';

export function Cart() {
  const cartItems = useCartItems();
  const settings = useSettings();
  const {
    state: { cartOpen },
    actions: { closeCart },
  } = useGlobalContext();

  useEffect(() => {
    const useKeyCloseCart = (event) => {
      if (event.key === 'Escape') {
        closeCart();
      }
    };
    document.addEventListener('keydown', useKeyCloseCart);
    return () => {
      document.removeEventListener('keydown', useKeyCloseCart);
    };
  }, []);

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full max-w-[355px] flex-col justify-between overflow-hidden bg-background transition md:max-w-[var(--sidebar-width)] ${
        cartOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      }`}
    >
      {cartOpen && (
        <>
          <CartHeader
            closeCart={closeCart}
            heading={settings?.cart?.heading}
            cartItemsLength={cartItems?.length}
            cartOpen={cartOpen}
          />

          <div
            className={`scrollbar-hide flex-1 overflow-y-auto ${
              cartItems?.length ? '' : 'flex items-center justify-center'
            }`}
          >
            <FreeShippingMeter
              customClasses="border-b border-b-grey2 md:p-6"
              cartItems={cartItems}
            />

            <ul className="relative my-0 flex-1 pl-0">
              {cartItems?.length ? (
                cartItems.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="mt-0 list-none border-b border-b-grey2 md:px-6"
                    >
                      <CartItem item={item} closeCart={closeCart} />
                    </li>
                  );
                })
              ) : (
                <>
                  <span className="absolute right-0 top-1/2 -z-10 -translate-y-1/4 transform">
                    <Svg
                      className="w-[160px] md:w-[276px]"
                      src="/svgs/sidecart-logo.svg#sidecart-logo"
                      viewBox="0 0 159 220"
                    />
                  </span>

                  <CartEmpty closeCart={closeCart} />
                </>
              )}
            </ul>
            <GiftWithPurchase />
          </div>

          {!!cartItems?.length && <RebuyUpsells />}

          {!!cartItems?.length && (
            <div className="border-t border-t-grey2 p-4 md:p-6">
              <CartTotals
                hideTaxesMessage
                hideShippingMessage
                cartItems={cartItems}
              />

              <p className="mb-0 mt-3 text-sm text-grey5">
                Shipping & taxes calculated at checkout.
              </p>
            </div>
          )}
        </>
      )}
    </aside>
  );
}

Cart.displayName = 'Cart';
