import { useCallback, useMemo, useState } from 'react';
import { useCartAddItem } from '@backpackjs/storefront';

import { Spinner } from '../../Spinner';
import { useGlobalContext } from '../../../contexts';

export function QuickShopOption({
  inventory,
  inventoryFetched,
  optionName,
  selectedProduct,
  selectedVariant,
  value,
}) {
  const {
    actions: { openCart },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();

  const [isAdding, setIsAdding] = useState(false);

  const variantToAdd = useMemo(() => {
    return selectedProduct.variants?.find((variant) => {
      const color = variant.selectedOptionsMap?.Color;
      return (
        variant.product?.handle === selectedVariant.product.handle &&
        variant.selectedOptionsMap[optionName] === value &&
        (!color || color === selectedVariant.selectedOptionsMap?.Color)
      );
    });
  }, [optionName, selectedProduct.id, selectedVariant.id, value]);

  const variantInventory = inventory?.variants?.[variantToAdd?.id];
  const variantIsSoldOut =
    inventoryFetched && !variantInventory?.availableForSale;
  // const variantIsPreorder =
  //   !variantIsSoldOut && variantInventory?.currentlyNotInStock;

  const handleAddToCart = useCallback(async () => {
    if (!variantToAdd?.id || !inventoryFetched || isAdding) return;
    setIsAdding(true);
    await cartAddItem({
      merchandiseId: variantToAdd.id,
      quantity: 1,
    });
    setIsAdding(false);
    openCart();
  }, [variantToAdd?.id, inventoryFetched, isAdding]);

  const disabled = !variantToAdd || variantIsSoldOut;

  const valueToText = useMemo(() => {
    const valueDowncase = value?.toLowerCase();
    if (optionName?.toLowerCase()?.indexOf('size') !== -1) {
      if (valueDowncase === 'xs') return 'Extra Small';
      if (valueDowncase === 's' || valueDowncase === 'sm') return 'Small';
      if (valueDowncase === 'm' || valueDowncase === 'med') return 'Medium';
      if (valueDowncase === 'l' || valueDowncase === 'lg') return 'Large';
      if (valueDowncase === 'xl') return 'Extra Large';
      if (valueDowncase === 'xxl' || valueDowncase === '2xl')
        return 'Double Extra Large';
      if (valueDowncase === 'xxxl' || valueDowncase === '3xl')
        return 'Triple Extra Large';
    }
    return value;
  }, [value, optionName]);

  return (
    <button
      aria-label={`Select ${valueToText} ${optionName} variant`}
      className={`group/option flex h-full w-full items-center justify-center whitespace-nowrap text-center text-sh-upper font-bold uppercase transition ${
        disabled
          ? 'cursor-not-allowed bg-grey2 text-grey4'
          : 'cursor-pointer hover:bg-orion hover:text-white'
      }`}
      disabled={disabled}
      onClick={handleAddToCart}
      type="button"
    >
      {isAdding ? (
        <div className="text-grey4 group-hover/option:text-white">
          <Spinner width="20" />
        </div>
      ) : (
        value
      )}
    </button>
  );
}

QuickShopOption.displayName = 'QuickShopOption';
