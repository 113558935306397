import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useGwp } from '../../hooks';
import { Image } from '../Image';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';

export function GiftWithPurchase({ isSidecart = true }) {
  const {
    gwpLine,
    gwpPrice,
    qualifiedFreeGift,
    gwpInCart,
    discountDescription,
    addGwp,
    removeGwp,
    isRemovingItem,
    gwpAddBack,
  } = useGwp();

  const optedOut = Cookies.get('gwp_opted-out') === 'true';
  const variant = gwpLine?.variant;
  const discountMsg =
    gwpLine?.discountAllocations?.[0]?.discountedAmount?.amount !== '0.0'
      ? discountDescription
      : null;

  useEffect(() => {
    if (qualifiedFreeGift && !gwpInCart && !optedOut) {
      addGwp();
    }
    if (!qualifiedFreeGift && gwpInCart) {
      removeGwp();
    }
  }, [gwpInCart, qualifiedFreeGift]);

  if (!qualifiedFreeGift) return null;

  if (!gwpInCart && optedOut) {
    return (
      <div
        className={`mt-0 list-none border-b border-b-grey2 ${
          isSidecart ? 'md:px-6' : ''
        }`}
      >
        <div className="relative grid grid-cols-[auto_1fr] gap-4 px-4 py-3 md:gap-6 md:px-0">
          <button
            type="button"
            className="font-gothamBold border-b-2 text-sm"
            onClick={() => {
              Cookies.remove('gwp_opted-out');
              addGwp();
            }}
          >
            {gwpAddBack}
          </button>
        </div>
      </div>
    );
  }

  if (!gwpLine) return null;

  return (
    <div
      className={`mt-0 list-none border-b border-b-grey2 ${
        isSidecart ? 'md:px-6' : 'mt-4'
      }`}
    >
      <div className="relative grid grid-cols-[auto_1fr] gap-4 px-4 py-6 md:gap-6 md:px-0">
        <div className="shrink-0">
          <Image
            alt={variant.product.title}
            className="bg-grey1 p-2 md:hidden"
            height={Math.floor(
              80 / (variant.image.width / variant.image.height)
            )}
            src={variant.image.src}
            width="80"
          />
          <Image
            alt={variant.product.title}
            className="hidden bg-grey1 p-2 md:block"
            height={Math.floor(
              120 / (variant.image.width / variant.image.height)
            )}
            src={variant.image.src}
            width="120"
          />
        </div>

        <div className="flex flex-col justify-between gap-4 md:gap-5">
          <div className="relative pr-6">
            <h4 className="mb-2 text-xs uppercase md:text-sh-lg">
              {variant.product.title}
            </h4>

            {variant.selectedOptions.length > 0 && (
              <div className="flex flex-col gap-2">
                {variant.selectedOptions.map((option, index) => {
                  return (
                    option?.value !== 'Default Title' && (
                      <p
                        className="mb-0 text-xs md:text-sm"
                        key={`variant-option-${variant?.id}-${index}`}
                      >
                        {option.name}: {option.value}
                      </p>
                    )
                  );
                })}
              </div>
            )}

            {discountMsg && (
              <p className="m-0 text-xs text-lava md:text-sm">{discountMsg}</p>
            )}

            {gwpLine?.attributes?.length > 0 && (
              <div className="mt-2 flex flex-col gap-2">
                {gwpLine.attributes.map((attribute, index) => {
                  const sendOnArr =
                    attribute.key == 'Send on'
                      ? attribute.value.split('-')
                      : [];
                  const sendOnFormat = sendOnArr.length
                    ? `${sendOnArr[1]}/${sendOnArr[2]}/${sendOnArr[0]}`
                    : '';
                  return (
                    attribute.key.indexOf('_') !== 0 && (
                      <p
                        className="mb-0 text-xs md:text-sm"
                        key={`item-attribute-${variant?.id}-${index}`}
                      >
                        {attribute.key}:{' '}
                        {attribute.key == 'Send on'
                          ? sendOnFormat
                          : attribute.value}
                      </p>
                    )
                  );
                })}
              </div>
            )}

            <button
              aria-label={`Remove ${variant.product.title} from cart`}
              className="absolute right-0 top-0 w-4"
              onClick={() => {
                removeGwp();
                Cookies.set('gwp_opted-out', true);
              }}
              type="button"
            >
              <Svg
                className="w-[16px] hover:text-lava"
                src="/svgs/icon-trash.svg#icon-trash"
                viewBox="0 0 18 19"
              />
            </button>
          </div>

          <div className="flex items-center justify-between gap-4">
            <div />
            <div className="flex flex-col items-center gap-0.5">
              <p className="mb-0 text-xs md:text-sm">
                {parseInt(gwpLine.estimatedCost.totalAmount.amount, 10) === 0
                  ? 'FREE'
                  : gwpPrice?.price}
              </p>
            </div>
          </div>
        </div>

        {isRemovingItem && (
          <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)]">
            <Spinner width="20" />
          </div>
        )}
      </div>
    </div>
  );
}

export default GiftWithPurchase;
