import { Schema } from './ProductDescription.schema';
import { ProductFeatureIcons } from '../../templates/product/ProductFeatureIcons';

export function ProductDescription({ cms }) {
  const { product, section } = cms;
  const moduleID = `${Schema({ product })?.key}-${
    cms.id || cms.cmsId || cms.tinaId
  }`;

  return (
    <div id={moduleID}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      {cms?.headingText && (
        <h3 className="mb-3 text-sh-upper uppercase">{cms?.headingText}</h3>
      )}
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: product?.descriptionHtml }}
        className="mb-6 text-sm [&>:last-child]:mb-0 [&_a]:underline [&_blockquote]:pl-8 [&_h1]:mb-3 [&_h2]:mb-3 [&_h3]:mb-3 [&_h4]:mb-3 [&_h5]:mb-3 [&_h6]:mb-3 [&_li>p]:mb-0 [&_li]:mb-2 [&_ol>li]:list-decimal [&_ol]:mb-3 [&_ol]:pl-8 [&_p]:mb-3 [&_ul>li]:list-disc [&_ul]:mb-3 [&_ul]:pl-8"
      />
      <ProductFeatureIcons product={product} />
    </div>
  );
}

ProductDescription.displayName = 'ProductDescription';
ProductDescription.Schema = Schema;
