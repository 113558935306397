import { useProductByHandle } from '@backpackjs/storefront';

import Image from 'next/image';
import { Link, Svg } from '@/snippets';

export function SearchContentItem({ closeSearch, index, item }) {
  const url = item?.url || '';
  const image = item?.seo?.image;
  return (
    <Link
      aria-label={`View ${item?.title}`}
      className="group/search-item relative grid grid-cols-[7.5rem_1fr] items-start gap-3 !text-black no-underline hover:decoration-1"
      href={url}
      onClick={() => {
        closeSearch();
      }}
    >
      <div
        className={`flex items-center justify-center bg-grey3 ${
          image ? '' : 'p-3'
        }`}
        style={{
          aspectRatio: 'var(--product-image-aspect-ratio)',
        }}
      >
        {image ? (
          <Image
            alt={item?.title}
            className="mx-auto max-w-full bg-transparent object-contain"
            height="117"
            src={image}
            width="117"
          />
        ) : (
          <Svg
            className="h-full w-full text-white"
            src="/svgs/logo-grey.svg#logo-grey"
            title={item?.title}
            viewBox="0 0 39 48"
          />
        )}
      </div>

      <div className="flex flex-col justify-between gap-3 pb-4 pt-1">
        <div>
          <h4 className="mb-2 text-sh-sm font-bold capitalize group-hover/search-item:text-darkLava group-hover/search-item:underline">
            {item?.title}
          </h4>
        </div>
      </div>
    </Link>
  );
}

SearchContentItem.displayName = 'SearchContentItem';
