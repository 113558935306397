import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { Svg } from '../Svg';

export function FooterLegal() {
  const settings = useSettings();
  const { links, copyrightNotice, appStoreURLDesktop } = {
    ...settings?.footer?.legal,
  };

  return (
    <div className="flex flex-col gap-x-12 gap-y-1 px-4 pt-8 text-base md:flex-row md:p-0 md:text-sm">
      <ul className="mb-4 mt-0 flex justify-center gap-6 pl-0 md:mb-0 md:mt-5 md:justify-start">
        {links?.map(({ link }, index) => {
          return (
            <li key={index} className="mt-0 list-none text-xs">
              <Link
                aria-label={link?.text}
                className="text-grey3 underline-offset-4 transition-colors hover:text-medDesert"
                href={link?.url}
                newTab={link?.newTab}
              >
                {link?.text}
              </Link>
            </li>
          );
        })}
      </ul>

      <div className="flex-1">
        <Link
          href="/"
          aria-label="Go to home page"
          className="flex items-center before:h-px before:w-full before:flex-1 before:bg-grey5 after:h-px after:w-full after:flex-1 after:bg-grey5"
        >
          <Svg
            className="mx-4 w-6 text-grey3 md:mx-12 md:w-12"
            src="/svgs/logo-grey.svg#logo-grey"
            title="Storefront logo"
            viewBox="0 0 38 48"
          />
        </Link>

        <p className="mb-0 mt-4 text-center text-xs text-grey3">
          &copy; {new Date().getFullYear()} {copyrightNotice}
        </p>
      </div>

      {appStoreURLDesktop && (
        <Link
          href={appStoreURLDesktop}
          aria-label="Download on the App Store"
          className="mt-2.5 hidden md:block"
        >
          <Svg
            className="w-[120px]"
            src="/svgs/app-store-download.svg#app-store-download"
            title="App Store"
            viewBox="0 0 120 40"
          />
        </Link>
      )}
    </div>
  );
}

FooterLegal.displayName = 'FooterLegal';
