import PropTypes from 'prop-types';

import { ReviewStars } from '../ReviewStars';
import { useYotpoProductBottomline } from '../../hooks';

export function ProductStars({ legacyResourceId, showAverage }) {
  const { bottomline } = useYotpoProductBottomline({
    legacyResourceId,
  });
  return (
    bottomline?.total_reviews >= 3 && (
      <div className="flex min-h-[1rem] flex-wrap items-center gap-1.5">
        {bottomline && (
          <>
            <ReviewStars rating={bottomline?.average_score || 0} size="small" />
            {bottomline?.total_reviews > 0 && (
              <>
                {showAverage && (
                  <p className="m-0 border-r border-r-grey4 pr-1.5 text-xs leading-[1.2] text-grey4 underline underline-offset-2">
                    {bottomline.average_score}
                    <span className="sr-only">average score</span>
                  </p>
                )}
                <p className="m-0 text-xs text-grey4 underline underline-offset-2">
                  {bottomline.total_reviews}
                  <span className="sr-only"> Reviews</span>
                </p>
              </>
            )}
          </>
        )}
      </div>
    )
  );
}

ProductStars.displayName = 'ProductStars';
ProductStars.propTypes = {
  legacyResourceId: PropTypes.string,
};
