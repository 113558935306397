import { useEffect } from 'react';

import { Svg } from '../Svg';
import { useBodyScrollLock } from '../../hooks';

export function CollectionMenuSidebar({ children, isOpen, setIsOpen, title, clearFilters, activeFilters }) {
  const { lockBodyScroll, unlockBodyScroll } = useBodyScrollLock();

  useEffect(() => {
    if (isOpen) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
  }, [isOpen]);

  return (
    <div
      className={`${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } position fixed inset-0 z-[100] flex h-full w-full flex-col gap-3 bg-white transition md:!hidden`}
    >
      {isOpen && (
        <>
          <div className="relative grid h-[3.5rem] grid-cols-[4rem_1fr_4rem] items-center justify-center gap-4 border-b border-black px-4">
            <div>
              <button
                className="rotate-180 flex items-center"
                onClick={() => setIsOpen(false)}
                type="button"
              >
                <Svg
                  className="w-5 text-black"
                  src="/svgs/arrow-right.svg#arrow-right"
                  title="Arrow Left"
                  viewBox="0 0 20 12"
                />
              </button>
            </div>

            <h3 className="text-center text-black text-h6 font-bold uppercase m-0">{title}</h3>

            <div className="flex justify-end">
            {clearFilters && JSON.stringify(activeFilters) != '{}' ? (
              <button
                className="button--underline text-black whitespace-nowrap"
                onClick={clearFilters}
                type="button"
              >
                Clear All
              </button>
            ) : null}
            </div>
          </div>

          <div className="scrollbar-hide relative flex-1 overflow-y-auto">
            {children}
          </div>
        </>
      )}
    </div>
  );
}

CollectionMenuSidebar.displayName = 'CollectionMenuSidebar';
