import { Link, Image } from '../../snippets';

export function ShopByDesignThumbs({ grid }) {
  const { thumbnails, colCount } = { ...grid };
  return (
    thumbnails?.length > 0 && (
      <div
        className={`grid grid-cols-2 md:col-span-2 ${colCount} order-3 gap-4 p-4 md:gap-6 md:px-0 md:py-6`}
      >
        {thumbnails.map(({ image, link, bgColor, bgColorHover }, index) => {
          return (
            <Link
              key={index}
              className={`relative flex flex-col items-center justify-between ${
                bgColor || 'bg-grey1'
              } no-underline ${
                link?.url ? bgColorHover || 'md:hover:bg-grey2' : ''
              } py-0 lg:py-10`}
              aria-label={link?.text}
              href={link?.url}
              newTab={link?.newTab}
            >
              {image?.src && (
                <Image
                  alt={link?.text}
                  sizes="(min-width: 768px) 25vw, 50vw"
                  src={image.src}
                  className="w-full"
                />
              )}

              {link?.text && (
                <p className="mb-5 w-full px-3 text-center text-sh-upper-sm font-bold uppercase text-black lg:absolute lg:bottom-10 lg:left-0 lg:mb-0 lg:px-6 lg:text-sh-upper-lg">
                  {link.text}
                </p>
              )}

              {link?.newTab && (
                <span className="sr-only">(opens in a new tab)</span>
              )}
            </Link>
          );
        })}
      </div>
    )
  );
}

ShopByDesignThumbs.displayName = 'ShopByDesignThumbs';
