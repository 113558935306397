import { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, EffectFade, Autoplay, Navigation } from 'swiper';
import { useSettings } from '@backpackjs/storefront';
import { CountdownTimer } from './CountdownTimer';

import { Link } from '../Link';

export function Promobar({
  promobarDisabled,
  promobarHidden,
  setPromobarHidden,
  promoBarSection,
}) {
  const settings = useSettings();
  let promobar = settings?.header?.promobar;
  const cms = promoBarSection?.props.children.props.children.props.cms;
  if (cms?.promobar) {
    promobar = cms.promobar;
  }

  const { autohide, bgColor, color, delay, enabled, ctaLink } = {
    ...promobar,
  };
  const [messages, SetMessages] = useState([]);
  const promoRef = useRef(null);

  const swiperParams = {
    autoplay: {
      delay: delay * 1000 || 5000,
      disableOnInteraction: true,
      pauseOnMouseEnter: true,
    },
    effect: 'slide',
    loop: messages?.length > 1,
    modules: [A11y, Autoplay, EffectFade, Navigation],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    speed: 300,
    style: {
      '--swiper-navigation-color': color,
      '--swiper-navigation-size': '12px',
    },
    shortSwipes: true,
    breakpoints: {
      1024: {
        autoplay: {
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
      },
    },
  };

  let appCtaBg;
  switch (bgColor) {
    case 'var(--loden)':
      appCtaBg = 'bg-darkLoden';
      break;
    case 'var(--lava)':
      appCtaBg = 'bg-darkLava';
      break;
    case 'var(--orion)':
      appCtaBg = 'bg-darkOrion';
      break;
    default:
      appCtaBg = 'bg-darkLoden';
  }

  const htmlRegexG = /<\/?[a][^>]*>/gi;

  useEffect(() => {
    const validateHTML = (html, length = 80) => {
      const doc = document.createElement('div');
      doc.innerHTML = html;
      if (doc.innerHTML === html) {
        if (doc.innerHTML === doc.innerText) {
          return html?.substring(0, length);
        }
        return html;
      }
      return '';
    };
    const mess = (promobar?.messages || []).reduce((acc, mes) => {
      const mD = validateHTML(mes?.message, 100);
      const mM = mes?.message_mobile
        ? validateHTML(mes?.message_mobile)
        : validateHTML(mes?.message);
      if (mD !== '') {
        return [...acc, { ...mes, message: mD, message_mobile: mM }];
      }
      return acc;
    }, []);
    SetMessages(mess);
  }, [promobar?.messages]);

  useEffect(() => {
    const setPromobarVisibility = () => {
      if (document.body.style.position === 'fixed') return;
      setPromobarHidden(window.scrollY > 48);
    };

    if (!autohide) {
      setPromobarHidden(false);
      window.removeEventListener('scroll', setPromobarVisibility);
      return undefined;
    }

    window.addEventListener('scroll', setPromobarVisibility);
    return () => {
      window.removeEventListener('scroll', setPromobarVisibility);
    };
  }, [autohide]);

  useEffect(() => {
    const setPromoHeight = () => {
      if (promoRef?.current) {
        document.body.style.setProperty('--promobar-height', '3.125rem');
        document.body.style.setProperty('--promobar-height-mobile', '4rem');
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      setPromoHeight();
    });

    resizeObserver.observe(document.body);
    return () => {
      document.body.style.removeProperty('--promobar-height-mobile');
      document.body.style.removeProperty('--promobar-height');
    };
  }, [promoRef?.current]);

  if (promobarDisabled) {
    return null;
  }

  return (
    <div
      className={`promobar relative z-0 overflow-hidden transition-[max-height,padding] ease-out ${
        promobarHidden
          ? 'max-h-0 py-0 duration-[50ms]'
          : `max-h-[fit-content] py-2.5 duration-300 lg:py-1`
      }`}
      style={{ backgroundColor: bgColor }}
      ref={promoRef}
    >
      {enabled && messages?.length ? (
        <Swiper
          {...swiperParams}
          className="swiper-wrapper-center relative flex min-h-[42px] max-w-[750px] items-center justify-center"
        >
          {messages.map(
            (
              { message, message_mobile, link, mobile_link, countdown },
              index
            ) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className="px-contained flex min-h-full items-center justify-center text-center text-xs tracking-[0.04em]"
                    style={{ color }}
                  >
                    {link.url && !htmlRegexG.test(message) ? (
                      <div>
                        <Link
                          aria-label={message}
                          className="hidden select-none no-underline lg:flex"
                          draggable={false}
                          href={link.url}
                          newTab={link.newTab}
                        >
                          <span
                            className={`promobar-message block text-white${
                              countdown?.targetTime
                                ? ' font-medium lg:mt-1'
                                : ' mt-1'
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: message,
                            }}
                          />
                          <CountdownTimer countdown={countdown} />
                        </Link>
                        {!htmlRegexG.test(message_mobile) ? (
                          <Link
                            aria-label={message}
                            className="block select-none no-underline lg:hidden"
                            draggable={false}
                            href={mobile_link ? mobile_link.url : link.url}
                            newTab={
                              mobile_link ? mobile_link.newTab : link.newTab
                            }
                          >
                            <span
                              className={`promobar-message block text-white${
                                countdown?.targetTime
                                  ? ' font-medium lg:mt-1'
                                  : ' mt-1'
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: message_mobile || message,
                              }}
                            />
                            <CountdownTimer countdown={countdown} />
                          </Link>
                        ) : (
                          <div className="block lg:hidden">
                            <div
                              className={`promobar-message block text-white lg:hidden${
                                countdown?.targetTime
                                  ? ' font-medium lg:mt-1'
                                  : ' mt-1'
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: message_mobile || message,
                              }}
                            />
                            <CountdownTimer countdown={countdown} />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        <div className="hidden lg:flex">
                          <div
                            className={`promobar-message block text-white${
                              countdown?.targetTime
                                ? ' font-medium lg:mt-1'
                                : ' mt-1'
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: message,
                            }}
                          />
                          <CountdownTimer countdown={countdown} />
                        </div>
                        {mobile_link?.url &&
                        !htmlRegexG.test(message_mobile) ? (
                          <Link
                            aria-label={message}
                            className="block select-none no-underline lg:hidden"
                            draggable={false}
                            href={mobile_link ? mobile_link.url : link.url}
                            newTab={
                              mobile_link ? mobile_link.newTab : link.newTab
                            }
                          >
                            <span
                              className={`promobar-message block text-white${
                                countdown?.targetTime
                                  ? ' font-medium lg:mt-1'
                                  : ' mt-1'
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: message_mobile || message,
                              }}
                            />
                            <CountdownTimer countdown={countdown} />
                          </Link>
                        ) : (
                          <div className="block lg:hidden">
                            <div
                              className={`promobar-message block text-white lg:hidden${
                                countdown?.targetTime
                                  ? ' font-medium lg:mt-1'
                                  : ' mt-1'
                              }`}
                              dangerouslySetInnerHTML={{
                                __html: message_mobile || message,
                              }}
                            />
                            <CountdownTimer countdown={countdown} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </SwiperSlide>
              );
            }
          )}

          {messages?.length > 1 ? (
            <div>
              <button
                aria-label="See previous slide"
                className="swiper-button-prev !left-2 h-3 w-4 after:hidden md:h-4"
                type="button"
              >
                <svg
                  className="h-full w-full"
                  width="7"
                  height="16"
                  viewBox="0 0 7 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.405472 8.72087C0.100418 8.28871 0.100418 7.71132 0.405472 7.27916L5.23586 0.436111C5.50127 0.0601121 6.02124 -0.0295381 6.39723 0.235874C6.77323 0.501285 6.86288 1.02125 6.59747 1.39725L1.9367 8.00001L6.59747 14.6028C6.86288 14.9788 6.77323 15.4987 6.39723 15.7642C6.02124 16.0296 5.50127 15.9399 5.23586 15.5639L0.405472 8.72087Z"
                    fill="white"
                  />
                </svg>
              </button>

              <button
                aria-label="See next slide"
                className="swiper-button-next !right-2 h-3 w-4 after:hidden md:h-4"
                type="button"
              >
                <svg
                  className="h-full w-full"
                  width="7"
                  height="16"
                  viewBox="0 0 7 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.42789 7.27913C6.73294 7.71129 6.73294 8.28868 6.42789 8.72084L1.5975 15.5639C1.33209 15.9399 0.812122 16.0295 0.436124 15.7641C0.0601244 15.4987 -0.0295248 14.9788 0.235886 14.6028L4.89666 7.99999L0.235886 1.39722C-0.0295248 1.02122 0.0601244 0.501259 0.436124 0.235847C0.812122 -0.0295637 1.33209 0.0600856 1.5975 0.436085L6.42789 7.27913Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
          ) : null}
        </Swiper>
      ) : null}

      {ctaLink && ctaLink.text && ctaLink.url ? (
        <div
          className={`${appCtaBg} absolute right-0 top-0 z-[1] hidden h-full min-w-[150px] items-center justify-center px-[1.25rem] text-sh font-bold uppercase text-white md:flex`}
        >
          <Link
            aria-label={ctaLink.text}
            className="focus-link py-[0.125rem] text-sm text-white hover:text-medDesert"
            draggable={false}
            href={ctaLink.url}
            newTab={ctaLink.newTab}
          >
            <span className="">{ctaLink.text}</span>
          </Link>
        </div>
      ) : null}
    </div>
  );
}

Promobar.displayName = 'Promobar';
