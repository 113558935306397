import { COLORS } from './common';

const label = {
  label: 'Label',
  name: 'label',
  component: 'text',
};

const isColor = {
  label: 'Uses Color Swatches',
  name: 'isColor',
  component: 'toggle',
  description:
    'Matches option value with color value set in:\nStorefront Settings > Product > Colors',
  toggleLabels: {
    true: 'On',
    false: 'Off',
  },
};

const defaultOpen = {
  label: 'Default Open',
  name: 'defaultOpen',
  component: 'toggle',
  description: 'Applicable only to filter dropdowns on tablet and desktop',
  toggleLabels: {
    true: 'On',
    false: 'Off',
  },
};

const orderValuesBy = {
  label: 'Order Options By',
  name: 'orderValuesBy',
  component: 'radio-group',
  direction: 'horizontal',
  variant: 'radio',
  options: [
    { label: 'Alphabet', value: 'alphabet' },
    { label: 'Number', value: 'number' },
    { label: 'Custom', value: 'custom' },
  ],
};

const customOrder = {
  label: 'Custom Order',
  name: 'customOrder',
  component: 'list',
  field: {
    component: 'text',
  },
};

export default {
  label: 'Collection',
  name: 'collection',
  component: 'group',
  description: 'Filters, sort, pagination, product item, promotion',
  fields: [
    {
      label: 'Filters',
      name: 'filtering',
      component: 'group',
      description: 'Enable, custom filters, show option count',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Show Option Count',
          name: 'showCount',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Sticky To Top',
          name: 'sticky',
          component: 'toggle',
          description: 'Sticky to top of left column on tablet/desktop',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Colors Mapping',
          name: 'colorsMapping',
          component: 'group',
          fields: [
            {
              label: 'Color',
              name: 'color',
              component: 'group-list',
              itemProps: {
                label: '{{item.mapping}}',
              },
              fields: [
                {
                  label: 'Mapping color',
                  name: 'mapping',
                  component: 'text',
                  description: `Mapping color`,
                },
                {
                  label: 'Color List',
                  name: 'colorList',
                  component: 'textarea',
                  description:
                    'Enter values with each value separated by a comma',
                },
              ],
              defaultItem: {
                mapping: '',
                colorList: '',
              },
            },
          ],
        },
        {
          label: 'Filters',
          name: 'filters',
          component: 'blocks',
          description: `A filter's options are automatically determined by the products in the collection.\n\nNote: a filter will be hidden if there are no options, or only has one option, which has a count equal to the total number of products in the collection.`,
          templates: {
            price: {
              label: 'Price Range',
              key: 'price',
              itemProps: {
                label: 'Price Range',
              },
              defaultItem: {
                label: 'Price',
                ranges: [],
                defaultOpen: true,
              },
              fields: [
                label,
                {
                  label: 'Price Ranges',
                  name: 'ranges',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.label}}',
                  },
                  fields: [
                    {
                      label: 'Label',
                      name: 'label',
                      component: 'text',
                      description:
                        'e.g. "Under $50", "$50 to $100", "$100 and over"',
                    },
                    {
                      label: 'Minimum Price (inclusive)',
                      name: 'min',
                      component: 'number',
                      description: 'Leave blank or add 0 for no minimum price',
                    },
                    {
                      label: 'Maximum Price (exclusive)',
                      name: 'max',
                      component: 'number',
                      description: 'Leave blank for no maximum price',
                    },
                  ],
                },
                defaultOpen,
              ],
            },
            option: {
              label: 'Product Option',
              key: 'option',
              itemProps: {
                label: 'Option: {{item.label}}',
              },
              defaultItem: {
                label: 'Option Label',
                isColor: false,
                defaultOpen: true,
                orderValuesBy: 'alphabet',
              },
              fields: [
                {
                  label: 'Option Name',
                  name: 'name',
                  component: 'text',
                  description: `Letter casing must be same as name in Shopify, e.g. "Color", "Size"`,
                },
                label,
                isColor,
                defaultOpen,
                orderValuesBy,
                customOrder,
              ],
            },
            tag: {
              label: 'Product Tag',
              key: 'tag',
              itemProps: {
                label: 'Tag: {{item.label}}',
              },
              defaultItem: {
                label: 'Tag Label',
                isColor: false,
                defaultOpen: true,
                orderValuesBy: 'alphabet',
              },
              fields: [
                {
                  label: 'Tag Name',
                  name: 'name',
                  component: 'text',
                  description: `Letter casing must be same as name in Shopify, e.g. "colorfilter", "material"\n\nNote: Shopify tags must separate name and value with "::", e.g. "colorfilter::Red"`,
                },
                label,
                isColor,
                defaultOpen,
                orderValuesBy,
                customOrder,
              ],
            },
            productType: {
              label: 'Product Type',
              key: 'productType',
              itemProps: {
                label: 'Product Type',
              },
              defaultItem: {
                label: 'Product Type',
                defaultOpen: true,
                orderValuesBy: 'alphabet',
              },
              fields: [
                label,
                defaultOpen,
                {
                  label: 'Order Options By',
                  name: 'orderValuesBy',
                  component: 'radio-group',
                  direction: 'horizontal',
                  variant: 'radio',
                  options: [
                    { label: 'Alphabet', value: 'alphabet' },
                    { label: 'Custom', value: 'custom' },
                  ],
                },
                customOrder,
              ],
            },
          },
          defaultValue: [
            {
              _template: 'productType',
              name: '',
              label: 'Product Type',
              isColor: false,
              defaultOpen: true,
              orderValuesBy: 'alphabet',
            },
            {
              _template: 'option',
              name: 'Color',
              label: 'Color',
              isColor: true,
              defaultOpen: true,
              orderValuesBy: 'alphabet',
            },
            {
              _template: 'option',
              name: 'Size',
              label: 'Size',
              isColor: false,
              defaultOpen: true,
              orderValuesBy: 'custom',
              customOrder: [
                'One Size',
                'OS',
                'O S',
                'O/S',
                'XXS',
                'XX-Small',
                '2XS',
                'XS',
                'X-Small',
                'S',
                'Small',
                'M',
                'Medium',
                'L',
                'Large',
                'XL',
                'X-Large',
                'XXL',
                'XX-Large',
                '2XL',
                'XXXL',
                'XXX-Large',
                '3XL',
              ],
            },
            {
              _template: 'price',
              label: 'Price',
              ranges: [
                {
                  label: 'Under $50',
                  min: 0,
                  max: 50,
                },
                {
                  label: '$50 to $100',
                  min: 50,
                  max: 100,
                },
                {
                  label: '$100 and over',
                  min: 100,
                },
              ],
              defaultOpen: true,
            },
          ],
        },
      ],
    },
    {
      label: 'Pagination',
      name: 'pagination',
      component: 'group',
      description: 'Enable, products per load, load type, load text',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          component: 'toggle',
          description: 'Loads products in segments',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Products Per Load',
          name: 'resultsPerPage',
          component: 'number',
          description:
            'Count includes any promo tiles that may be positioned within each load',
        },
        {
          label: 'Load Type',
          name: 'loadType',
          component: 'radio-group',
          direction: 'horizontal',
          variant: 'radio',
          options: [
            { label: 'Button', value: 'button' },
            { label: 'Infinite Scroll', value: 'infinite' },
          ],
        },
        {
          label: 'Load Text',
          name: 'loadText',
          component: 'text',
        },
        {
          label: 'Load Button Style',
          name: 'buttonStyle',
          component: 'select',
          options: [
            { label: 'Orion Fill', value: 'button--orion' },
            { label: 'Black Fill', value: 'button--black' },
            { label: 'Light Fill', value: 'button--light' },
            { label: 'Dark Outline', value: 'button--dark-outline' },
            { label: 'Light Outline', value: 'button--light-outline' },
          ],
        },
      ],
      defaultValue: {
        enabled: true,
        resultsPerPage: 24,
        loadType: 'button',
        loadText: 'Load More',
        buttonStyle: 'button--black',
      },
    },
    {
      label: 'Product Item',
      name: 'productItem',
      component: 'group',
      description: 'Star rating, color variant selector, quick shop',
      fields: [
        {
          label: 'Enable Star Rating',
          name: 'enabledStarRating',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Color Variant Selector',
          name: 'enabledColorSelector',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Color Name On Hover',
          name: 'enabledColorNameOnHover',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Enable Quick Shop',
          name: 'enabledQuickShop',
          component: 'toggle',
          description:
            'Note: Quick shop is hidden on mobile and will only show if the product item has only one variant or multiple variants through a single option, e.g. "Size"',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Quick Shop Text (Multi-variant)',
          name: 'quickShopMultiText',
          description: 'Use {{option}} to display the variant option name',
          component: 'text',
        },
        {
          label: 'Quick Shop Text (Single-variant)',
          name: 'quickShopSingleText',
          component: 'text',
        },
      ],
      defaultValue: {
        enabledStarRating: true,
        enabledColorSelector: true,
        enabledColorNameOnHover: false,
        enabledQuickShop: true,
        quickShopMultiText: '+ Quick Add {{option}}',
        quickShopSingleText: '+ Quick Add',
      },
    },
    {
      label: 'Promotion',
      name: 'promotion',
      component: 'group',
      description: 'Promo tile campaigns',
      fields: [
        {
          label: 'Promo Tile Campaigns',
          name: 'campaigns',
          component: 'group-list',
          itemProps: {
            label: '{{item.name}}',
          },
          fields: [
            {
              label: 'Enable',
              name: 'enabled',
              component: 'toggle',
              toggleLabels: {
                true: 'On',
                false: 'Off',
              },
            },
            {
              label: 'Name',
              name: 'name',
              component: 'text',
            },
            {
              label: 'Promo Tiles',
              name: 'promoTiles',
              component: 'group-list',
              itemProps: {
                label: '{{item.position}}: {{item.text.heading}}',
              },
              fields: [
                {
                  label: 'Grid Position',
                  name: 'position',
                  component: 'number',
                  description: 'Assigns grid order',
                },
                {
                  label: 'Grid Width',
                  name: 'gridWidth',
                  component: 'select',
                  options: [
                    { label: 'Normal', value: 'normal' },
                    { label: 'Large', value: 'large' },
                  ],
                  defaultValue: 'normal',
                },
                {
                  label: 'Aspect Ratio',
                  name: 'aspectRatio',
                  component: 'select',
                  options: [
                    { label: '1:1', value: 'aspect-[1/1]' },
                    { label: '4:5', value: 'aspect-[4/5]' },
                    { label: '3:4', value: 'aspect-[3/4]' },
                    { label: '2:3', value: 'aspect-[2/3]' },
                    { label: '9:16', value: 'aspect-[9/16]' },
                    { label: 'Fill', value: 'h-full' },
                  ],
                },
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                  description: 'Makes the entire tile a clickable link',
                },
                {
                  label: 'Link Style',
                  name: 'LinkStyle',
                  component: 'select',
                  options: [
                    { label: 'Orion Fill', value: 'button--orion' },
                    { label: 'Black Fill', value: 'button--black' },
                    { label: 'Light Fill', value: 'button--light' },
                    { label: 'Dark Outline', value: 'button--dark-outline' },
                    { label: 'Light Outline', value: 'button--light-outline' },
                  ],
                  defaultValue: 'button--light-outline',
                },
                {
                  label: 'Background Settings',
                  name: 'background',
                  component: 'group',
                  description: 'Background color, image, video, dark overlay',
                  fields: [
                    {
                      label: 'Background Color',
                      name: 'bgColor',
                      component: 'select',
                      options: COLORS,
                    },
                    {
                      label: 'Image Alt',
                      name: 'alt',
                      component: 'text',
                      description: 'Brief description of image',
                    },
                    {
                      label: 'Image',
                      name: 'image',
                      component: 'image',
                      description: 'Overrides background color',
                    },
                    {
                      label: 'Video URL',
                      name: 'videoSrc',
                      component: 'text',
                      description: 'Overrides image. Autoplays once in view',
                    },
                    {
                      label: 'Video Poster Image',
                      name: 'videoPoster',
                      component: 'image',
                      description: 'First frame of video while video loads',
                    },
                    {
                      label: 'Dark Overlay',
                      name: 'darkOverlay',
                      component: 'toggle',
                      description: 'Adds 20% opacity black overlay over media',
                      toggleLabels: {
                        true: 'On',
                        false: 'Off',
                      },
                    },
                  ],
                },
                {
                  label: 'Text Settings',
                  name: 'text',
                  component: 'group',
                  description: 'Heading, subtext, text color',
                  fields: [
                    {
                      label: 'Heading',
                      name: 'heading',
                      component: 'html',
                    },
                    {
                      label: 'Heading Size (Mobile) - Pixels',
                      name: 'headingSize',
                      component: 'number',
                      defaultValue: '28',
                      description: 'Enter number only',
                    },
                    {
                      label: 'Subtext',
                      name: 'subtext',
                      component: 'html',
                    },
                    {
                      label: 'Text Color',
                      name: 'textColor',
                      component: 'select',
                      options: COLORS,
                    },
                    {
                      label: 'Text Align',
                      name: 'textAlign',
                      component: 'select',
                      options: [
                        { label: 'Left', value: 'text-left' },
                        { label: 'Center', value: 'text-center' },
                        { label: 'Right', value: 'text-right' },
                      ],
                      defaultValue: 'text-center',
                    },
                    {
                      label: 'Text block vertical Align',
                      name: 'verticalAlign',
                      component: 'select',
                      options: [
                        { label: 'Top', value: 'items-start' },
                        { label: 'Center', value: 'items-center' },
                        { label: 'Bottom', value: 'items-end' },
                      ],
                      defaultValue: 'items-center',
                    },
                    {
                      label: 'Text block horizontal Align',
                      name: 'horizontalAlign',
                      component: 'select',
                      options: [
                        { label: 'Left', value: 'justify-start' },
                        { label: 'Center', value: 'justify-center' },
                        { label: 'Right', value: 'justify-end' },
                      ],
                      defaultValue: 'justify-center',
                    },
                  ],
                },
              ],
              defaultItem: {
                position: 5,
                aspectRatio: 'aspect-[3/4]',
                background: { bgColor: 'transparent', darkOverlay: false },
                text: {
                  heading: 'Promo Tile Heading',
                  subtext:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                  textColor: 'var(--text)',
                },
              },
            },
            {
              label: 'Collection Handles',
              name: 'collections',
              component: 'list',
              description:
                'Add all collection handles that will run this campaign, e.g. "all", "best-sellers".\n\nTo apply to search results page, add "search"',
              field: {
                component: 'text',
              },
            },
          ],
          defaultItem: {
            enabled: true,
            name: 'Campaign',
            promoTiles: [
              {
                position: 5,
                aspectRatio: 'aspect-[3/4]',
                background: { bgColor: 'transparent', darkOverlay: false },
                text: {
                  heading: 'Promo Tile Heading',
                  subtext:
                    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                  textColor: 'var(--text)',
                },
              },
            ],
            collections: ['all'],
          },
        },
      ],
    },
    {
      label: 'Sort',
      name: 'sort',
      component: 'group',
      description: 'Enable, sort labels',
      fields: [
        {
          label: 'Enable',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Default Label',
          name: 'defaultLabel',
          component: 'text',
        },
        {
          label: 'Price High to Low Label',
          name: 'highToLowLabel',
          component: 'text',
        },
        {
          label: 'Price Low to High Label',
          name: 'lowToHighLabel',
          component: 'text',
        },
        {
          label: 'A to Z Label',
          name: 'aToZLabel',
          component: 'text',
        },
        {
          label: 'Z to A Label',
          name: 'zToALabel',
          component: 'text',
        },
      ],
      defaultValue: {
        defaultLabel: 'Featured',
        highToLowLabel: 'High to Low',
        lowToHighLabel: 'Low to High',
        aToZLabel: 'A - Z',
        zToALabel: 'Z - A',
      },
    },
  ],
};
