import { useSettings } from '@backpackjs/storefront';
import { useEffect, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import { Image } from '@/snippets';
import { useGlobalContext } from '../../contexts';

export function GetItBy() {
  const {
    actions: { openModal },
  } = useGlobalContext();
  const settings = useSettings();
  const getItByMessage = settings?.product?.getItByMessage;
  const modalContent = getItByMessage?.modalContent || {};
  const { title, content } = modalContent;
  const [loaded, setLoaded] = useState(false);
  const { asPath } = useRouter();

  const handleGetItByClick = useCallback(
    async (event) => {
      if (
        event?.target?.closest('[href*="#getItByContent"]') ||
        (event?.target?.getAttribute('href') &&
          event?.target?.getAttribute('href')?.indexOf('#getItByContent') !==
            -1)
      ) {
        // Get it by modal
        openModal(
          <div className="scrollbar -my-5 overflow-auto px-1 text-center md:-my-4 md:px-4">
            {!!getItByMessage?.image?.src && (
              <Image
                alt={`${getItByMessage.imageAlt} icon`}
                fill={false}
                width="68px"
                height="68px"
                className="mx-auto mb-2 block h-12 w-12 md:h-[68px] md:w-[68px]"
                src={getItByMessage.image.src}
              />
            )}
            {!!title && <h2 className="m-0 text-center uppercase">{title}</h2>}
            {!!content && (
              <div className="mt-4 text-center [&_p:last-child]:mb-0 [&_table]:mb-0 [&_table]:mt-6 [&_table]:w-full [&_table]:table-fixed">
                <ReactMarkdown
                  children={content}
                  remarkPlugins={[remarkGfm, remarkBreaks]}
                  rehypePlugins={[rehypeRaw]}
                />
              </div>
            )}
          </div>,
          { className: 'max-w-[710px]' }
        );
      }
    },
    [getItByMessage]
  );

  useEffect(() => {
    setLoaded(true);
    return () => {
      setLoaded(false);
    };
  }, []);
  useEffect(() => {
    const getItByActions = Array.from(
      document.querySelectorAll('*[href*="#getItByContent"]')
    );
    getItByActions.forEach((action) => {
      action.addEventListener('click', (e) => {
        e.preventDefault();
        return false;
      });
    });
    document.body.addEventListener('click', handleGetItByClick);
    return () => {
      document.body.removeEventListener('click', handleGetItByClick);
    };
  }, [loaded, asPath]);

  return null;
}

GetItBy.displayName = 'GetItBy';
