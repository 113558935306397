import { useCallback, useEffect, useState } from 'react';
import { store, useCustomer } from '@backpackjs/storefront';

export function useDataLayerAccount({
  generateUserProperties,
  userDataEvent,
  userDataEventTriggered,
}) {
  const customer = useCustomer();

  const accessToken = store.recoil.useRecoilValue(store.state.accessToken);
  const [loggedIn, setLoggedIn] = useState(false);
  const [registered, setRegistered] = useState(false);

  const loggedInEvent = useCallback(({ userProperties }) => {
    const event = {
      event: 'dl_login',
      user_properties: userProperties,
    };

    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
    window.ElevarDataLayer.push(event);
  }, []);

  const registeredEvent = useCallback(({ userProperties }) => {
    const event = {
      event: 'dl_sign_up',
      user_properties: userProperties,
    };

    window.ElevarDataLayer = window.ElevarDataLayer ?? [];
    window.ElevarDataLayer.push(event);
  }, []);

  useEffect(() => {
    const customerLoggedIn = PubSub.subscribe(
      'CUSTOMER_LOGGED_IN',
      async () => {
        setLoggedIn(true);
      }
    );
    const customerRegistered = PubSub.subscribe(
      'CUSTOMER_REGISTERED',
      async () => {
        setRegistered(true);
      }
    );
    return () => {
      if (customerLoggedIn) {
        PubSub.unsubscribe(customerLoggedIn);
      }
      if (customerRegistered) {
        PubSub.unsubscribe(customerRegistered);
      }
    };
  }, [customer, accessToken?.token]);

  // Generate new base data after customer login and customer profile is ready
  // Trigger 'dl_user_data' and 'dl_login' events
  useEffect(() => {
    if (
      !customer ||
      !accessToken?.token ||
      !loggedIn ||
      !userDataEventTriggered
    )
      return;
    generateUserProperties({ customer, accessToken: accessToken?.token }).then(
      (userProperties) => {
        userDataEvent({ userProperties });
        loggedInEvent({ userProperties });
      }
    );
  }, [!!customer, accessToken?.token, loggedIn, userDataEventTriggered]);

  // Generate new base data after customer register and customer profile is ready
  // Trigger 'dl_user_data' and 'dl_sign_up' events
  useEffect(() => {
    if (
      !customer ||
      !accessToken?.token ||
      !registered ||
      !userDataEventTriggered
    )
      return;
    generateUserProperties({ customer, accessToken: accessToken?.token }).then(
      (userProperties) => {
        userDataEvent({ userProperties });
        registeredEvent({ userProperties });
      }
    );
  }, [!!customer, accessToken?.token, registered, userDataEventTriggered]);
}
