import { Image, Link } from '../../snippets';

export function ImageTile({ aspectRatio, content, item }) {
  return (
    <Link
      aria-label={item.link.text}
      className="w-full"
      href={content?.clickableImage ? item.link.url : ''}
      newTab={item.link.newTab}
    >
      <div className={`relative bg-offWhite ${aspectRatio}`}>
        {item.image?.src && (
          <Image
            alt={item.alt}
            className={`${item.position}`}
            fill
            sizes="(min-width: 1024px) 30vw, (min-width: 768px) 50vw, 100vw"
            src={item.image.src}
          />
        )}
      </div>

      <div
        className={`pointer-events-none absolute inset-0 flex h-full w-full p-6 text-center md:p-8 ${
          content?.darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : ''
        } ${content?.contentPosition}`}
      >
        <div className="pointer-events-auto flex flex-col gap-3 lg:gap-4">
          {item.heading && (
            <h3 className="text-xl text-white sm:text-2xl">{item.heading}</h3>
          )}

          {!content?.hideButton && item.link?.text && (
            <div className="">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {content?.clickableImage ? (
                <button
                  aria-label={item.link.text}
                  className={`${content?.buttonStyle}`}
                  type='button'
                >{item.link.text}</button>  
              ) : (
                <Link
                  aria-label={item.link.text}
                  className={`${content?.buttonStyle}`}
                  href={item.link.url}
                  newTab={item.link.newTab}
                >
                  {item.link.text}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

ImageTile.displayName = 'ImageTile';
