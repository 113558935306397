import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

export function Badges({
  selectedVariant = null,
  className = '',
  isDraft, // in customizer, badge added to indicate product is draft
  tags = [],
}) {
  const badgeColorsMap = {
    sale: {
      tag: 'Sale',
      bgColor: 'var(--lava)',
      textColor: 'var(--white)',
      borderColor: 'var(--lava)',
    },
    'final sale': {
      tag: 'Sale',
      bgColor: 'var(--white)',
      textColor: 'var(--black)',
      borderColor: 'var(--black)',
    },
    new: {
      tag: 'New',
      bgColor: 'var(--white)',
      textColor: 'var(--black)',
      borderColor: 'var(--black)',
    },
    'best seller': {
      tag: 'Best Seller',
      bgColor: 'var(--white)',
      textColor: 'var(--black)',
      borderColor: 'var(--black)',
    },
    'out of stock': {
      tag: 'Out of Stock',
      bgColor: 'var(--white)',
      textColor: 'var(--black)',
      borderColor: 'var(--black)',
    }
  };

  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });
  
  const isLoading = !inventoryStatus?.success;
  const productIsSoldOut = !isLoading && !inventory?.availableForSale;
  let badge = '';
  if (productIsSoldOut) {
    badge = 'Out of Stock';
  }
  
  const badges = useMemo(() => {
    return tags.reduce(
      (acc, tag) => {
        const tag_downcase = tag.toLowerCase();
        if (tag.startsWith('badge::')) {
          const value = tag.split('badge::')[1]?.trim();
          if (!value) return acc;
          return [...acc, value];
        }
        if (
          tag_downcase == 'sale' ||
          tag_downcase == 'final sale' ||
          tag_downcase == 'new' ||
          tag_downcase == 'best seller'
        ) {
          const value = tag_downcase.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
          return [...acc, value];
        }
        return acc;
      },
      isDraft ? ['Draft'] : []
    );
  }, [isDraft, tags]);

  if (badge == '') {
    if (!badges.length) {
      return null;
    }
    if (badges.indexOf('Sale') != -1) {
      badge = 'Sale';
    } else if (badges.indexOf('Final Sale') != -1) {
      badge = 'Final Sale';
    } else if (badges.indexOf('New') != -1) {
      badge = 'New';
    } else if (badges.indexOf('Best Seller') != -1) {
      badge = 'Best Seller';
    } else {
      badge = badges[0];
    }
  }

  return (
    <div
      className={`text-label flex flex-wrap gap-2 text-[11px] text-sh-sm font-bold xs:gap-2 [&_div]:rounded-none [&_div]:px-2 [&_div]:py-1 ${className}`}
    >
      {badge != '' && (
        <div
          className="border"
          style={{
            backgroundColor:
              badgeColorsMap[badge?.toLowerCase()]?.bgColor || 'var(--white)',
            color:
              badgeColorsMap[badge?.toLowerCase()]?.textColor || 'var(--black)',
            borderColor:
              badgeColorsMap[badge?.toLowerCase()]?.borderColor ||
              'var(--black)',
          }}
        >
          {badge}
        </div>
      )}
    </div>
  );
}

Badges.displayName = 'Badges';
Badges.propTypes = {
  className: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
};
