export default {
  label: 'Cart',
  name: 'cart',
  component: 'group',
  description: 'Free shipping meter, empty cart, totals',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'html',
      defaultValue: 'Cart',
    },
    {
      label: 'Empty Cart',
      name: 'emptyCart',
      component: 'group',
      description: 'Message, link',
      fields: [
        {
          label: 'Empty Cart Message',
          name: 'message',
          component: 'text',
          defaultValue: 'There are no items in your cart.',
        },
        {
          label: 'Link',
          name: 'link',
          component: 'link',
          defaultValue: { url: '/', text: 'Continue Shopping' },
        },
      ],
    },
    {
      label: 'Free Shipping Meter',
      name: 'freeShipping',
      component: 'group',
      description: 'Enable, minimum cart spend, messages',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Minimum Cart Spend',
          name: 'minCartSpend',
          component: 'number',
          description: 'Minimum cart spend to qualify for free shipping',
        },
        {
          label: 'Progress Message',
          name: 'progressMessage',
          component: 'text',
          description:
            'Message when cart has not yet reached minimum spend. Use {{amount}} to display the remaining amount',
        },
        {
          label: 'Qualified Message',
          name: 'qualifiedMessage',
          component: 'text',
          description: 'Message when cart has qualified',
        },
      ],
      defaultValue: {
        enabled: false,
        minCartSpend: 100,
        progressMessage: `Spend {{amount}} more to receive free shipping`,
        qualifiedMessage: `You've qualified for free shipping!`,
      },
    },
    {
      label: 'Totals',
      name: 'totals',
      component: 'group',
      description: 'Heading, Subtotal text, checkout text',
      fields: [
        {
          label: 'Summary Heading',
          name: 'summaryHeading',
          component: 'text',
        },
        {
          label: 'Total Text',
          name: 'totalText',
          component: 'text',
        },
        {
          label: 'Checkout Text',
          name: 'checkoutText',
          component: 'text',
        },
      ],
      defaultValue: {
        summaryHeading: 'Order summary',
        totalText: 'Total',
        checkoutText: 'Checkout',
      },
    },
    {
      label: 'Rebuy Upsells',
      name: 'rebuyUpsells',
      component: 'group',
      description: 'Heading, Rebuy RuleSet Id',
      fields: [
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Rebuy RuleSet Id',
          name: 'rebuyRulesetId',
          component: 'number',
        },
      ],
    },
  ],
};
