import { Navigation, Pagination, Scrollbar, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link, ReviewStars, Svg } from '../../snippets';
import { Schema } from './TestimonialSlider.schema';

export function TestimonialSlider({ cms }) {
  const { heading, link, section, testimonialSlides: blocks } = { ...cms };
  const { presetTheme, fullWidth } = {
    ...section,
  };
  const maxWidthClass = fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  let bgColor = '';
  let headingColor = '';
  let textColor = '';
  let reviewStarColor = '';
  let slideBorderClass = '';
  let quoteColorClass = '';
  if (presetTheme == 'dark') {
    bgColor = 'var(--dark-orion)';
    headingColor = 'var(--white)';
    textColor = 'var(--white)';
    reviewStarColor = 'var(--dark-desert)';
    slideBorderClass = 'border-l border-orion';
    quoteColorClass = 'text-orion';
  } else if (presetTheme == 'light') {
    bgColor = 'var(--light-desert)';
    headingColor = 'var(--black)';
    textColor = 'var(--grey-5)';
    reviewStarColor = 'var(--lava)';
    slideBorderClass = 'border-l border-darkDesert';
    quoteColorClass = 'text-darkDesert';
  }
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;

  return (
    <div
      className="testimonial-slider-container"
      data-client-id={cms.id || cms.cmsId || cms.tinaId || cms.clientId}
      id={moduleID}
    >
      <style>{`
        #${moduleID}{ margin-bottom: ${section.mobilePadding || 0}px; }
        @media(min-width: 1024px) {
          #${moduleID}{ margin-bottom: ${section.desktopPadding || 0}px; }
        }
      `}</style>
      <div
        className={`${presetTheme} md-px-contained px-4 py-6 md:p-12`}
        style={{ backgroundColor: bgColor }}
      >
        <div
          className={`${maxWidthClass} relative mx-auto flex flex-col items-center overflow-hidden px-4 md:px-12`}
        >
          <h2
            className="mb-6 text-center text-sh-upper-lg uppercase md:mb-8"
            style={{ color: headingColor }}
            dangerouslySetInnerHTML={{
              __html: heading,
            }}
          />

          {blocks?.length > 0 && (
            <Swiper
              className="!static w-full overflow-visible"
              grabCursor
              modules={[Navigation, Scrollbar, Mousewheel]}
              navigation={false}
              mousewheel={{
                forceToAxis: true,
              }}
              scrollbar={{
                draggable: true,
                dragSize: 96,
              }}
              slidesPerView={1.2}
              spaceBetween={20}
              breakpoints={{
                768: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 24,
                },
              }}
            >
              {blocks.map((item, index) => {
                const rating = item?.rating ? parseFloat(item.rating) : 5;
                return (
                  <SwiperSlide key={index}>
                    <div
                      className={`${slideBorderClass} relative flex flex-col items-start pb-6 pl-8 pt-11 md:py-12 md:pl-12`}
                      style={{
                        color: textColor,
                      }}
                    >
                      <Svg
                        className={`${quoteColorClass} absolute left-4 top-0 h-4 md:left-6 md:h-6`}
                        src="/svgs/quote.svg#quote"
                        title="Testimonial Quote"
                        viewBox="0 0 21 16"
                      />

                      <ReviewStars rating={rating} color={reviewStarColor} />

                      {item.body && (
                        <h3 className="mb-0 mt-4 text-[1.25rem] font-bold italic leading-normal tracking-normal md:text-[2rem]">
                          {item.body}
                        </h3>
                      )}

                      {item.author && (
                        <p className="mb-0 mt-4 text-base font-normal">
                          {item.author}
                        </p>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          {link?.text && (
            <div className="mt-10">
              <Link
                aria-label={link.text}
                className="btn-primary"
                href={link.url}
                newTab={link.newTab}
              >
                {link.text}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

TestimonialSlider.displayName = 'TestimonialSlider';
TestimonialSlider.Schema = Schema;
