import { FLEX_POSITIONS, OBJECT_POSITIONS } from '../../settings/common';

const TEXTCOLORS = [
  { label: 'Black', value: 'var(--black)' },
  { label: 'White', value: 'var(--white)' },
];

const BGCOLORS = [
  { label: 'Black', value: 'var(--black)' },
  { label: 'White', value: 'var(--white)' },
  { label: 'Loden', value: 'var(--loden)' },
  { label: 'Dark Loden', value: 'var(--dark-loden)' },
  { label: 'Orion', value: 'var(--orion)' },
  { label: 'Dark Orion', value: 'var(--dark-orion)' },
  { label: 'Dark Desert', value: 'var(--dark-desert)' },
  { label: 'Medium Desert', value: 'var(--med-desert)' },
  { label: 'Light Desert', value: 'var(--light-desert)' },
  { label: 'Grey 1', value: 'var(--grey-1)' },
];

const image = {
  label: 'Image Settings',
  name: 'image',
  description: 'Image, image position',
  component: 'group',
  fields: [
    {
      label: 'Image Alt',
      name: 'alt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Image (tablet/desktop)',
      name: 'imageDesktop',
      component: 'image',
      description:
        'Recommended Image Size: 1400px x 360px (height can be variable)',
    },
    {
      label: 'Image Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: OBJECT_POSITIONS.desktop,
    },
    {
      label: 'Image (mobile)',
      name: 'imageMobile',
      component: 'image',
      description:
        'Recommended Image Size: 720px x 480px (height can be variable)',
    },
    {
      label: 'Image Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: OBJECT_POSITIONS.mobile,
    },
    {
      label: 'Image Height',
      name: 'heightDesktop',
      component: 'select',
      options: [
        { label: 'short', value: 'h-' },
        { label: 'normal', value: 'md:text-center md:items-center' },
        { label: 'tall', value: 'md:text-right md:items-end' },
      ],
    },
  ],
  defaultValue: {
    alt: 'Rack of green t-shirts',
    imageDesktop: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
    },
    positionDesktop: 'md:object-center',
    imageMobile: {
      src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/keagan-henman-xPJYL0l5Ii8-unsplash_20_281_29.jpg?v=1672349016',
    },
    positionMobile: 'object-center',
  },
};

const text = {
  label: 'Text Settings',
  name: 'text',
  description: 'Heading, subheading, color',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'html',
    },
    {
      label: 'Subheading',
      name: 'subheading',
      component: 'html',
    },
    {
      label: 'Text Color',
      name: 'color',
      component: 'select',
      options: TEXTCOLORS,
    },
  ],
  defaultValue: {
    heading: 'Collection Heading',
    color: 'var(--white)',
  },
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description: 'Dark overlay, content position, content alignment',
  component: 'group',
  fields: [
    {
      label: 'Enable Dark Overlay',
      name: 'darkOverlay',
      component: 'toggle',
      description: 'Adds 20% opacity black overlay over media',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
    },
    {
      label: 'Enable Content Overlay',
      name: 'contentOverlay',
      component: 'toggle',
      description:
        'Adds 70% opacity black overlay with white copy or 70% white overlay with black copy',
      toggleLabels: {
        true: 'On',
        false: 'Off',
      },
      defaultValue: true,
    },
    {
      label: 'Content Position (tablet/desktop)',
      name: 'positionDesktop',
      component: 'select',
      options: FLEX_POSITIONS.desktop,
    },
    {
      label: 'Content Alignment (tablet/desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
        { label: 'Right', value: 'md:text-right md:items-end' },
      ],
    },
    {
      label: 'Max Content Width (tablet/desktop)',
      name: 'maxWidthDesktop',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'max-w-[41.5rem]' },
        { label: 'Full', value: 'md:max-w-full' },
      ],
    },
    {
      label: 'Content Position (mobile)',
      name: 'positionMobile',
      component: 'select',
      options: FLEX_POSITIONS.mobile,
    },
    {
      label: 'Content Alignment (mobile)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
        { label: 'Right', value: 'text-right items-end' },
      ],
    },
    {
      label: 'Max Content Width (mobile)',
      name: 'maxWidthMobile',
      component: 'select',
      options: [
        { label: 'Narrow', value: 'max-w-[41.5rem]' },
        { label: 'Full', value: 'max-w-full' },
      ],
    },
  ],
  defaultValue: {
    darkOverlay: false,
    alignmentDesktop: 'md:text-center md:items-center',
    positionDesktop: 'md:justify-center md:items-center',
    maxWidthDesktop: 'max-w-[7.5rem]',
    alignmentMobile: 'text-center items-center',
    positionMobile: 'justify-center items-center',
    maxWidthMobile: 'max-w-[7.5rem]',
  },
};

const video = {
  label: 'Video Settings',
  name: 'video',
  description: 'Video link, poster image',
  component: 'group',
  fields: [
    {
      label: 'Video URL (tablet/desktop)',
      name: 'srcDesktop',
      component: 'text',
      description: 'Overrides tablet/desktop image option',
    },
    {
      label: 'Poster Image (tablet/desktop)',
      name: 'posterDesktop',
      component: 'image',
      description:
        'First frame of video while video loads. Recommended Image Size: 1400px x 360px (height can be variable)',
    },
    {
      label: 'Video URL (mobile)',
      name: 'srcMobile',
      component: 'text',
      description:
        'Overrides mobile image option. Recommended Image Size: 720px x 480px (height can be variable)',
    },
    {
      label: 'Poster Image (mobile)',
      name: 'posterMobile',
      component: 'image',
      description: 'First frame of video while video loads',
    },
  ],
};

export function Schema({ collection }) {
  if (!collection) return null;

  return {
    category: 'Collection',
    label: 'Collection Hero',
    key: 'collection-hero',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/collection-hero-preview_e3870d31-1425-4aab-bc91-9bcc087a0ce8.jpg?v=1675795219',
    fields: [
      image,
      video,
      text,
      content,
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Background color, full width, full bleed, height',
        fields: [
          {
            label: 'Hero Type',
            name: 'heroType',
            component: 'select',
            options: [
              { label: 'Stack', value: 'stack' },
              { label: 'Overlay', value: 'overlay' },
            ],
          },
          {
            label: 'Background Color (if no image)',
            name: 'bgColor',
            component: 'select',
            options: BGCOLORS,
          },
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Height (desktop)',
            name: 'heightDesktop',
            component: 'select',
            description: 'Sets desktop height of this section',
            options: [
              { label: 'Auto', value: 'lg:h-auto' },
              { label: 'Short - 240px', value: 'lg:h-[15rem]' },
              { label: 'Normal - 360px', value: 'lg:h-[22.5rem]' },
              { label: 'Tall - 480px', value: 'lg:h-[30rem]' },
            ],
          },
          {
            label: 'Height (tablet)',
            name: 'heightTablet',
            component: 'select',
            description: 'Sets tablet height of this section',
            options: [
              { label: 'Auto', value: 'md-:h-auto' },
              { label: 'Short - 240px', value: 'md-:h-[15rem]' },
              { label: 'Normal - 360px', value: 'md-:h-[22.5rem]' },
              { label: 'Tall - 480px', value: 'md-:h-[30rem]' },
            ],
          },
          {
            label: 'Height (mobile)',
            name: 'heightMobile',
            component: 'select',
            description: 'Sets mobile height of this section',
            options: [
              { label: 'Auto', value: 'h-auto' },
              { label: 'Short - 240px', value: 'h-[15rem]' },
              { label: 'Normal - 360px', value: 'h-[22.5rem]' },
              { label: 'Tall - 480px', value: 'h-[30rem]' },
            ],
          },
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          heroType: 'overlay',
          bgColor: 'var(--off-white)',
          fullWidth: true,
          fullBleed: true,
          heightDesktop: 'lg:h-[22.5rem]',
          heightTablet: 'md-:h-[22.5rem]',
          heightMobile: 'h-[15rem]',
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}
