import { Schema } from './EmbedContent.schema';

export function EmbedContent({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { section } = cms;

  return (
    <div id={moduleID} className="md-py-contained md-px-contained">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>

      {cms?.snippet && (
        <div
          className="m-auto block"
          dangerouslySetInnerHTML={{ __html: cms.snippet }}
        />
      )}
    </div>
  );
}

EmbedContent.displayName = 'EmbedContent';
EmbedContent.Schema = Schema;
