import { useState } from 'react';

import { CollectionFilterOption } from './CollectionFilterOption';
import { Svg } from '../../Svg';

export function CollectionDropdownFilter({
  activeFilters,
  addFilter,
  defaultOpen = false,
  filter,
  removeFilter,
  showCount,
  swatchesMap,
}) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className="py-4 md:py-5">
      <button
        aria-label={filter.label}
        className="relative flex w-full items-center justify-between gap-4 text-left px-4 md:px-0 py-1"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h3 className="m-0 uppercase text-sh-upper font-bold">{filter.label}</h3>

        <Svg
          className={`text-current w-5 ${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down-wide.svg#chevron-down-wide"
          title={`Chevron ${isOpen ? 'Up' : 'Down'}`}
          viewBox="0 0 20 8"
        />
      </button>

      <ul
        className={`flex-col md:items-start gap-2 list-none m-0 mt-4 md:mb-1 px-4 md:p-0 md:scrollbar md:max-h-[11.5rem] md:mr-[8.5px] ${
          isOpen ? 'flex' : 'hidden'
        }`}
      >
        {filter.values.map(({ count, value }, index) => {
          return (
            <li
              key={index}
              className="m-0"
            >
              <CollectionFilterOption
                activeFilters={activeFilters}
                addFilter={addFilter}
                count={count}
                isColor={filter.isColor}
                name={filter.name}
                removeFilter={removeFilter}
                showCount={showCount}
                swatchesMap={swatchesMap}
                value={value}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

CollectionDropdownFilter.displayName = 'CollectionDropdownFilter';
