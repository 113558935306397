import { useProductByHandle } from '@backpackjs/storefront';

import { Image } from '../Image';
import { Link } from '../Link';
import { useVariantPrices } from '../../hooks';

export function SearchItem({ closeSearch, index, item }) {
  const { product } = useProductByHandle({ handle: item.handle });

  const { price, compareAtPrice } = useVariantPrices({
    variant: product?.variants?.[0],
  });

  const url = `/products/${item.handle}`;
  const color = item.optionsMap?.Color?.[0];
  const image = item.featuredImage;

  return (
    <Link
      aria-label={`View ${product?.title}`}
      className="group/search-item relative grid grid-cols-[7.5rem_1fr] items-center gap-3 !text-black no-underline hover:decoration-1"
      href={url}
      onClick={() => {
        PubSub.publish('CLICK_SEARCH_ITEM', {
          ...product?.variants?.[0],
          image,
          index,
        });
        closeSearch();
      }}
    >
      <div
        className="bg-grey1"
        style={{
          aspectRatio: image
            ? image.width / image.height
            : 'var(--product-image-aspect-ratio)',
        }}
      >
        {image.src && (
          <Image
            alt={product?.title}
            className="mx-auto max-w-full bg-transparent object-contain"
            height="117"
            src={image.src}
            width={Math.floor(117 / (image.height / image.width))}
          />
        )}
      </div>

      <div className="flex flex-col justify-between gap-3 py-4">
        <div>
          <h4 className="mb-2 text-sh-sm font-bold capitalize group-hover/search-item:text-darkLava group-hover/search-item:underline">
            {product?.title}
          </h4>

          {color && <p className="m-0 text-xs">{color}</p>}
        </div>

        {(compareAtPrice || price) && (
          <div className="flex flex-wrap gap-x-1">
            <p className="m-0 min-h-[1.25rem] text-xs">{price}</p>
            {compareAtPrice && (
              <p className="m-0 text-sm text-grey4 line-through">
                {compareAtPrice}
              </p>
            )}
          </div>
        )}
      </div>
    </Link>
  );
}

SearchItem.displayName = 'SearchItem';
