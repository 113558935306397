import { useCallback } from 'react';
import {
  useStartFinishErrors,
  useCart,
  useProduct,
} from '@backpackjs/storefront';

export const useRebuy = () => {
  const { setStarted, setFinished, setErrors, reset, ...status } =
    useStartFinishErrors();
  const cart = useCart();
  const product = useProduct();
  const inputProductIds = (cart?.lines || []).reduce((acc, line) => {
    const productId = line?.variant?.product?.id?.replace(
      'gid://shopify/Product/',
      ''
    );
    if (productId && productId !== '') {
      return [...acc, productId];
    }
    return acc;
  }, []);

  if (
    product?.legacyResourceId &&
    !inputProductIds.includes(product?.legacyResourceId)
  ) {
    inputProductIds.unshift(product.legacyResourceId);
  }

  const getRebuyProducts = useCallback(
    async ({ rulesetId, shopifyProductIds, cartItemCount, limit }) => {
      try {
        reset();
        setStarted(true);
        const url = `/api/rebuy`;
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            action: 'customEndpoint',
            rulesetId,
            shopifyProductIds: shopifyProductIds || inputProductIds.join(','),
            cartItemCount,
            limit,
          }),
        };
        const response = await fetch(url, options);

        if (!response.ok) {
          setErrors(['Error with rebuy.']);
          console.error(`Response back from API not successful:`, response);
          return null;
        }
        const body = await response.json();

        if (!body?.data) {
          setErrors(['Error with rebuy.']);
          return null;
        }

        setFinished(true);
        return body;
      } catch (error) {
        setErrors(['Error with rebuy.']);
        console.error(`Error with rebuy: ${error}`);
      }
      return {
        error: 'Unknown',
      };
    },
    [cart, product, inputProductIds]
  );

  const pushRebuyEvent = useCallback(
    async ({ subject, verb, noun, shopifyProductHandle }) => {
      const uuid = window.crypto.randomUUID
        ? window.crypto.randomUUID()
        : String(Math.random()).replace('0.', '');
      const url = `/api/rebuy`;
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'rebuyEvent',
          uuid,
          subject,
          verb,
          noun,
          shopifyProductHandle,
        }),
      };
      const response = await fetch(url, options);

      if (!response.ok) {
        setErrors(['Error with rebuy.']);
        console.error(`Response back from API not successful:`, response);
        return null;
      }
      const body = await response.json();
      if (!body?.data) {
        setErrors(['Error with rebuy.']);
        return null;
      }
      return body;
    },
    [cart, product, inputProductIds]
  );

  return {
    getRebuyProducts,
    pushRebuyEvent,
    setErrors,
    reset,
    ...status,
  };
};
