import { useEffect, useState } from 'react';
import Script from 'next/script';
import { Schema } from './YotpoReviewsCarousel.schema';

export function YotpoReviewsCarousel({ cms }) {
  const [loaded, setLoaded] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { yotpoInstanceId, section } = cms;
  const styles = { backgroundColor: section?.bgColor };

  useEffect(() => {
    setLoaded(true);
    if (document.querySelector('#yotpo-widgets-script-loader')) {
      setScriptLoaded(true);
    } else {
      setScriptLoaded(false);
    }
    return () => {
      setLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (
     scriptLoaded &&
      typeof yotpoWidgetsContainer !== 'undefined' &&
      typeof yotpoWidgetsContainer?.initWidgets !== 'undefined' &&
      !document.querySelector(
        `#${moduleID} [data-yotpo-instance-id="${
          yotpoInstanceId || 466386
        }"][data-yotpo-element-loaded="true"]`
      )
    ) {
      window.yotpoWidgetsContainer.initWidgets();
    }
  }, [loaded]);

  return (
    <div id={moduleID} style={styles}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      {!scriptLoaded && (
        <Script
          id="yotpo-widgets-script-loader"
          src="https://widgetsrepository.yotpo.com/v1/loader/I0mDSYjtEhV2GxToIR7qn2Wwefnhyw5n5AaMZ9Jy?all=true"
        />
      )}
      {loaded && (
        <div
          className="yotpo-widget-instance yotpo-widget-override-css"
          data-yotpo-instance-id={yotpoInstanceId || 466386}
        />
      )}
    </div>
  );
}

YotpoReviewsCarousel.displayName = Schema().label;
YotpoReviewsCarousel.Schema = Schema;
