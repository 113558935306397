import snakeCase from 'lodash.snakecase';

export function FormField({ field }) {
  const {
    type,
    column,
    direction,
    hideLabel,
    infoMessage,
    label,
    link,
    options,
    placeholder,
    required,
    className,
    value,
    checked,
    disabled,
    errorText,
  } = field;
  const name = field.name?.startsWith('_')
    ? `_${snakeCase(field.name)}`
    : snakeCase(field.name);

  const isTextarea = type === 'textArea';
  const isRadio = type === 'radio';
  const isSelect = type === 'select';

  // Non inputs
  const isLabel = type === 'label';
  const hasLabel = !isLabel && label;

  // Uses <checkbox>
  const isCheckbox = type === 'checkbox';
  const isMultiCheckbox = type === 'multipleCheckbox';
  const isCheckboxType = isCheckbox || isMultiCheckbox;

  // Uses <input type="file">
  const isFile = type === 'file';
  const isFileType = isFile;

  // Uses <input>
  const isText = type === 'text';
  const isEmail = type === 'email';
  const isPhone = type === 'phone';
  const isDate = type === 'date';
  const isNumber = type === 'number';
  const isUrl = type === 'url';
  const isTime = type === 'time';
  const isAddress = type === 'address';
  const isHidden = type === 'hidden';
  const isInput =
    isText ||
    isEmail ||
    isPhone ||
    isDate ||
    isNumber ||
    isUrl ||
    isTime ||
    isHidden ||
    isAddress;

  const isNotFullWidth = isCheckbox || isRadio || isFileType || isCheckboxType;

  const inputClass =
    'form-field-input ' + (className || '');
  const labelClass = (isNotFullWidth ? '' : 'form-field-label');
  const selectClass = `px-5 py-3 ${inputClass}`;

  return (
    <div
      className={`flex flex-col ${isNotFullWidth ? 'items-start' : 'items-stretch'}`}
      key={name}
    >
      <label
        className={`relative ${isNotFullWidth ? 'w-auto' : 'w-full'} ${ isCheckbox ? 'flex gap-1': ''}`}
        htmlFor={name}
      >
        {isLabel && <span className={`${labelClass}`}>{label}</span>}

        {isFileType && (
          <input
            className="py-2"
            id={name}
            name={name}
            required={required}
            type="file"
            defaultValue={value}
            disabled={disabled}
          />
        )}

        {isInput && (
          <input
            className={`${inputClass}`}
            id={name}
            name={name}
            placeholder={placeholder}
            required={required}
            type={isPhone ? 'tel' : type}
            defaultValue={value}
            disabled={disabled}
          />
        )}

        {isTextarea && (
          <textarea
            className={`resize-none ${inputClass}`}
            id={name}
            name={name}
            placeholder={placeholder}
            required={required}
            type="textarea"
            disabled={disabled}
          />
        )}

        {isCheckbox && (
          <>
            <input
              id={name}
              name={name}
              required={required}
              type="checkbox"
              value="yes"
              defaultChecked={checked}
              disabled={disabled}
            />
            <div type="hidden" name={name} value="no" />
          </>
        )}

        {isMultiCheckbox && (
          <div
            className={`flex flex-wrap justify-start gap-x-4 gap-y-2 self-start ${
              direction === 'vertical' ? 'flex-col' : 'flex-row'
            }`}
          >
            {options?.map((value, index) => (
              <label
                key={`${name}.${snakeCase(value)}`}
                className="flex gap-1"
                htmlFor={`${name}.${snakeCase(value)}`}
              >
                <input
                  id={`${name}.${snakeCase(value)}`}
                  name={value}
                  type="checkbox"
                  value="yes"
                  defaultChecked={checked == index}
                />
                <div type="hidden" name={value} value="no" />
                {value}
              </label>
            ))}
          </div>
        )}

        {isRadio && (
          <div
            className={`flex flex-wrap justify-start gap-x-4 gap-y-2 self-start ${
              direction === 'vertical' ? 'flex-col' : 'flex-row'
            }`}
          >
            {options?.map((value, index) => (
              <label
                className="flex gap-1"
                htmlFor={`${name}.${snakeCase(value)}`}
                key={`${name}.${snakeCase(value)}`}
              >
                <input
                  id={`${name}.${snakeCase(value)}`}
                  name={name}
                  type="radio"
                  value={value}
                  defaultChecked={checked == index}
                  disabled={disabled}
                />
                <span>{value}</span>
              </label>
            ))}
          </div>
        )}

        {isSelect && (
          <select
            className={`${selectClass}`}
            id={name}
            name={name}
            required={required}
            defaultValue=""
            disabled={disabled}
            onChange={(e) =>
              e.target.classList.toggle('selected', e.target.value != '')
            }
          >
            {['', ...(options || [])].map((value, index) => (
              <option
                disabled={index === 0}
                hidden={index === 0}
                key={`${name}.${index}}`}
                value={value}
              >
                {value || placeholder}
              </option>
            ))}
          </select>
        )}

        {hasLabel && !hideLabel && (
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `${label}${
                link?.text
                  ? `&nbsp;<a href="${link.url}" onclick="event.preventDefault" target="_blank" style"position:inline;">${link.text}</a>`
                  : ''
              }${required ? ' *' : ''}`,
            }}
            className={`[&_a]:underline ${labelClass}`}
          />
        )}
        {errorText &&
          <span className="error-text">{errorText}</span>
        }
      </label>

      {infoMessage && (
        <p className="mt-2 text-xs text-mediumDarkGray xs:text-sm">
          {infoMessage}
        </p>
      )}
    </div>
  );
}

FormField.displayName = 'FormField';