import { useState, useEffect, useCallback } from 'react';

export function useLoopOnstoreParams() {
  const [loopOnstoreParams, setLoopOnstoreParams] = useState(null);
  const fetchLoopOnstoreParams = useCallback(async () => {
    let paramsObject = null;
    // Check if we already have saved params
    const savedParams = localStorage.getItem('loopOnstoreParams');
    paramsObject = savedParams ? JSON.parse(savedParams) : null;
    if (window.location.search.includes('loop_total')) {
      const params = new URLSearchParams(window.location.search);
      // Remove any non-loop related query params, then convert the query string
      // to an object of key/value pairs to make it easier to work with
      paramsObject = [...params.entries()]
        .filter(([key]) => key.startsWith('loop'))
        .reduce((acc, [key, value]) => {
          return {
            ...acc,

            [key]: value,
          };
        }, {});
      localStorage.setItem('loopOnstoreParams', JSON.stringify(paramsObject));
    }
    setLoopOnstoreParams(paramsObject);
  }, []);

  useEffect(() => {
    fetchLoopOnstoreParams();
  }, []);

  return { loopOnstoreParams };
}
