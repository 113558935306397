import { COLORS, OBJECT_POSITIONS } from '../../settings/common';

const BGCOLORS = [
  { label: 'Black', value: 'bg-black' },
  { label: 'White', value: 'bg-white' },
  { label: 'Transparent', value: 'bg-transparent' },
  { label: 'Loden', value: 'bg-loden' },
  { label: 'Dark Loden', value: 'bg-darkLoden' },
  { label: 'Orion', value: 'bg-orion' },
  { label: 'Dark Orion', value: 'bg-darkOrion' },
  { label: 'Dark Desert', value: 'bg-darkDesert' },
  { label: 'Medium Desert', value: 'bg-medDesert' },
  { label: 'Light Desert', value: 'bg-lightDesert' },
  { label: 'Lava', value: 'bg-lava' },
  { label: 'Dark Lava', value: 'bg-darkLava' },
  { label: 'Grey 1', value: 'bg-grey1' },
  { label: 'Grey 2', value: 'bg-grey2' },
  { label: 'Grey 3', value: 'bg-grey3' },
  { label: 'Grey 4', value: 'bg-grey4' },
  { label: 'Grey 5', value: 'bg-grey5' },
  { label: 'Error', value: 'bg-error' },
  { label: 'Success', value: 'bg-success' },
];

const BGCOLORSHOVER = [
  { label: 'Black', value: 'md:hover:bg-black' },
  { label: 'White', value: 'md:hover:bg-white' },
  { label: 'Transparent', value: 'md:hover:bg-transparent' },
  { label: 'Loden', value: 'md:hover:bg-loden' },
  { label: 'Dark Loden', value: 'md:hover:bg-darkLoden' },
  { label: 'Orion', value: 'md:hover:bg-orion' },
  { label: 'Dark Orion', value: 'md:hover:bg-darkOrion' },
  { label: 'Dark Desert', value: 'md:hover:bg-darkDesert' },
  { label: 'Medium Desert', value: 'md:hover:bg-medDesert' },
  { label: 'Light Desert', value: 'md:hover:bg-lightDesert' },
  { label: 'Lava', value: 'md:hover:bg-lava' },
  { label: 'Dark Lava', value: 'md:hover:bg-darkLava' },
  { label: 'Grey 1', value: 'md:hover:bg-grey1' },
  { label: 'Grey 2', value: 'md:hover:bg-grey2' },
  { label: 'Grey 3', value: 'md:hover:bg-grey3' },
  { label: 'Grey 4', value: 'md:hover:bg-grey4' },
  { label: 'Grey 5', value: 'md:hover:bg-grey5' },
  { label: 'Error', value: 'md:hover:bg-error' },
  { label: 'Success', value: 'md:hover:bg-success' },
];

const media = {
  label: 'Media Settings',
  name: 'media',
  description: 'Image settings, order, aspect ratio',
  component: 'group',
  fields: [
    {
      label: 'Image Settings',
      name: 'image',
      description: 'Image, image position',
      component: 'group',
      fields: [
        {
          label: 'Image Alt',
          name: 'alt',
          component: 'text',
          description: 'Brief description of image',
        },
        {
          label: 'Image (desktop)',
          name: 'imageDesktop',
          component: 'image',
          description: 'Recommended image size: 660px x 660px',
        },
        {
          label: 'Image (mobile/tablet)',
          name: 'imageMobile',
          component: 'image',
          description: 'Recommended image size: 375px x 375px',
        },
      ],
    },
    {
      label: 'Media Order (desktop)',
      name: 'mediaOrderDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: '1' },
        { label: 'Right', value: '2' },
      ],
    },
    {
      label: 'Media Order (mobile/tablet)',
      name: 'mediaOrderMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Top', value: '1' },
        { label: 'Bottom', value: '2' },
      ],
    },
  ],
  defaultValue: {
    image: {
      alt: 'Man with backpack crossing the street',
      imageDesktop: {
        src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-tn1yJqxNj-8-unsplash.jpg?v=1672787927',
      },
      imageMobile: {
        src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-tn1yJqxNj-8-unsplash.jpg?v=1672787927',
      },
    },
    mediaOrderDesktop: '1',
    mediaOrderMobile: '1',
  },
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description: 'Heading, subtext, button, text color, alignment, content width',
  component: 'group',
  fields: [
    {
      label: 'Intro Title',
      name: 'intro',
      component: 'text',
    },
    {
      label: 'Heading',
      name: 'heading',
      component: 'html',
    },
    {
      label: 'Subtext',
      name: 'subtext',
      component: 'html',
    },
    {
      label: 'Icon/Text List',
      name: 'list',
      component: 'group-list',
      description: 'Max of four items',
      itemProps: {
        label: '{{item.label}}',
      },
      validate: {
        maxItems: 4,
      },
      fields: [
        {
          label: 'Icon',
          name: 'icon',
          component: 'image',
        },
        {
          label: 'Label',
          name: 'label',
          component: 'text',
        },
      ],
    },
    {
      label: 'Button',
      name: 'buttonLink',
      component: 'link',
    },
    {
      label: 'Button Style',
      name: 'buttonStyle',
      component: 'select',
      options: [
        { label: 'Orion Fill', value: 'button--orion' },
        { label: 'Light Fill', value: 'button--light' },
        { label: 'Black Fill', value: 'button--black' },
        { label: 'Dark Outline', value: 'button--dark-outline' },
        { label: 'Light Outline', value: 'button--light-outline' },
      ],
    },
    {
      label: 'Focus Link',
      name: 'focusLink',
      component: 'link',
    },
    {
      label: 'Background Color',
      name: 'bgColor',
      component: 'select',
      options: COLORS,
      defaultValue: 'transparent',
    },
    {
      label: 'Text Color',
      name: 'textColor',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Light', value: 'light' },
        { label: 'Dark', value: 'dark' },
      ],
    },
    {
      label: 'Content Alignment (desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
      ],
    },
    {
      label: 'Content Alignment (mobile/tablet)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
      ],
    },
  ],
  defaultValue: {
    intro: 'Intro title',
    heading: 'Shop By Design Heading',
    subtext:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    buttonLink: { text: 'Shop Now', url: '' },
    buttonStyle: 'button--orion',
    focusLink: { text: 'Find your state', url: '' },
    bgColor: 'transparent',
    textColor: 'dark',
    alignmentDesktop: 'md:text-left md:items-start',
    maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
    alignmentMobile: 'text-left items-start',
  },
};

export const Schema = () => {
  return {
    category: 'Media',
    label: 'Shop By Design',
    key: 'shop-by-design',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/shop-by-design-preview.png?v=1689177657',
    fields: [
      media,
      content,
      {
        label: 'Thumbnail Grid Settings',
        name: 'grid',
        component: 'group',
        description: 'Thumbnails and their settings',
        fields: [
          {
            label: 'Thumbnails',
            component: 'group-list',
            name: 'thumbnails',
            fields: [
              {
                label: 'Thumbnail Image',
                name: 'image',
                component: 'image',
              },
              {
                label: 'Thumbnail Link',
                name: 'link',
                component: 'link',
              },
              {
                label: 'Background Color',
                name: 'bgColor',
                component: 'select',
                options: BGCOLORS,
                defaultValue: 'bg-grey1',
              },
              {
                label: 'Background Color (Hover)',
                name: 'bgColorHover',
                component: 'select',
                options: BGCOLORSHOVER,
                defaultValue: 'md:hover:bg-grey2',
              },
            ],
          },
          {
            label: 'Column Count - Desktop',
            name: 'colCount',
            component: 'select',
            options: [
              { label: '3 Columns', value: 'md:grid-cols-3' },
              { label: '4 Columns', value: 'md:grid-cols-4' },
            ],
          }
        ],
        defaultValue: {
          thumbnails: [],
          colCount: 'md:grid-cols-4',
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Section Bottom Spacing',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
};
