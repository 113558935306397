import { useEffect } from 'react';

export function InjectInlineScript({ html, id }) {
  if (!html) return null;
  const asyncLoad = () => {
    const e = document.createElement('script');
    (e.type = 'text/javascript'),
      (e.innerHTML = html),
      (e.id = id),
      e.setAttribute('id', id);
    const t = document.getElementsByTagName('script')[0];
    const b = document.getElementById(id);
    if (!b) {
      t.parentNode.insertBefore(e, t);
    }
  };

  const removeScripts = () => {
    if (!id) return;
    const b = document.getElementById(id);
    if (b) {
      t.parentNode.removeChild(b);
    }
  };

  useEffect(() => {
    if (html) {
      asyncLoad(html);
    }
    // return removeScripts;
  }, []);

  return null;
}

InjectInlineScript.displayName = 'InjectInlineScript';
