'use client';

import Script from 'next/script';
import { useEffect, useState, useCallback } from 'react';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';

export function HubspotScriptLoader() {
  const [loaded, setLoaded] = useState(false);
  const [hubspotLoader, setHubspotLoader] = useState(false);
  const [hubspotReady, setHubspotReady] = useState(false);
  const {
    state: { hubspotOpen },
    actions: { openHubspot, closeHubspot },
  } = useGlobalContext();

  const handleHubspotForm = useCallback(async (event) => {
    if (
      !event?.target?.closest('[href*="#hsForm_"]') ||
      event?.target?.getAttribute('href')?.indexOf('#hsForm_') === -1 ||
      typeof window?.hbspt?.forms !== 'object'
    )
      return;
    const formId = (
      event?.target?.closest('[href*="#hsForm_"]')
        ? event.target.closest('[href*="#hsForm_"]')
        : event?.target
    )
      ?.getAttribute('href')
      ?.split('#hsForm_')
      ?.pop();
    if (formId?.indexOf('#hsForm_') !== -1) return;

    const hubspotForms = Array.from(
      document.querySelectorAll('form[class^="#hsForm_"]')
    );
    hubspotForms.forEach((form) => {
      form.classList.add('hidden');
    });

    if (!document.querySelector(`#hsForm_${formId}`)) {
      window.hbspt.forms.create({
        target: `#hubspot-popup-forms`,
        region: 'na1',
        portalId: '24397049',
        formId: `${formId}`,
        cssClass: 'hidden',
        onFormReady: () => {
          document
            .querySelector(`#hsForm_${formId}`)
            .classList.remove('hidden');
          openHubspot();
        },
      });
    } else {
      document.querySelector(`#hsForm_${formId}`).classList.remove('hidden');
      openHubspot();
    }
  }, []);
  const handleHubspotEmbedForm = () => {
    const hubspotEmbedForms = Array.from(
      document.querySelectorAll('*[id^="hsEmbedForm_"], *[data-hs-form-id]')
    );
    if (!hubspotEmbedForms?.length || typeof window?.hbspt?.forms !== 'object')
      return;
    hubspotEmbedForms.forEach((form) => {
      let formId;
      if (form?.dataset?.hsFormId) {
        formId = form.dataset.hsFormId?.split('hsEmbedForm_')?.pop();
      } else if (form?.getAttribute('id')?.indexOf('hsEmbedForm_') !== -1) {
        formId = form?.getAttribute('id')?.split('hsEmbedForm_')?.pop();
      }
      if (formId && !form.querySelector(`#hsForm_${formId}`)) {
        window.hbspt.forms.create({
          target: form?.dataset?.hsFormId
            ? `[data-hs-form-id="hsEmbedForm_${formId}"]`
            : `#hsEmbedForm_${formId}`,
          region: 'na1',
          portalId: '24397049',
          formId: `${formId}`,
          cssClass: 'hidden',
          onFormReady: () => {
            document
              .querySelector(`#hsForm_${formId}`)
              .classList.remove('hidden');
          },
        });
      }
    });
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    setHubspotLoader(true);
    return () => {
      setHubspotLoader(false);
    };
  }, [loaded]);

  useEffect(() => {
    if (hubspotReady) {
      handleHubspotEmbedForm();
    }
    const formActions = Array.from(
      document.querySelectorAll('*[href*="#hsForm_"]')
    );
    formActions.forEach((action) => {
      action.addEventListener('click', (e) => {
        e.preventDefault();
      });
    });

    const HubspotFormEvent = (event) => {
      if (
        event?.data?.type === 'hsFormCallback' &&
        event?.data?.eventName === 'onFormSubmit'
      ) {
        if (!window.dataLayer) window.dataLayer = [];
        window.dataLayer.push({
          event: 'hubspot-form-success',
          'hs-form-guid': event.data.id,
          'event-data': event.data,
        });
      }
    };
    document.body.addEventListener('click', handleHubspotForm);
    window.addEventListener('message', HubspotFormEvent);
    return () => {
      document.body.removeEventListener('click', handleHubspotForm);
      window.removeEventListener('message', HubspotFormEvent);
    };
  }, [hubspotReady]);

  return (
    hubspotLoader && (
      <>
        <div
          className={`hubspot-forms-modal fixed top-1/2 z-50 max-h-[calc(var(--viewport-height)-2rem)] w-[calc(100%-2rem)] max-w-[30rem] -translate-x-1/2 -translate-y-1/2 overflow-hidden bg-background ${
            hubspotOpen
              ? 'pointer-events-auto left-1/2 -translate-x-1/2'
              : 'pointer-events-none left-full translate-x-full'
          }`}
        >
          <button
            aria-label="Close modal"
            className="absolute right-5 top-5 z-10 flex  h-[18px] w-[18px] items-center justify-center bg-transparent md:right-6 md:top-6 md:h-6 md:w-6"
            onClick={closeHubspot}
            type="button"
          >
            <Svg
              className="w-[18px] text-text"
              src="/svgs/icon-close.svg#icon-close"
              title="Close"
              viewBox="0 0 18 18"
            />
          </button>

          <div className="scrollbar-hide max-h-[calc(var(--viewport-height)-2rem)] overflow-y-auto px-4 py-10 md:px-6 md:py-14">
            <div id="hubspot-popup-forms" className="py-4" />
          </div>
        </div>
        <Script
          src="https://js.hsforms.net/forms/embed/v2.js"
          id="hs-script-form-loader"
          charset="utf-8"
          onReady={() => {
            setHubspotReady(true);
          }}
        />
      </>
    )
  );
}
