export default {
	label: 'Login/Register/Reset Background',
  name: 'accountBackground',
  component: 'group',
  fields: [
		{
		  label: 'Image (tablet/desktop)',
		  name: 'imageDesktop',
		  component: 'image',
		  description: "Recommended image size: 1440 x 830px",
		},
		{
		  label: 'Image (mobile)',
		  name: 'imageMobile',
		  component: 'image',
		  description: "Recommended image size: 720 x 1280px",
		}
	]
}