import { useCallback, useState } from 'react';

export function useMegaMenu() {
  const [activeIndex, setActiveIndex] = useState(-1);

  const clearUnHoverTimer = useCallback(() => {
    if (window.unHover) {
      clearTimeout(window.unHover);
      window.unHover = null;
    }
  }, []);

  const handleMenuDrawerClose = useCallback(() => {
    setActiveIndex(-1);
  }, []);

  const handleMenuDrawerStayOpen = useCallback(() => {
    clearUnHoverTimer();
    setActiveIndex(activeIndex);
  }, [activeIndex]);

  const handleMenuHoverIn = useCallback((index) => {
    clearUnHoverTimer();
    setActiveIndex(index);
  }, []);

  const handleMenuHoverOut = useCallback(() => {
    clearUnHoverTimer();
    window.unHover = setTimeout(() => {
      setActiveIndex(-1);
      clearUnHoverTimer();
    }, 100);
  }, []);

  return {
    state: {
      activeIndex,
    },
    actions: {
      handleMenuDrawerClose,
      handleMenuDrawerStayOpen,
      handleMenuHoverIn,
      handleMenuHoverOut,
    },
  };
}
