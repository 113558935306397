import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import {
  useProductByHandle,
  useProductInventoryByHandle,
} from '@backpackjs/storefront';
import { useInView } from 'react-intersection-observer';
import { Svg } from '../../snippets';
import { ProductItemMedia } from '../../snippets/ProductItem/ProductItemMedia/ProductItemMedia';
import { ProductItemPrice } from '../../snippets/ProductItem/ProductItemPrice';

export function UpsellItem({
  enabledColorSelector,
  enabledQuickShop,
  handle,
  index,
  isSearchPage,
  onClick,
  product: passedProduct,
  isRebuyPanel,
  rulesetid,
  upsells,
  setUpsells,
  setUpdate,
  setOosProducts,
  rebuyProductId,
}) {
  const { ref, inView } = useInView({
    rootMargin: '1200px',
    triggerOnce: true,
  });
  const { product: fetchedFullProduct, finished } = useProductByHandle({
    handle,
    fetchOnMount: inView,
  });
  const selectedProduct =
    fetchedFullProduct || (passedProduct?.loaded ? passedProduct : null);

  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: handle || 'null',
    withInventory: false,
  });
  const initialVariant = useMemo(() => {
    return selectedProduct?.variants?.find(
      (variant) => variant.inventoryQuantity > 0
    );
  }, [selectedProduct]);

  const title = selectedProduct?.title;
  const [isActive, setIsActive] = useState(false);
  const emptyRef = useRef(null);

  const checkmarkColor = useMemo(() => {
    return isActive ? `text-white` : `text-black`;
  }, [isActive]);

  const handleClick = useCallback(async () => {
    if (initialVariant?.id) {
      const upsellProducts = (upsells?.products || []).filter((p) => {
        return p?.id !== initialVariant?.id;
      });
      if (!isActive) {
        upsellProducts.push(initialVariant);
      }
      setUpsells({
        products: upsellProducts,
        rulesetid,
      });
    }
    setIsActive(!isActive);
    setUpdate((prev) => !prev);
    if (typeof onClick === 'function') onClick();
  }, [
    index,
    selectedProduct?.id,
    initialVariant?.id,
    isActive,
    upsells?.products,
  ]);

  useEffect(() => {
    if (
      inView &&
      selectedProduct &&
      !inventory?.availableForSale &&
      (inventoryStatus?.success || inventoryStatus?.finished)
    ) {
      setOosProducts((prev) => [...prev, selectedProduct.id]);
    }
  }, [index, inView, selectedProduct, inventory]);

  useEffect(() => {
    if (emptyRef?.current) {
      setOosProducts((prev) => [...prev, rebuyProductId]);
    }
  }, [emptyRef?.current]);

  useEffect(() => {
    if (!upsells?.products?.length) {
      setIsActive(false);
    }
  }, [upsells?.products]);

  if (
    inView &&
    selectedProduct &&
    !inventory?.availableForSale &&
    (inventoryStatus?.success || inventoryStatus?.finished)
  ) {
    return <div ref={emptyRef} />;
  }
  if (!selectedProduct && finished) {
    return <div ref={emptyRef} />;
  }

  return (
    <div
      className={`flex h-full flex-col justify-between bg-grey1 p-5 ${
        !inventory?.availableForSale &&
        (inventoryStatus?.success || inventoryStatus?.finished)
          ? 'oos'
          : ''
      }`}
      ref={ref}
    >
      {initialVariant && (
        <div className="grid h-full grid-cols-[32px_auto_1fr] items-start">
          <div className="-mt-0.5 self-center p-1">
            <button
              aria-label={`Add Product ${title}`}
              className="flex gap-2 text-left text-base text-black after:absolute after:left-0 after:top-0 after:block after:h-full after:w-full"
              onClick={handleClick}
              type="button"
            >
              <div
                className="relative h-6 w-6 flex-shrink-0 cursor-pointer appearance-none rounded-md- border border-black bg-white transition-all"
                style={{
                  backgroundColor: isActive ? 'var(--black)' : 'var(--white)',
                }}
              >
                {isActive && (
                  <Svg
                    src="/svgs/checkmark.svg#checkmark"
                    title="Checkmark"
                    viewBox="0 0 24 24"
                    className={`pointer-events-none absolute left-1/2 top-1/2 w-5 -translate-x-1/2 -translate-y-1/2 ${checkmarkColor}`}
                  />
                )}
              </div>
            </button>
          </div>
          <div className="px-3 text-black no-underline xl:pl-8 xl:pr-6">
            <div className="w-[76px] bg-grey1">
              <ProductItemMedia
                selectedProduct={selectedProduct}
                selectedVariant={initialVariant}
                enabledQuickShop={enabledQuickShop}
                enabledColorSelector={enabledColorSelector}
                isRebuyPanel={isRebuyPanel}
              />
            </div>
          </div>
          <div className="p-0">
            {title && (
              <div className="-mb-2 block w-full text-black no-underline">
                <h3 className="text-bold m-0 text-sh-lg uppercase">{title}</h3>
              </div>
            )}
            <ProductItemPrice selectedVariant={initialVariant} />
          </div>
        </div>
      )}
    </div>
  );
}

UpsellItem.displayName = 'UpsellItem';
