const Section = ({ cms }) => {
	return (
		<div id={`spacer-${cms.id || cms.cmsId || cms.tinaId}`} className="space-section-container" data-comp={Section.displayName}>
			<style>{`
        #spacer-${cms.id || cms.cmsId || cms.tinaId}.space-section-container { margin-bottom: ${cms.mobilePadding}px; }
        @media(min-width: 1024px) {
          #spacer-${cms.id || cms.cmsId || cms.tinaId}.space-section-container { margin-bottom: ${cms.desktopPadding}px; }
        }
      `}</style>
		</div>
	)
}

Section.displayName = 'Spacer';
Section.Schema = {
  category: 'Extras',
  label: Section.displayName,
  key: 'spacer',
  fields: [
    {
      component: 'number',
      name: 'mobilePadding',
      label: 'Section Bottom Margin - Mobile',
      defaultValue: 50
    },
    {
      component: 'number',
      name: 'desktopPadding',
      label: 'Section Bottom Margin - Desktop',
      defaultValue: 100
    }
  ]
};

export const Spacer = Section;