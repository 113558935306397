import { useSettings, useCart } from '@backpackjs/storefront';

import { Link } from '../Link';
import { CartEmpty } from './CartEmpty';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { FreeShippingMeter } from './FreeShippingMeter';
import { GiftWithPurchase } from './GiftWithPurchase';

export function CartPage({ closeCart }) {
  const settings = useSettings();
  const cart = useCart();
  const cartItems = cart?.lines || [];
  const { heading } = { ...settings?.cart };
  const { link } = { ...settings?.cart?.emptyCart };
  const { summaryHeading } = { ...settings?.cart?.totals };
  const hasCartItems = cartItems?.length > 0;

  return (
    <div
      className={`grid grid-cols-1 md:min-h-[80vh] ${
        hasCartItems ? 'md:grid-cols-[1fr_38%] lg:grid-cols-[1fr_32%]' : ''
      }`}
    >
      {cart?.id && (
        <div
          className={`md-px-contained pt-10 md:pb-[115px] md:pt-20 ${
            hasCartItems ? '' : 'flex flex-col justify-center pb-[100px]'
          }`}
        >
          <div
            className={`uppercase ${
              hasCartItems
                ? 'mb-5 flex items-center justify-between gap-6 px-4 md:justify-start md:px-0'
                : 'mb-12 text-center'
            }`}
          >
            <h1 className="mb-0 text-darkOrion">{heading || 'Cart'}</h1>

            {hasCartItems && link?.text && (
              <Link
                aria-label={link?.text}
                className="focus-link text-sm "
                href={link?.url}
                newTab={link?.newTab}
                onClick={closeCart}
              >
                {link?.text}
              </Link>
            )}
          </div>

          {hasCartItems ? (
            <>
              <ul className="relative my-0 border-t border-grey2 pl-0">
                {cartItems.map((item) => {
                  return (
                    <li
                      key={item.id}
                      className="list-none border-b border-b-grey2"
                    >
                      <CartItem item={item} />
                    </li>
                  );
                })}
              </ul>
              <GiftWithPurchase isSidecart={false} />
            </>
          ) : (
            <CartEmpty />
          )}
        </div>
      )}

      {hasCartItems && (
        <div className="px-contained bg-grey1 py-8 md:pb-[115px] md:pt-20">
          <h4 className="mb-4 text-center uppercase text-darkOrion md:mb-6">
            {summaryHeading}
          </h4>

          <FreeShippingMeter
            customClasses="bg-white rounded mb-6"
            cartItems={cartItems}
          />

          <CartTotals />
        </div>
      )}
    </div>
  );
}

CartPage.displayName = 'CartPage';
