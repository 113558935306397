export function TabbedThreeTilesTabs({
  activeTabIndex,
  maxWidthClass,
  setActiveTabIndex,
  tabs,
  textColor,
}) {
  return (
    <div
      className={`mx-auto px-4 ${maxWidthClass} scrollbar-hide mb-6 overflow-x-auto border-b border-border md:mb-10`}
    >
      <ul className="flex justify-center gap-4 xs:gap-8">
        {tabs?.map((tab, index) => {
          return (
            <li key={index}>
              <button
                aria-label={tab.tabName}
                className={`before:z-1 text-nav before:border-current relative flex h-full flex-col whitespace-nowrap pb-3 before:absolute before:bottom-0 before:w-full before:origin-center before:border-b-2 before:transition max-xs:pb-2 max-xs:text-xs ${
                  activeTabIndex === index
                    ? 'before:scale-100'
                    : 'before:scale-0'
                }`}
                onClick={() => setActiveTabIndex(index)}
                style={{ color: textColor }}
                type="button"
              >
                <p>{tab.tabName}</p>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

TabbedThreeTilesTabs.displayName = 'TabbedThreeTilesTabs';
