import { Fragment, useRef, useState, useCallback } from 'react';
import Reaptcha from 'reaptcha';

import { FormField } from './FormField';
import { Schema } from './FormBuilder.schema';
import { useForm } from './useForm';

export function FormBuilder({ cms }) {
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  const { endpoint, heading, fields, section, submitText } = cms;
  const { parsedFields } = useForm({ fields });

  const [errors, setErrors] = useState([]);
  const [captchaLoaded, setCaptchaLoaded] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);

  const recaptchaEnabled =
    cms.recaptchaEnabled && !!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;

  const handleSubmit = useCallback(
    async (e) => {
      try {
        setErrors([]);
        const formIsValid = formRef.current?.checkValidity();
        if (!formIsValid) {
          return false;
        }
        if (recaptchaEnabled && !captchaLoaded) {
          e.preventDefault();
          setErrors(['Please verify you are not a robot']);
          return false;
        }
        if (
          recaptchaEnabled &&
          !captchaVerified &&
          cms?.serverSideValidationEnabled
        ) {
          e.preventDefault();
          setErrors(['Captcha verification failed']);
          if (recaptchaRef?.current) {
            recaptchaRef.current.reset();
            setCaptchaLoaded(false);
          }
          return false;
        }
        formRef.current.submit();
        return true;
      } catch (error) {
        console.error(error.message);
      }
      return true;
    },
    [recaptchaEnabled, captchaLoaded, captchaVerified]
  );

  const onReCAPTCHAVerify = async (captchaCode) => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode || !recaptchaRef.current) {
      setErrors(['Please verify you are not a robot']);
      return;
    }
    setCaptchaLoaded(true);
    if (!cms?.serverSideValidationEnabled) {
      setCaptchaVerified(true);
      return;
    }
    const reCaptchaEndpoint = '/api/reCaptcha';
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        action: 'verifyReCAPTCHA',
        res: captchaCode,
      }),
    };
    const res = await fetch(reCaptchaEndpoint, options);
    const body = await res.json();
    if (body?.success) {
      setCaptchaVerified(true);
      return;
    }
    setCaptchaVerified(false);
  };

  return (
    <div id={moduleID} className="px-contained py-contained">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div className={`mx-auto ${section?.maxWidth}`}>
        {heading && (
          <h2 className="text-title-h2 mb-4 md:mb-6 lg:mb-8" dangerouslySetInnerHTML={{__html: heading}}></h2>
        )}

        <form
          action={endpoint}
          className="grid grid-cols-2 gap-8"
          encType="multipart/form-data"
          method="POST"
          ref={formRef}
        >
          {parsedFields?.map((field) => (
            <Fragment key={field.name}>
              <FormField field={field} />
            </Fragment>
          ))}

          <div className="col-span-2 flex flex-col gap-4">
            {recaptchaEnabled && (
              <div className="my-4">
                <Reaptcha
                  ref={recaptchaRef}
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                  onVerify={onReCAPTCHAVerify}
                />
              </div>
            )}

            <button
              className={`button button--orion w-auto max-w-[12rem] ${
                endpoint ? 'cursor-pointer' : 'cursor-not-allowed'
              }`}
              disabled={!endpoint}
              onClick={handleSubmit}
              type="submit"
            >
              {submitText || 'Submit'}
            </button>

            {errors?.length > 0 && (
              <div className="flex flex-col gap-1">
                {errors.map((error) => (
                  <p key={error} className="text-red-500">
                    {error}
                  </p>
                ))}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

FormBuilder.displayName = 'FormBuilder';
FormBuilder.Schema = Schema;
