import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { BackInStockModal } from './BackInStockModal';
import { Spinner } from '../Spinner';
import { useAddToCart } from './useAddToCart';

export const AddToCart = forwardRef(
  (
    { addToCartText, className, isPdp, selectedVariant, rulesetid, upsells, setUpsells },
    ref
  ) => {
    const {
      state: {
        buttonText,
        isAdding,
        isLoading,
        isNotifyMe,
        isSoldOut,
        isUnavailable,
        subtext,
      },
      actions: { handleAddToCart, handleNotifyMe, handleAddToCartWithUpsells },
    } = useAddToCart({
      addToCartText,
      selectedVariant,
      isPdp,
      rulesetid,
      upsells,
      setUpsells,
    });

    let cursorClass = '';
    if (isAdding) cursorClass = 'cursor-default';
    else if (isLoading) cursorClass = 'cursor-wait';
    else if (isUnavailable) cursorClass = 'cursor-not-allowed';
    else if (isSoldOut && !isNotifyMe) cursorClass = 'cursor-not-allowed';

    return (
      <div ref={ref}>
        <button
          aria-label={buttonText}
          className={`button h-10 md:h-12 ${
            isNotifyMe ? 'button--dark-outline' : 'button--black'
          } relative w-full ${cursorClass} ${className}`}
          disabled={isUnavailable || (isSoldOut && !isNotifyMe)}
          onClick={() => {
            if (isNotifyMe) {
              handleNotifyMe(
                <BackInStockModal selectedVariant={selectedVariant} />
              );
            } else if (upsells?.products?.length) {
              handleAddToCartWithUpsells();
            } else {
              handleAddToCart();
            }
          }}
          type="button"
        >
          {isAdding ? (
            <Spinner className="mx-auto" width="20" />
          ) : (
            <p
              className={`m-0 transition-opacity ${
                isLoading ? 'opacity-30' : ''
              }`}
            >
              {buttonText}
            </p>
          )}
        </button>

        {isPdp && subtext && (
          <p className="mt-2 text-center text-xs">{subtext}</p>
        )}
      </div>
    );
  }
);

AddToCart.displayName = 'AddToCart';
AddToCart.propTypes = {
  addToCartText: PropTypes.string,
  className: PropTypes.string,
  isPdp: PropTypes.bool,
  selectedVariant: PropTypes.object,
};
