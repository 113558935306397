import { useCartCount, useCustomer, useSettings } from '@backpackjs/storefront';
import { useEffect, useState } from 'react';
import { Link } from '../Link';
import { Image } from '../Image';
import { Svg } from '../Svg';
import { MenuItem } from './MenuItem';
import { useGlobalContext } from '../../contexts';
import { useMegaMenu } from './useMegaMenu';

export function Menu({
  handleOpenSidebar,
  handleCloseSidebar,
  setHiddenHeader,
  menuSidebarOpen,
}) {
  const customer = useCustomer();
  const {
    actions: { openCart, openSearch },
  } = useGlobalContext();
  const cartCount = useCartCount();
  const settings = useSettings();
  const { menuItems } = { ...settings?.header?.menu };
  const { logo, logoAlt } = { ...settings?.header };
  const [lastScroll, setLastScroll] = useState(0);

  const {
    state: { activeIndex },
    actions: {
      handleMenuDrawerClose,
      handleMenuHoverIn,
      handleMenuHoverOut,
      handleMenuDrawerStayOpen,
    },
  } = useMegaMenu();

  useEffect(() => {
    let threshold = 0;
    threshold = document.querySelector('header').offsetHeight;

    const setHeaderVisibility = () => {
      if (window.innerWidth >= 1024) {
        setHiddenHeader(false);
      } else {
        const currentScroll = window.pageYOffset;

        if (currentScroll <= threshold) {
          setHiddenHeader(false);
        }

        // Have you scrolled past the Promobar?
        if (currentScroll > threshold) {
          if (currentScroll > lastScroll) {
            // On scroll Down
            setHiddenHeader(true);
          } else if (currentScroll < lastScroll) {
            // On scroll Up
            setHiddenHeader(false);
          }

          setLastScroll(currentScroll);
        }
      }
    };

    window.addEventListener('scroll', setHeaderVisibility);
    return () => {
      window.removeEventListener('scroll', setHeaderVisibility);
    };
  }, [lastScroll]);

  return (
    <div className="h-[var(--header-height-mobile)] border-b border-b-black md:h-[var(--header-height)]">
      <div className="relative z-[1] mx-auto grid h-full max-w-[123rem] flex-1 grid-cols-6 gap-4 bg-background px-4 transition md:px-6 lg:grid-cols-12 lg:gap-6">
        <div className="col-span-4 grid grid-cols-4 lg:col-span-10 lg:flex">
          <div className="hidden items-center lg:flex">
            <Link
              aria-label="Go to homepage"
              href="/"
              className="relative h-[40px] w-[40px]"
            >
              {logo && logo.src ? (
                <Image
                  className="absolute left-0 top-0 h-full w-full !object-contain"
                  alt={logoAlt}
                  fill
                  sizes="50vw"
                  src={logo.src}
                />
              ) : (
                <Svg
                  className="h-10 text-text"
                  src="/svgs/logo.svg#logo"
                  title="Storefront logo"
                  viewBox="0 0 40 40"
                />
              )}
            </Link>
          </div>

          <div className="col-span-2 flex items-center">
            <nav className="hidden h-full lg:flex">
              <ul className="m-0 flex">
                {menuItems?.map((item, index) => {
                  const isHovered = index === activeIndex;
                  const hasContent =
                    item?.imageLinks?.length > 0 || item?.submenus?.length > 0;

                  return (
                    <li
                      key={index}
                      className="m-0 flex"
                      onMouseEnter={() => handleMenuHoverIn(index)}
                      onMouseLeave={handleMenuHoverOut}
                      onFocus={() => handleMenuHoverIn(index)}
                      onBlur={handleMenuHoverOut}
                    >
                      <Link
                        aria-label={item.menuItem?.text}
                        className="nav-item group relative z-[1] flex items-center px-[1.09375rem] text-black no-underline transition hover:text-lava group-hover:block focus:text-black"
                        href={item.menuItem?.url}
                        onClick={handleMenuDrawerClose}
                        newTab={item.menuItem?.newTab}
                        aria-haspopup={hasContent ? 'true' : null}
                        aria-expanded={hasContent && isHovered ? true : null}
                        aria-controls={hasContent ? 'header-menu-drawer' : null}
                      >
                        <span className={`m-0 block text-center text-sh-upper font-bold uppercase ${ item.menuItem?.text.includes('Custom') ? 'bg-grey1 py-3 px-4' : '' }`}>
                          {item.menuItem?.text}
                        </span>
                        {item.menuItem?.newTab && (
                          <span className="sr-only">(opens in a new tab)</span>
                        )}

                        {item.menuItem?.text.includes('Custom') && (
                          <button className="button button--arrow-right hidden"><span className="sr-only"></span></button>
                        )}

                        <div
                          className={`after:w-fill absolute left-1/2 top-[calc(100%_-_2px)] ${ item.menuItem?.text.includes('Custom') ? 'hidden' : '' } h-[3px] w-4/5 origin-center translate-x-[-50%] scale-0 border-t-2 border-t-lava bg-transparent transition group-hover:scale-100 ${
                            isHovered ? 'scale-100' : 'scale-0'
                          }`}
                        />
                      </Link>
                      {!!hasContent && (
                        <MenuItem
                          menuDrawerContent={item}
                          isActive={index === activeIndex}
                          handleMenuDrawerClose={handleMenuDrawerClose}
                          handleMenuDrawerStayOpen={handleMenuDrawerStayOpen}
                        />
                      )}
                    </li>
                  );
                })}
              </ul>
            </nav>

            <div className="flex items-center">
              <button
                aria-label="Open menu"
                className="w-[1.5625rem] lg:hidden"
                onClick={
                  menuSidebarOpen ? handleCloseSidebar : handleOpenSidebar
                }
                type="button"
              >
                {menuSidebarOpen ? (
                  <Svg
                    className="w-[22px]"
                    src="/svgs/close-menu.svg#close-menu"
                    title="Close Menu"
                    viewBox="0 0 22 20"
                  />
                ) : (
                  <Svg
                    className="w-6"
                    src="/svgs/menu.svg#menu"
                    title="Open Menu"
                    viewBox="0 0 24 24"
                  />
                )}
              </button>

              <button
                aria-label="Open search"
                className="header-utility ml-6 block w-[1.5625rem] lg:hidden"
                onClick={openSearch}
                type="button"
              >
                <Svg
                  className="w-[26px]"
                  src="/svgs/search.svg#search"
                  title="Open search"
                  viewBox="0 0 26 26"
                />
              </button>
            </div>
          </div>

          <div className="col-span-2 flex w-full items-center justify-center lg:hidden">
            <Link aria-label="Go to homepage" href="/">
              {logo && logo.src ? (
                <Image
                  className="absolute left-0 top-0 h-full w-full !object-contain"
                  alt={logoAlt}
                  fill
                  sizes="50vw"
                  src={logo.src}
                />
              ) : (
                <Svg
                  className="h-11 text-text"
                  src="/svgs/logo.svg#logo"
                  title="Storefront logo"
                  viewBox="0 0 40 40"
                />
              )}
            </Link>
          </div>
        </div>

        <div className="col-span-2 flex items-center justify-end">
          <button
            aria-label="Open search"
            className="header-utility hidden w-[1.5625rem] lg:block"
            onClick={openSearch}
            type="button"
          >
            <Svg
              className="w-[26px]"
              src="/svgs/search.svg#search"
              title="Open search"
              viewBox="0 0 26 26"
            />
          </button>

          <Link
            className="header-utility text-black hover:text-lava lg:ml-[2.1875rem]"
            aria-label="Go to account page"
            href={customer ? '/account/orders' : '/account/login'}
          >
            <Svg
              className="w-[26px]"
              src="/svgs/account.svg#account"
              title="Go to account page"
              viewBox="0 0 26 26"
            />
          </Link>

          <button
            aria-label="Open cart"
            className="header-utility relative ml-[2.1875rem] w-[1.5625rem]"
            onClick={openCart}
            type="button"
          >
            <Svg
              className="w-[19px]"
              src="/svgs/cart.svg#cart"
              title="Open cart"
              viewBox="0 0 19 25"
            />

            {!!cartCount && (
              <p className="absolute right-0 top-0 m-0 h-4 w-4 whitespace-nowrap rounded-[50%] bg-lava text-center text-[0.5625rem] font-bold leading-4 text-white">
                {cartCount || 0}
              </p>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

Menu.displayName = 'Menu';
