import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useLoopOnstoreParams } from '../../hooks';

export function Loopreturns() {
  const router = useRouter();
  const { loopOnstoreParams } = useLoopOnstoreParams();
  useEffect(() => {
    let paramsObject = null;
    // Check if we already have saved params
    const savedParams = localStorage.getItem('loopOnstoreParams');
    paramsObject = savedParams ? JSON.parse(savedParams) : null;
    if (window.location.search.includes('loop_total')) {
      const params = new URLSearchParams(window.location.search);
      // Remove any non-loop related query params, then convert the query string
      // to an object of key/value pairs to make it easier to work with
      paramsObject = [...params.entries()]
        .filter(([key]) => key.startsWith('loop'))
        .reduce((acc, [key, value]) => {
          return {
            ...acc,

            [key]: value,
          };
        }, {});
      localStorage.setItem('loopOnstoreParams', JSON.stringify(paramsObject));
    }
  }, []);

  useEffect(() => {
    document.querySelector('body').classList.add('loop-returns-activated');
  }, [loopOnstoreParams?.loop_redirect_url]);

  const backToLoop = () => {
    localStorage.removeItem('loopOnstoreParams');
    localStorage.removeItem('loopOnstoreCart');
    router.push(`https://${loopOnstoreParams.loop_redirect_url}`);
  };

  return (
    loopOnstoreParams && (
      <div className="loop-onstore" id="loop-onstore">
        <span className="loop-onstore__amount">{`$${
          parseFloat(loopOnstoreParams.loop_total) / 100
        }`}</span>
        <p className="loop-onstore__copy">in available credit</p>
        <button
          className="loop-onstore__back"
          type="button"
          onClick={backToLoop}
        >
          Go Back
        </button>
      </div>
    )
  );
}

Loopreturns.displayName = 'Loopreturns';
