import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Spinner, Link } from '../../snippets';
import { CategoryTile } from './CategoryTile';
import { Schema } from './ShopByCategory.schema';

export function ShopByCategory({ cms }) {
  const { content, heading, cta_link, section, tiles } = cms;

  const [swiper, setSwiper] = useState(null);

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;

  return (
    <div
      className="shop-by-category-container"
      data-client-id={cms.id || cms.cmsId || cms.tinaId || cms.clientId}
      id={moduleID}
    >
      <style>{`
        #${moduleID}{ margin-bottom: ${section.mobilePadding || 0}px; }
        @media(min-width: 1024px) {
          #${moduleID}{ margin-bottom: ${section.desktopPadding || 0}px; }
        }
      `}</style>
      <div className="lg-px-contained py-4 md:py-6">
        <div className={`mx-auto ${maxWidthClass}`}>
          {(heading || cta_link) && (
            <div className="mb-4 flex flex-wrap items-center justify-between px-4 lg:px-0">
              {heading && <h2 className="h4 mb-2 uppercase" dangerouslySetInnerHTML={{__html: heading}} />}
              {cta_link && cta_link?.url && cta_link?.text && (
                <Link
                  aria-label={cta_link.text}
                  className="focus-link mb-2 text-sm"
                  href={cta_link.url}
                  newTab={cta_link.newTab}
                >
                  {cta_link.text}
                </Link>
              )}
            </div>
          )}

          {tiles?.length > 0 && (
            <>
              {/* mobile/tablet */}
              <div
                className={`relative lg:hidden ${
                  !swiper ? 'min-h-[25rem]' : ''
                }`}
              >
                <Swiper
                  grabCursor
                  onSwiper={setSwiper}
                  slidesOffsetAfter={16}
                  slidesOffsetBefore={16}
                  slidesPerView={1.4}
                  spaceBetween={12}
                  breakpoints={{
                    768: {
                      slidesPerView: tiles.length < 3 ? 1.4 : 2.4,
                      spaceBetween: 16,
                    },
                  }}
                >
                  {swiper &&
                    tiles.slice(0, 6).map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <CategoryTile
                            content={content}
                            item={item}
                            extraClass="aspect-[4/5]"
                            mobile
                          />
                        </SwiperSlide>
                      );
                    })}
                </Swiper>

                {!swiper && (
                  <Spinner
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                    width="32"
                  />
                )}
              </div>

              {/* desktop */}
              <div
                className={`hidden grid-rows-2 gap-6 lg:grid ${
                  tiles.length < 3 ? 'grid-cols-2' : 'grid-cols-3'
                }`}
              >
                {tiles.slice(0, 6).map((item, index) => {
                  return (
                    <div
                      className={`relative ${
                        index < 6 - tiles.length ? 'row-span-2' : 'row-span-1'
                      }`}
                      key={index}
                    >
                      <CategoryTile
                        content={content}
                        item={item}
                        extraClass={`${
                          index < 6 - tiles.length
                            ? 'aspect-[4/5]'
                            : 'pt-[calc(62.5%-12px)]'
                        }`}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

ShopByCategory.displayName = 'ShopByCategory';
ShopByCategory.Schema = Schema;
