import { useEffect } from 'react';

import { ColorVariantOptions } from './ColorVariantOptions';

export function ColorVariantSelector({
  enabledColorNameOnHover,
  initialProduct,
  selectedVariant,
  setProductFromColor,
  setVariantFromColor,
  swatchesMap,
}) {
  const hasMultipleColorsFromProduct =
    !initialProduct?.grouping?.isTransformed &&
    initialProduct?.optionsMap?.Color?.length > 1;
  const hasMultipleColorsFromGrouping =
    initialProduct?.grouping?.isTransformed &&
    initialProduct.grouping.optionsMap.Color?.length > 1;
  const hasMultipleColors =
    hasMultipleColorsFromProduct || hasMultipleColorsFromGrouping;

  return hasMultipleColors && selectedVariant ? (
    <div className="mt-3">
      <ColorVariantOptions
        enabledColorNameOnHover={enabledColorNameOnHover}
        initialProduct={initialProduct}
        selectedVariant={selectedVariant}
        setProductFromColor={setProductFromColor}
        setVariantFromColor={setVariantFromColor}
        swatchesMap={swatchesMap}
      />
    </div>
  ) : null;
}

ColorVariantSelector.displayName = 'ColorVariantSelector';
