import { useEffect, useRef, useState } from 'react';
import { useSettings, useCartCount } from '@backpackjs/storefront';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Mousewheel, Scrollbar, Manipulation } from 'swiper';

import { ProductItem } from '../../snippets/ProductItem';
import { useColorSwatches, useRebuy } from '../../hooks';

import { Schema } from './RebuyRecommendation.schema';
import { Link } from '../../snippets';

function Button({ ctaLink, buttonStyle }) {
  return (
    ctaLink &&
    ctaLink?.url &&
    ctaLink?.text && (
      <Link
        aria-label={ctaLink.text}
        className={`button inline-block min-w-[10rem] px-10 text-center ${
          buttonStyle || 'button--orion'
        }`}
        href={ctaLink.url}
        newTab={ctaLink.newTab}
      >
        <span className="block">{ctaLink.text}</span>
      </Link>
    )
  );
}

export function RebuyRecommendation({ cms }) {
  const { rebuyRulesetId, cta_link, buttonStyle, heading } = cms;
  const rebuyContainer = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [, setActiveIndex] = useState(0);
  const settings = useSettings();
  const { productItem: itemSettings } = { ...settings?.collection };
  const { swatchesMap } = useColorSwatches();
  const [rebuyProducts, setRebuyProducts] = useState(null);
  const { getRebuyProducts } = useRebuy();
  const cartItemCount = useCartCount();
  const moduleID = `${Schema().key}-${cms?.id || cms?.cmsId || cms?.tinaId}`;
  const [oosProducts, setOosProducts] = useState([]);

  useEffect(() => {
    const handleRebuy = async () => {
      const rebuyProductsData = await getRebuyProducts({
        rulesetId: rebuyRulesetId,
        cartItemCount,
      });
      setRebuyProducts(rebuyProductsData?.data);
    };

    if (rebuyRulesetId && cartItemCount != null) handleRebuy();
  }, [rebuyRulesetId, cartItemCount]);

  useEffect(() => {
    const rebuyProductsFiltered = (rebuyProducts || []).reduce((acc, item) => {
      if (!oosProducts.includes(item?.admin_graphql_api_id)) {
        return [...acc, item];
      }
      return acc;
    }, []);
    setRebuyProducts(rebuyProductsFiltered);
  }, [oosProducts]);

  useEffect(() => {
    const reachRightEdge = () => {
      if (!rebuyContainer.current || rebuyProducts?.length < 5 || !swiper)
        return;
      const innerboundingRect = rebuyContainer.current.getBoundingClientRect();
      if (swiper && swiper.el) {
        swiper.el.style.marginRight = `${-1 * innerboundingRect.left}px`;
      }
      if (
        swiper?.slidesGrid?.[4] &&
        rebuyContainer.current.querySelector('.rebuy-header')
      ) {
        const headerWidth =
          rebuyContainer.current.querySelector('.rebuy-header').offsetWidth ||
          0;
        if (headerWidth > swiper.slidesGrid?.[4]) {
          rebuyContainer.current.querySelector(
            '.rebuy-header'
          ).style.paddingRight = `${headerWidth - swiper.slidesGrid[4]}px`;
        } else {
          rebuyContainer.current.querySelector('.rebuy-header').paddingRight =
            '0px';
        }
      }
    };
    reachRightEdge();
    window.addEventListener('resize', reachRightEdge);
    return () => window.removeEventListener('resize', reachRightEdge);
  }, [rebuyProducts, swiper]);

  if (!rebuyRulesetId) return null;

  return (
    rebuyRulesetId && (
      <div className="overflow-hidden">
        <div className="container mx-auto" id={moduleID}>
          <div className="nosto-container rebuy-container" ref={rebuyContainer}>
            <div className="rebuy-header mb-6 flex justify-center max-md:!pr-0 md:mb-8 md:justify-between">
              {heading && (
                <h2
                  className="rebuy-title h3 m-0 uppercase"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              )}
              {cta_link && cta_link?.url && cta_link?.text && (
                <div className="hidden flex-shrink-0 pl-4 pr-6 md:block">
                  <Button ctaLink={cta_link} buttonStyle={buttonStyle} />
                </div>
              )}
            </div>
            <Swiper
              onSwiper={setSwiper}
              scrollbar={{
                hide: false,
                draggable: true,
              }}
              navigation={false}
              mousewheel={{
                forceToAxis: true,
              }}
              modules={[A11y, Scrollbar, Mousewheel, Manipulation]}
              onSlideChange={(_swiper) => {
                setActiveIndex(_swiper.realIndex);
              }}
              slidesPerView={1.5}
              spaceBetween={16}
              grabCursor
              lazy={{
                loadPrevNext: true,
              }}
              breakpoints={{
                769: {
                  slidesPerView: 4.5,
                  spaceBetween: 24,
                },
              }}
              className="-mr-4 pb-4 md:mr-0 md:pb-6"
            >
              {(rebuyProducts || []).map((product, index) => {
                const handle = product?.handle;
                return (
                  <SwiperSlide
                    className="w-full py-2"
                    key={`rebuy-rec-product-${handle}-${index}`}
                  >
                    <ProductItem
                      enabledColorNameOnHover={
                        itemSettings?.enabledColorNameOnHover
                      }
                      enabledColorSelector
                      enabledQuickShop={itemSettings?.enabledQuickShop}
                      enabledStarRating={itemSettings?.enabledStarRating}
                      handle={handle}
                      index={index}
                      isSearchPage={false}
                      swatchesMap={swatchesMap}
                      isRebuyPanel
                      rulesetid={rebuyRulesetId}
                      setOosProducts={setOosProducts}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {cta_link && cta_link?.url && cta_link?.text && (
              <div className="my-6 text-center md:hidden">
                <Button ctaLink={cta_link} buttonStyle={buttonStyle} />
              </div>
            )}
          </div>
          <style>{`
          #${moduleID} { margin-bottom: ${cms?.mobilePadding}px; }
          @media(min-width: 1024px) {
            #${moduleID} { margin-bottom: ${cms?.desktopPadding}px; }
          }
        `}</style>
        </div>
      </div>
    )
  );
}

RebuyRecommendation.displayName = 'RebuyRecommendation';
RebuyRecommendation.Schema = Schema;
