import { Accordions } from './Accordions';
import { BlogCategories } from './BlogCategories';
import { BlogGrid } from './BlogGrid';
import { Collection } from './Collection';
import { CollectionHero } from './CollectionHero';
import { Content } from './Content';
import { EmbedContent } from './EmbedContent';
import { FiftyFifty } from './FiftyFifty';
import { FormBuilder } from './FormBuilder';
import { Hero } from './Hero';
import { Html } from './Html';
import { IconRow } from './IconRow';
import { Image } from './Image';
import { ImageTiles } from './ImageTiles';
import { Markdown } from './Markdown';
import { PressSlider } from './PressSlider';
import { ProductDetailAccordions } from './ProductDetailAccordions';
import { ProductsSlider } from './ProductsSlider';
import { ProductDescription } from './ProductDescription';
import { ProductFitFabric } from './ProductFitFabric';
import { ProductSizeChart } from './ProductSizeChart';
import { SearchResults } from './SearchResults';
import { SocialImagesGrid } from './SocialImagesGrid';
import { Spacer } from './Spacer';
import { StyleGuide } from './StyleGuide';
import { TabbedThreeTiles } from './TabbedThreeTiles';
import { TestimonialSlider } from './TestimonialSlider';
import { TextBlock } from './TextBlock';
import { ThreeTiles } from './ThreeTiles';
import { TwoTiles } from './TwoTiles';
import { Video } from './Video';
import { FourSixty } from './FourSixty';
import { EmbedFourSixty } from './EmbedFourSixty';
import { YotpoRewards } from './YotpoRewards';
import { YotpoReviewsCarousel } from './YotpoReviewsCarousel';
import { ShopByCategory } from './ShopByCategory';
import { RebuyRecommendation } from './RebuyRecommendation';
import { RebuyProductUpsells } from './RebuyProductUpsells';
import { ShopByState } from './ShopByState';
import { ShopByDesign } from './ShopByDesign';
import { PromoBar } from './PromoBar';
import { KickflipCustomizer } from './KickflipCustomizer';
import { HubspotEmbedForm } from './HubspotEmbedForm';
import { VisualCategories } from './VisualCategories';
import { InPageNavigation } from './InPageNavigation';

export default [
  Accordions,
  BlogCategories,
  BlogGrid,
  Collection,
  CollectionHero,
  Content,
  EmbedContent,
  FiftyFifty,
  FormBuilder,
  Hero,
  IconRow,
  Image,
  ImageTiles,
  Markdown,
  PressSlider,
  ProductDetailAccordions,
  ProductsSlider,
  ProductDescription,
  ProductFitFabric,
  ProductSizeChart,
  SearchResults,
  SocialImagesGrid,
  Spacer,
  StyleGuide,
  TabbedThreeTiles,
  TestimonialSlider,
  TextBlock,
  ThreeTiles,
  TwoTiles,
  Video,
  StyleGuide,
  Content,
  FourSixty,
  EmbedFourSixty,
  YotpoRewards,
  YotpoReviewsCarousel,
  Html,
  ShopByCategory,
  RebuyRecommendation,
  RebuyProductUpsells,
  ShopByState,
  ShopByDesign,
  PromoBar,
  KickflipCustomizer,
  HubspotEmbedForm,
  VisualCategories,
  InPageNavigation,
];
