'use client';

import Script from 'next/script';
import { useEffect, useState } from 'react';
import { Schema } from './HubspotEmbedForm.schema';

export function HubspotEmbedForm({ cms }) {
  const { content, hubspotFormId, section } = cms;
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const [loaded, setLoaded] = useState(false);
  const [hubspotLoader, setHubspotLoader] = useState(false);
  const [hubspotReady, setHubspotReady] = useState(false);

  const handleHubspotEmbedForm = () => {
    if (typeof window?.hbspt?.forms !== 'object') return;
    const formId = hubspotFormId;
    if (
      formId &&
      !document.querySelector(
        `#${moduleID} #hsEmbedForm_${formId} #hsForm_${formId}`
      )
    ) {
      window.hbspt.forms.create({
        target: `#hsEmbedForm_${formId}`,
        region: 'na1',
        portalId: '24397049',
        formId: `${formId}`,
        cssClass: 'hidden',
        onFormReady: () => {
          document
            .querySelector(
              `#${moduleID} #hsEmbedForm_${formId} #hsForm_${formId}`
            )
            .classList.remove('hidden');
        },
      });
    }
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    setHubspotLoader(true);
    return () => {
      setHubspotLoader(false);
    };
  }, [loaded]);

  useEffect(() => {
    if (hubspotReady) {
      handleHubspotEmbedForm();
    }
    const HubspotFormEvent = (event) => {
      if (
        event?.data?.type === 'hsFormCallback' &&
        event?.data?.eventName === 'onFormSubmit'
      ) {
        if (!window.dataLayer) window.dataLayer = [];
        window.dataLayer.push({
          event: 'hubspot-form-success',
          'hs-form-guid': event.data.id,
          'event-data': event.data,
        });
      }
    };
    window.addEventListener('message', HubspotFormEvent);
    return () => {
      window.removeEventListener('message', HubspotFormEvent);
    };
  }, [hubspotReady]);

  return hubspotFormId && hubspotLoader ? (
    <div
      id={moduleID}
      className={`${section?.hasXPadding ? 'px-contained' : ''} ${
        section?.hasYPadding ? 'py-contained' : ''
      }`}
    >
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div
        className={`mx-auto flex flex-col [&>:first-child]:mt-0 [&>:last-child]:mb-0 [&_a]:underline [&_h1]:mb-6 [&_h2]:mb-5 [&_h2]:mt-8 [&_h3]:mb-4 [&_h3]:mt-6 [&_h4]:mb-4 [&_h4]:mt-4 [&_h5]:mb-4 [&_h5]:mt-2 [&_h6]:mb-4 [&_li>p]:mb-0 [&_li]:mb-2 [&_ol>li]:list-decimal [&_ol]:mb-4 [&_ol]:pl-8 [&_p]:mb-4 [&_ul>li]:list-disc [&_ul]:mb-4 [&_ul]:pl-8 ${content?.contentAlign} ${content?.textAlign} ${section?.maxWidth}`}
      >
        <div id={`hsEmbedForm_${hubspotFormId}`} className="w-full" />
      </div>
      <Script
        src="https://js.hsforms.net/forms/embed/v2.js"
        id="hs-script-form-loader"
        charset="utf-8"
        onReady={() => {
          setHubspotReady(true);
        }}
      />
    </div>
  ) : null;
}

HubspotEmbedForm.displayName = 'Hubspot Embed Form';
HubspotEmbedForm.Schema = Schema;
