import { Schema } from './ProductSizeChart.schema';
import { Image } from '../../snippets';

export function ProductSizeChart({ cms }) {
  const { title, alt, imageDesktop, imageMobile, section, product } = cms;
  const moduleID = `${Schema({ product })?.key}-${
    cms.id || cms.cmsId || cms.tinaId
  }`;
  if (!product || (!imageDesktop?.src && !imageMobile?.src)) return null;

  return (
    <div id={moduleID}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div className="max-h-[calc(var(--viewport-height)-7rem)] pr-4 text-center md:max-h-[calc(var(--viewport-height)-9rem)] md:pr-0">
        {imageDesktop?.src && (
          <Image
            alt={alt || title || 'size guide'}
            className={`mx-auto w-auto max-w-full ${
              imageMobile?.src ? 'hidden md:block' : ''
            }`}
            fill={false}
            priority
            width="860px"
            height="360px"
            sizes="100vw"
            src={imageDesktop.src}
            srcSetSizes={[700, 1400]}
          />
        )}
        {imageMobile?.src && (
          <Image
            alt={alt || title || 'size guide'}
            className={`mx-auto w-auto max-w-full ${
              imageDesktop?.src ? 'md:hidden' : ''
            }`}
            fill={false}
            priority
            width="720px"
            height="400px"
            sizes="720"
            src={imageMobile.src}
            srcSetSizes={[360, 720]}
          />
        )}
      </div>
    </div>
  );
}

ProductSizeChart.displayName = 'ProductSizeChart';
ProductSizeChart.Schema = Schema;
