import Head from 'next/head';

export function ProductSchemaMarkup({ pageUrl, product = {} }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
                item: process.env.SITE_URL || '',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: product.title || '',
                item: pageUrl,
              },
            ],
          }),
        }}
      />

      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org/',
            '@type': 'Product',
            name: product.title || '',
            image: product.images?.[0]?.src,
            description: product.description,
            brand: {
              '@type': 'Brand',
              name: process.env.NEXT_PUBLIC_SITE_TITLE || '',
            },
            offers: !product?.variants?.length
              ? {
                  '@type': 'Offer',
                  url: pageUrl,
                  priceCurrency: 'USD',
                  price: product.priceRange?.min || '0',
                  availability: `https://schema.org/OutOfStock`,
                  itemCondition: 'https://schema.org/NewCondition',
                }
              : product.variants.map((variant) => {
                  return {
                    '@type': 'Offer',
                    url: `${product.onlineStoreUrl}?variant=${variant.legacyResourceId}`,
                    image: variant.image?.src,
                    priceCurrency: variant.currencyCode,
                    price: variant.price,
                    itemCondition: 'https://schema.org/NewCondition',
                    availability: `https://schema.org/${
                      variant.availableForSale ? 'InStock' : 'OutOfStock'
                    }`,
                  };
                }),
          }),
        }}
      />
    </Head>
  );
}

ProductSchemaMarkup.displayName = 'ProductSchemaMarkup';
