export function Schema({ product }) {
  if (!product) return null;

  return {
    category: 'Product',
    label: 'Product Size Chart',
    key: 'product-size-chart',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/product-fit-and-fabric-preview.jpg?v=1682972863',
    fields: [
      {
        label: 'Title',
        name: 'title',
        component: 'text',
        defaultValue: 'Size Guide',
      },
      {
        label: 'Image Alt',
        name: 'alt',
        component: 'text',
        description: 'Brief description of image',
      },
      {
        label: 'Image (tablet/desktop)',
        name: 'imageDesktop',
        component: 'image',
        description: 'Recommended Image Size: 1400px x 360px (height can be variable)',
      },
      {
        label: 'Image (mobile)',
        name: 'imageMobile',
        component: 'image',
        description: 'Recommended Image Size: 720px x 480px (height can be variable)',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}
