import { Image, Link } from '../../snippets';

export function VisualCategoriesTile({ content, item, extraClass, mobile }) {
  const image = mobile ? item.image_m : item.image;
  return (
    <Link
      aria-label={item.link.text}
      className={`flex h-full w-full flex-col bg-grey1 text-black no-underline hover:text-lava ${content?.contentAlign}`}
      href={content?.clickableImage ? item.link.url : ''}
      newTab={item.link.newTab}
    >
      <div
        className={`w-full p-3 ${
          content?.darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : ''
        }`}
      >
        {image?.src && (
          <div className={`relative ${extraClass}`}>
            <Image
              alt={item.alt}
              fill
              sizes="(min-width: 1024px) 30vw, (min-width: 768px) 50vw, 100vw"
              src={image.src}
            />
          </div>
        )}
      </div>

      <div className="pointer-events-none inset-0 flex w-full flex-grow flex-col px-4 pb-4 pt-3">
        <div className="pointer-events-auto flex h-full flex-col gap-2.5">
          {item.heading && (
            <h3
              className="m-0 text-sh-upper font-bold uppercase text-currentColor"
              dangerouslySetInnerHTML={{ __html: item.heading }}
            />
          )}
          {item.description && (
            <p className="mb-0 flex-grow text-sm font-normal text-black">
              {item.description}
            </p>
          )}

          {!content?.hideButton && item.link?.text && (
            <div className="mt-1">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              {content?.clickableImage ? (
                <button
                  aria-label={item.link.text}
                  className={`${
                    item?.BtnStyle || 'button button--black'
                  } w-full px-2 text-center`}
                  type="button"
                >
                  {item.link.text}
                </button>
              ) : (
                <Link
                  aria-label={item.link.text}
                  className={`${
                    item?.BtnStyle || 'button button--black'
                  } w-full px-2 text-center`}
                  href={!content?.clickableImage ? item.link.url : ''}
                  newTab={item.link.newTab}
                >
                  {item.link.text}
                </Link>
              )}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
}

VisualCategoriesTile.displayName = 'VisualCategoriesTile';
