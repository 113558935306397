import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { Image } from '../Image';
import { Link } from '../Link';

export function CollectionPromoTile({ tile }) {
  const { ref, inView, entry } = useInView({
    rootMargin: '0px',
    triggerOnce: true,
  });

  const { aspectRatio, background, link, LinkStyle, text } = tile;
  const { alt, bgColor, darkOverlay, image, videoPoster, videoSrc } = {
    ...background,
  };
  const textAlign = text?.textAlign || 'text-center';
  const verticalAlign = text?.verticalAlign || 'items-center';
  const horizontalAlign = text?.horizontalAlign || 'justify-center';

  return (
    <Link
      aria-label={text?.heading || link?.text}
      className="h-full"
      href={!link?.text && link?.url ? link?.url : null}
      newTab={link?.newTab}
      ref={ref}
    >
      <div
        className={`relative ${aspectRatio} ${
          aspectRatio === 'h-full' ? 'max-md:min-h-[315px]' : ''
        } overflow-hidden`}
        style={{
          backgroundColor: image ? 'var(--off-white)' : bgColor,
        }}
      >
        {videoSrc && (
          <video
            autoPlay
            className="promo-media absolute inset-0 h-full w-full object-cover"
            controls={false}
            loop
            muted
            playsInline
            poster={videoPoster?.src}
          >
            {inView && <source src={videoSrc} type="video/mp4" />}
          </video>
        )}

        {image?.src && !videoSrc && (
          <Image
            alt={alt}
            fill
            sizes="(min-width: 768px) 33vw, 50vw"
            src={image.src}
            className="promo-media"
          />
        )}

        {(videoSrc || image?.src) && darkOverlay && (
          <div className="pointer-events-none absolute inset-0 h-full w-full bg-[rgba(0,0,0,0.2)]" />
        )}

        {(text?.heading || text?.subtext) && (
          <div
            className={`absolute left-0 top-0 flex h-full w-full ${verticalAlign} ${horizontalAlign}`}
          >
            <div
              className={`w-full max-w-[235px] px-4 py-4 ${textAlign}`}
              style={{ color: text?.textColor }}
            >
              <h3
                className="mb-1 text-[length:var(--text-headingSize-mobile)] uppercase md:mb-5 md:text-h4"
                style={{
                  '--text-headingSize-mobile': text?.headingSize
                    ? `${text.headingSize}px`
                    : '28px',
                }}
              >
                {text?.heading}
              </h3>

              {text?.subtext && (
                <div className="my-1 text-sh-sm md:my-5 md:text-sh">
                  {text?.subtext}
                </div>
              )}
              {link?.url && link?.text && (
                <div className={`mt-5 md:mt-6 ${textAlign}`}>
                  <Link
                    aria-label={link?.text}
                    className={`button inline-block w-[170px] max-w-full px-2 ${LinkStyle}`}
                    href={link?.url}
                    newTab={link?.newTab}
                  >
                    {link?.text}
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Link>
  );
}

CollectionPromoTile.displayName = 'CollectionPromoTile';
