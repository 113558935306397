import { useMemo } from 'react';

export function useHandleize(string) {
  let handleizedValue;
  if( string && string != '' ){
    handleizedValue =  string.toString().toLowerCase()
                            .replace(/\s+/g, '-')           // Replace spaces with -
                            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                            .replace(/^-+/, '')             // Trim - from start of text
                            .replace(/-+$/, '')             // Trim - from end of text
                            .replace(/[^a-z0-9]+/g, '-')
                            .replace(/-$/, '')
                            .replace(/^-/, '');
  }

  return handleizedValue;
}