import { useEffect, useState } from 'react';

import { useSettings } from '@backpackjs/storefront';
import { Link } from '../Link';
import { SearchHeader } from './SearchHeader';
import { SearchInput } from './SearchInput';
import { SearchResults } from './SearchResults';
import { SearchSuggestions } from './SearchSuggestions';
import {
  useSearchCollectionResults,
  useSearchProductResults,
  useSearchContentResults,
} from '../../hooks';
import { useGlobalContext } from '../../contexts';

export function Search() {
  const {
    state: { searchOpen },
    actions: { closeSearch },
  } = useGlobalContext();

  const [rawTerm, setRawTerm] = useState('');
  const [term, setTerm] = useState(rawTerm);

  const { productResults } = useSearchProductResults({
    term,
    mounted: searchOpen,
  });
  const { collectionResults } = useSearchCollectionResults({
    term,
    mounted: searchOpen,
  });

  const { contentResults } = useSearchContentResults({
    term,
    mounted: searchOpen,
  });

  const settings = useSettings();
  const { contentsEnabled } = {
    ...settings?.search?.results,
  };

  const hasProductResults = productResults?.length > 0;
  const hasCollectionResults = collectionResults?.length > 0;
  const hasContentResults = contentsEnabled && contentResults?.length > 0;
  const hasResults = hasProductResults || hasCollectionResults || hasContentResults;

  const resultsCount = productResults?.length + (hasContentResults ? contentResults?.length : 0);

  useEffect(() => {
    // debounce raw term
    const handler = window.requestTimeout(() => {
      setTerm(rawTerm);
    }, 300);
    return () => {
      window.clearRequestTimeout(handler);
    };
  }, [rawTerm]);

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col justify-between overflow-hidden bg-background transition md:max-w-[23.5rem] ${
        searchOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      }`}
    >
      {searchOpen && (
        <>
          <SearchHeader closeSearch={closeSearch} />

          <SearchInput
            closeSearch={closeSearch}
            rawTerm={rawTerm}
            searchOpen={searchOpen}
            setRawTerm={setRawTerm}
          />

          {hasResults ? (
            <SearchResults
              closeSearch={closeSearch}
              collectionResults={collectionResults}
              productResults={productResults}
              contentResults={contentResults}
              term={term}
              searchOpen={searchOpen}
              setRawTerm={setRawTerm}
            />
          ) : (
            <SearchSuggestions term={term} setRawTerm={setRawTerm} />
          )}

          {resultsCount > 0 && (
            <div className="border-t border-black p-4">
              <Link
                aria-label="See all search results"
                className="button button--orion block w-full text-center no-underline hover:text-white"
                href={{ pathname: '/pages/search', query: { term } }}
                onClick={closeSearch}
              >
                See All {resultsCount} Results
              </Link>
            </div>
          )}
        </>
      )}
    </aside>
  );
}

Search.displayName = 'Search';
