import { useMemo } from 'react';

import { formatImageItemAsMediaItem } from '../../../utilities';

export function useProductItemMedia({ selectedProduct, selectedVariant }) {
  const hasNonGroupColorVariants =
    !selectedProduct?.grouping?.isTransformed &&
    selectedProduct?.optionsMap?.Color?.length > 1;

  // if multiple color variants from same product, use first media w/ color as alt
  const mediaMapByColor = useMemo(() => {
    if (!hasNonGroupColorVariants) return null;

    const colorOptions = selectedProduct.optionsMap?.Color;
    const colorKeys = colorOptions.map((color) => color.toLowerCase());

    return colorOptions.reduce((acc, color) => {
      let firstMediaIndex = 0;
      let secondMediaIndex = 1;
      const medias = selectedProduct?.media || selectedProduct?.images || [];
      const colorKey = color.toLowerCase();
      firstMediaIndex =
        medias.findIndex((item) => {
          const alt = (item.alt || item.altText)?.trim()?.toLowerCase();
          const variantImage = selectedVariant?.image?.src?.split('?')[0];
          const itemImage = (item?.preview?.image || item)?.src?.split('?')[0];
          return alt?.indexOf(colorKey) !== -1 || variantImage === itemImage;
        }) ||
        medias.findIndex((item) => {
          const alt = (item.alt || item.altText)?.trim().toLowerCase();
          return !colorKeys.includes(alt);
        });
      if (firstMediaIndex < 0) {
        firstMediaIndex = 0;
      }
      let media = [medias[firstMediaIndex]];
      secondMediaIndex =
        medias.findIndex((item) => {
          const alt = (item.alt || item.altText)?.trim().toLowerCase();
          return alt?.indexOf('hover:') !== -1 && alt?.indexOf(colorKey) !== -1;
        }) || null;
      if (secondMediaIndex >= 0) {
        media.push(medias[secondMediaIndex]);
      }
      if (!selectedProduct?.media) {
        // if shopify media transforms is off, shape image item similar to media item
        media = media.map((image) => {
          return formatImageItemAsMediaItem({
            image,
            product: selectedProduct,
          });
        });
      }
      return { ...acc, [color]: media };
    }, {});
  }, [selectedProduct?.id, selectedVariant?.id]);

  const selectedMedia = useMemo(() => {
    return hasNonGroupColorVariants && selectedVariant
      ? mediaMapByColor[selectedVariant.selectedOptionsMap?.Color]
      : selectedProduct?.media ||
          // if shopify media transforms is off, shape image item similar to media item
          selectedProduct?.images.reduce((acc, image, index) => {
            const alt = (image.alt || image.altText)?.trim().toLowerCase();
            const mediaFormat = formatImageItemAsMediaItem({
              image,
              product: selectedProduct,
            });
            if (index === 0 || alt?.indexOf('hover:') !== -1) {
              return [...acc, mediaFormat];
            }
            return acc;
          }, []);
  }, [mediaMapByColor, selectedProduct?.id, selectedVariant?.id]);

  return {
    primaryMedia: selectedMedia?.[0],
    hoverMedia: selectedMedia?.[1],
  };
}
