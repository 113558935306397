import { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { useSettings, useStartFinishErrors } from '@backpackjs/storefront';

export function useSearchContentResults({ term, mounted = true }) {
  const settings = useSettings();
  const { setStarted, setFinished, reset, ...status } = useStartFinishErrors();

  const [contentFuse, setContentFuse] = useState(null);
  const [contentResults, setContentResults] = useState(null);
  const { contentsEnabled } = { ...settings?.search?.results };

  const setContentFuseOnMount = useCallback(async () => {
    try {
      if (contentFuse) return;
      const pagesResponse = await fetch('/json/pages-list.json');
      const pagesList = await pagesResponse.json();
      const articlesResponse = await fetch('/json/articles-list.json');
      const articlesList = await articlesResponse.json();
      const list = pagesList.concat(articlesList);
      setContentFuse(
        new Fuse(list, {
          keys: ['title'],
          ignoreLocation: true,
          minMatchCharLength: 3,
          threshold: 0.3,
        })
      );
    } catch (error) {
      console.error(error.message);
    }
  }, [contentFuse]);

  const searchContentOnTermChange = useCallback(() => {
    reset();
    setStarted(true);
    if (!contentFuse) return;
    if (!term) {
      setFinished(true);
      setContentResults([]);
      return;
    }
    const results = contentFuse.search(term);
    setContentResults(
      results.reduce((acc, { item }) => {
        return [...acc, item];
      }, [])
    );
    setFinished(true);
  }, [contentFuse, term]);

  useEffect(() => {
    if (!mounted || !contentsEnabled) return;
    setContentFuseOnMount();
  }, [contentsEnabled, mounted]);

  useEffect(() => {
    searchContentOnTermChange();
  }, [term, contentFuse]);

  return { contentResults, ...status };
}
