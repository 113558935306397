import { useEffect, useCallback, useState } from 'react';

export const useYotpoProductBottomline = ({ legacyResourceId }) => {
  const [bottomline, setBottomline] = useState(null);

  const fetchYotpoBottomline = useCallback(async (id) => {
    try {
      if (!id) return;

      const endpoint = '/api/yotpo';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getYotpoProductBottomline',
          legacyResourceId: id,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      if( !data || !data?.response?.bottomline ){
        setBottomline({average_score: 0, total_reviews: 0});
      }
      setBottomline(data?.response?.bottomline);
      return;
    } catch (error) {
      console.error(`fetchYotpoBottomline error: ${error.message}`);
    }
  }, []);

  useEffect(() => {
    if (!legacyResourceId) return;
    fetchYotpoBottomline(legacyResourceId);
  }, [legacyResourceId]);

  return { bottomline };
};
