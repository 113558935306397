import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { Svg } from '../Svg';

export function FooterSocial() {
  const settings = useSettings();
  const { links } = { ...settings?.footer?.social };
  const { appStoreURLMobile } = { ...settings?.footer?.legal };

  return (
    <>
      {links?.length > 0 ? (
        <ul className="my-0 flex flex-wrap gap-8 pl-0 md:mt-9">
          {links.map(({ platform, url }, index) => {
            return (
              url && (
                <li key={index} className="mt-0 list-none">
                  <Link aria-label={platform} href={url} newTab>
                    <Svg
                      className="w-6 text-white transition hover:text-medDesert"
                      src={`/svgs/noprecache/social/${platform}.svg#${platform}`}
                      title={platform}
                      viewBox="0 0 24 24"
                    />
                    <span className="sr-only text-grey1">
                      (opens in a new tab)
                    </span>
                  </Link>
                </li>
              )
            );
          })}
        </ul>
      ) : null}

      {appStoreURLMobile && (
        <Link
          href={appStoreURLMobile}
          aria-label="Download on the App Store"
          className="ml-8 md:hidden"
        >
          <Svg
            className="w-[94px]"
            src="/svgs/app-store-download.svg#app-store-download"
            title="App Store"
            viewBox="0 0 120 40"
          />
        </Link>
      )}
    </>
  );
}

FooterSocial.displayName = 'FooterSocial';
