const COLORS = [
  { label: 'Black', value: 'var(--black)' },
  { label: 'White', value: 'var(--white)' },
  { label: 'Dark Loden', value: 'var(--dark-loden)' },
  { label: 'Dark Orion', value: 'var(--dark-orion)' },
  { label: 'Grey 1', value: 'var(--grey-1)' },
  { label: 'Light Desert', value: 'var(--light-desert)' },
  { label: 'Medium Desert', value: 'var(--med-desert)' },
];

export function Schema() {
  return {
    category: 'Text',
    label: 'Content Block',
    key: 'content-block',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/text-block-preview.jpg?v=1675730349',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
        defaultValue: 'Text Block Heading',
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'html',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        description: 'Max of two buttons',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        fields: [
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          {
            label: 'Button Style',
            name: 'style',
            component: 'select',
            options: [
              { label: 'Orion Fill', value: 'button--orion' },
              { label: 'Black Fill', value: 'button--black' },
              { label: 'Light Fill', value: 'button--light' },
              { label: 'Dark Outline', value: 'button--dark-outline' },
              { label: 'Light Outline', value: 'button--light-outline' },
            ],
          },
        ],
        defaultItem: {
          link: { text: 'Shop Now', url: '' },
          style: 'button--orion',
        },
        defaultValue: [
          {
            link: { text: 'Shop Now', url: '' },
            style: 'button--orion',
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Above the fold, background color, text color, icon color, full width',
        fields: [
          {
            label: 'Above The Fold',
            name: 'aboveTheFold',
            component: 'toggle',
            description: `Sets the heading as H1`,
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            label: 'Text Align',
            name: 'textAlign',
            component: 'select',
            options: [
              { label: 'Left', value: 'text-left' },
              { label: 'Center', value: 'text-center' },
            ],
          },
          {
            label: 'Max Content Width',
            name: 'maxWidth',
            component: 'select',
            options: [
              {
                label: 'Narrow',
                value: 'max-w-[30rem]',
              },
              {
                label: 'Medium Narrow',
                value: 'max-w-[45rem]',
              },
              {
                label: 'Medium',
                value: 'max-w-[55rem]',
              },
              {
                label: 'Wide',
                value: 'max-w-[65rem]',
              },
              { label: 'Full', value: 'max-w-full' },
            ],
            defaultValue: 'max-w-[55rem]',
          },
          {
            component: 'number',
            name: 'mobileSpacing',
            label: 'Section Bottom Margin - Mobile',
            defaultValue: 60,
          },
          {
            component: 'number',
            name: 'desktopSpacing',
            label: 'Section Bottom Margin - Desktop',
            defaultValue: 80,
          },
        ],
        defaultValue: {
          aboveTheFold: false,
          bgColor: 'var(--white)',
          textAlign: 'text-left',
          mobileSpacing: 60,
          desktopSpacing: 80,
        },
      },
    ],
  };
}
