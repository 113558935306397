import { useEffect, useCallback, useRef } from 'react';
import { useRouter } from '@backpackjs/storefront';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';
import { useAddCustomizedToCart } from './useAddCustomizedToCart';

export function Customize({ product, customizer, cms }) {
  const { query, push } = useRouter();
  const mczrShopId = process.env.NEXT_PUBLIC_MCZR_SHOPID;
  const mczrBaseUrl = process.env.NEXT_PUBLIC_MCZR_BASEURL;
  const mczrButton = useRef(null);

  const {
    actions: { openCart, openModal },
  } = useGlobalContext();
  const { handleAddToCart } = useAddCustomizedToCart();
  const { startingPointId, startingPoint, headingText, description } =
    customizer;

  const { replaceAtc, buttonAction, customUrl } = cms;

  const handleClick = useCallback(() => {
    if (buttonAction !== 'openKickflip') {
      if (customUrl && customUrl !== '') {
        push(customUrl);
      }
      return;
    }
    let pathUrl;
    if (query?.designId) {
      pathUrl = ''
        .concat(mczrBaseUrl, '/customize/design/')
        .concat(query.designId, '?shopid=')
        .concat(mczrShopId);
    } else if (startingPointId) {
      pathUrl = ''
        .concat(mczrBaseUrl, '/customize/startingpoint/')
        .concat(startingPointId, '?shopid=')
        .concat(encodeURIComponent(mczrShopId));
    } else {
      pathUrl = ''
        .concat(mczrBaseUrl, '/customize/')
        .concat(startingPoint, '?shopid=')
        .concat(encodeURIComponent(mczrShopId));
    }
    const storeProductUrl = window.location.href.replace(
      /(.*\/products\/[\w\-]+)(.*)/,
      '$1?designId=<designId>'
    );
    const iframeSrc =
      pathUrl +
      '&lang='.concat('en') +
      '&country='.concat('US') +
      '&storeProductUrl='.concat(storeProductUrl);
    openModal(
      <div className="scrollbar overflow-auto pl-0 pr-4 max-md:-mr-4 md:px-5">
        <div id="mczrLoader">
          <iframe
            id="mczrProductIframe"
            title="mczrProductIframe"
            src={iframeSrc}
            width="100%"
            height="800px"
            allow="clipboard-write"
          />
        </div>
      </div>,
      {
        className:
          'max-w-[calc(100%-2rem)] md:max-w-[calc(100%-4rem)] md:!w-[1440px]',
      }
    );
  }, [query?.designId, buttonAction]);

  useEffect(() => {
    if (query?.designId) {
      handleClick();
    }
  }, [query?.designId]);

  useEffect(() => {
    const handleAddToCartPostMessage = (event) => {
      if (
        event.data.eventName === 'mczrAddToCart' &&
        event.origin === mczrBaseUrl
      ) {
        const details = event.data.detail;
        // Handle the post message
        handleAddToCart({
          details,
          product,
          callback: openCart,
        });
      }
    };

    if (mczrButton?.current) {
      const formActions = Array.from(mczrButton.current.querySelectorAll('a'));
      formActions.forEach((action) => {
        action.addEventListener('click', (e) => {
          e.stopPropagation();
        });
      });
    }

    window.addEventListener('message', handleAddToCartPostMessage, false);
    return () => {
      window.removeEventListener('message', handleAddToCartPostMessage);
    };
  }, []);

  return (
    <div>
      <button
        aria-label={headingText}
        className={`button ${
          replaceAtc
            ? 'button--black relative h-10 w-full md:h-12'
            : 'button--dark-outline h-auto w-full p-4 text-left tracking-normal hover:bg-[#f1f1f1]/10 hover:text-black'
        }`}
        onClick={handleClick}
        type="button"
        data-mczr="customize_button"
        ref={mczrButton}
      >
        {replaceAtc ? (
          <p className="m-0 transition-opacity">{headingText}</p>
        ) : (
          <>
            <div className="flex items-center gap-2">
              <Svg
                src="/svgs/icon-customizer.svg#icon-customizer"
                title="Arrow"
                viewBox="0 0 32 32"
                className="h-8 w-8"
              />
              <span className="flex-grow text-sh font-bold capitalize">
                {headingText}
              </span>
              <span className="button button--arrow-right flex h-8 w-8 items-center justify-center rounded-full" />
            </div>
            {description && (
              <div
                className="mt-2 text-sm font-normal normal-case [&_a]:decoration-1 [&_a]:underline-offset-4"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            )}
          </>
        )}
      </button>
      <style>
        {`
          button[data-mczr=customize_button]:hover .button--arrow-right::before{
            transform: translate3d(300%,-50%,0);
          }
          button[data-mczr=customize_button]:hover .button--arrow-right::after{
              transform: translate3d(-50%,-50%,0);
          }
      `}
      </style>
    </div>
  );
}

Customize.displayName = 'kickflip Customize';
