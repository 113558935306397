'use client';

import Script from 'next/script';
import { useEffect, useState, useRef } from 'react';
import PinchZoom from 'pinch-zoom-js';

import settings from 'public/map/settings';
import { Schema } from './ShopByState.schema';
import { Svg } from '@/snippets';

export function ShopByState({ cms }) {
  const { heading, subtext, section } = cms;
  const [mapJsLoaded, setMapJsLoaded] = useState(
    typeof FlaMap !== 'undefined'
  );
  const [raphaelJsLoaded, setRaphaelJsLoaded] = useState(
    typeof Raphael !== 'undefined'
  );
  const [pz, setPinchZoom] = useState(null);
  const mapRef = useRef(null);
  const mapContainer = useRef(null);
  const [loaded, setLoaded] = useState(false);

  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;

  const renderMap = () => {
    if (mapJsLoaded && mapContainer.current && typeof FlaMap !== 'undefined') {
      const map = new FlaMap(settings);
      map.draw('map-container');
    }
  };

  useEffect(() => {
    setLoaded(true);
    return () => {
      setLoaded(false);
    };
  }, []);

  useEffect(() => {
    if (!mapJsLoaded) {
      let i = 0;
      const t = window.setInterval(() => {
        i++;
        if (typeof FlaMap !== 'undefined') {
          setMapJsLoaded(true);
          clearInterval(t);
        } else if (i > 6) {
          clearInterval(t);
        }
      }, 500);
    }
    return () => {
      setMapJsLoaded(false);
    };
  }, [loaded]);

  useEffect(() => {
    renderMap();
  }, [mapJsLoaded, mapContainer]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (!pz) return;
      if (window.innerWidth > 768) {
        pz.disable();
      } else {
        pz.enable();
      }
    });
  }, []);

  return (
    <div
      className="shop-by-state-container"
      data-client-id={cms.id || cms.cmsId || cms.tinaId || cms.clientId}
      id={moduleID}
    >
      <style>{`
        #${moduleID}{ margin-bottom: ${section?.mobilePadding || 0}px; }
        @media(min-width: 1024px) {
          #${moduleID}{ margin-bottom: ${section?.desktopPadding || 0}px; }
        }
      `}</style>
      <div className="lg-px-contained bg-darkLoden py-12 md:py-14">
        <div className={`mx-auto ${maxWidthClass}`}>
          {(heading || subtext) && (
            <div className="mx-auto mb-4 px-4 text-center lg:max-w-[468px] lg:px-0">
              {heading && (
                <h2 className="mb-2 text-h3-m uppercase text-white lg:mb-4 lg:text-h1"
                  dangerouslySetInnerHTML={{__html: heading}}
                >
                </h2>
              )}
              {subtext && <p className="mb-0 text-white" dangerouslySetInnerHTML={{__html: subtext}}></p>}
            </div>
          )}
          <button
            aria-label="Pinch to Zoom"
            className="button button--light-outline mx-auto flex w-full max-w-[220px] items-center justify-center px-6 lg:hidden"
            type="button"
            onClick={() => {
              if (mapRef.current) {
                setPinchZoom(
                  new PinchZoom(mapRef.current, {
                    minZoom: 1,
                    draggableUnzoomed: false,
                  })
                );
              }
            }}
          >
            <Svg
              className="mr-3.5 w-4"
              src="/svgs/icon-pinch-zoom.svg#icon-pinch-zoom"
              title="Pinch to Zoom"
              viewBox="0 0 17 16"
            />
            <span>Pinch to zoom</span>
          </button>

          <Script src={`${process.env.SITE_URL}/map/paths.js`} />
          {loaded && (
            <Script
              src={`${process.env.SITE_URL}/map/raphael.min.js`}
              onLoad={() => {
                setRaphaelJsLoaded(true);
              }}
            />
          )}
          {raphaelJsLoaded && (
            <Script
              src={`${process.env.SITE_URL}/map/map.js`}
              onLoad={() => {
                setMapJsLoaded(true);
              }}
            />
          )}
          <div ref={mapRef}>
            <div id="map-container" ref={mapContainer} />
          </div>
        </div>
      </div>
    </div>
  );
}

ShopByState.displayName = 'ShopByState';
ShopByState.Schema = Schema;
