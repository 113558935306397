export function Schema({ blog }) {
  if (!blog) return null;

  return {
    category: 'Blog',
    label: 'Blog Categories',
    key: 'blog-categories',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/blog-categories-preview.jpg?v=1675732821',
    fields: [
      {
        label: 'Categories',
        name: 'categories',
        component: 'list',
        description: '"All" category is added to the front automatically',
        field: {
          component: 'text',
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Spacing bottom (mobile and desktop)',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}
