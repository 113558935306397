import { useMemo } from 'react';

import {
  useCartAddDiscountUrl,
  useSettings,
  useStorefrontInit,
} from '@backpackjs/storefront';

import {
  Cart,
  DataLayer,
  Footer,
  Header,
  Modal,
  Overlay,
  SchemaMarkup,
  Search,
  StorefrontHead,
  InjectScript,
  SkipLink,
  Loopreturns,
  HubspotScriptLoader,
  Vwo,
  GetItBy,
  KickflipCtaLoader,
} from '../snippets';
import { GlobalContextProvider } from '../contexts';
import {
  useSetViewportHeightCssVar,
  useThemeColors,
  useShopifyAnalytics,
  useLoadScript,
} from '../hooks';

function StorefrontLayout({ ContentForLayout, ...props }) {
  const settings = useSettings();
  const sections = props?.renderSections();
  const { promoBarSection } = useMemo(() => {
    if (!sections) return {};
    return {
      promoBarSection: sections.find(({ props }) => {
        return props?.['data-comp'] === 'promo-bar' && !props?.hidden;
      }),
    };
  }, [sections]);

  let promobar = settings?.header?.promobar;
  const cms = promoBarSection?.props.children.props.children.props.cms;
  if (cms?.promobar) {
    promobar = cms.promobar;
  }

  const promobarDisabled =
    !!promobar && (!promobar.enabled || !promobar.messages?.length);

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // set css var for viewport height
  useSetViewportHeightCssVar();

  // set css vars for theme colors
  useThemeColors();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  useLoadScript(
    'https://cdn-widgetsrepository.yotpo.com/v1/loader/0lzdHVAuHKHmTf4oB_6NHw'
  );

  return (
    <GlobalContextProvider>
      <StorefrontHead {...props} />

      <SkipLink />

      <DataLayer {...props} />
      <Vwo />

      <SchemaMarkup {...props} />

      <Header
        promobarDisabled={promobarDisabled}
        promoBarSection={promoBarSection}
        {...props}
      />

      <main
        id="main-content"
        className={`${
          promobarDisabled
            ? 'pt-[var(--header-height-mobile)] md:pt-[var(--header-height)]'
            : 'pt-[calc(var(--header-height-mobile)+var(--promobar-height-mobile))] md:pt-[calc(var(--header-height)+var(--promobar-height))]'
        }`}
      >
        <ContentForLayout {...props} />
      </main>

      <Footer />

      <Overlay />

      <Cart />

      <Search />

      <Modal />
      <Loopreturns />
      <GetItBy />
      <InjectScript
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=KhZrFi"
        id="klaviyo"
      />
      <InjectScript
        src="//js.hs-scripts.com/24397049.js"
        id="hs-script-loader"
      />
      <HubspotScriptLoader />
      <KickflipCtaLoader />
    </GlobalContextProvider>
  );
}

export default StorefrontLayout;
