import React from 'react';
import PropTypes from 'prop-types';

import { Image } from '../Image';
import { Link } from '../Link';
import { Svg } from '../Svg';

export function ThreeTilesTile({ aspectRatio, item, textColor }) {
  return (
    <div className="w-full tile-container" style={{ color: textColor }}>
      <div className={`relative mb-4 bg-offWhite ${aspectRatio}`}>
        <Link
          aria-label={item.heading}
          href={item.link?.url}
          newTab={item.link?.newTab}
          tabIndex="-1"
        >
          {item.image?.src && (
            <Image
              alt={item.alt}
              className={`${item.position}`}
              fill
              sizes="(min-width: 768px) 50vw, 100vw"
              src={item.image.src}
            />
          )}
        </Link>
      </div>

      <div className="inset-0 flex h-full w-full relative link-container">
        <Link
          className="no-underline"
          aria-label={item.heading}
          href={item.link?.url}
          newTab={item.link?.newTab}
        >
          <div className="group flex flex-col w-3/4">
            <h4 className="font-semibold mb-4 text-black"
            dangerouslySetInnerHTML={{__html: item.heading}}
            >
            </h4>

            {item.description && <p className='font-normal text-black'>{item.description}</p>}

          </div>

          <div className="absolute p-3 sm:p-4 top-[-45px] right-4 rounded-full border-medDesert border-[1px]">
            <button className="button button--arrow-right p-2 bg-medDesert w-10 h-10 sm:w-14 sm:h-14 shadow-none relative"></button>
          </div>
        </Link>

      </div>
    </div>
  );
}

ThreeTilesTile.displayName = 'ThreeTilesTile';
ThreeTilesTile.propTypes = {
  aspectRatio: PropTypes.string,
  item: PropTypes.object,
  textColor: PropTypes.string,
};
