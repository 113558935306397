import { useEffect, useRef, useState } from 'react';
import { useCartCount } from '@backpackjs/storefront';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Mousewheel, Scrollbar, Manipulation } from 'swiper';

import { UpsellItem } from './UpsellItem';
import { useRebuy } from '../../hooks';

import { Schema } from './RebuyProductUpsells.schema';

export function RebuyProductUpsells({ cms }) {
  const { rebuyRulesetId, heading, section } = cms;
  const rebuyContainer = useRef(null);
  const [swiper, setSwiper] = useState(null);
  const [, setActiveIndex] = useState(0);
  const [rebuyProducts, setRebuyProducts] = useState(null);
  const { getRebuyProducts } = useRebuy();
  const cartItemCount = useCartCount();
  const moduleID = `${Schema().key}-${cms?.id || cms?.cmsId || cms?.tinaId}`;
  const [update, setUpdate] = useState(false);
  const [oosProducts, setOosProducts] = useState([]);

  useEffect(() => {
    const handleRebuy = async () => {
      const rebuyProductsData = await getRebuyProducts({
        rulesetId: rebuyRulesetId,
        cartItemCount,
      });
      setRebuyProducts(rebuyProductsData?.data);
    };

    if (rebuyRulesetId && cartItemCount != null) handleRebuy();
  }, [rebuyRulesetId, cartItemCount]);

  useEffect(() => {
    const rebuyProductsFiltered = (rebuyProducts || []).reduce((acc, item) => {
      if (!oosProducts.includes(item?.admin_graphql_api_id)) {
        return [...acc, item];
      }
      return acc;
    }, []);
    setRebuyProducts(rebuyProductsFiltered);
  }, [oosProducts]);

  useEffect(() => {
    const reachRightEdge = () => {
      if (!rebuyContainer.current || rebuyProducts?.length < 2 || !swiper)
        return;
      const sectionEl = rebuyContainer.current.closest(`#${moduleID}`);
      const innerboundingRect = sectionEl.getBoundingClientRect();
      const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      sectionEl.style.removeProperty('marginRight');
      if (swiper && swiper.el) {
        //swiper.el.style.marginRight = `${innerboundingRect.right - viewportWidth}px`;
        //sectionEl.style.marginRight = `${innerboundingRect.left + sectionEl.offsetWidth - viewportWidth}px`;
      }
    };
    reachRightEdge();
    window.addEventListener('resize', reachRightEdge);
    return () => window.removeEventListener('resize', reachRightEdge);
  }, [rebuyProducts, swiper]);

  if (!rebuyRulesetId) return null;

  return (
    rebuyRulesetId && (
      <div className="w-full overflow-hidden">
        <div className="" id={moduleID}>
          <div className="nosto-container rebuy-container" ref={rebuyContainer}>
            <div className="rebuy-header mb-4 flex justify-center max-md:!pr-0 md:mb-3 md:justify-between">
              {heading && (
                <h2
                  className="h6 m-0 uppercase"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              )}
            </div>
            <Swiper
              onSwiper={setSwiper}
              scrollbar={{
                hide: false,
                draggable: true,
              }}
              navigation={false}
              mousewheel={{
                forceToAxis: true,
              }}
              modules={[A11y, Scrollbar, Mousewheel, Manipulation]}
              onSlideChange={(_swiper) => {
                setActiveIndex(_swiper.realIndex);
              }}
              slidesPerView={1}
              spaceBetween={16}
              grabCursor
              lazy={{
                loadPrevNext: true,
              }}
              breakpoints={{
                769: {
                  slidesPerView: 1.3,
                  spaceBetween: 24,
                },
              }}
              className="-mr-4 pb-4 md:mr-0 md:pb-6"
            >
              {(rebuyProducts || []).map((product, index) => {
                const handle = product?.handle;
                return (
                  <SwiperSlide
                    className="h-auto w-full py-2"
                    key={`rebuy-product-${handle}-${index}`}
                  >
                    <UpsellItem
                      enabledColorNameOnHover={false}
                      enabledColorSelector={false}
                      enabledQuickShop={false}
                      enabledStarRating={false}
                      handle={handle}
                      index={index}
                      isSearchPage={false}
                      isRebuyPanel
                      rulesetid={rebuyRulesetId}
                      upsells={cms.upsells}
                      setUpsells={cms.setUpsells}
                      setUpdate={setUpdate}
                      setOosProducts={setOosProducts}
                      rebuyProductId={product?.admin_graphql_api_id}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      </div>
    )
  );
}

RebuyProductUpsells.displayName = 'RebuyProductUpsells';
RebuyProductUpsells.Schema = Schema;
