import { useEffect, useState } from 'react';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import { fetchProductsFromHandles } from '../../utilities';
import { UpsellColorOptionValue } from './UpsellColorOptionValue';
import { UpsellOptionValue } from './UpsellOptionValue';

export function UpsellOptions({
  product,
  selectedOptions,
  selectedVariant,
  setSelectedOptions,
}) {
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle,
    withInventory: false,
  });
  const [groupingProductsMapByColor, setGroupingProductsMapByColor] =
    useState(null);

  useEffect(() => {
    if (!product?.grouping?.isTransformed) return;

    const fetchProductsForMap = async () => {
      const products = await fetchProductsFromHandles(
        product.grouping.products
      );
      setGroupingProductsMapByColor(
        products?.reduce((acc, groupProduct) => {
          return {
            ...acc,
            [groupProduct.optionsMap?.Color?.[0]]: groupProduct,
          };
        }, {})
      );
    };
    fetchProductsForMap();
  }, [product?.id]);

  return (
    <div className="flex flex-col gap-y-3">
      {product?.options?.map(({ id, name, values }) => {
        const productOrGroupingValues = product.grouping?.isTransformed
          ? product.grouping.optionsMap[name]
          : values;

        return (
          <div key={id} className="flex flex-col">
            <div className="flex items-center justify-start gap-9">
              <div className="hidden items-center gap-2">
                <p className="m-0 text-sh-upper-sm font-bold uppercase">
                  {name === 'Color' ? selectedOptions?.[name] || name : name}
                </p>
                <p className="m-0 hidden text-sm text-grey4">
                  {selectedOptions?.[name]}
                </p>
              </div>
            </div>

            <ul className="m-0 flex list-none flex-wrap gap-2 p-0 [&_li]:m-0">
              {productOrGroupingValues?.map((value) => {
                const isSelected = selectedOptions?.[name] === value;

                return (
                  <li key={value} className="mt-0 list-none">
                    {name === 'Color' ? (
                      <UpsellColorOptionValue
                        groupingProductsMapByColor={groupingProductsMapByColor}
                        inventory={inventory}
                        isLoading={!inventoryFetched}
                        isSelected={isSelected}
                        name={name}
                        product={product}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        value={value}
                      />
                    ) : (
                      <UpsellOptionValue
                        inventory={inventory}
                        isLoading={!inventoryFetched}
                        isSelected={isSelected}
                        name={name}
                        product={product}
                        selectedOptions={selectedOptions}
                        setSelectedOptions={setSelectedOptions}
                        value={value}
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

UpsellOptions.displayName = 'UpsellOptions';
