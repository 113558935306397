import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import { Schema } from './ProductFitFabric.schema';
import { Svg } from '../../snippets';

export function ProductFitFabric({ cms }) {
  const { product, section } = cms;
  const moduleID = `${Schema({ product })?.key}-${
    cms.id || cms.cmsId || cms.tinaId
  }`;
  const [isOpen, setIsOpen] = useState(false);

  if (!product) return null;

  return (
    (cms?.headingText || cms?.subtext) && (
      <div id={moduleID}>
        <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
        {cms?.headingText && (
          <button
            aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${
              cms?.headingText
            }`}
            className="flex w-full items-center justify-between gap-x-4 py-1 text-left outline-none"
            onClick={() => setIsOpen(!isOpen)}
            type="button"
          >
            <h2 className="m-0 text-sh-upper-sm uppercase">
              {cms?.headingText}
            </h2>
            <Svg
              className={`text-current w-5 ${isOpen ? 'rotate-180' : ''}`}
              src="/svgs/chevron-down-wide.svg#chevron-down-wide"
              title={`Chevron ${isOpen ? 'Up' : 'Down'}`}
              viewBox="0 0 20 8"
            />
          </button>
        )}
        {cms?.subtext && (
          <div
            className={`mt-4 text-sm [&_p:last-child]:mb-0 ${
              isOpen ? 'block' : 'hidden'
            }`}
          >
            <ReactMarkdown
              children={cms?.subtext}
              remarkPlugins={[remarkGfm, remarkBreaks]}
              rehypePlugins={[rehypeRaw]}
            />
          </div>
        )}
      </div>
    )
  );
}

ProductFitFabric.displayName = 'ProductFitFabric';
ProductFitFabric.Schema = Schema;
