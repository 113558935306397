import { useMemo } from 'react';
import { useProductInventoryByHandle } from '@backpackjs/storefront';

import { QuickShopOption } from './QuickShopOption';

export function QuickShopOptions({
  quickShopMultiText,
  selectedProduct,
  selectedVariant,
}) {
  const { inventory, success: inventoryFetched } = useProductInventoryByHandle({
    handle: selectedProduct.handle,
    withInventory: false,
  });

  const option = useMemo(() => {
    if (!selectedProduct) return { name: '', values: [], text: '' };
    const options = selectedProduct.grouping?.isTransformed
      ? selectedProduct.grouping.options
      : selectedProduct.options;
    const _option = options.find(({ name }) => name !== 'Color');
    return {
      name: _option?.name,
      values: _option?.values || [],
      text: quickShopMultiText?.replace('{{option}}', _option?.name),
    };
  }, [quickShopMultiText, selectedProduct.id]);

  return (
    option?.name && (
      <div className="group/quickshop relative flex h-10 items-center justify-center overflow-hidden rounded-md- bg-white">
        <p className="m-0 truncate px-3 text-sh-upper font-bold uppercase text-black">
          {option.text}
        </p>

        <ul
          className="invisible absolute inset-0 m-0 grid h-full w-full list-none bg-background p-0 group-hover/quickshop:visible group-focus/quickshop:visible"
          style={{
            gridTemplateColumns: `repeat(${option.values.length}, 1fr)`,
          }}
        >
          {option.values.map((value) => {
            return (
              <li
                key={value}
                className="m-0 overflow-hidden border-r border-grey2 text-black last:border-none"
              >
                <QuickShopOption
                  optionName={option.name}
                  inventory={inventory}
                  inventoryFetched={inventoryFetched}
                  selectedProduct={selectedProduct}
                  selectedVariant={selectedVariant}
                  value={value}
                />
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
}

QuickShopOptions.displayName = 'QuickShopOptions';
