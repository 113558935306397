import Head from 'next/head';

import { useSettings, useStorefrontInit } from '@backpackjs/storefront';

export function StorefrontHead(props) {
  const { settings, page } = props;

  return (
    <Head>
      {/* fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />

      {/* favicon */}
      <link
        rel="shortcut icon"
        href={settings?.favicon || '/icons/favicon.svg'}
      />

      {/* additional open graph */}
      <meta
        property="og:site_name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta
        key="page:fb:image"
        property="og:image"
        content={
          page?.seo?.image ||
          props?.product?.featuredImage?.src ||
          settings?.settings?.globalShareImage?.image?.src
        }
      />

      {/* pwa (progressive web app) */}
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/icon-32x32.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="192x192"
        href="/icons/icon-384x384.png"
      />
      <meta
        name="application-name"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content={process.env.NEXT_PUBLIC_SITE_TITLE}
      />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="msapplication-TileColor" content="#191919" />
      <meta name="msapplication-tap-highlight" content="no" />
      <meta name="theme-color" content="#191919" />
    </Head>
  );
}

StorefrontHead.displayName = 'StorefrontHead';
