import { useMemo, useEffect, useRef } from 'react';

export function CollectionHeroContent({
  content,
  text,
  collection,
  heroType,
  hasMedia,
  containerRef,
}) {
  const { color, heading, subheading } = { ...text };
  const {
    alignmentMobile,
    alignmentDesktop,
    darkOverlay,
    maxWidthMobile,
    maxWidthDesktop,
    positionMobile,
    positionDesktop,
    contentOverlay,
  } = {
    ...content,
  };
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
  const positionClasses = `${positionMobile} ${positionDesktop}`;
  const maxWidthContentClasses = `${maxWidthMobile} ${maxWidthDesktop}`;
  const darkOverlayClass =
    hasMedia && darkOverlay ? 'bg-[rgba(0,0,0,0.2)]' : '';
  const contentOverlayClass = contentOverlay
    ? `${
        color == 'var(--white)'
          ? 'bg-[rgba(25,25,25,0.7)]'
          : 'bg-[rgba(255,255,255,0.7)]'
      } px-12 py-6 md:py-12`
    : '';

  const contentRef = useRef(null);
  useEffect(() => {
    const fixHeroHeight = () => {
      if (contentRef.current && containerRef.current) {
        containerRef.current.style.minHeight = 0;
        const containerHeight = containerRef.current.offsetHeight;
        const contentHeight = contentRef.current.offsetHeight;
        if (contentHeight > containerHeight) {
          containerRef.current.style.minHeight = `${contentHeight}px`;
        }
      }
    };
    fixHeroHeight();
    window.addEventListener('resize', fixHeroHeight);
    return () => {
      window.removeEventListener('resize', fixHeroHeight);
    };
  }, []);

  return (
    <div
      className={`pointer-events-none relative flex h-min w-full ${
        heroType != 'overlay'
          ? 'px-4 pb-0 pt-6 text-black lg:px-6 lg:pt-12'
          : 'px-4 py-[34px] lg:px-6 lg:py-[42px]'
      }`}
      ref={contentRef}
    >
      <div
        className={`container-3xl flex h-full w-full !px-0 ${
          heroType == 'overlay'
            ? positionClasses
            : 'items-center justify-center'
        }`}
      >
        <div
          className={`pointer-events-auto box-content flex min-w-min flex-col gap-4 md:w-1/2 ${alignmentClasses} ${contentOverlayClass} ${
            heroType == 'overlay' ? maxWidthContentClasses : ''
          }`}
          style={hasMedia && heroType == 'overlay' ? { color } : {}}
        >
          <h1 className="h1 m-0 uppercase" dangerouslySetInnerHTML={{__html: heading || collection?.title}} >
          </h1>

          {subheading && <p className="m-0" dangerouslySetInnerHTML={{__html: subheading}}></p>}
          {!subheading && collection?.description && (
            <p className="m-0">{collection?.description}</p>
          )}
        </div>
      </div>
    </div>
  );
}

CollectionHeroContent.displayName = 'CollectionHeroContent';
