import { useSettings } from '@backpackjs/storefront';

export function SearchSuggestions({ setRawTerm, term }) {
  const settings = useSettings();
  const { noResultsText } = { ...settings?.search?.results };
  const { enabled, heading, terms } = { ...settings?.search?.suggestions };

  return (
    <div className="scrollbar-hide flex flex-1 flex-col gap-8 overflow-y-auto p-8">
      {term && <h3 className="text-sm font-normal tracking-normal m-0">{noResultsText || 'No results found.'}</h3>}

      {enabled && terms?.length > 0 && (
        <div>
          <h3 className="text-sh mb-3">{heading}</h3>

          <ul className="flex flex-col items-start gap-1 list-none text-sm m-0 p-0">
            {terms.map((suggestion) => {
              return (
                <li key={suggestion} className="my-0">
                  <button
                    aria-label={suggestion}
                    onClick={() => setRawTerm(suggestion)}
                    type="button"
                  >
                    <p className="text-grey5 m-0 hover:text-darkLava hover:underline underline-offset-2 decoration-1">{suggestion}</p>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

SearchSuggestions.displayName = 'SearchSuggestions';
