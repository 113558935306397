import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import equal from 'fast-deep-equal';

import { formatImageItemAsMediaItem } from '../../utilities';

export function useSelectedVariant(product) {
  const { query } = useRouter();
  const hasOneVariant = product?.variants?.length === 1;
  const isNonGroupedProductWithMultiColors =
    !product?.grouping?.isTransformed && product?.optionsMap?.Color?.length > 1;

  const productMedia = useMemo(() => {
    return (
      product?.media ||
      // if shopify media transforms is off, shape image item similar to media item
      product?.images.map((image) => {
        return formatImageItemAsMediaItem({ image, product });
      })
    );
  }, [product?.id]);

  const [selectedVariant, setSelectedVariant] = useState(
    hasOneVariant ? product.variants?.[0] : null
  );
  const [selectedOptions, setSelectedOptions] = useState(
    hasOneVariant ? product.variants?.[0]?.selectedOptionsMap : null
  );
  const [selectedMedia, setSelectedMedia] = useState(
    isNonGroupedProductWithMultiColors ? null : productMedia
  );

  // set initial selected options on mount unless has one variant
  const setInitialSelectedOptionsOnProductMount = useCallback(() => {
    if (product?.variants?.length === 1 && selectedOptions) return;

    const params = new URLSearchParams(window.location.search);
    const queriedVariantId = params.get('variant');
    let queriedVariant = null;
    if (queriedVariantId) {
      queriedVariant = product?.variants?.find(
        ({ legacyResourceId }) => legacyResourceId === queriedVariantId
      );
    }
    if (queriedVariant) {
      setSelectedOptions(queriedVariant.selectedOptionsMap);
    } else {
      const firstAvailableVariant = product?.variants?.find(
        ({ availableForSale }) => availableForSale
      );

      if (firstAvailableVariant) {
        setSelectedOptions(firstAvailableVariant.selectedOptionsMap);
      } else if (product?.variants?.length === 0) {
        const options = (product?.options || []).reduce((acc, option) => {
          acc[option.name] = option.values[0];
          return {
            ...acc,
            [option.name]: option.values[0],
          };
        }, {});
        setSelectedOptions(options);
      } else {
        // If no variants are available, fall back to the first variant
        setSelectedOptions(product?.variants?.[0]?.selectedOptionsMap || null);
      }
    }
  }, [query.handle, product?.id]);

  // set selected variant from selected options unless has one variant
  const setSelectedVariantFromSelectedOptions = useCallback(() => {
    if (!selectedOptions) {
      setSelectedVariant(null);
      return;
    }

    if (product?.variants?.length === 1 && selectedVariant) return;

    const selectedVariantFromOptions = product.variants?.find(
      ({ selectedOptionsMap }) => {
        return equal(selectedOptions, selectedOptionsMap);
      }
    );
    setSelectedVariant(selectedVariantFromOptions || null);
  }, [query.handle, product?.id, selectedOptions, selectedVariant?.id]);

  // set selected media on selected variant change
  const setSelectedMediaOnSelectedVariantChange = useCallback(() => {
    // filter media by color via possible alt tag if product is not grouped and has multiple colors
    if (product?.optionsMap?.Color && selectedVariant) {
      const selectedColor =
        selectedVariant.selectedOptionsMap?.Color?.toLowerCase();
      const variantMedia = productMedia.filter((item) => {
        const alt = item.alt?.trim().toLowerCase() || '';
        const variantImage = selectedVariant?.image?.src?.split('?')[0];
        const itemImage = item?.preview?.image?.src?.split('?')[0];
        return alt.indexOf(selectedColor) !== -1 || variantImage === itemImage;
      });
      if (variantMedia?.length) {
        setSelectedMedia(variantMedia);
      } else if (productMedia.length) {
        setSelectedMedia(productMedia);
      }
    } else {
      setSelectedMedia(productMedia);
    }
  }, [query.handle, product?.id, productMedia, selectedVariant?.id]);

  useEffect(() => {
    setInitialSelectedOptionsOnProductMount();
  }, [product?.id]);

  useEffect(() => {
    setSelectedVariantFromSelectedOptions();
  }, [selectedOptions]);

  useEffect(() => {
    setSelectedMediaOnSelectedVariantChange();
  }, [selectedVariant?.id]);

  if (!product) {
    return {
      selectedOptions: null,
      selectedVariant: null,
      setSelectedOptions: null,
      selectedMedia: null,
    };
  }

  return {
    selectedOptions,
    selectedVariant,
    setSelectedOptions,
    selectedMedia,
  };
}
