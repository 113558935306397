import { Markdown as MarkdownSnippet } from '../../snippets';
import { Schema } from './Markdown.schema';

export function Markdown({ cms }) {
  const { centerAllText, content, section } = cms;
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;

  return (
    <div id={moduleID} className="px-contained py-contained">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div className={`mx-auto ${section?.maxWidth}`}>
        <MarkdownSnippet centerAllText={centerAllText}>
          {content}
        </MarkdownSnippet>
      </div>
    </div>
  );
}

Markdown.displayName = 'Markdown';
Markdown.Schema = Schema;
