import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { MobileMenuItem } from './MobileMenuItem';

export function FooterMenu() {
  const settings = useSettings();
  const { menuItems } = { ...settings?.footer?.menu };

  return (
    <>
      {/* mobile */}
      <ul className="my-0 pl-0 md:hidden">
        {menuItems?.map((item, menuIndex) => {
          return (
            <li key={menuIndex} className="mt-0 list-none">
              <MobileMenuItem item={item} />
            </li>
          );
        })}
      </ul>

      {/* desktop */}
      <ul className="my-0 hidden pl-0 md:flex md:gap-4 lg:gap-24">
        {menuItems?.map(({ title, links }, menuIndex) => {
          return (
            <li key={menuIndex} className="mt-0 max-w-[180px] flex-1 list-none">
              <h2 className="mb-4 text-sh-upper uppercase">{title}</h2>

              <ul className="my-0 flex flex-col items-start gap-3 pl-0">
                {links?.map(({ link }, linkIndex) => {
                  return (
                    <li key={linkIndex} className="mt-0 list-none text-xs">
                      <Link
                        aria-label={link?.text}
                        className="text-gray no-underline underline-offset-4 transition-all hover:text-medDesert md:hover:underline"
                        href={link?.url}
                        newTab={link?.newTab}
                      >
                        {link?.text}
                        {link?.newTab && (
                          <span className="sr-only">(opens in a new tab)</span>
                        )}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    </>
  );
}

FooterMenu.displayName = 'FooterMenu';
