import { Link, Markdown } from '../../snippets';
import { Accordion } from './Accordion';
import { Schema } from './Accordions.schema';

export function Accordions({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { accordions, heading, description, buttonLink, buttonStyle, section } =
    cms;
  const jsonSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: cms?.accordions?.map((accordion) => {
      if (accordion?.header && accordion?.body) {
        return {
          '@type': 'Question',
          name: accordion.header,
          acceptedAnswer: {
            '@type': 'Answer',
            text: accordion.body,
          },
        };
      }
      return null;
    }),
  };

  return (
    <div id={moduleID} className="px-contained mt-6 lg:mt-12">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div className="mx-auto max-w-[55rem] text-center">
        {heading && (
          <h3 className="mb-4" dangerouslySetInnerHTML={{ __html: heading }} />
        )}

        {description && (
          <div className="mx-auto mb-6 max-w-[30rem] text-grey5 xl:mb-8 [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <Markdown>{description}</Markdown>
          </div>
        )}

        <ul className="list-none pl-0">
          {accordions?.map((accordion, index) => {
            return <Accordion key={index} accordion={accordion} />;
          })}
        </ul>

        {buttonLink?.text && (
          <Link
            aria-label={buttonLink?.text}
            className={`button inline-block no-underline ${buttonStyle}`}
            href={buttonLink?.url}
            newTab={buttonLink?.newTab}
            tabIndex={buttonLink?.url ? 0 : -1}
          >
            {buttonLink?.text}
          </Link>
        )}
      </div>
      {cms?.enableFaq && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }}
        />
      )}
    </div>
  );
}

Accordions.displayName = 'Accordions';
Accordions.Schema = Schema;
