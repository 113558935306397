import { useEffect } from 'react';

export function SkipLink() {
  useEffect(() => {
    if (!document.querySelector('.skip-link[href="#main-content"]')) {
      const skipLink = document.createElement('a');
      skipLink.href = '#main-content';
      skipLink.className = 'skip-link';
      skipLink.textContent = 'Skip to main content';
      document.body.prepend(skipLink);
    }
    return () => {
      if (document.querySelector('.skip-link[href="#main-content"]')) {
        const skipLink = document.querySelector(
          '.skip-link[href="#main-content"]'
        );
        document.body.removeChild(skipLink);
      }
    };
  }, []);
  return null;
}

SkipLink.displayName = 'SkipLink';
