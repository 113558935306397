export default {
  label: 'Global Share Image',
  name: 'globalShareImage',
  component: 'group',
  fields: [
    {
      label: 'Image',
      name: 'image',
      component: 'image',
      description: 'Recommended image size: 1200 x 628px',
    },
  ],
}