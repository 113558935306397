import { useSettings } from '@backpackjs/storefront';

import { useSearchAutocomplete } from '../../hooks';

export function SearchAutocomplete({ searchOpen, setRawTerm, term }) {
  const settings = useSettings();
  const { autocompleteResults } = useSearchAutocomplete({
    term,
    mounted: searchOpen,
  });

  const { enabled, heading, limit } = { ...settings?.search?.autocomplete };

  return enabled && autocompleteResults.length > 0 ? (
    <div className="px-4 py-6 mb-2 border-b border-grey2">
      <h3 className="text-sh-sm font-bold mb-2.5">{heading}</h3>

      <ul className="flex flex-wrap gap-x-3 gap-y-1 m-0 p-0 list-none">
        {autocompleteResults.slice(0, limit || 5).map(({ item, refIndex }) => {
          return (
            <li key={refIndex} className="m-0">
              <button
                aria-label={item.suggestion}
                onClick={() => setRawTerm(item.suggestion)}
                type="button"
              >
                <p className="underline underline-offset-2 text-xs text-[#000] m-0 hover:text-darkLava">{item.suggestion}</p>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

SearchAutocomplete.displayName = 'SearchAutocomplete';
