export function Schema() {
  return {
    category: 'Rebuy',
    label: 'Rebuy Product Upsells',
    key: 'rebuy-product-upsells',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/NostoProductRecommendation-preview.jpg?v=1684172265',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
      },
      {
        label: 'Rebuy RuleSet Id',
        name: 'rebuyRulesetId',
        component: 'text',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'spacing bottom (mobile and desktop)',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
}
