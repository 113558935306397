import { useCallback, useEffect, useState } from 'react';
import Fuse from 'fuse.js';
import { useSettings, useStartFinishErrors } from '@backpackjs/storefront';

export function useSearchProductResults({ term, mounted = true }) {
  const settings = useSettings();
  const { setStarted, setFinished, reset, ...status } = useStartFinishErrors();

  const [productsFuse, setProductsFuse] = useState(null);
  const [productResults, setProductResults] = useState(null);
  const { productsEnabled } = { ...settings?.search?.results };

  const setProductsFuseOnMount = useCallback(async () => {
    try {
      if (productsFuse) return;
      const response = await fetch('/json/products-list.json');
      const list = await response.json();
      setProductsFuse(
        new Fuse(list, {
          keys: [
            { name: 'title', weight: 2 },
            'productType',
            'optionsMap.Color',
            'tags',
          ],
          ignoreLocation: true,
          minMatchCharLength: 3,
          threshold: 0.3,
        })
      );
    } catch (error) {
      console.error(error.message);
    }
  }, [productsFuse]);

  const searchProductsOnTermChange = useCallback(() => {
    reset();
    setStarted(true);
    if (!productsFuse) return;
    if (!term) {
      setFinished(true);
      setProductResults([]);
      return;
    }
    const results = productsFuse.search(term);
    setProductResults(
      results.reduce((acc, { item }) => {
        if (item.status !== 'ACTIVE') return acc;
        return [...acc, item];
      }, [])
    );
    setFinished(true);
  }, [productsFuse, term]);

  useEffect(() => {
    if (!mounted || !productsEnabled) return;
    setProductsFuseOnMount();
  }, [mounted, productsEnabled]);

  useEffect(() => {
    searchProductsOnTermChange();
  }, [term, productsFuse]);

  return { productResults, ...status };
}
