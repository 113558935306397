export function Schema() {
  return {
    category: 'Rebuy',
    label: 'Rebuy Product Recommendation',
    key: 'rebuy-product-recommendation',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/NostoProductRecommendation-preview.jpg?v=1684172265',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
      },
      {
        label: 'Rebuy RuleSet Id',
        name: 'rebuyRulesetId',
        component: 'text',
      },
      {
        label: 'CTA Link',
        name: 'cta_link',
        component: 'link',
      },
      {
        label: 'CTA Button Style',
        name: 'buttonStyle',
        component: 'select',
        options: [
          { label: 'Orion Fill', value: 'button--orion' },
          { label: 'Black Fill', value: 'button--black' },
          { label: 'Dark Outline', value: 'button--dark-outline' },
        ],
        defaultValue: 'button--orion',
      },
      {
        component: 'number',
        name: 'mobilePadding',
        label: 'Section Bottom Margin - Mobile',
      },
      {
        component: 'number',
        name: 'desktopPadding',
        label: 'Section Bottom Margin - Desktop',
      },
    ],
    defaultValue: {
      mobilePadding: 24,
      desktopPadding: 48,
    },
  };
}
