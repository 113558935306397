export function Schema({ collection }) {
  if (!collection) return null;

  return {
    category: 'Collection',
    label: 'Collection',
    key: 'collection',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/collection-preview.jpg?v=1675732828',
    fields: [
      {
        label: 'Enable Filters',
        name: 'enabledFilters',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: true,
      },
    ],
  };
}
