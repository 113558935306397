import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Svg } from '../Svg';

export function ReviewStars({
  color = 'var(--orion)',
  rating = 0,
  size = 'large', // small | large
}) {
  const stars = useMemo(() => {
    const fullStar = {
      key: 'star-full',
      label: 'Full Star',
    };
    const emptyStar = {
      key: 'star-empty',
      label: 'Empty Star',
    };
    const halfStar = {
      key: 'star-half-empty',
      label: 'Half Star',
    };
    const blankStar = {
      key: 'star-full',
      label: 'Empty Star',
    };

    return [...Array(5).keys()].map((index) => {
      if (rating === 0) {
        return blankStar;
      }
      const diff = rating - index;
      if (diff >= 0.75) {
        return fullStar;
      }
      if (diff >= 0.25) {
        return halfStar;
      }
      return emptyStar;
    });
  }, [rating]);

  const classBySize = {
    small: {
      gap: 'gap-1 md:gap-1.5',
      width: 'w-3 md:w-3.5',
    },
    large: {
      gap: 'gap-1 md:gap-1.5',
      width: 'w-4 md:w-5',
    },
  };

  return (
    <ul
      className={`flex items-center ${classBySize[size]?.gap} m-0 list-none p-0 [&_li]:m-0`}
    >
      {stars.map(({ key, label }, index) => (
        <li key={index}>
          <Svg
            className={`${classBySize[size]?.width}`}
            src={`/svgs/${key}.svg#${key}`}
            style={rating === 0 ? { color: 'var(--grey-3)' } : { color }}
            title={label}
            viewBox="0 0 24 24"
          />
        </li>
      ))}
    </ul>
  );
}

ReviewStars.displayName = 'ReviewStars';
ReviewStars.propTypes = {
  color: PropTypes.string,
  rating: PropTypes.number,
  size: PropTypes.oneOf(['small', 'large']),
};
