import { HeroContent } from './HeroContent';
import { HeroVideo } from './HeroVideo';
import { Image } from '../../snippets';

export function HeroSlide({ aboveTheFold, isFirstSlide, isVisible, slide }) {
  const { image, video } = slide;

  return (
    <div className="hero-content-container relative h-full w-full">
      <div className="relative h-full w-full overflow-hidden md:hidden">
        {video?.srcMobile && (
          <HeroVideo
            isVisible={isVisible}
            posterSrc={video.posterMobile?.src}
            videoSrc={video.srcMobile}
          />
        )}

        {image?.imageMobile?.src && !video?.srcMobile && (
          <Image
            alt={image.alt}
            className={`${image.positionMobile}`}
            fill
            priority={aboveTheFold && isFirstSlide}
            sizes="100vw"
            src={image.imageMobile.src}
          />
        )}
      </div>

      <div className="relative hidden h-full w-full overflow-hidden md:block">
        {video?.srcDesktop && (
          <HeroVideo
            isVisible={isVisible}
            posterSrc={video.posterDesktop?.src}
            videoSrc={video.srcDesktop}
          />
        )}

        {image?.imageDesktop?.src && !video?.srcDesktop && (
          <Image
            alt={image.alt}
            className={`${image.positionDesktop}`}
            fill
            priority={aboveTheFold && isFirstSlide}
            sizes="100vw"
            src={image.imageDesktop.src}
          />
        )}
      </div>

      <HeroContent
        aboveTheFold={aboveTheFold}
        isFirstSlide={isFirstSlide}
        slide={slide}
      />
    </div>
  );
}

HeroSlide.displayName = 'HeroSlide';
