import { useMemo } from 'react';

import { Svg } from '../../Svg';

export function CollectionFiltersSummary({
  activeFilters,
  clearFilters,
  filtersMap,
  removeFilter,
}) {
  const activeFiltersList = useMemo(() => {
    const filterEntries = Object.entries(activeFilters);
    if (!filterEntries.length) return [];

    return filterEntries.reduce((acc, [filterKey, filterValues]) => {
      return [
        ...acc,
        ...filterValues.map((value) => {
          return {
            key: filterKey,
            value,
          };
        }),
      ];
    }, []);
  }, [activeFilters]);

  return (
    <div>
      <div className="hidden items-center justify-between gap-4 md:flex">
        <h2 className="m-0 text-h4 font-bold uppercase">Filter</h2>
        {clearFilters && activeFiltersList.length ? (
          <button
            className="button--underline text-black"
            onClick={clearFilters}
            type="button"
          >
            Clear All
          </button>
        ) : null}
      </div>
      {activeFiltersList.length ? (
        <ul className="mx-0 my-4 flex list-none flex-wrap gap-2 p-0 md:mb-2 md:flex-col">
          {activeFiltersList.map(({ key, value }, index) => {
            return (
              <li key={index} className="m-0 max-w-full">
                <button
                  className="flex w-full items-center rounded-md- bg-grey1 px-2 py-1 text-left text-xs transition"
                  onClick={() => removeFilter({ key, value })}
                  type="button"
                >
                  <div className="flex-1 truncate">
                    <span className="font-bold">{filtersMap[key]?.label}:</span>{' '}
                    {value}
                  </div>
                  <Svg
                    className="ml-3 w-3 text-text"
                    src="/svgs/close.svg#close"
                    title="Close"
                    viewBox="0 0 24 24"
                  />
                </button>
              </li>
            );
          })}
        </ul>
      ) : null}
      <div className="mb-6 block md:hidden">
        {clearFilters && activeFiltersList.length ? (
          <button
            className="button--underline text-black"
            onClick={clearFilters}
            type="button"
          >
            Clear All
          </button>
        ) : null}
      </div>
    </div>
  );
}

CollectionFiltersSummary.displayName = 'CollectionFiltersSummary';
