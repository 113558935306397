import { useMemo, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CollectionMenuSidebar } from './CollectionMenuSidebar';
import { SelectSort } from './SelectSort';
import { Svg } from '../Svg';
import { sortKeys } from './utils';

export function CollectionSort({ selectedSort, selectSort }) {
  const settings = useSettings();
  const sortOptions = { ...settings?.collection?.sort };

  const [isOpen, setIsOpen] = useState(false);

  const options = useMemo(() => {
    return sortKeys.map((key) => ({
      value: key,
      label: sortOptions[`${key}Label`],
    }));
  }, [sortOptions, sortKeys]);

  return (
    <>
      {/* desktop */}
      <div className="ml-auto hidden max-w-[11rem] md:block">
        <SelectSort
          onSelect={(option) => selectSort(option)}
          options={options}
          placeholder="Sort"
          selectedOption={selectedSort}
          placeholderClass="text-black"
        />
      </div>

      {/* mobile */}
      <button
        aria-label="Open sort sidebar"
        className="flex gap-2.5 h-10 w-full items-center justify-center rounded-md- border border-black px-2 text-left md:hidden md:px-0"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <Svg
          className={`text-current w-5 ${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down-wide.svg#chevron-down-wide"
          title={`Chevron ${isOpen ? 'Up' : 'Down'}`}
          viewBox="0 0 20 8"
        />
        <p
          className={`truncate m-0 uppercase text-sh-upper leading-normal font-bold`}
        >
          {selectedSort?.label || 'Sort'}
        </p>
      </button>

      <CollectionMenuSidebar title="Sort" isOpen={isOpen} setIsOpen={setIsOpen}>
        <ul className="overflow-y-auto list-none m-0 p-0 [&_li]:m-0">
          {options.map((option, index) => {
            const isActive = option.value === selectedSort?.value;
            return (
              <li key={index}>
                <button
                  aria-label={option.label}
                  className={`flex w-full items-center gap-4 px-4 py-1 text-left text-base text-black ${
                    isActive ? 'text-lava decoration-1 underline-offset-2 underline' : ''
                  }`}
                  onClick={() => {
                    selectSort(option);
                    setIsOpen(false);
                  }}
                  type="button"
                >
                  <span>{option.label}</span>
                </button>
              </li>
            );
          })}
        </ul>
      </CollectionMenuSidebar>
    </>
  );
}

CollectionSort.displayName = 'CollectionSort';
