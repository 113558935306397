import { useRef } from 'react';
import { CollectionHeroContent } from './CollectionHeroContent';
import { Image } from '../../snippets';
import { Schema } from './CollectionHero.schema';
import { CollectionVideo } from '../../snippets/Collection/CollectionVideo';

export function CollectionHero({ cms }) {
  const { content, image, section, text, collection, video } = cms;
  const maxWidthContainerClass = section?.fullWidth ? '' : 'container-3xl';
  const hasMedia =
    video?.srcMobile ||
    video?.srcDesktop ||
    image?.imageMobile ||
    image?.imageDesktop;
  const heightContainerClasses = hasMedia
    ? `${section?.heightMobile} ${section?.heightTablet} ${section?.heightDesktop}`
    : 'border-b border-black';
  const heroType = section?.heroType || 'overlay';
  const moduleID = `${Schema({ collection })?.key}-${
    cms.id || cms.cmsId || cms.tinaId
  }`;
  const containerRef = useRef(null);
  const darkOverlayClass =
    hasMedia && content?.darkOverlay
      ? 'after:bg-[rgba(0,0,0,0.2)] after:absolute after:w-full after:h-full after:block'
      : '';

  return (
    <div id={moduleID}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div
        className={`relative z-0 mx-auto flex items-center ${heightContainerClasses} ${maxWidthContainerClass}`}
        ref={containerRef}
      >
        <div
          className={`absolute inset-0 h-full w-full overflow-hidden ${
            !video?.srcDesktop && !image?.imageDesktop?.src ? '' : 'md:hidden'
          } ${heroType == 'overlay' ? darkOverlayClass : ''}`}
          style={{
            backgroundColor: image?.imageMobile?.src
              ? 'var(--off-white)'
              : section?.bgColor,
          }}
        >
          {video?.srcMobile && (
            <CollectionVideo
              isVisible
              videoSrc={video?.srcMobile}
              posterSrc={video.posterMobile?.src}
            />
          )}

          {image?.imageMobile?.src && !video?.srcMobile && (
            <Image
              alt={image.alt}
              className={`${image.positionMobile}`}
              fill
              priority
              sizes="100vw"
              src={image.imageMobile.src}
            />
          )}
        </div>

        <div
          className={`absolute inset-0 h-full w-full overflow-hidden ${
            !video?.srcMobile && !image?.imageMobile?.src
              ? 'block'
              : 'hidden md:block'
          } ${heroType == 'overlay' ? darkOverlayClass : ''}`}
          style={{
            backgroundColor: image?.imageDesktop?.src
              ? 'var(--off-white)'
              : section?.bgColor,
          }}
        >
          {video?.srcDesktop && (
            <CollectionVideo
              isVisible
              videoSrc={video?.srcDesktop}
              posterSrc={video.posterDesktop?.src}
            />
          )}

          {image?.imageDesktop?.src && !video?.srcDesktop && (
            <Image
              alt={image.alt}
              className={`${image.positionDesktop}`}
              fill
              priority
              sizes="100vw"
              src={image.imageDesktop.src}
            />
          )}
        </div>

        {heroType == 'overlay' ? (
          <CollectionHeroContent
            content={content}
            text={text}
            collection={collection}
            heroType={heroType}
            hasMedia={hasMedia}
            containerRef={containerRef}
          />
        ) : null}
      </div>
      {heroType != 'overlay' ? (
        <CollectionHeroContent
          content={content}
          text={text}
          collection={collection}
          heroType={heroType}
          hasMedia={hasMedia}
          containerRef={containerRef}
        />
      ) : null}
    </div>
  );
}

CollectionHero.displayName = 'CollectionHero';
CollectionHero.Schema = Schema;
