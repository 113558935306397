import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { Svg } from '../Svg';

export function SearchInput({ closeSearch, rawTerm, searchOpen, setRawTerm }) {
  const inputRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    if (!searchOpen) return;
    inputRef.current.focus();
  }, [searchOpen]);

  return (
    <div className="border-b border-black p-4 pb-5">
      <div className="relative flex justify-between gap-3 rounded border border-grey3 px-3">
        <Svg
          className="w-5 text-black"
          src="/svgs/search.svg#search"
          title="Search"
          viewBox="0 0 26 26"
        />

        <input
          aria-label="Search here"
          className="min-w-0 flex-1 py-3 text-base !outline-none"
          onChange={(e) => setRawTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !!e.target.value) {
              closeSearch();
              router.push({
                pathname: '/pages/search',
                query: { term: e.target.value },
              });
            }
          }}
          placeholder="Search here"
          ref={inputRef}
          value={rawTerm}
        />

        <button
          aria-label="Clear search"
          className={`${rawTerm ? '' : 'invisible'}`}
          onClick={() => setRawTerm('')}
          type="button"
        >
          <Svg
            className="w-4 text-black"
            src="/svgs/close.svg#close"
            title="Close"
            viewBox="0 0 24 24"
          />
        </button>
      </div>

    </div>
  );
}

SearchInput.displayName = 'SearchInput';
