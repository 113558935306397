import { DateTime } from 'luxon';
import { TIMEZONES } from '../settings/common';

const COLORS = [
  { label: 'Black', value: 'var(--black)' },
  { label: 'White', value: 'var(--white)' },
  { label: 'Loden', value: 'var(--loden)' },
  { label: 'Dark Loden', value: 'var(--dark-loden)' },
  { label: 'Orion', value: 'var(--orion)' },
  { label: 'Dark Orion', value: 'var(--dark-orion)' },
  { label: 'Dark Desert', value: 'var(--dark-desert)' },
  { label: 'Medium Desert', value: 'var(--med-desert)' },
  { label: 'Light Desert', value: 'var(--light-desert)' },
  { label: 'Lava', value: 'var(--lava)' },
  { label: 'Dark Lava', value: 'var(--dark-lava)' },
  { label: 'Grey 1', value: 'var(--grey-1)' },
  { label: 'Grey 2', value: 'var(--grey-2)' },
  { label: 'Grey 3', value: 'var(--grey-3)' },
  { label: 'Grey 4', value: 'var(--grey-4)' },
  { label: 'Grey 5', value: 'var(--grey-5)' },
  { label: 'Error', value: 'var(--error)' },
  { label: 'Success', value: 'var(--success)' },
];

const promoBackgroundColors = [
  { label: 'Loden', value: 'var(--loden)' },
  { label: 'Lava', value: 'var(--lava)' },
  { label: 'Orion', value: 'var(--orion)' },
];

export default {
  label: 'Header',
  name: 'header',
  component: 'group',
  description: 'Menu, promobar',
  fields: [
    {
      label: 'Logo',
      name: 'logo',
      component: 'image',
      description: 'Recommended image size: 100 x 100px'
    },
    {
      label: 'Logo Alt',
      name: 'logoAlt',
      component: 'text',
      description: 'Brief description of image',
    },
    {
      label: 'Promobar',
      name: 'promobar',
      component: 'group',
      description: 'Enable, messages, colors, slider settings',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Autohide',
          name: 'autohide',
          component: 'toggle',
          description:
            'Hides promobar after scrolling away from top of the page',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'CTA - Link - Desktop',
          name: 'ctaLink',
          component: 'link',
        },
        {
          label: 'CTA - Link - Mobile',
          name: 'ctaLinkMobile',
          component: 'link',
        },
        {
          label: 'Autoplay Delay',
          name: 'delay',
          component: 'number',
          description: 'Delay between transitions (in seconds). This should be 5 or above, any lower number will be set to 5.',
          defaultValue: 5,
        },
        {
          label: 'Background Color',
          name: 'bgColor',
          component: 'select',
          options: promoBackgroundColors,
          defaultValue: 'var(--primary)',
        },
        {
          label: 'Messages',
          name: 'messages',
          component: 'group-list',
          itemProps: {
            label: '{{item.message}}',
          },
          fields: [
            {
              label: 'Countdown Timer',
              name: 'countdown',
              component: 'group',
              fields: [
                {
                  label: 'Countdown Clock Color',
                  name: 'clockColor',
                  component: 'select',
                  options: COLORS,
                  defaultValue: 'var(--white)',
                },
                {
                  label: 'Target Time',
                  name: 'targetTime',
                  component: 'text',
                  description: `Date format: ${DateTime.now().toFormat(
                    'MM-dd-yyyy tt'
                  )}`,
                  defaultValue: DateTime.now().toFormat('MM-dd-yyyy tt'),
                },
                {
                  label: 'Target TimeZone',
                  name: 'timezone',
                  component: 'select',
                  options: TIMEZONES,
                  defaultValue: 'America/Los_Angeles',
                },
              ],
            },
            {
              label: 'Message',
              name: 'message',
              component: 'html',
              description: 'Up to 100 characters.'
            },
            {
              label: 'Message - Mobile',
              name: 'message_mobile',
              component: 'html',
              description: 'Up to 80 characters.'
            },
            {
              label: 'Link (optional)',
              name: 'link',
              component: 'link',
              description: 'Link wrapping entire message.',
            },
            {
              label: 'Link (optional) - Mobile',
              name: 'mobile_link',
              component: 'link',
              description: 'Link wrapping entire message.',
            },
          ],
          defaultItem: {
            message: 'Free shipping on orders over $100. Shop Now',
            link: { url: '/', text: '' },
          },
        },
      ],
    },
    {
      label: 'Menu',
      name: 'menu',
      component: 'group',
      description: 'Menu items, products slider, links',
      fields: [
        {
          label: 'Menu Items',
          name: 'menuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.menuItem.text}}',
          },
          fields: [
            {
              label: 'Menu Item',
              name: 'menuItem',
              component: 'link',
            },
            {
              label: 'Submenu Columns',
              name: 'submenus',
              component: 'group-list',
              description: 'Navigation will only show up to 5 columns of text links, any content populated past 5 will not show',
              itemProps: {
                label: '{{item.sublink_title}}',
              },
              fields: [
                {
                  label: 'Sublinks Title',
                  name: 'sublink_title',
                  component: 'text',
                },
                {
                  label: 'Submenu Links',
                  name: 'submenuLinks',
                  component: 'group-list',
                  itemProps: {
                    label: '{{item.sublink.text}}',
                  },
                  fields: [
                    {
                      label: 'Show Blank Icon',
                      name: 'blankIcon',
                      component: 'toggle',
                      toggleLabels: {
                        true: 'On',
                        false: 'Off',
                      },
                      defaultValue: false,
                    },
                    {
                      label: 'Image',
                      name: 'icon',
                      component: 'image',
                      description: 'Recommended image size: 42 x 42px',
                    },
                    {
                      label: 'Link',
                      name: 'sublink',
                      component: 'link',
                    },
                    {
                      label: 'Bold Text',
                      name: 'textBold',
                      component: 'toggle',
                      toggleLabels: {
                        true: 'On',
                        false: 'Off',
                      },
                      defaultValue: false,
                    },
                  ],
                  defaultItem: {},
                },
              ],
              defaultItem: {},
            },
            {
              label: 'CTA Columns',
              name: 'imageLinks',
              component: 'group-list',
              description: 'Navigation will only show up to 10 images, any content populated past 10 will not show',
              itemProps: {
                label: '{{item.link.text}}',
              },
              fields: [
                {
                  label: 'Image',
                  name: 'image',
                  component: 'image',
                  description: 'Recommended image size: 345 x 280px'
                },
                {
                  label: 'Image Alt',
                  name: 'alt',
                  component: 'text',
                  description: 'Brief description of image',
                },
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                },
                {
                  label: 'Description',
                  name: 'description',
                  component: 'text',
                },
              ],
              defaultItem: {},
            },
          ],
          defaultItem: {
            menuItem: { text: 'Shop', url: '/collections/all' },
          },
        },
        {
          label: 'Aditional Links',
          name: 'additionalLinks',
          component: 'group-list',
          description: 'Additional links shown under the Menu Items - Mobile Only',
          itemProps: {
            label: '{{item.sublink.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
          defaultItem: {},
        },
      ],
    },
  ],
};
