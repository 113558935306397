import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useCartCheckout, useCartItems } from '@backpackjs/storefront';
import { useLoopOnstoreParams } from './useLoopOnstoreParams';

const removeLoopOnstoreParams = () => {
  localStorage.removeItem('loopOnstoreParams');
  localStorage.removeItem('loopOnstoreCart');
};

export function useLoopreturnsCheckout() {
  const router = useRouter();
  const cartItems = useCartItems();
  const { loopOnstoreParams } = useLoopOnstoreParams();
  const { cartCheckout } = useCartCheckout();
  const loopReturnsCheckout = useCallback(async () => {
    if (!loopOnstoreParams || !cartItems) {
      cartCheckout();
    } else {
      const variantIDs = cartItems.reduce((acc, item) => {
        const items = Array.from(
          { length: item.quantity },
          (_, i) => i + 1
        ).map(() => {
          const id = item.variant.id.split('/');
          return parseInt(id.pop(), 10);
        });
        return [...acc, ...items];
      }, []);

      const savedParams = localStorage.getItem('loopOnstoreCart');
      const loopOnstoreCart = savedParams ? JSON.parse(savedParams) : [];
      if (loopOnstoreCart.length) {
        const cartToken = loopOnstoreCart.find((cart) => {
          return cart[loopOnstoreParams.loop_return_id] !== undefined;
        })?.[loopOnstoreParams.loop_return_id];
        if (cartToken) {
          const endpoint = '/api/loopreturns';
          const cartResponse = await fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              action: 'getCart',
              token: cartToken,
            }),
          });
          if (cartResponse?.cart?.length) {
            const options = {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                action: 'updateCart',
                token: cartToken,
                variantIDs,
              }),
            };
            const response = await fetch(endpoint, options);
            const result = await response.json();
            if (result && result?.updated) {
              removeLoopOnstoreParams();
              router.push(
                `https://${loopOnstoreParams.loop_domain}/#/cart/v2/${cartToken}`
              );
            }
          }
        }
      }

      const endpoint = '/api/loopreturns';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'createCart', variantIDs }),
      };
      const response = await fetch(endpoint, options);
      const result = await response.json();
      if (result && result?.token) {
        loopOnstoreCart.push({
          [`${loopOnstoreParams.loop_return_id}`]: result.token,
        });
        localStorage.setItem(
          'loopOnstoreCart',
          JSON.stringify(loopOnstoreCart)
        );
        removeLoopOnstoreParams();
        router.push(
          `https://${loopOnstoreParams.loop_domain}/#/cart/v2/${result.token}`
        );
      }
    }
  }, [loopOnstoreParams, cartItems]);
  return { loopReturnsCheckout };
}
