export function Schema() {
  return {
    category: 'Map',
    label: 'Shop By State',
    key: 'shop-by-state',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'html'
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Image aspect ratio, full width',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
            defaultValue: 50
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
            defaultValue: 100
          }
        ],
        defaultValue: {
          fullWidth: false,
          mobilePadding: 50,
          desktopPadding: 100
        },
      },
    ],
  };
}
