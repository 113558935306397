import { ProductDetailAccordion } from './ProductDetailAccordion';
import { Schema } from './ProductDetailAccordions.schema';

export function ProductDetailAccordions({ cms }) {
  const { accordions, headerBgColor, headerTextColor, section, product } = cms;
  const moduleID = `${Schema({ product })?.key}-${
    cms.id || cms.cmsId || cms.tinaId
  }`;

  return accordions?.length ? (
    <div id={moduleID}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <ul className="grid grid-cols-1 gap-4">
        {accordions.map((accordion, index) => {
          return (
            <li key={index}>
              <ProductDetailAccordion
                accordion={accordion}
                headerBgColor={headerBgColor}
                headerTextColor={headerTextColor}
              />
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
}

ProductDetailAccordions.displayName = 'ProductDetailAccordions';
ProductDetailAccordions.Schema = Schema;
