import { useState } from 'react';
import { useSettings, useStorefrontInit } from '@backpackjs/storefront';

import { Menu } from './Menu';
import { MenuSidebar } from './MenuSidebar';
import { Promobar } from './Promobar';
import { useMenuSidebar } from './useMenuSidebar';

export function Header({ promobarDisabled, promoBarSection }) {
  const {
    state: { menuSidebarOpen, nestedSidebarContent, nestedSidebarIndex },
    actions: { handleCloseSidebar, handleNestedSidebar, handleOpenSidebar },
  } = useMenuSidebar();

  const [promobarHidden, setPromobarHidden] = useState(false);
  const [hideHeader, setHiddenHeader] = useState(false);
  const mobileNavPosition =
    promobarHidden || promobarDisabled
      ? 'top-[calc(var(--header-height-mobile))] md:top-[calc(var(--header-height))]'
      : 'top-[calc(var(--header-height-mobile)+var(--promobar-height-mobile))] md:top-[calc(var(--header-height)+var(--promobar-height))]';
  const mobileNavHeight =
    promobarHidden || promobarDisabled
      ? 'h-[calc(100%-(var(--header-height-mobile))+1px)] md:h-[calc(100%-(var(--header-height))+1px)]'
      : 'h-[calc(100%-(var(--header-height-mobile)+var(--promobar-height-mobile))+1px)] md:h-[calc(100%-(var(--header-height)+var(--promobar-height))+1px)]';

  const logo = useSettings()?.header?.logo;
  const jsonSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: useStorefrontInit()?.shop?.name,
    url: process.env.SITE_URL,
    logo: logo
      ? logo.src
      : process.env.NEXT_PUBLIC_SITE_LOGO ||
        `${process.env.SITE_URL}/svgs/logo.svg`,
  };

  return (
    <>
      <header
        className={`fixed left-0 right-0 top-0 z-20 flex flex-col bg-background transition-[all] duration-300 ease-out

      ${hideHeader ? 'translate-y-[-100%]' : 'translate-y-[0%]'}
      `}
      >
        <Promobar
          promobarDisabled={promobarDisabled}
          promobarHidden={promobarHidden}
          setPromobarHidden={setPromobarHidden}
          promoBarSection={promoBarSection}
        />

        <Menu
          handleCloseSidebar={handleCloseSidebar}
          handleOpenSidebar={handleOpenSidebar}
          setHiddenHeader={setHiddenHeader}
          menuSidebarOpen={menuSidebarOpen}
        />
      </header>
      <MenuSidebar
        handleCloseSidebar={handleCloseSidebar}
        handleNestedSidebar={handleNestedSidebar}
        menuSidebarOpen={menuSidebarOpen}
        nestedSidebarContent={nestedSidebarContent}
        nestedSidebarIndex={nestedSidebarIndex}
        mobileNavPosition={mobileNavPosition}
        mobileNavHeight={mobileNavHeight}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonSchema) }}
      />
    </>
  );
}

Header.displayName = 'Header';
