export function Schema({ product }) {
  if (!product) return null;

  return {
    category: 'Product',
    label: 'Kickflip Customizer',
    key: 'kickflip-customizer',
    previewSrc: `${process.env.SITE_URL}/images/customizer-cta.jpg`,
    fields: [
      {
        label: 'Replace default ATC',
        name: 'replaceAtc',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        label: 'Button Action',
        name: 'buttonAction',
        component: 'select',
        options: [
          { label: 'Open Kickflip Modal', value: 'openKickflip' },
          { label: 'Custom URL', value: '' },
        ],
        defaultValue: 'openKickflip',
      },
      {
        label: 'Custom URL',
        name: 'customUrl',
        component: 'text',
      },
      {
        label: 'Customizer Product',
        name: 'customizerProduct',
        component: 'group-list',
        itemProps: {
          label: '{{item.productEnable.handle}}',
        },
        fields: [
          {
            name: 'productEnable',
            label: 'Product Enable',
            component: 'productSearch',
          },
          {
            label: 'Heading',
            name: 'headingText',
            component: 'text',
            defaultValue: 'Customize This Product',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'html',
          },
          {
            label: 'startingPointId',
            name: 'startingPointId',
            component: 'text',
          },
        ],
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}
