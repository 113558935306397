import { useEffect, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useProductsFromHandles, useSettings } from '@backpackjs/storefront';

import { Collection, Spinner } from '../../snippets';
import { Schema } from './SearchResults.schema';
import { useSearchProductResults, useSearchContentResults } from '../../hooks';
import { CollectionSort } from '../../snippets/Collection/CollectionSort';

export function SearchResults({ cms }) {
  const { query } = useRouter();
  const { productResults: results, finished } = useSearchProductResults({
    term: query.term,
  });
  const { products: productImpressions } = useProductsFromHandles({
    handles: results?.slice(0, 7).map(({ handle }) => handle),
  });
  const { section } = cms;
  const moduleID = `${Schema({ page: cms?.page })?.key}-${
    cms.id || cms.cmsId || cms.tinaId
  }`;
  const [collectionProductsData, setCollectionProductsData] = useState(null);

  const { contentResults, finished: contentFinished } = useSearchContentResults(
    {
      term: query.term,
    }
  );
  const settings = useSettings();
  const { contentsEnabled } = {
    ...settings?.search?.results,
  };
  const finishedLoading = finished && contentFinished;

  const productResults = useMemo(() => {
    if (!finishedLoading) return [];
    if (!contentsEnabled) return results;
    return results?.length ? results?.concat(contentResults) : contentResults;
  }, [finishedLoading, contentsEnabled, results, contentResults]);

  const [productLength, setProductLength] = useState(productResults?.length);

  useEffect(() => {
    if (!productImpressions?.length) return;
    PubSub.publish('VIEW_SEARCH_PAGE_RESULTS', productImpressions);
  }, [productImpressions]);

  return (
    <div id={moduleID}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div className="search-header flex items-center justify-center py-[1.5rem]">
        <div className="container-3xl relative w-full">
          <div className="mx-auto flex max-w-[41.5rem] flex-col items-center justify-center gap-4">
            <h1 className="h5 m-0 text-center uppercase">
              {query.term && productLength > 0 && finishedLoading
                ? `Found ${productLength} ${
                    productLength === 1 ? 'result' : 'results'
                  } for "${query.term}"`
                : null}
            </h1>

            {productResults?.length === 0 && finishedLoading && (
              <h1 className="h1 m-0 text-center uppercase">
                {`Found 0 results for "${query.term || ''}"`}
              </h1>
            )}
            {productResults?.length > 0 && collectionProductsData && (
              <div className="absolute right-6 z-[2] w-full max-w-[11rem] max-md:hidden">
                <CollectionSort
                  selectedSort={collectionProductsData.state.selectedSort}
                  selectSort={collectionProductsData.actions.selectSort}
                />
              </div>
            )}
          </div>

          {(!Array.isArray(productResults) || !finishedLoading) && (
            <div className="flex min-h-[20rem] items-center justify-center">
              <Spinner width="32" />
            </div>
          )}
        </div>
      </div>

      <div className="container-3xl pb-6 lg:pb-10">
        {productResults?.length > 0 && (
          <Collection
            handle="search"
            products={productResults}
            setCollectionProductsData={setCollectionProductsData}
            setProductLength={setProductLength}
          />
        )}
      </div>
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
SearchResults.Schema = Schema;
