import { useState } from 'react';
import { Markdown, Svg } from '../../snippets';

export function Accordion({ accordion }) {
  const { body, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <li
      className={`mt-0 border-b-medDesert py-4 last:border-b xl:pb-6 ${
        isOpen ? 'border-t-2 border-darkOrion' : 'border-t border-medDesert'
      }`}
    >
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="flex w-full items-center justify-between gap-x-4 text-left outline-none"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <h3
          className="mb-0 flex-1 uppercase text-darkOrion"
          dangerouslySetInnerHTML={{ __html: header }}
        />

        <Svg
          className={`text-current w-5 ${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down-wide.svg#chevron-down-wide"
          title={`Chevron ${isOpen ? 'Up' : 'Down'}`}
          viewBox="0 0 20 8"
        />
      </button>

      <div className={`pt-4 text-grey5 xs:pt-6 ${isOpen ? 'block' : 'hidden'}`}>
        <Markdown>{body}</Markdown>
      </div>
    </li>
  );
}

Accordion.displayName = 'Accordion';
