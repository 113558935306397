import activate from './activate';
import forgot from './forgot';
import login from './login';
import menu from './menu';
import register from './register';
import reset from './reset';
import background from './background';

const referFriendImage = {
        label: 'Refer A Friend Image',
        name: 'referFriendImage',
        description: 'Recommended Image Size: 1000 x 1000',
        component: 'group',
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
            description: 'Recommended Image Size: 1000 x 1000',
          },
        ]
      };

export default {
  label: 'Account',
  name: 'account',
  component: 'group',
  description: 'Menu, login, register, password reset',
  fields: [background, menu, login, register, forgot, reset, activate, referFriendImage],
};
