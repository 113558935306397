import { useEffect, useState, useMemo, useCallback } from 'react';
import { useSettings, useCartCount, useCart } from '@backpackjs/storefront';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Mousewheel, Scrollbar, Manipulation } from 'swiper';
import { UpsellItem } from './UpsellItem';
import { useColorSwatches, useRebuy } from '../../hooks';

export function RebuyUpsells() {
  const settings = useSettings();
  const { heading, rebuyRulesetId } = { ...settings?.cart?.rebuyUpsells };
  const { swatchesMap } = useColorSwatches();
  const [swiper, setSwiper] = useState(null);
  const [, setActiveIndex] = useState(0);
  const [rebuyProducts, setRebuyProducts] = useState(null);
  const { getRebuyProducts } = useRebuy();
  const cartItemCount = useCartCount();
  const cart = useCart();
  const [oosProducts, setOosProducts] = useState([]);

  const productsInCart = useMemo(() => {
    return (cart?.lines || []).reduce((acc, line) => {
      const productId = line?.variant?.product?.id;
      if (productId && productId !== '') {
        return [...acc, productId];
      }
      return acc;
    }, []);
  }, [cart?.lines]);

  const handleRebuy = useCallback(async () => {
    if (!rebuyRulesetId || !cartItemCount) {
      return [];
    }
    const rebuyProductsData = await getRebuyProducts({
      rulesetId: rebuyRulesetId,
      cartItemCount,
    });
    const Filteredproducts = (rebuyProductsData?.data || []).reduce(
      (acc, item) => {
        if (
          !productsInCart.includes(item?.admin_graphql_api_id) &&
          !oosProducts.includes(item?.admin_graphql_api_id)
        ) {
          return [...acc, item];
        }
        return acc;
      },
      []
    );
    setRebuyProducts(Filteredproducts);
    return Filteredproducts;
  }, [rebuyRulesetId, cartItemCount, productsInCart, oosProducts]);

  useEffect(() => {
    handleRebuy();
  }, [handleRebuy]);

  if (!rebuyRulesetId || !rebuyProducts?.length) return null;
  return (
    <div className="rebuy-container px-4 py-3 md:px-6">
      <div className="rebuy-header mb-1.5 flex max-md:!pr-0 md:mb-3">
        {heading && (
          <h2 className="rebuy-title m-0 text-sh-sm capitalize md:text-sh">
            {heading}
          </h2>
        )}
      </div>
      <div>
        <Swiper
          onSwiper={setSwiper}
          scrollbar={{
            hide: false,
            draggable: true,
          }}
          navigation={false}
          mousewheel={{
            forceToAxis: true,
          }}
          modules={[A11y, Scrollbar, Mousewheel, Manipulation]}
          onSlideChange={(_swiper) => {
            setActiveIndex(_swiper.realIndex);
          }}
          slidesPerView={1}
          spaceBetween={16}
          grabCursor
          breakpoints={{
            769: {
              slidesPerView: 1,
              spaceBetween: 24,
            },
          }}
          className="pb-3 md:pb-4"
        >
          {(rebuyProducts || []).map((product, index) => {
            const handle = product?.handle;
            return (
              <SwiperSlide
                className="h-auto w-full bg-grey1"
                key={`sidecart-rebuy-product-${handle}-${index}`}
              >
                <UpsellItem
                  enabledColorNameOnHover={false}
                  enabledColorSelector
                  enabledQuickShop={false}
                  enabledStarRating={false}
                  handle={handle}
                  index={index}
                  isSearchPage={false}
                  swatchesMap={swatchesMap}
                  isRebuyPanel
                  rulesetid={rebuyRulesetId}
                  setOosProducts={setOosProducts}
                  rebuyProductId={product?.admin_graphql_api_id}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

RebuyUpsells.displayName = 'RebuyUpsells';
