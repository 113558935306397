import { useMemo } from 'react';
import PropTypes from 'prop-types';

const baseWidth = 32;
const baseBorderWidth = 4;

const formatWidth = (width) => {
  if (typeof width === 'number') {
    return `${width}px`;
  }
  return `${parseInt(width, 10) || baseWidth}px`;
};

const getBorderWidth = (width) => {
  const widthNumber =
    typeof width === 'number' ? width : parseInt(width, 10) || baseWidth;
  return `${(widthNumber * (baseBorderWidth / baseWidth)).toFixed(2)}px`;
};

export function Spinner({ className, color, width }) {
  const formattedWidth = useMemo(() => formatWidth(width), [width]);
  const borderWidth = useMemo(() => getBorderWidth(width), [width]);

  return (
    <div
      style={{ width: formattedWidth, height: formattedWidth, color }}
      className={`relative ${className}`}
    >
      <div
        className="border-current inline-block h-full w-full animate-spin rounded-full border-solid border-r-transparent align-[-0.125em]"
        style={{ borderWidth }}
        role="status"
      >
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
      <div
        className="border-current absolute inset-0 z-[-1] inline-block h-full w-full rounded-full border-solid opacity-[12.5%]"
        style={{ borderWidth }}
      />
    </div>
  );
}

Spinner.displayName = 'Spinner';
Spinner.defaultProps = {
  className: '',
  color: 'currentColor',
  width: baseWidth,
};
Spinner.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
