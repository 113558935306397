import {
  useCartTotals,
  useCartCount,
  useSettings,
} from '@backpackjs/storefront';
import { useLoopreturnsCheckout } from '../../hooks';

export function CartTotals(props) {
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const settings = useSettings();
  const { checkoutText, totalText } = { ...settings?.cart?.totals };
  const { hideTaxesMessage, hideShippingMessage } = props;
  const { loopReturnsCheckout } = useLoopreturnsCheckout();

  return (
    <div className={`flex-col gap-4 md:gap-6 ${cartCount ? 'flex' : 'hidden'}`}>
      <div className="flex flex-col gap-4">
        {!hideShippingMessage && (
          <div className="flex justify-between">
            <p className="mb-0">Shipping</p>
            <p className="mb-0">TBD</p>
          </div>
        )}

        {!hideTaxesMessage && (
          <div className="flex justify-between">
            <p className="mb-0">Taxes</p>
            <p className="mb-0">TBD</p>
          </div>
        )}

        <div className="flex justify-between font-bold">
          <p className="mb-0 uppercase">{totalText || 'Total'}</p>
          <p className="mb-0">
            $
            {parseFloat(cartTotals?.total || '0')
              .toFixed(2)
              .replace('.00', '')}
          </p>
        </div>
      </div>

      <button
        aria-label="Go to checkout page"
        className="button button--black w-full no-underline"
        onClick={loopReturnsCheckout}
        type="button"
      >
        {checkoutText || 'Checkout'}
      </button>
    </div>
  );
}

CartTotals.displayName = 'CartTotals';
