export function Schema() {
  return {
    category: 'Embed',
    label: 'Embed FourSixty',
    key: 'embed-foursixty',
    fields: [
      {
        label: 'Heading',
        name: 'instaHandle',
        component: 'html',
      },
      {
        label: 'CTA Link',
        name: 'instaLink',
        component: 'link',
      },
      {
        name: 'snippet',
        label: 'Embed Code',
        component: 'html',
        description: 'Paste in snippet we want to display',
      },
      {
        component: 'number',
        name: 'mobilePadding',
        label: 'Section Bottom Margin - Mobile',
        defaultValue: 24,
      },
      {
        component: 'number',
        name: 'desktopPadding',
        label: 'Section Bottom Margin - Desktop',
        defaultValue: 48,
      },
    ],
  };
}
