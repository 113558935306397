import { useMemo } from 'react';
import {
  useCartCount,
  useCartTotals,
  useSettings,
} from '@backpackjs/storefront';

export function FreeShippingMeter(props) {
  const cartCount = useCartCount();
  const cartTotals = useCartTotals();
  const cartItems = props?.cartItems || [];
  const settings = useSettings();
  const { customClasses } = props;
  const { enabled, minCartSpend, progressMessage, qualifiedMessage } = {
    ...settings?.cart?.freeShipping,
  };
  const showMeter = enabled && cartCount > 0 && minCartSpend > 0;

  const cartTotalsWithoutCustomized = useMemo(() => {
    if (!cartItems.length) return cartTotals;
    const customizedTotals = cartItems.reduce((acc, item) => {
      if (item.attributes.find((attr) => attr.key === '_mczr_mczrStoreId')) {
        return acc + parseFloat(item?.estimatedCost?.totalAmount?.amount || 0);
      }
      return acc;
    }, 0);
    return {
      ...cartTotals,
      total: (parseFloat(cartTotals.total) - customizedTotals).toFixed(2),
    };
  }, [cartTotals, cartItems]);

  const progress = useMemo(() => {
    if (!showMeter) return null;

    const total = parseFloat(cartTotalsWithoutCustomized.total);
    const fraction = total / minCartSpend;
    const remainder = minCartSpend - total;
    const progressMessageSplit = progressMessage?.split('{{amount}}');

    if (fraction >= 1) {
      return {
        percent: 100,
        firstPartMessage: qualifiedMessage,
      };
    }

    return {
      percent: fraction * 100,
      firstPartMessage: progressMessageSplit[0],
      secondPartMessage: progressMessageSplit[1],
      amountLeft: `$${remainder.toFixed(2).replace('.00', '')}`,
    };
  }, [
    cartTotalsWithoutCustomized.total,
    minCartSpend,
    progressMessage,
    qualifiedMessage,
    showMeter,
  ]);

  return showMeter ? (
    <div className={`p-4 ${customClasses || ''}`}>
      <p className="mb-2 text-center text-xs">
        {progress.firstPartMessage} <b>{progress.amountLeft}</b>
        {progress.secondPartMessage}
      </p>

      <div className="h-1.5 w-full overflow-hidden rounded bg-lightGray">
        <div
          className="h-full w-full origin-left bg-lava transition"
          style={{
            transform: `scaleX(${progress.percent}%)`,
          }}
        />
      </div>
    </div>
  ) : null;
}

FreeShippingMeter.displayName = 'FreeShippingMeter';
