import { OBJECT_POSITIONS } from '../../settings/common';

const media = {
  label: 'Media Settings',
  name: 'media',
  description: 'Image settings, order, aspect ratio',
  component: 'group',
  fields: [
    {
      label: 'Image Settings',
      name: 'image',
      description: 'Image, image position',
      component: 'group',
      fields: [
        {
          label: 'Image Alt',
          name: 'alt',
          component: 'text',
          description: 'Brief description of image',
        },
        {
          label: 'Image (desktop)',
          name: 'imageDesktop',
          component: 'image',
          description: 'Recommended image size: 660px x 660px',
        },
        {
          label: 'Image Position (desktop)',
          name: 'positionDesktop',
          component: 'select',
          options: OBJECT_POSITIONS.desktop,
        },
        {
          label: 'Image (mobile/tablet)',
          name: 'imageMobile',
          component: 'image',
          description: 'Recommended image size: 375px x 375px',
        },
        {
          label: 'Image Position (mobile/tablet)',
          name: 'positionMobile',
          component: 'select',
          options: OBJECT_POSITIONS.mobile,
        },
      ],
    },
    {
      label: 'Media Order (desktop)',
      name: 'mediaOrderDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: '1' },
        { label: 'Right', value: '2' },
      ],
    },
    {
      label: 'Media Aspect Ratio (tablet)',
      name: 'aspectTablet',
      component: 'select',
      options: [
        { label: '3:2', value: 'md:before:pb-[67%]' },
        { label: '4:3', value: 'md:before:pb-[75%]' },
        { label: '5:4', value: 'md:before:pb-[80%]' },
        { label: '8:7', value: 'md:before:pb-[87.5%]' },
        { label: '1:1', value: 'md:before:pb-[100%]' },
        { label: '7:8', value: 'md:before:pb-[114%]' },
        { label: '4:5', value: 'md:before:pb-[125%]' },
        { label: '3:4', value: 'md:before:pb-[133%]' },
        { label: '2:3', value: 'md:before:pb-[150%]' },
      ],
    },
    {
      label: 'Media Aspect Ratio (desktop)',
      name: 'aspectDesktop',
      component: 'select',
      options: [
        { label: '3:2', value: 'lg:before:pb-[67%]' },
        { label: '4:3', value: 'lg:before:pb-[75%]' },
        { label: '5:4', value: 'lg:before:pb-[80%]' },
        { label: '8:7', value: 'lg:before:pb-[87.5%]' },
        { label: '1:1', value: 'lg:before:pb-[100%]' },
        { label: '7:8', value: 'lg:before:pb-[114%]' },
        { label: '4:5', value: 'lg:before:pb-[125%]' },
        { label: '3:4', value: 'lg:before:pb-[133%]' },
        { label: '2:3', value: 'lg:before:pb-[150%]' },
      ],
    },
    {
      label: 'Media Order (mobile/tablet)',
      name: 'mediaOrderMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Top', value: '1' },
        { label: 'Bottom', value: '2' },
      ],
    },
    {
      label: 'Media Aspect Ratio (mobile)',
      name: 'aspectMobile',
      component: 'select',
      options: [
        { label: '3:2', value: 'max-md:before:pb-[67%]' },
        { label: '4:3', value: 'max-md:before:pb-[75%]' },
        { label: '5:4', value: 'max-md:before:pb-[80%]' },
        { label: '8:7', value: 'max-md:before:pb-[87.5%]' },
        { label: '1:1', value: 'max-md:before:pb-[100%]' },
        { label: '7:8', value: 'max-md:before:pb-[114%]' },
        { label: '4:5', value: 'max-md:before:pb-[125%]' },
        { label: '3:4', value: 'max-md:before:pb-[133%]' },
        { label: '2:3', value: 'max-md:before:pb-[150%]' },
      ],
    },
  ],
  defaultValue: {
    image: {
      alt: 'Man with backpack crossing the street',
      imageDesktop: {
        src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-tn1yJqxNj-8-unsplash.jpg?v=1672787927',
      },
      positionDesktop: 'md:object-center',
      imageMobile: {
        src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/mad-rabbit-tattoo-tn1yJqxNj-8-unsplash.jpg?v=1672787927',
      },
      positionMobile: 'object-center',
    },
    mediaOrderDesktop: '1',
    aspectTablet: 'md:before:pb-[100%]',
    aspectDesktop: 'lg:before:pb-[100%]',
    mediaOrderMobile: '1',
    aspectMobile: 'max-md:before:pb-[100%]',
  },
};

const content = {
  label: 'Content Settings',
  name: 'content',
  description: 'Heading, subtext, button, text color, alignment, content width',
  component: 'group',
  fields: [
    {
      label: 'Heading',
      name: 'heading',
      component: 'html',
    },
    {
      label: 'Subtext',
      name: 'subtext',
      component: 'html',
    },
    {
      label: 'Icon/Text List',
      name: 'list',
      component: 'group-list',
      description: 'Max of four items',
      itemProps: {
        label: '{{item.label}}',
      },
      validate: {
        maxItems: 4,
      },
      fields: [
        {
          label: 'Icon',
          name: 'icon',
          component: 'image',
        },
        {
          label: 'Label',
          name: 'label',
          component: 'text',
        },
      ],
    },
    {
      label: 'Button',
      name: 'buttonLink',
      component: 'link',
    },
    {
      label: 'Button Style',
      name: 'buttonStyle',
      component: 'select',
      options: [
        { label: 'Orion Fill', value: 'button--orion' },
        { label: 'Black Fill', value: 'button--black' },
        { label: 'Dark Outline', value: 'button--dark-outline' },
      ],
    },
    {
      label: 'Content Alignment (desktop)',
      name: 'alignmentDesktop',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'md:text-left md:items-start' },
        { label: 'Center', value: 'md:text-center md:items-center' },
      ],
    },
    {
      label: 'Content Alignment (mobile/tablet)',
      name: 'alignmentMobile',
      component: 'radio-group',
      direction: 'horizontal',
      variant: 'radio',
      options: [
        { label: 'Left', value: 'text-left items-start' },
        { label: 'Center', value: 'text-center items-center' },
      ],
    },
  ],
  defaultValue: {
    heading: '50/50 Heading',
    subtext:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    buttonLink: { text: 'Shop Now', url: '' },
    buttonStyle: 'button--orion',
    alignmentDesktop: 'md:text-left md:items-start',
    maxWidthDesktop: 'md:max-w-[30rem] lg:max-w-[38rem]',
    alignmentMobile: 'text-left items-start',
  },
};

export const Schema = () => {
  return {
    category: 'Media',
    label: '50/50',
    key: 'fifty-fifty',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/fifty-fifty-screenshot.jpg?v=1680879252',
    fields: [
      media,
      content,
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Section Bottom Spacing',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
};
