import { useMemo } from 'react';

import { Link, Markdown, Image } from '../../snippets';

export function ShopByDesignContent({ content }) {
  const {
    alignmentDesktop,
    alignmentMobile,
    intro,
    heading,
    subtext,
    list,
    buttonLink,
    buttonStyle,
    focusLink,
    bgColor,
    textColor,
  } = { ...content };
  const alignmentClasses = `${alignmentMobile} ${alignmentDesktop}`;
  let listAlignmentDesktop;
  let listAlignmentMobile;

  switch (alignmentDesktop) {
    case 'md:text-center md:items-center':
      listAlignmentDesktop = 'md:mx-auto md:max-w-[400px]';
      break;
    default:
      listAlignmentDesktop = 'md:mx-0 md:max-w-none';
  }

  switch (alignmentMobile) {
    case 'text-center items-center':
      listAlignmentMobile = 'mx-auto max-w-[240px]';
      break;
    default:
      listAlignmentMobile = '';
  }

  return (
    <div className="px-4 py-6 md:mx-auto md:max-w-[500px]">
      <div className={`flex flex-col ${alignmentClasses}`}>
        {intro && (
          <p className={`text-[.875rem] font-bold leading-x-tight tracking-widest uppercase ${textColor == 'dark' ? 'text-darkLava' : 'text-white'} mb-4`}>
            {intro}
          </p>
        )}

        <h2 className={`text-h2-m md:text-h1 mb-4 uppercase ${textColor == 'dark' ? 'text-black' : 'text-white'}`} dangerouslySetInnerHTML={{__html: heading}}></h2>

        {subtext && (
          <div className={`${textColor == 'dark' ? 'text-black' : 'text-white'} [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-sm [&_p]:md:text-base`}>
            <div
              dangerouslySetInnerHTML={{__html: subtext}}
            />
          </div>
        )}

        {list?.length > 0 && (
          <div
            className={`mt-6 flex flex-col gap-4 text-left ${listAlignmentMobile} ${listAlignmentDesktop}`}
          >
            {list.map(({ icon, label }, index) => {
              return (
                <div key={index} className="flex items-center gap-2">
                  {icon?.src && (
                    <div className="relative h-8 w-8">
                      <Image
                        alt={label}
                        fill
                        sizes="(min-width: 768px) 25vw, 50vw"
                        src={icon.src}
                      />
                    </div>
                  )}

                  {label && (
                    <p className="my-0 flex-1 text-sm font-bold">{label}</p>
                  )}
                </div>
              );
            })}
          </div>
        )}

        {buttonLink?.text && buttonLink?.url && (
          <Link
            aria-label={buttonLink?.text}
            className={`button mt-4 inline-block no-underline md:mt-6 ${buttonStyle}`}
            href={buttonLink?.url}
            newTab={buttonLink?.newTab}
          >
            {buttonLink?.text}
            {buttonLink?.newTab && (
              <span className="sr-only">(opens in a new tab)</span>
            )}
          </Link>
        )}

        {focusLink?.text && focusLink?.url && (
          <Link
            aria-label={focusLink?.text}
            className={`focus-link text-[.875rem] mt-6 ${textColor == 'dark' ? 'text-black' : 'text-white hover:text-medDesert'}`}
            href={focusLink?.url}
            newTab={focusLink?.newTab}
          >
            {focusLink?.text}
            {focusLink?.newTab && (
              <span className="sr-only">(opens in a new tab)</span>
            )}
          </Link>
        )}
      </div>
    </div>
  );
}

ShopByDesignContent.displayName = 'ShopByDesignContent';
