'use client';

import { useEffect, useState, useCallback } from 'react';
import { useProductByHandle } from '@backpackjs/storefront';
import { useGlobalContext } from '../../contexts';
import { useAddCustomizedToCart } from './useAddCustomizedToCart';

export function KickflipCtaLoader() {
  const mczrShopId = process.env.NEXT_PUBLIC_MCZR_SHOPID;
  const mczrBaseUrl = process.env.NEXT_PUBLIC_MCZR_BASEURL;
  const [kfProductHandle, setKfProductHandle] = useState('');
  const { product } = useProductByHandle({ handle: kfProductHandle });

  const {
    actions: { openCart, openModal },
  } = useGlobalContext();
  const { handleAddToCart } = useAddCustomizedToCart();

  const handleKickflipAction = useCallback(
    async (event) => {
      if (
        !event?.target?.closest('[href*="#kickflip_"]') ||
        event?.target?.getAttribute('href')?.indexOf('#kickflip_') === -1
      )
        return;
      const kickflipParams = (
        event?.target?.closest('[href*="#kickflip_"]')
          ? event.target.closest('[href*="#kickflip_"]')
          : event?.target
      )
        ?.getAttribute('href')
        ?.split('#kickflip_')
        ?.pop();

      if (kickflipParams?.indexOf(':') === -1) return;
      const startingPointId = kickflipParams?.split(':')?.pop();
      setKfProductHandle(kickflipParams?.split(':')[0]);
      const pathUrl = ''
        .concat(mczrBaseUrl, '/customize/startingpoint/')
        .concat(startingPointId, '?shopid=')
        .concat(encodeURIComponent(mczrShopId));
      const storeProductUrl = window.location.href.replace(
        /(.*\/products\/[\w\-]+)(.*)/,
        '$1?designId=<designId>'
      );
      const iframeSrc =
        pathUrl +
        '&lang='.concat('en') +
        '&country='.concat('US') +
        '&storeProductUrl='.concat(storeProductUrl);

      openModal(
        <div className="scrollbar overflow-auto pl-0 pr-4 max-md:-mr-4 md:px-5">
          <div id="mczrLoader">
            <iframe
              id="mczrProductIframe"
              title="mczrProductIframe"
              src={iframeSrc}
              width="100%"
              height="800px"
              allow="clipboard-write"
            />
          </div>
        </div>,
        {
          className:
            'max-w-[calc(100%-2rem)] md:max-w-[calc(100%-4rem)] md:!w-[1440px]',
        }
      );
    },
    [product?.handle]
  );

  useEffect(() => {
    const formActions = Array.from(
      document.querySelectorAll('*[href*="#kickflip_"]')
    );
    formActions.forEach((action) => {
      action.addEventListener('click', (e) => {
        e.preventDefault();
      });
    });

    const handleAddToCartPostMessage = (event) => {
      if (
        event.data.eventName === 'mczrAddToCart' &&
        event.origin === mczrBaseUrl
      ) {
        const details = event.data.detail;
        handleAddToCart({
          details,
          product,
          callback: openCart,
        });
      }
    };

    window.addEventListener('message', handleAddToCartPostMessage, false);
    document.body.addEventListener('click', handleKickflipAction);
    return () => {
      window.removeEventListener('message', handleAddToCartPostMessage);
      document.body.removeEventListener('click', handleKickflipAction);
    };
  }, [product?.handle]);

  return null;
}
