import { useMemo } from 'react';

export function useVariantPrices({ variant }) {
  const { id, priceV2, compareAtPriceV2 } = { ...variant };

  return useMemo(() => {
    if (!priceV2?.amount) {
      return { price: null, compareAtPrice: null };
    }

    const amount = parseFloat(priceV2.amount);
    const compareAtAmount = parseFloat(compareAtPriceV2?.amount || '0');

    return {
      price: `$${amount.toFixed(2).replace(/\.00/, '')}`,
      compareAtPrice:
        compareAtAmount > amount
          ? `$${compareAtAmount.toFixed(2).replace(/\.00/, '')}`
          : null,
    };
  }, [id]);
}
