import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';

export function Modal() {
  const {
    state: { modal },
    actions: { closeModal },
  } = useGlobalContext();

  const { className, ...props } = { ...modal.props };

  return modal.children ? (
    <div
      className={`fixed top-1/2 left-1/2 z-50 max-h-[calc(var(--viewport-height)-2rem)] w-[calc(100%-2rem)] max-w-[58.5rem] -translate-x-1/2 -translate-y-1/2 overflow-hidden bg-background ${className}`}
      {...props}
    >
      <button
        aria-label="Close modal"
        className="absolute right-5 md:right-6 top-5 md:top-6  z-10 flex h-[18px] md:h-6 w-[18px] md:w-6 items-center justify-center bg-transparent"
        onClick={closeModal}
        type="button"
      >
        <Svg
          className="w-[18px] text-text"
          src="/svgs/icon-close.svg#icon-close"
          title="Close"
          viewBox="0 0 18 18"
        />
      </button>

      <div className="scrollbar-hide px-4 md:px-6 py-10 md:py-14 max-h-[calc(var(--viewport-height)-2rem)] overflow-y-auto">
        {modal.children}
      </div>
    </div>
  ) : null;
}

Modal.displayName = 'Modal';
