export const Schema = () => {
  return {
    category: 'Embed',
    label: 'Embed Content',
    key: 'embed-content',
    fields: [
      {
        name: 'snippet',
        label: 'Embed Code',
        component: 'html',
        description: 'Paste in snippet we want to display',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Section Bottom Spacing',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
};
