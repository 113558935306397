export default {
  label: 'Footer',
  name: 'footer',
  component: 'group',
  description: 'Menu, social, legal, email marketing',
  fields: [
    {
      label: 'Menu',
      name: 'menu',
      component: 'group',
      description: 'Menu items',
      fields: [
        {
          label: 'Menu Items',
          name: 'menuItems',
          component: 'group-list',
          itemProps: {
            label: '{{item.title}}',
          },
          fields: [
            {
              label: 'Menu Item Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Menu Item Links',
              name: 'links',
              component: 'group-list',
              itemProps: {
                label: '{{item.link.text}}',
              },
              fields: [
                {
                  label: 'Link',
                  name: 'link',
                  component: 'link',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      label: 'Email Marketing',
      name: 'email',
      component: 'group',
      description: 'Enable, heading, subtext, klaviyo list ID, messages',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
        },
        {
          label: 'Heading',
          name: 'heading',
          component: 'text',
        },
        {
          label: 'Subtext',
          name: 'subtext',
          component: 'text',
        },
        {
          label: 'Klaviyo List ID',
          name: 'listId',
          component: 'text',
        },
        {
          label: 'Placeholder Text',
          name: 'placeholder',
          component: 'text',
        },
        {
          label: 'Button Text',
          name: 'buttonText',
          component: 'text',
        },
        {
          label: 'Success Message',
          name: 'successMessage',
          component: 'text',
        },
        {
          label: 'Already Submitted Message',
          name: 'submittedMessage',
          component: 'text',
        },
      ],
      defaultValue: {
        enabled: true,
        heading: 'NEWSLETTER SIGN UP',
        subtext:
          'Sign up for exclusive updates, new arrivals & insider only discounts',
        placeholder: 'Enter your email...',
        buttonText: 'Submit',
      },
    },
    {
      label: 'Social',
      name: 'social',
      component: 'group',
      description: 'Social links',
      fields: [
        {
          label: 'Social Links',
          name: 'links',
          component: 'group-list',
          itemProps: {
            label: '{{item.platform}}',
          },
          fields: [
            {
              label: 'Platform',
              name: 'platform',
              component: 'select',
              options: [
                { label: 'Facebook', value: 'facebook' },
                { label: 'Instagram', value: 'instagram' },
                { label: 'TikTok', value: 'tiktok' },
                { label: 'YouTube', value: 'youtube' },
              ],
            },
            {
              label: 'Url',
              name: 'url',
              component: 'text',
            },
          ],
          defaultItem: {
            platform: 'facebook',
            url: '',
          },
        },
      ],
    },
    {
      label: 'Legal',
      name: 'legal',
      component: 'group',
      description: 'Legal links, copyright notice, download app',
      fields: [
        {
          label: 'Legal Links',
          name: 'links',
          component: 'group-list',
          itemProps: {
            label: '{{item.link.text}}',
          },
          fields: [
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
          defaultValue: [
            {
              link: {
                text: 'Privacy Policy',
                url: '/pages/privacy-policy',
              },
            },
            {
              link: {
                text: 'Terms & Conditions',
                url: '/pages/terms-conditions',
              },
            },
          ],
        },
        {
          label: 'Site Copyright Notice',
          name: 'copyrightNotice',
          component: 'text',
          defaultValue: 'All Rights Reserved',
        },
        {
          label: 'App Store URL - Desktop',
          name: 'appStoreURLDesktop',
          component: 'text',
        },
        {
          label: 'App Store URL - Mobile',
          name: 'appStoreURLMobile',
          component: 'text',
        },
      ],
    },
  ],
};
