import React from 'react';

import { Image, Link } from '../../snippets';
import { Schema } from './TwoTiles.schema';

export function TwoTiles({ cms }) {
  const { section, tiles, heading } = cms;
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const mobileLayout = section?.mobileLayout;

  return (
    <div id={moduleID} className="px-contained py-contained">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      {heading && (
        <h2
          className="h3 mb-6 text-center md:mb-8"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      )}
      <div
        className={`${maxWidthClass} mx-auto grid gap-x-5 gap-y-5 ${
          mobileLayout === 'stack' ? 'md:grid-cols-2' : 'grid-cols-2'
        } lg:gap-x-8`}
      >
        {tiles?.slice(0, 2).map((item, index) => {
          return (
            <div key={index} className="tile-container w-full">
              <Link
                aria-label={item.heading}
                href={item.link?.url}
                newTab={item.link?.newTab}
                className="link-container block w-full text-black no-underline"
              >
                <div
                  className={`relative bg-offWhite md:mb-7 ${
                    section?.aspectRatio
                  } ${mobileLayout === 'stack' ? 'mb-5' : 'mb-4'}`}
                >
                  {item?.image?.src && (
                    <Image
                      alt={item.alt}
                      className={`${item.position}`}
                      fill
                      sizes="(min-width: 1024px) 40vw, 100vw"
                      src={item.image.src}
                    />
                  )}
                  <div
                    className={`z-1 absolute bottom-0 flex ${
                      mobileLayout === 'stack'
                        ? 'right-7 h-16 w-16'
                        : 'right-2 h-8 w-8'
                    } translate-y-1/2 items-center justify-center rounded-full border border-medDesert sm:right-3 sm:h-[5.5rem] sm:w-[5.5rem]`}
                  >
                    <button
                      className={`button button--arrow-right relative min-h-0 bg-medDesert p-2 shadow-none ${
                        mobileLayout === 'stack'
                          ? 'h-10 w-10'
                          : 'h-5 w-5 max-sm:before:h-3 max-sm:before:w-3 max-sm:after:h-3 max-sm:after:w-3'
                      } sm:h-14 sm:w-14`}
                      type="button"
                      aria-label={item.heading}
                    />
                  </div>
                </div>

                <div className="flex flex-col items-start text-black">
                  <h3
                    className={` text-2xl ${
                      mobileLayout === 'stack' ? 'mb-2' : 'mb-4'
                    } sm:mb-4 lg:text-3xl`}
                    dangerouslySetInnerHTML={{ __html: item.heading }}
                  />

                  {item.description && (
                    <p className="mt-0 text-sm sm:text-base">
                      {item.description}
                    </p>
                  )}
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}

TwoTiles.displayName = 'TwoTiles';
TwoTiles.Schema = Schema;
