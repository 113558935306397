import { useVariantPrices } from '../../hooks';

export function ProductItemPrice({ selectedVariant, promoText2, promoText }) {
  const { price, compareAtPrice } = useVariantPrices({
    variant: selectedVariant,
  });

  return (
    <div className="mt-3 flex flex-1 flex-wrap gap-x-1">
      <p
        className={`text-sm ${compareAtPrice ? 'font-bold text-lava' : ''} m-0`}
      >
        <span
          aria-label={
            compareAtPrice ? `Sale price ${price}` : `Regular price ${price}`
          }
        >
          {price}
        </span>
      </p>
      {compareAtPrice && (
        <p className="m-0 text-sm text-grey4">
          <span
            className="line-through"
            aria-label={`Regular price ${compareAtPrice}`}
          >
            {compareAtPrice}
          </span>
        </p>
      )}
      {promoText2 && !promoText ? (
        <div>
          <p className="m-0 pl-1 text-sm text-lava">{promoText2}</p>
        </div>
      ) : null}
    </div>
  );
}

ProductItemPrice.displayName = 'ProductItemPrice';
