import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
import remarkDirective from 'remark-directive';
import { visit } from 'unist-util-visit';
import { h } from 'hastscript/html.js';
import { Schema } from './Content.schema';
import { Link, Svg } from '../../snippets';

const components = {
  iframe: ({ node, ...props }) => {
    return props.src.includes('youtube.com') ? (
      <div className="responsive-video pb-youtube">
        <iframe {...props} />
      </div>
    ) : (
      <iframe {...props} />
    );
  },
};

// remark plugin to add a custom tag to the AST
function htmlDirectives() {
  function ondirective(node) {
    const data = node.data || (node.data = {});
    const hast = h(node.name, node.attributes);
    const attributes = node.attributes || {};
    if (node.name !== 'youtube' && node.type !== 'textDirective') {
      data.hName = hast.tagname;
      data.hProperties = hast.properties;
    } else {
      const { id } = attributes;
      data.hName = 'iframe';
      data.hProperties = {
        src: `https://www.youtube.com/embed/${id}`,
        width: '100%',
        height: 200,
        frameBorder: 0,
        allow:
          'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
        allowFullScreen: true,
      };
    }
  }

  function transform(tree) {
    visit(
      tree,
      ['textDirective', 'leafDirective', 'containerDirective'],
      ondirective
    );
  }

  return transform;
}

export function Content({ cms }) {
  const { buttons, heading, section, subtext } = cms;
  const styles = { backgroundColor: section?.bgColor, color: 'var(--black)' };
  let linkColor = null;
  let contentType = '';
  switch (section?.bgColor) {
    case 'var(--black)':
    case 'var(--dark-loden)':
    case 'var(--dark-orion)':
      styles.color = 'var(--white)';
      linkColor = 'inherit';
      contentType = 'content-dark';
      break;
    default:
      styles.color = 'var(--black)';
  }
  const buttonAlign = section?.textAlign == 'text-center' ? 'center' : 'start';

  return (
    <div
      id={`${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`}
      style={styles}
      className={contentType}
    >
      <div className={`container mx-auto ${section?.textAlign}`}>
        <div
          className={`rte mx-auto ${section?.maxWidth || 'max-w-[55rem]'} ${
            section?.textAlign === 'text-center' ? '[&_table]:mx-auto' : ''
          }`}
        >
          {heading &&
            (section?.aboveTheFold ? (
              <h1
                className="section-title h1"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            ) : (
              <h2
                className="section-title h1"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
            ))}

          {subtext && (
            <ReactMarkdown
              children={subtext}
              remarkPlugins={[
                remarkDirective,
                remarkGfm,
                remarkBreaks,
                htmlDirectives,
              ]}
              rehypePlugins={[rehypeRaw]}
              components={components}
            />
          )}

          {buttons?.length > 0 && (
            <ul
              className={`mt-10 flex list-none flex-col p-0 justify-${buttonAlign} gap-4 xs:flex-row`}
            >
              {buttons.slice(0, 2).map(({ link, style }, index) => {
                return (
                  link?.url &&
                  link?.text && (
                    <li className="m-0" key={index}>
                      <Link
                        aria-label={link?.text}
                        className={`button ${style} ${
                          style === 'button--light' ? 'text-black' : ''
                        } text-center max-md:px-6`}
                        href={link?.url}
                        newTab={link?.newTab}
                      >
                        {link?.text}
                      </Link>
                    </li>
                  )
                );
              })}
            </ul>
          )}
        </div>
      </div>
      <style>{`
        #${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}{ margin-bottom: ${
        section?.mobileSpacing
      }px; }
        ${
          linkColor
            ? `#${Schema().key}-${
                cms.id || cms.cmsId || cms.tinaId
              } a:not(:hover, .button){ color: ${linkColor} }`
            : ''
        }
        #${Schema().key}-${
        cms.id || cms.cmsId || cms.tinaId
      } .rte{ padding: 40px 0; }
        @media(min-width: 1024px) {
          #${Schema().key}-${
        cms.id || cms.cmsId || cms.tinaId
      } .rte{ padding: 80px 0; }
          #${Schema().key}-${
        cms.id || cms.cmsId || cms.tinaId
      }{ margin-bottom: ${section?.desktopSpacing}px; }
        }
      `}</style>
    </div>
  );
}

Content.displayName = 'Content';
Content.Schema = Schema;
