import { forwardRef } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import PropTypes from 'prop-types';

export const Markdown = forwardRef(
  ({ centerAllText, children, components = {} }, ref) => {
    return (
      <div
        ref={ref}
        className={`[&_p]:mb-4 [&_h2]:mt-8 [&_h2]:mb-5 [&_h1]:mb-6 [&_h1]:text-center [&_h3]:mb-4 [&_h3]:mt-6 [&_h4]:mb-4 [&_h4]:mt-4 [&_h5]:mb-4 [&_h5]:mt-2 [&_h6]:mb-4 [&_ul]:mb-4 [&_ul]:pl-8 [&_ol]:mb-4 [&_ol]:pl-8 [&_a]:underline [&_li]:mb-2 [&_ul>li]:list-disc [&_ol>li]:list-decimal [&_li>p]:mb-0 [&>:last-child]:mb-0 [&>:first-child]:mt-0 ${
          centerAllText
            ? '[&>p]:text-center [&>h2]:text-center [&>h3]:text-center [&>h4]:text-center [&>h5]:text-center [&>h6]:text-center'
            : ''
        }}`}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkBreaks]}
          components={components}
        >
          {children}
        </ReactMarkdown>
      </div>
    );
  }
);

Markdown.displayName = 'Markdown';
Markdown.propTypes = {
  centerAllText: PropTypes.bool,
  children: PropTypes.string,
  components: PropTypes.object,
};
