import { useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';

export function CartEmpty({ closeCart }) {
  const settings = useSettings();
  const { message, link } = { ...settings?.cart?.emptyCart };

  return (
    <div className="text-center">
      <p className="mb-4 text-sm text-grey5">{message}</p>

      {link?.text && (
        <Link
          aria-label={link?.text}
          className="button button--black inline-block no-underline"
          href={link?.url}
          newTab={link?.newTab}
          onClick={closeCart}
        >
          {link?.text}
        </Link>
      )}
    </div>
  );
}

CartEmpty.displayName = 'CartEmpty';
