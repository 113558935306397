import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y } from 'swiper';
import { useSettings, useCustomer, useCustomerLogout} from '@backpackjs/storefront';

import { Link } from '../Link';
import { MenuNestedSidebar } from './MenuNestedSidebar';
import { Svg } from '../Svg';
import { useGlobalContext } from '../../contexts';

// usar el index como estado dentro de usemenusidebar.jsx y matchear ese con el index del loop de aca

export function MenuSidebar({
  handleCloseSidebar,
  menuSidebarOpen,
  nestedSidebarContent,
  handleNestedSidebar,
  nestedSidebarIndex,
  mobileNavPosition,
  mobileNavHeight
}) {
  const settings = useSettings();

  const { promobar } = { ...settings?.header };
  const { ctaLinkMobile } = {...promobar };

  const {
    actions: { openSearch },
  } = useGlobalContext();

  const {
    additionalLinks,
    menuItems,
  } = { ...settings?.header?.menu };

  const customer = useCustomer();

  const { customerLogout } = useCustomerLogout();

  return (
    <div
      className={`fixed left-0 z-[60] h-[100vh] w-full lg:hidden
      ${
        menuSidebarOpen ? 'pointer-events-auto' : 'pointer-events-none'
      }
      ${mobileNavPosition}

      ${mobileNavHeight}`
    }
    >
      {/* Overlay */}
      <div
        role="button"
        aria-hidden="true"
        aria-label="Close menu sidebar"
        tabIndex="-1"
        className={`absolute top-0 left-0 hidden h-full w-full bg-[rgba(0,0,0,0.3)] transition duration-200 md:block ${
          menuSidebarOpen ? 'opacity-100' : 'opacity-0'
        }`}
        onClick={handleCloseSidebar}
      />

      <aside
        className={`absolute right-full top-0 flex h-full w-full flex-col overflow-hidden bg-background transition md:max-w-[var(--sidebar-width)] ${
          menuSidebarOpen ? 'translate-x-full' : '-translate-x-0'
        }`}
      >
        {menuSidebarOpen && (
          <>
            <div className="relative w-full flex-1 overflow-x-hidden">
              <div className={`scrollbar-hide h-full w-full overflow-y-auto flex flex-col`}>
                <nav className="flex">
                  <ul className="w-full flex-col list-none px-0 mb-0 mt-0">
                    {menuItems?.map((item, index) => {
                      const hasContent = item.submenus?.length || item.imageLinks?.length;

                      return (
                        <li
                          key={index}
                          className={`flex flex-col min-h-[3.5rem] w-full mt-0 first:border-t-transparent first:border-t-[2px]

                          ${nestedSidebarIndex == null || nestedSidebarIndex != index ? 'border-t-[2px] border-t-grey1' : 'border-t-[2px] border-t-lava first:!border-t-lava first:border-t-[2px]'}`
                          }
                        >

                          {hasContent ? (
                            <button
                              aria-label={item.menuItem.text}
                              className="flex h-14 w-full items-center justify-between gap-5 p-4"
                              onClick={() => handleNestedSidebar(nestedSidebarIndex == null || nestedSidebarIndex != index ? index : null)}
                              type="button"
                            >
                              <p className={`flex-1 text-left text-sh-upper uppercase font-bold mb-0 transition-all duration-150

                              ${nestedSidebarIndex == null || nestedSidebarIndex != index ? 'text-text' : 'text-lava'
                                }`
                              }
                              >
                                {item.menuItem.text}
                              </p>

                              <div className={`transition-all duration-150 ${nestedSidebarIndex == null || nestedSidebarIndex != index ? '' : 'rotate-180'}`}>
                                <svg width="19" height="8" viewBox="0 0 19 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.63506 7.61343C9.15365 7.97949 9.84652 7.97949 10.3651 7.61343L18.5768 1.81696C19.028 1.49847 19.1355 0.874512 18.817 0.423314C18.4986 -0.0278854 17.8746 -0.135465 17.4234 0.183029L9.50008 5.77596L1.57677 0.183029C1.12557 -0.135465 0.501608 -0.0278854 0.183115 0.423314C-0.135379 0.874512 -0.0277996 1.49847 0.423399 1.81696L8.63506 7.61343Z"
                                    fill={nestedSidebarIndex == null || nestedSidebarIndex != index ? '#191919' : '#C53C1D'} />
                                </svg>
                              </div>

                            </button>
                          ) : (

                            <div className='flex h-14 w-full items-center justify-between gap-5 p-4'>
                              <Link
                                aria-label={item.menuItem.text}
                                className={`text-left ${ item.menuItem?.text.includes('Custom') ? 'text-lava' : 'text-text' } text-sh-upper uppercase font-bold mb-0 transition-all duration-150 no-underline`}
                                href={item.menuItem.url}
                                onClick={handleCloseSidebar}
                              >
                                {item.menuItem.text}
                              </Link>

                              {item.menuItem?.text.includes('Custom') && (
                                <button className="button button--arrow-right"><span className="sr-only"></span></button>
                              )}
                            </div>
                          )}

                          {nestedSidebarIndex == index ?
                            (
                              <MenuNestedSidebar
                                handleCloseSidebar={handleCloseSidebar}
                                handleNestedSidebar={handleNestedSidebar}
                                nestedSidebarContent={nestedSidebarContent}
                                nestedSidebarIndex={nestedSidebarIndex}
                              />
                            ) :
                              null
                          }
                        </li>
                      );
                    })}
                  </ul>
                </nav>

                <div className='flex flex-col h-full'>
                  <div className='bg-orion px-4 py-6'>
                    <p className='text-white text-h6-m uppercase mb-6 font-[600]'>My Account</p>

                    {customer == null ?
                      <div className='grid grid-cols-2 gap-4'>
                        <Link
                          aria-label="Sign In"
                          className='button button--light-outline col-span-1 no-underline text-center'
                          draggable={false}
                          href="/account/login"
                          onClick={handleCloseSidebar}
                        >
                          <span className="">Sign In</span>
                        </Link>

                        <Link
                          aria-label="Sign Up"
                          className='button button--light col-span-1 no-underline text-center'
                          draggable={false}
                          href="/account/register"
                          onClick={handleCloseSidebar}
                        >
                          <span className="">Sign Up</span>
                        </Link>
                      </div>
                    :
                      <div className='grid grid-cols-2 gap-4'>
                        <Link
                          aria-label="View Account"
                          className='button button--light col-span-2 no-underline text-center'
                          draggable={false}
                          href="/account/orders"
                          onClick={handleCloseSidebar}
                        >
                          <span className="">View Account</span>
                        </Link>
                      </div>
                    }
                  </div>

                  <div className='bg-darkOrion px-4 py-6 flex flex-col h-full'>

                    <Link
                      aria-label="Order History"
                      className="text-left mb-6 py-0 focus-link text-sh-upper-sm text-white font-bold hover:text-medDesert no-underline"
                      draggable={false}
                      href={customer == null ? "/account/login" : "/account/orders"}
                      onClick={handleCloseSidebar}
                    >
                      <span className="">Order History</span>
                    </Link>

                    {ctaLinkMobile && ctaLinkMobile.text && ctaLinkMobile.url ? (
                      <Link
                        aria-label={ctaLinkMobile.text}
                        className="text-left mb-6 py-0 focus-link text-sh-upper-sm text-white font-bold hover:text-medDesert no-underline"
                        draggable={false}
                        href={ctaLinkMobile.url}
                        newTab={ctaLinkMobile.newTab}
                      >
                        <span className="">{ctaLinkMobile.text}</span>
                      </Link>
                    ) : null}

                    {additionalLinks?.length > 0 && (
                      <ul className="list-none px-0 my-0">
                        {additionalLinks.map(({ link }, index) => {
                          return (
                            <li key={`additional-link-${index}`} className='my-0 leading-[0px] last:mb-0 mb-6'>
                              <Link
                                aria-label={link?.text}
                                href={link?.url}
                                onClick={handleCloseSidebar}
                                className="text-left py-0 focus-link text-sh-upper-sm text-white font-bold hover:text-medDesert no-underline"
                              >
                                {link?.text}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}


                    {customer == null ?
                      null
                    :
                      <button
                        aria-label="Sign out"
                        className="mt-6 text-left py-[0.125rem] focus-link text-sm tracking-[0.05em] text-white font-bold hover:text-medDesert"
                        onClick={customerLogout}
                        type="button"
                      >
                        Log Out
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </aside>
    </div>
  );
}

MenuSidebar.displayName = 'MenuSidebar';
