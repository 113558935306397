import { EmailSignup } from './EmailSignup';
import { FooterLegal } from './FooterLegal';
import { FooterMenu } from './FooterMenu';
import { FooterSocial } from './FooterSocial';

export function Footer() {
  return (
    <div>
    <footer className="md-px-contained bg-black pt-8 pb-6 text-white md:py-12 md:pt-14 lg:pt-24">
      <div className="mx-auto grid max-w-[var(--content-max-width)] grid-cols-1 md:grid-cols-[1fr_300px] md:gap-x-5 md:gap-y-10 lg:grid-cols-[1fr_420px]">
        <div className="order-2 md:order-1">
          <FooterMenu />

          <div className="flex items-center justify-center border-b border-b-grey5 py-6 md:hidden">
            <FooterSocial />
          </div>
        </div>

        <div className="order-1 md:order-2">
          <EmailSignup />

          <div className="hidden md:block">
            <FooterSocial />
          </div>
        </div>

        <div className="order-3 md:col-span-2">
          <FooterLegal />
        </div>
      </div>
    </footer>
    </div>
  );
}

Footer.displayName = 'Footer';
