export function Schema() {
  return {
    category: 'Yotpo',
    label: 'Yotpo Rewards',
    key: 'yotpo-rewards',
    fields: [
      {
        label: 'Yotpo Instance Id',
        name: 'yotpoInstanceId',
        component: 'number',
        description: 'Yotpo Instance Id',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}