import { useEffect } from 'react';
import { useProductsFromHandles, useSettings } from '@backpackjs/storefront';

import { Link } from '../Link';
import { SearchItem } from './SearchItem';
import { SearchAutocomplete } from './SearchAutocomplete';
import { SearchContentItem } from './SearchContentItem';

export function SearchResults({
  closeSearch,
  collectionResults,
  productResults,
  contentResults,
  searchOpen,
  setRawTerm,
  term,
}) {
  const settings = useSettings();
  const { productsEnabled, collectionsEnabled, contentsEnabled } = {
    ...settings?.search?.results,
  };

  const { products: productImpressions } = useProductsFromHandles({
    handles: productResults?.slice(0, 7).map(({ handle }) => handle),
  });

  useEffect(() => {
    if (!productImpressions?.length) return;
    PubSub.publish('VIEW_SEARCH_RESULTS', productImpressions);
  }, [productImpressions]);

  return (
    <div className="scrollbar-hide relative flex flex-1 flex-col gap-0 overflow-y-auto">
      <SearchAutocomplete
        searchOpen={searchOpen}
        setRawTerm={setRawTerm}
        term={term}
      />

      {productsEnabled && productResults?.length > 0 && (
        <div className="p-4 pt-6">
          <h3 className="m-0 text-sh-lg font-bold capitalize">Top Products</h3>

          <ul className="-mx-4 my-0 list-none p-0">
            {productResults?.slice(0, 10).map((item, index) => {
              return item ? (
                <li
                  key={index}
                  className="m-0 border-b border-grey2 px-4 py-4 last:border-none"
                >
                  <SearchItem
                    closeSearch={closeSearch}
                    index={index}
                    item={item}
                  />
                </li>
              ) : null;
            })}
          </ul>
        </div>
      )}

      {contentsEnabled && contentResults?.length > 0 && (
        <div className="border-t border-grey2 p-4 pt-6">
          <h3 className="m-0 text-sh-lg font-bold capitalize">Pages</h3>

          <ul className="-mx-4 my-0 list-none p-0">
            {contentResults?.slice(0, 10).map((item, index) => {
              return item ? (
                <li
                  key={index}
                  className="m-0 border-b border-grey2 px-4 py-4 last:border-none"
                >
                  <SearchContentItem
                    closeSearch={closeSearch}
                    index={index}
                    item={item}
                  />
                </li>
              ) : null;
            })}
          </ul>
        </div>
      )}

      {collectionsEnabled && collectionResults?.length > 0 && (
        <div className="border-t border-grey2 p-4 pt-6">
          <h3 className="mb-4 text-sh-lg font-bold capitalize">Collections</h3>

          <ul className="m-0 flex list-none flex-col items-start gap-1 p-0">
            {collectionResults.map(({ item, refIndex }) => {
              return (
                <li key={refIndex} className="m-0">
                  <Link
                    aria-label={item.title}
                    href={`/collections/${item.handle}`}
                    className="text-black decoration-1 underline-offset-2 hover:text-darkLava hover:underline"
                    onClick={() => closeSearch()}
                  >
                    <p className="m-0 text-xs">{item.title}</p>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

SearchResults.displayName = 'SearchResults';
