import { Svg } from '../../snippets';
import { FormField } from '../../snippets';
import { Schema } from './StyleGuide.schema';

export function StyleGuide({ cms }) {
  return (
    <div>
      <div className="styleguide-header">
        <img src="../images/style-guide-banner.png" className="w-full lazyload" alt="Branded style guide" />
      </div>
      <div className="container py-14 px-5 m-auto">
        <div className="styleguide-title mb-8">Black & White</div>
        <div className="grid grid-cols-3 md:grid-cols-5 gap-6 gapy-9 mb-16 ">
          <div className="bg-black">
            <div className="h-[140px] flex-grow border-b border-grey2"></div>
            <div className="text-white p-5 text-sm font-medium">Black<br /><span className="text-2xs leading-[18px]">#191919</span></div>
          </div>
          <div className="bg-white border border-grey3">
            <div className="h-[140px] flex-grow border-b border-grey2"></div>
            <div className="text-black p-5 text-sm font-medium">White<br /><span className="text-2xs leading-[18px]">#FFFFFF</span></div>
          </div>
        </div>

        <div className="styleguide-title mb-8">BRAND Colors</div>
        <div className="grid grid-cols-3 md:grid-cols-5 gap-6 gapy-9 mb-16 ">
          <div className="bg-loden flex flex-col">
            <div className="h-[140px] flex-grow border-b border-white flex items-end relative">
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
              <div className="text-white p-5 text-sm font-medium">Loden<br /><span className="text-2xs leading-[18px]">#6D7356</span></div>
            </div>
            <div className="bg-darkLoden text-white p-5 text-2xs font-medium relative">Loden - Dark<br /><span>#484B39</span>
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
            </div>
          </div>
          <div className="bg-orion flex flex-col">
            <div className="h-[140px] flex-grow border-b border-white flex items-end relative">
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
              <div className="text-white p-5 text-sm font-medium">Orion<br /><span className="text-2xs leading-[18px]">#3E4F5C</span></div>
            </div>
            <div className="bg-darkOrion text-white p-5 text-2xs font-medium relative">Orion - Dark<br /><span>#202535</span>
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
            </div>
          </div>
          <div className="bg-darkDesert flex flex-col">
            <div className="h-[140px] flex-grow border-b border-white flex items-end relative">
              <Svg
                className="w-6 text-black absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
              <div className="text-black p-5 text-sm font-medium">Desert - Dark<br /><span className="text-2xs leading-[18px]">#C3B091</span></div>
            </div>
            <div className="block lg:flex">
              <div className="lg:w-1/2 bg-medDesert text-black p-3 pr-10 text-2xs font-medium relative">Desert - Med<br /><span>#EFE8DB</span>
                <Svg
                  className="w-6 text-black absolute top-4 right-4"
                  src={`/svgs/circle-check.svg#circle-check`}
                  viewBox="0 0 24 24"
                />
              </div>
              <div className="lg:w-1/2 bg-lightDesert text-black p-3 pr-10 text-2xs font-medium relative">Desert - Light<br /><span>#FAF7F2</span>
                <Svg
                  className="w-6 text-black absolute top-4 right-4"
                  src={`/svgs/circle-check.svg#circle-check`}
                  viewBox="0 0 24 24"
                />
              </div>
            </div>
          </div>
          <div className="bg-lava flex flex-col">
            <div className="h-[140px] flex-grow border-b border-white flex items-end relative">
              <div className="text-white p-5 text-sm font-medium">Lava<br /><span className="text-2xs leading-[18px]">#C53C1D</span></div>
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
            </div>
            <div className="bg-darkLava text-white p-5 text-2xs font-medium relative">Lava - Dark<br /><span>#B22F12</span>
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
            </div>
          </div>
        </div>

        <div className="styleguide-title mb-8">GREYSCALE Colors</div>
        <div className="grid grid-cols-3 md:grid-cols-5 gap-6 gapy-9 mb-16">
          <div className="bg-grey1 flex flex-col">
            <div className="h-[140px] flex-grow relative">
              <Svg
                  className="w-6 text-black absolute top-4 right-4"
                  src={`/svgs/circle-check.svg#circle-check`}
                  viewBox="0 0 24 24"
                />
            </div>
            <div className="text-black p-5 text-sm font-medium">Grey 1<br /><span className="text-2xs leading-[18px]">#F5F5F5</span></div>
          </div>
          <div className="bg-grey2 flex flex-col">
            <div className="h-[140px] flex-grow relative">
              <Svg
                  className="w-6 text-black absolute top-4 right-4"
                  src={`/svgs/circle-check.svg#circle-check`}
                  viewBox="0 0 24 24"
                />
            </div>
            <div className="text-black p-5 text-sm font-medium">Grey 2<br /><span className="text-2xs leading-[18px]">#E8E8E9</span></div>
          </div>
          <div className="bg-grey3 flex flex-col">
            <div className="h-[140px] flex-grow relative">
              <Svg
                  className="w-6 text-black absolute top-4 right-4"
                  src={`/svgs/circle-check.svg#circle-check`}
                  viewBox="0 0 24 24"
                />
            </div>
            <div className="text-black p-5 text-sm font-medium">Grey 3<br /><span className="text-2xs leading-[18px]">#D0D0D1</span></div>
          </div>
          <div className="bg-grey4 flex flex-col">
            <div className="h-[140px] flex-grow relative">
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
            </div>
            <div className="text-white p-5 text-sm font-medium">Grey 4<br /><span className="text-2xs leading-[18px]">#74757B</span></div>
          </div>
          <div className="bg-grey5 flex flex-col">
            <div className="h-[140px] flex-grow relative">
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
            </div>
            <div className="text-white p-5 text-sm font-medium">Grey 5<br /><span className="text-2xs leading-[18px]">#353B3E</span></div>
          </div>
        </div>

        <div className="styleguide-title mb-8">Utility Colors</div>
        <div className="grid grid-cols-3 md:grid-cols-5 gap-6 gapy-9 mb-16 ">
          <div className="bg-error">
            <div className="h-[140px] flex-grow relative">
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
            </div>
            <div className="text-white p-5 text-sm font-medium">Error<br /><span className="text-2xs leading-[18px]">#CC1E1E</span></div>
          </div>
          <div className="bg-success">
            <div className="h-[140px] flex-grow relative">
              <Svg
                className="w-6 text-white absolute top-4 right-4"
                src={`/svgs/circle-check.svg#circle-check`}
                viewBox="0 0 24 24"
              />
            </div>
            <div className="text-white p-5 text-sm font-medium">Success<br /><span className="text-2xs leading-[18px]">#4B7A2C</span></div>
          </div>
        </div>

        <div className="paragraph block  mb-16">
          <div className="desktop-headings mb-16">
            <div className="mb-10 text-lg uppercase font-bold">Desktop Type Scales</div>
            <div className="block md:flex justify-between mt-8">
              <h1 className="uppercase">H1 Title Example lorem ipsum dorem</h1>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 48px<br />
                Line-Height - 120%<br />
                Letter Spacing - 2%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h2 className="uppercase">H2 Title Example lorem ipsum dorem</h2>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 40px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h3 className="uppercase">H3 Title Example lorem ipsum dorem</h3>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 34px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h4 className="uppercase">H4 Title Example lorem ipsum dorem</h4>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 28px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h5 className="uppercase">H5 Title Example lorem ipsum dorem</h5>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 24px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h6 className="uppercase">H6 Title Example lorem ipsum dorem</h6>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 20px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
          </div>
          <div className="mobile-headings mb-16">
            <div className="mb-10 text-lg uppercase font-bold">Mobile Type Scale</div>
            <div className="block md:flex justify-between mt-8">
              <h1 className="text-h1-m uppercase">H1 Title Example lorem ipsum dorem</h1>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 32px<br />
                Line-Height - 120%<br />
                Letter Spacing - 2%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h2 className="text-h2-m uppercase">H2 Title Example lorem ipsum dorem</h2>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 26px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h3 className="text-h3-m uppercase">H3 Title Example lorem ipsum dorem</h3>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 24px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h4 className="text-h4-m uppercase">H4 Title Example lorem ipsum dorem</h4>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 22px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h5 className="text-h5-m uppercase">H5 Title Example lorem ipsum dorem</h5>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 20px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
            <div className="block md:flex justify-between mt-8">
              <h6 className="text-h6-m uppercase">H6 Title Example lorem ipsum dorem</h6>
              <div className="pl-5 flex-shrink-0 text-grey4">
                Font-Size - 18px<br />
                Line-Height - 120%<br />
                Letter Spacing - 3%
              </div>
            </div>
          </div>
          <div className="font-bold uppercase leading-x-tight mb-3">Subheading Type Scale - All caps</div>
          <p>Subheading styles are linked between desktop and mobile screens. Meaning a large subheading style should be used in both desktop and mobile designs as these styles correspond/link in the dev styleguide. If you wish for mobile to have a separate corresponding style create additional coordinating subheading styles similar to how the heading (H1-H6) styles are set up.</p>
          <div className="block md:flex justify-between mt-8">
            <h4 className="text-sh-upper-lg uppercase">Subheading Large</h4>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 16px<br />
              Line-Height - 120%<br />
              Letter Spacing - 10%
            </div>
          </div>
          <div className="block md:flex justify-between mt-8">
            <h4 className="text-sh-upper uppercase">Subheading Regular</h4>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 14px<br />
              Line-Height - 120%<br />
              Letter Spacing - 10%
            </div>
          </div>
          <div className="block md:flex justify-between mt-8">
            <h4 className="text-sh-upper-sm uppercase">Subheading Small</h4>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 13px<br />
              Line-Height - 120%<br />
              Letter Spacing - 10%
            </div>
          </div>

          <div className="font-bold uppercase leading-x-tight mt-16 mb-3">Subheading Type Scale - SentEnce Case</div>
          <p>Subheading styles are linked between desktop and mobile screens. Meaning a large subheading style should be used in both desktop and mobile designs as these styles correspond/link in the dev styleguide. If you wish for mobile to have a separate corresponding style create additional coordinating subheading styles similar to how the heading (H1-H6) styles are set up.</p>
          <div className="block md:flex justify-between mt-8">
            <h4 className="text-sh-lg">Subheading Large</h4>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 16px<br />
              Line-Height - 120%<br />
              Letter Spacing - 10%
            </div>
          </div>
          <div className="block md:flex justify-between mt-8">
            <h4 className="text-sh">Subheading Regular</h4>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 14px<br />
              Line-Height - 120%<br />
              Letter Spacing - 10%
            </div>
          </div>
          <div className="block md:flex justify-between mt-8">
            <h4 className="text-sh-sm">Subheading Small</h4>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 13px<br />
              Line-Height - 120%<br />
              Letter Spacing - 10%
            </div>
          </div>

          <div className="font-bold uppercase leading-x-tight mt-16 mb-3">paragraph Type Scale</div>
          <p>Subheading styles are linked between desktop and mobile screens. Meaning a large subheading style should be used in both desktop and mobile designs as these styles correspond/link in the dev styleguide. If you wish for mobile to have a separate corresponding style create additional coordinating subheading styles similar to how the heading (H1-H6) styles are set up.</p>
          <div className="block md:flex justify-between mt-8">
            <div  className="text-lg">
              <p>Paragraph Large<br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate odio ut enim blandit volutpat maecenas. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Integer quis auctor elit sed vulputate.</p>
            </div>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 18px<br />
              Line-Height - 150%
            </div>
          </div>
          <div className="block md:flex justify-between mt-8">
            <div className="text-base">
              <p>Paragraph Regular<br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate odio ut enim blandit volutpat maecenas. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Integer quis auctor elit sed vulputate.</p>
            </div>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 16px<br />
              Line-Height - 150%
            </div>
          </div>
          <div className="block md:flex justify-between mt-8">
            <div className="text-sm">
              <p>Paragraph Small<br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate odio ut enim blandit volutpat maecenas. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Integer quis auctor elit sed vulputate.</p>
            </div>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 14px<br />
              Line-Height - 150%
            </div>
          </div>
          <div className="block md:flex justify-between mt-8">
            <div className="text-xs">
              <p>Paragraph XSmall<br />
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vulputate odio ut enim blandit volutpat maecenas. Dapibus ultrices in iaculis nunc sed augue lacus viverra vitae. Integer quis auctor elit sed vulputate.</p>
            </div>
            <div className="pl-5 flex-shrink-0 text-grey4">
              Font-Size - 13px<br />
              Line-Height - 150%
            </div>
          </div>

        </div>

        <div className="ux-elements block mb-16">
          <div className="styleguide-title text-xs uppercase font-bold mb-7">UX Elements</div>
          <div className="grid grid-cols-3 gap-x-20">
            <div>
              <div className="text-xs uppercase font-bold mb-2 pl-5">Orion Fill</div>
              <div className="p-5 mb-6 flex flex-col justify-end items-start bg-[url('/images/style-guide/style-guide-1.jpg')] bg-no-repeat h-[235px] bg-cover">
                <button className="button button--orion mb-3">Button</button>
                <button className="button button--orion bg-darkOrion">Button</button>
              </div>
              <div className="text-xs uppercase font-bold mb-2 pl-5">Light Fill</div>
              <div className="p-5 mb-6 flex flex-col justify-between items-start bg-[url('/images/style-guide/style-guide-3.jpg')] bg-no-repeat h-[281px] bg-cover">
                <div>
                  <button className="button button--light-icon mb-3">Button <span className="inner-arrow"></span></button>
                  <button className="button button--light-icon">Button<span className="inner-arrow"></span></button>
                </div>
                <div>
                  <div><button className="button button--light mb-3">Button</button></div>
                  <button className="button button--light border-medDesert bg-medDesert">Button</button>
                </div>
              </div>

              <div className="text-xs uppercase font-bold mb-2 pl-5">Dark Outline</div>
              <div className="p-5 mb-6 flex flex-col justify-end items-start bg-[url('/images/style-guide/style-guide-5.jpg')] bg-no-repeat h-[235px] bg-cover">
                <button className="button button--dark-outline mb-3">Button</button>
                <button className="button button--dark-outline text-white bg-black">Button</button>
              </div>
              <div className="text-xs uppercase font-bold mb-2 pl-5">Light Outline</div>
              <div className="p-5 mb-6 flex flex-col justify-end items-start bg-[url('/images/style-guide/style-guide-6.jpg')] bg-no-repeat h-[235px] bg-cover">
                <button className="button button--light-outline mb-3">Button</button>
                <button className="button button--light-outline text-black bg-white">Button</button>
              </div>
            </div>
            <div>
              <div className="text-xs uppercase font-bold mb-2 pl-5">Black Fill</div>
              <div className="p-5 mb-6 flex flex-col justify-end bg-[url('/images/style-guide/style-guide-2.jpg')] bg-no-repeat h-[235px] bg-cover">
                <button className="button button--black mb-3 w-full">Add to Cart</button>
                <button className="button button--black w-full text-black bg-white">Add to Cart</button>
              </div>
              <div className="text-xs uppercase font-bold mb-5">ICON CTA</div>
              <div className="mb-6">
                <button className="button button--arrow-left"><span className="sr-only">Button</span></button>
                <button className="button button--arrow-right ml-2"><span className="sr-only">Button</span></button>
              </div>
              <div className="text-xs uppercase font-bold mb-5">Global disabled</div>
              <div className="mb-6">
                <button className="button button--black" disabled>Button Text</button>
              </div>
              <div className="text-xs uppercase font-bold mb-7">links</div>
              <div className="mb-6">
                <p>This is an <a href="/">inline link</a> example.</p>
                <p>This is an <a href="/" className="text-darkLava">inline link hover</a> example.</p>
                <p><a href="/" className="focus-link text-sm">Content Module Link</a></p>
                <p><a href="/" className="focus-link text-sm text-orion">Content Module Link Hover</a></p>
              </div>
              <div className="mb-6 px-6 py-7 bg-black text-white">
                <p>This is an <a href="/" className="text-white hover:text-medDesert">inline link</a> example.</p>
                <p>This is an <a href="/" className="text-medDesert">inline link hover</a> example.</p>
                <p><a href="/" className="focus-link text-sm text-white hover:text-medDesert">Content Module Link</a></p>
                <p><a href="/" className="focus-link text-sm text-medDesert hover:text-medDesert">Content Module Link Hover</a></p>
              </div>
            </div>
            <div>
              <div className="text-xs uppercase font-bold mb-7">checkboxes</div>
              <div className="mb-6">
                <FormField field={{type: 'checkbox',name: 'flat bills', label: 'Flat Bills'}} />
              </div>
              <div className="mb-6">
                <FormField field={{type: 'checkbox',name: 'curved bills', label: 'Curved Bills', checked: true}} />
              </div>
              <div className="mb-6">
                <FormField field={{type: 'checkbox',name: 'disabled checkbox', label: 'This is a disabled selected checkbox.', checked: true, disabled: true}} />
              </div>
              <div className="text-xs uppercase font-bold mt-16 mb-7">radio buttons</div>
              <div className="mb-6">
                <FormField field={{type: 'radio',name: 'sign up 1', options: ['Yes, sign me up.']}} />
              </div>
              <div className="mb-6">
                <FormField field={{type: 'radio',name: 'sign up 2', options: ['Yes, sign me up.'], checked: 0}} />
              </div>
              <div className="mb-6">
                <FormField field={{type: 'radio',name: 'sign up 3', options: ['This is a disabled selected radio button.'], checked: 0, disabled: true}} />
              </div>
              <div className="text-xs uppercase font-bold mt-16 mb-7">Pagination</div>
              <div className="block lg:flex justify-between items-center gap-8">
                <ul className="pagination mb-5 lg:mb-0">
                  <li className="current"><span>1</span></li>
                  <li><a href="#">2</a></li>
                  <li><a href="#">3</a></li>
                  <li className="separate"><span>...</span></li>
                  <li><a href="#">10</a></li>
                </ul>
                <ul className="pagination">
                  <li><a href="#">1</a></li>
                  <li className="separate"><span>...</span></li>
                  <li className="current"><span>8</span></li>
                  <li><a href="#">9</a></li>
                  <li><a href="#">10</a></li>
                </ul>
              </div>

              <div className="text-xs uppercase font-bold mt-16 mb-7">Iconography</div>
              <div className="flex gap-x-3 items-end justify-between">
                <Svg
                  className="w-[18px] hover:text-lava"
                  src={`/svgs/icon-close.svg#icon-close`}
                  viewBox="0 0 18 18"
                />
                <Svg
                  className="w-[18px] hover:text-lava"
                  src={`/svgs/icon-menu.svg#icon-menu`}
                  viewBox="0 0 18 18"
                />
                <Svg
                  className="w-[18px] hover:text-lava"
                  src={`/svgs/icon-search.svg#icon-search`}
                  viewBox="0 0 22 22"
                />
                <Svg
                  className="w-[18px] hover:text-lava"
                  src={`/svgs/icon-user.svg#icon-user`}
                  viewBox="0 0 22 22"
                />
                <Svg
                  className="w-[16px] hover:text-lava"
                  src={`/svgs/icon-cart.svg#icon-cart`}
                  viewBox="0 0 16 22"
                />
                <div className="relative z-0">
                  <Svg
                    className="w-[16px] hover:text-lava"
                    src={`/svgs/icon-cart.svg#icon-cart`}
                    viewBox="0 0 16 22"
                  />
                  <span className="absolute top-0 right-[-8px] w-[15px] h-[15px] text-[8px] flex items-center justify-center text-white bg-lava rounded-full">10</span>
                </div>
                <Svg
                  className="w-[18px] hover:text-lava"
                  src={`/svgs/icon-trash.svg#icon-trash`}
                  viewBox="0 0 18 19"
                />
                <Svg
                  className="w-[18px] text-lava hover:text-lava"
                  src={`/svgs/icon-trash.svg#icon-trash`}
                  viewBox="0 0 18 19"
                />
                <Svg
                  className="w-[18px] hover:text-lava"
                  src={`/svgs/arrow-right.svg#arrow-right`}
                  viewBox="0 0 20 12"
                />
              </div>

            </div>
          </div>
        </div>

        <form action="">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 gapy-9 mb-16">
          <div>
            <div className="styleguide-title text-xs uppercase font-bold mb-7">Form Fields</div>
            <div className="mb-8 flex items-center">
              <div className="flex-grow">
                <FormField field={{type: 'text', name: 'first name', label: 'First Name', placeholder: 'First Name'}} />
              </div>
              <div className="pl-5 text-sh-sm font-bold w-20">default</div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-grow">
                <FormField field={{type: 'text', name: 'first name1', label: 'First Name', placeholder: 'First Name', className: 'border-darkOrion'}} />
              </div>
              <div className="pl-5 text-sh-sm font-bold w-20">hover</div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-grow">
                <FormField field={{type: 'text', name: 'first name2', label: 'First Name', placeholder: 'First Name', className: 'border-darkOrion', value: 'Savannah'}} />
              </div>
              <div className="pl-5 text-sh-sm font-bold w-20">Active</div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-grow">
                <FormField field={{type: 'email', name: 'email', label: 'Email', placeholder: 'Email', className: 'input-error', value: 'savannah@gmail.com', errorText: 'Please enter a valid email address'}} />
              </div>
              <div className="pl-5 text-sh-sm font-bold w-20">Error</div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-grow">
                <FormField field={{type: 'text', name: 'first name3', label: 'First Name', placeholder: 'First Name', className: 'is-focus'}} />
              </div>
              <div className="pl-5 text-sh-sm font-bold w-20">Keyboard Focus</div>
            </div>
          </div>
          <div>
            <div className="styleguide-title text-xs uppercase font-bold mb-7">Dropdowns</div>
            <div className="mb-8 flex items-center">
              <div className="flex-grow">
                <FormField field={{type: 'select',name: 'First Name 4', placeholder: 'Sample State', hideLabel: true, options: ['Sample State']}} />
              </div>
              <div className="pl-5 text-sh-sm font-bold w-20">default</div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-grow">
                <FormField field={{type: 'select',name: 'First Name 5', placeholder: 'Sample State', className: '!border-darkOrion', hideLabel: true, options: ['Sample State']}} />
              </div>
              <div className="pl-5 text-sh-sm font-bold w-20">hover</div>
            </div>
            <div className="mb-8 flex items-center">
              <div className="flex-grow">
                <FormField field={{type: 'select',name: 'State', label: 'State', placeholder: 'Sample State', className: '!border-darkOrion', options: ['', 'Florida']}} />
              </div>
              <div className="pl-5 text-sh-sm font-bold w-20">Input</div>
            </div>
          </div>
          
        </div>
        </form>
        
      </div>
    </div>
  );
}

StyleGuide.displayName = 'StyleGuide';
StyleGuide.Schema = Schema;