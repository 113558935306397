import { useMemo } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { Image } from '../../snippets';

export function ProductFeatureIcons({ product }) {
  const productTags = product?.tags || [];
  const settings = useSettings();
  const features = settings?.product?.featureIcons?.features || [];

  const tagsArr = useMemo(() => {
    return productTags.reduce((acc, tag) => {
      for (let i = 0; i < features.length; i++) {
        const start = features[i]?.tag;
        if (features[i]?.comparisonType === 'equal' && tag === start) {
          return {
            ...acc,
            [start]: features[i]?.title,
          };
        }
        if (features[i]?.comparisonType !== 'equal' && tag.startsWith(start)) {
          const value = tag.split(start)[1]?.trim();
          return {
            ...acc,
            [start]: value,
          };
        }
      }

      return acc;
    }, []);
  }, [productTags, features]);

  if (!product) return null;

  return (
    <>
      <h2 className="mb-3 mt-6 text-sh-upper-sm uppercase md:hidden">
        Features
      </h2>
      <div className="grid grid-cols-1 gap-2 text-sm md:mt-6 md:grid-cols-2 md:gap-4">
        {features.map((feature, index) => {
          const alt = `${
            feature?.comparisonType !== 'equal' ? `${feature?.tag}` : ''
          } ${tagsArr?.[feature?.tag]?.replace(/(<([^>]+)>)/gi, '')}`;

          return feature?.icon?.src &&
            feature?.title &&
            tagsArr?.[feature?.tag] ? (
            <div
              key={`pdp-feature-${index}`}
              className="flex items-center gap-2"
            >
              <div className="relative z-0 h-10 w-10 flex-shrink-0">
                <Image
                  alt={alt}
                  className={`${
                    feature?.iconStyle === 'circle' ? 'rounded-full' : ''
                  } object-contain`}
                  fill={false}
                  width="40px"
                  height="40px"
                  sizes="20vw"
                  src={feature?.icon?.src}
                />
              </div>
              {feature?.comparisonType === 'equal' ? (
                <div
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: tagsArr?.[feature?.tag] }}
                />
              ) : (
                <div className="m-0">
                  <strong>{feature?.tag}</strong> {tagsArr?.[feature?.tag]}
                </div>
              )}
            </div>
          ) : null;
        })}
      </div>
    </>
  );
}

ProductFeatureIcons.displayName = 'Product Feature Icons';
