import { FLEX_POSITIONS, OBJECT_POSITIONS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Image',
    label: 'Shop By Category',
    key: 'shop-by-category',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/image-tiles-preview.jpg?v=1675730325',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
      },
      {
        label: 'CTA Link',
        name: 'cta_link',
        component: 'link'
      },
      {
        label: 'Tiles',
        name: 'tiles',
        description: 'Min of 3 tiles, max of 6 tiles',
        component: 'group-list',
        itemProps: {
          label: '{{item.heading}}',
        },
        validate: {
          maxItems: 6,
        },
        fields: [
          {
            label: 'Image Alt',
            name: 'alt',
            component: 'text',
            description: 'Brief description of image',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
            description: 'Recommended image size is 992px x 1240px'
          },
          {
            label: 'Image (Mobile)',
            name: 'image_m',
            component: 'image',
            description: 'Recommended image size is 512px x 640px'
          },
          {
            label: 'Image Position',
            name: 'position',
            component: 'select',
            options: OBJECT_POSITIONS.mobile,
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
        ],
        defaultItem: {
          alt: 'Man in white and light tan outfit',
          image: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
          },
          image_m: {
            src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
          },
          position: 'object-center',
          link: {
            text: 'Shop Now',
            url: '',
          },
        },
        defaultValue: [
          {
            alt: 'Man in white and light tan outfit',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
            },
            image_m: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-in-white-and-light-tan-outfit.jpg?v=1672348139',
            },
            position: 'object-center',
            link: {
              text: 'Shop Now',
              url: '',
            },
          },
          {
            alt: 'Man in brown coat sitting down',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/austin-wade-d2s8NQ6WD24-unsplash.jpg?v=1672348122',
            },
            image_m: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/austin-wade-d2s8NQ6WD24-unsplash.jpg?v=1672348122',
            },
            position: 'object-center',
            link: {
              text: 'Shop Now',
              url: '',
            },
          },
          {
            alt: 'Man in gray sweater and tan coat',
            image: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-poses-in-light-colored-overcoat.jpg?v=1672348143',
            },
            image_m: {
              src: 'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/man-poses-in-light-colored-overcoat.jpg?v=1672348143',
            },
            position: 'object-center',
            link: {
              text: 'Shop Now',
              url: '',
            },
          },
        ],
      },
      {
        label: 'Content Settings',
        name: 'content',
        component: 'group',
        description:
          'Dark overlay, content position, clickable image, hide button',
        fields: [
          {
            label: 'Dark Overlay',
            name: 'darkOverlay',
            component: 'toggle',
            description: 'Adds 20% opacity black overlay over media',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Content Position',
            name: 'contentPosition',
            component: 'select',
            options: FLEX_POSITIONS.mobile,
          },
          {
            label: 'Clickable Image',
            name: 'clickableImage',
            component: 'toggle',
            description: 'Makes entire image a clickable link',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            label: 'Hide Button',
            name: 'hideButton',
            component: 'toggle',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: {
          darkOverlay: false,
          contentPosition: 'justify-center items-end',
          clickableImage: true,
          hideButton: false,
        },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Image aspect ratio, full width',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
            defaultValue: 50
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
            defaultValue: 100
          }
        ],
        defaultValue: {
          fullWidth: false,
          mobilePadding: 50,
          desktopPadding: 100
        },
      },
    ],
  };
}
