import { useCallback } from 'react';
import { useCartAddItem } from '@backpackjs/storefront';

export function useAddCustomizedToCart() {
  const { cartAddItem } = useCartAddItem();

  const handleAddToCart = useCallback(
    async ({ details, product, callback = () => {} }) => {
      if (!details?.id || !product?.handle) return;
      const endpoint = '/api/kickflip';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'getVariants',
          design: details,
          product,
        }),
      };

      const response = await fetch(endpoint, options);
      const data = await response.json();
      if (!data?.variant?.id) {
        return;
      }
      const attributes = [];
      attributes.push(
        {
          key: '_mczr_image',
          value: data?.props?.image,
        },
        {
          key: '_mczr_designId',
          value: data?.props?.designId,
        },
        {
          key: '_mczr_mczrStoreId',
          value: data?.props?.mczrStoreId,
        },
        {
          key: '_mczr_brand',
          value: data?.props?.brand,
        },
        {
          key: '_mczr_variantPrice',
          value: data?.props?.mczrStoreId,
        },
        {
          key: '_mczr_productTitle',
          value: product?.title,
        },
        {
          key: '_mczr_productHandle',
          value: product?.handle,
        }
      );
      (data?.props?.summary_v2 || []).forEach((summary) => {
        if (typeof summary?.value === 'object') {
          const summaryValue = summary?.value?.join(', ');
          attributes.push({ ...summary, value: summaryValue });
        } else {
          attributes.push(summary);
        }
      });
      await cartAddItem({
        merchandiseId: `gid://shopify/ProductVariant/${data.variant.id}`,
        quantity: 1,
        attributes,
      });
      if (typeof callback === 'function') {
        callback();
      }
    },
    []
  );

  return {
    handleAddToCart,
  };
}
