import { HeroSlide } from './HeroSlide';
import { HeroSlider } from './HeroSlider';
import { Schema } from './Hero.schema';

export function Hero({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { section, slider, slides } = cms;
  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-width)]';
  const isFullBleed = section?.fullBleed;
  const heightContainerClasses = `${section?.heightMobile} ${section?.heightTablet} ${section?.heightDesktop}`;
  const isMobileStacked = section?.mobileStacked;

  return (
    <div id={moduleID} className={`${isFullBleed ? '' : 'px-contained'}`}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div
        className={`relative mx-auto flex flex-col ${
          isMobileStacked ? 'hero-mobile-stacked' : ''
        } ${heightContainerClasses} ${maxWidthContainerClass}`}
      >
        {slides?.length > 1 && (
          <HeroSlider
            aboveTheFold={section?.aboveTheFold}
            slider={slider}
            slides={slides}
          />
        )}

        {slides?.length === 1 && (
          <HeroSlide
            aboveTheFold={section?.aboveTheFold}
            isFirstSlide
            isVisible
            slide={slides[0]}
          />
        )}
      </div>
    </div>
  );
}

Hero.displayName = 'Hero';
Hero.Schema = Schema;
