import { COLORS } from '../../settings/common';

export function Schema() {
  return {
    category: 'Slider',
    label: 'Testimonial Slider',
    key: 'testimonial-slider',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/testimonials-screenshot.png?v=1681910324',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
        defaultValue: 'Testimonial Slider Heading',
      },
      {
        label: 'Testimonial Slides',
        name: 'testimonialSlides',
        component: 'group-list',
        itemProps: {
          label: '{{item.title}}',
        },
        fields: [
          {
            label: 'Body',
            name: 'body',
            component: 'textarea',
          },
          {
            label: 'Author',
            name: 'author',
            component: 'text',
          },
          {
            label: 'Rating',
            name: 'rating',
            component: 'select',
            options: [
              { label: '1', value: '1' },
              { label: '1.5', value: '1.5' },
              { label: '2', value: '2' },
              { label: '2.5', value: '2.5' },
              { label: '3', value: '3' },
              { label: '3.5', value: '3.5' },
              { label: '4', value: '4' },
              { label: '4.5', value: '4.5' },
              { label: '5', value: '5' },
            ],
            defaultValue: '5',
          },
        ],
        defaultItem: {
          body: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
          author: '– John Doe',
          rating: '5',
        },
        defaultValue: [
          {
            body: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
            author: '– John Doe',
            rating: '5',
          },
          {
            body: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
            author: '– John Doe',
            rating: '5',
          },
          {
            body: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur et.',
            author: '– John Doe',
            rating: '5',
          },
        ],
      },
      {
        label: 'Link',
        name: 'link',
        component: 'link',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description:
          'Full width, background color, text color, bullet color, star color',
        fields: [
          {
            label: 'Full Width',
            name: 'fullWidth',
            component: 'toggle',
            description: 'Removes max width of this section',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
            defaultValue: false,
          },
          {
            label: 'Preset Theme',
            name: 'presetTheme',
            component: 'select',
            options: [
              { label: 'Light Theme', value: 'light' },
              { label: 'Dark Theme', value: 'dark' },
            ]
          },
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
            defaultValue: 50
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
            defaultValue: 100
          }
        ],
        defaultValue: {
          fullWidth: false,
          presetTheme: 'light',
          mobilePadding: 50,
          desktopPadding: 100
        },
      },
    ],
  };
}
