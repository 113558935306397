export function Schema({ blog }) {
  if (!blog) return null;

  return {
    category: 'Blog',
    label: 'Blog Grid',
    key: 'blog-grid',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/0671/5074/1778/files/blog-preview.jpg?v=1675732824',
    fields: [
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Spacing bottom (mobile and desktop)',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 0,
          desktopPadding: 0,
        },
      },
    ],
  };
}
