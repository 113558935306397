import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Svg } from '../Svg';

export function SelectSort({
  onSelect = () => {},
  options = [], // [{ label: 'label', value: 'value' }]
  placeholder,
  placeholderClass,
  selectedOption, // { label: 'label', value: 'value' }
  name,
  label,
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const outsideClickHandler = (e) => {
      if (!ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('click', outsideClickHandler);
    } else {
      document.removeEventListener('click', outsideClickHandler);
    }
    return () => {
      document.removeEventListener('click', outsideClickHandler);
    };
  }, [isOpen]);

  return (
    <div ref={ref} className="relative w-full selectbox">
      <button
        aria-label={`${placeholder || 'Open account menu'}`}
        className="flex h-10 w-full items-center justify-center gap-2 rounded-md- border border-black px-2"
        form-select={name}
        data-value={selectedOption?.value}
        onClick={() => {
          if (options.length) setIsOpen(!isOpen);
        }}
        type="button"
      >
        <Svg
          className={`text-current w-5 ${isOpen ? 'rotate-180' : ''}`}
          src="/svgs/chevron-down-wide.svg#chevron-down-wide"
          title={`Chevron ${isOpen ? 'Up' : 'Down'}`}
          viewBox="0 0 20 8"
        />
        <p
          className={`truncate m-0 uppercase text-sh-upper leading-normal font-bold ${
            selectedOption ? '' : placeholderClass || 'text-grey4'
          }`}
        >
          {selectedOption?.label || placeholder}
        </p>

      </button>
      <span className="form-field-label">{label || placeholder}</span>

      <div
        className={`absolute top-[calc(100%+0.5rem)] left-0 z-10 w-full rounded-md- border border-black bg-background text-base transition duration-100 ${
          isOpen
            ? 'pointer-events-auto translate-y-0 opacity-100'
            : 'pointer-events-none -translate-y-1 opacity-0'
        }`}
      >
        <ul className="flex max-h-[18rem] flex-col gap-0 overflow-y-auto px-0 py-3 m-0">
          {options.map((option, index) => {
            const isActive = option.value === selectedOption?.value;
            return (
              <li key={index} className="flex m-0 leading-none">
                <button
                  aria-hidden={isOpen ? 'true' : 'false'}
                  className={`w-full py-2 px-5 transition text-black text-left hover:text-lava decoration-1 underline-offset-2 hover:underline ${
                    isActive ? 'bg-transparent text-lava underline' : 'bg-transparent'
                  }`}
                  onClick={() => {
                    onSelect(option);
                    setIsOpen(false);
                  }}
                  tabIndex={isOpen ? '0' : '-1'}
                  type="button"
                >
                  {option.label}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

SelectSort.displayname = 'SelectSort';
SelectSort.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  placeholderClass: PropTypes.string,
  selectedOption: PropTypes.object,
};
