import { Link } from '../../snippets';
import { Schema } from './InPageNavigation.schema';

export function InPageNavigation({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { aboveTheFold, heading, subtext, buttonStyle, items, section } = cms;
  const styles = { backgroundColor: section?.bgColor, color: 'var(--black)' };

  switch (section?.bgColor) {
    case 'var(--orion)':
    case 'var(--dark-loden)':
    case 'var(--dark-orion)':
      styles.color = 'var(--white)';
      break;
    default:
      styles.color = 'var(--black)';
  }

  const handleClick = (evt) => {
    if (
      typeof window === 'undefined' ||
      evt.currentTarget.hash === '#' ||
      evt.currentTarget.hash === ''
    ) {
      return false;
    }
    evt.preventDefault();
    const element = document.querySelector(evt.currentTarget.hash);
    const headerHeight = document.querySelector('header')
      ? document.querySelector('header').offsetHeight
      : 0;
    window.scrollTo({
      top: element.offsetTop - headerHeight,
    });
    return true;
  };

  return (
    <div id={moduleID} className="px-contained pb-7 pt-5" style={styles}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div className="mx-auto text-center">
        {heading &&
          (aboveTheFold ? (
            <h1
              className="h6 mb-6 uppercase"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          ) : (
            <h2
              className="h6 mb-6 uppercase"
              dangerouslySetInnerHTML={{ __html: heading }}
            />
          ))}

        {subtext && (
          <div className="mx-auto mb-6 max-w-[30rem] [&_a]:underline [&_h1]:text-base [&_h2]:text-base [&_h3]:text-base [&_h4]:text-base [&_h5]:text-base [&_h6]:text-base [&_p]:text-base">
            <div dangerouslySetInnerHTML={{ __html: subtext }} />
          </div>
        )}

        {items?.length > 0 && (
          <ul className="my-0 flex list-none flex-wrap justify-center gap-x-2 gap-y-3 pl-0">
            {items.map(({ anchorLink }, index) => {
              return (
                anchorLink?.url &&
                anchorLink?.text && (
                  <li
                    key={index}
                    className="mt-0 flex text-center max-md:w-[calc(50%-0.25rem)]"
                  >
                    <Link
                      aria-label={anchorLink?.text}
                      href={anchorLink?.url}
                      newTab={anchorLink?.newTab}
                      className={`button ${buttonStyle} flex w-full items-center justify-center px-3 no-underline`}
                      onClick={handleClick}
                    >
                      {anchorLink?.text}
                      {anchorLink?.newTab && (
                        <span className="sr-only">(opens in a new tab)</span>
                      )}
                    </Link>
                  </li>
                )
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

InPageNavigation.displayName = 'In Page Navigation';
InPageNavigation.Schema = Schema;
