import { Spinner } from '../Spinner';
import { Svg } from '../Svg';

export function QuantitySelector({
  handleDecrement,
  handleIncrement,
  isUpdatingItem,
  item,
  isCustomizedItem,
  mczrQuantity,
}) {
  const { variant, quantity } = item;

  return (
    <div
      className={`flex w-[6.5rem] items-center justify-between rounded border border-grey3${
        isCustomizedItem ? ' h-8' : ''
      }`}
    >
      {!isCustomizedItem && (
        <button
          aria-label={`Reduce quantity of ${variant.product.title} by 1 to ${
            quantity - 1
          }`}
          className="relative h-8 w-8 hover:text-grey4 disabled:opacity-50"
          disabled={isUpdatingItem}
          onClick={handleDecrement}
          type="button"
        >
          <Svg
            className="mx-auto w-3.5"
            src="/svgs/minus.svg#minus"
            title="Minus"
            viewBox="0 0 24 24"
          />
        </button>
      )}

      <div className="relative flex-1">
        {isUpdatingItem ? (
          <Spinner className="mx-auto" width="20" />
        ) : (
          <p className="mb-0 text-center outline-none">
            {mczrQuantity || quantity}
          </p>
        )}
      </div>

      {!isCustomizedItem && (
        <button
          aria-label={`Increase quantity of ${variant.product.title} by 1 to ${
            quantity + 1
          }`}
          className="relative h-8 w-8 hover:text-grey4 disabled:opacity-50"
          disabled={isUpdatingItem}
          onClick={handleIncrement}
          type="button"
        >
          <Svg
            className="mx-auto w-3.5"
            src="/svgs/plus.svg#plus"
            title="Plus"
            viewBox="0 0 20 20"
          />
        </button>
      )}
    </div>
  );
}

QuantitySelector.displayName = 'QuantitySelector';
