const COLORS = [
  { label: 'Transparent', value: 'var(--transparent)' },
  { label: 'White', value: 'var(--white)' },
  { label: 'Dark Loden', value: 'var(--dark-loden)' },
  { label: 'Dark Orion', value: 'var(--dark-orion)' },
  { label: 'Orion', value: 'var(--orion)' },
  { label: 'Light Desert', value: 'var(--light-desert)' },
];

export function Schema() {
  return {
    category: 'Navigation',
    label: 'In Page Navigation',
    key: 'in-page-navigation',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/in-page-nagigation.jpg?v=1717094674',
    fields: [
      {
        label: 'Above The Fold',
        name: 'aboveTheFold',
        component: 'toggle',
        description: `Sets the first slide's heading as H1 and preloads the image`,
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
        defaultValue: 'Jump to style:',
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'html',
      },
      {
        label: 'Button Style',
        name: 'buttonStyle',
        component: 'select',
        options: [
          { label: 'Orion Fill', value: 'button--orion' },
          { label: 'Light Fill', value: 'button--light' },
          { label: 'Black Fill', value: 'button--black' },
          { label: 'Dark Outline', value: 'button--dark-outline' },
          { label: 'Light Outline', value: 'button--light-outline' },
        ],
        defaultValue: 'button--dark-outline',
      },
      {
        label: 'anchor links',
        name: 'items',
        component: 'group-list',
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          minItems: 2,
          maxItems: 8,
        },
        fields: [
          {
            label: 'anchor link',
            name: 'anchorLink',
            component: 'link',
            description: 'Max 14 characters per anchor link CTA',
          },
        ],
        defaultItem: { label: 'New anchor' },
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Background color, spacing bottom (mobile and desktop)',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          bgColor: 'var(--transparent)',
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
}
