import { FiftyFiftyContent } from './FiftyFiftyContent';
import { FiftyFiftyMedia } from './FiftyFiftyMedia';
import { Schema } from './FiftyFifty.schema';

export function FiftyFifty({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { section, content, media } = cms;
  const {
    aspectDesktop,
    aspectMobile,
    aspectTablet,
    mediaOrderDesktop,
    mediaOrderMobile,
  } = {
    ...media,
  };

  const mediaOrderClasses = `${
    mediaOrderMobile === '2' ? 'order-2' : 'order-1'
  } ${mediaOrderDesktop === '2' ? 'md:order-2' : 'md:order-1'}`;
  const contentOrderClasses = `${
    mediaOrderMobile === '2' ? 'order-1' : 'order-2'
  } ${mediaOrderDesktop === '2' ? 'md:order-1' : 'md:order-2'}`;

  return (
    <div id={moduleID} className="md-py-contained md-px-contained">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>

      <div className="relative mx-auto grid max-w-[var(--content-max-width)] grid-cols-1 items-center md:grid-cols-2">
        <div
          className={`relative h-full w-full max-md:before:float-left ${aspectMobile} ${mediaOrderClasses}`}
        >
          <FiftyFiftyMedia media={media} />
        </div>

        <div
          className={`flex w-full items-center md:before:float-left ${aspectTablet} ${aspectDesktop} ${contentOrderClasses}`}
        >
          <FiftyFiftyContent content={content} />
        </div>
      </div>
    </div>
  );
}

FiftyFifty.displayName = 'FiftyFifty';
FiftyFifty.Schema = Schema;
