import { useCallback, useState } from 'react';
import {
  useCartAddItem,
  useCartAddItems,
  useProductInventoryByHandle,
  useSettings,
  useRouter,
} from '@backpackjs/storefront';

import { useGlobalContext } from '../../contexts';
import { useRebuy } from '../../hooks';

export function useAddToCart(
  {
    addToCartText: addToCartTextOverride,
    selectedVariant,
    isPdp,
    rulesetid,
    upsells,
    setUpsells,
    recipient,
    recipientFormReset,
  } = {
    addToCartTextOverride: '',
    selectedVariant: null,
    rulesetid: null,
    upsells: null,
    recipient: null,
  }
) {
  const {
    actions: { openCart, openModal },
  } = useGlobalContext();
  const { cartAddItem } = useCartAddItem();
  const { cartAddItems } = useCartAddItems();
  const { inventory, ...inventoryStatus } = useProductInventoryByHandle({
    handle: selectedVariant?.product?.handle || 'null',
    withInventory: false,
  });
  const settings = useSettings();

  const [isAdding, setIsAdding] = useState(false);
  const router = useRouter();
  const { pushRebuyEvent } = useRebuy();

  const isLoading = !inventoryStatus?.success && !inventoryStatus?.finished;
  const variantInventory = inventory?.variants?.[selectedVariant?.id];
  const variantIsSoldOut = !isLoading && !variantInventory?.availableForSale;
  const variantIsPreorder =
    !variantIsSoldOut && variantInventory?.currentlyNotInStock;
  const variantIsUnavailable = !isLoading && !selectedVariant;
  const enabledNotifyMe = settings?.product?.backInStock?.enabled;

  let buttonText = '';
  if (variantIsPreorder) {
    buttonText = settings?.product?.addToCart?.preorderText || 'Preorder';
  } else if (variantIsSoldOut) {
    buttonText = enabledNotifyMe
      ? settings?.product?.backInStock?.notifyMeText || 'Notify Me'
      : settings?.product?.addToCart?.soldOutText || 'Sold Out';
  } else {
    buttonText =
      addToCartTextOverride ||
      settings?.product?.addToCart?.addToCartText ||
      'Add To Cart';
  }
  if (!isLoading && !selectedVariant) {
    buttonText = 'Unavailable';
  }

  const handleAddToCartWithUpsells = useCallback(async () => {
    if (!selectedVariant?.id || isLoading || isAdding) return;
    setIsAdding(true);
    const lineItems = [];

    const attributes = [];
    if (isPdp && router?.query?._source === 'rebuy') {
      attributes.push({ key: '_source', value: 'Rebuy' });
      attributes.push({
        key: '_attribution',
        value: `data source ID: ${router?.query?.rulesetid || ''}`,
      });
    } else if (rulesetid) {
      attributes.push({ key: '_source', value: 'Rebuy' });
      attributes.push({
        key: '_attribution',
        value: `data source ID: ${rulesetid || ''}`,
      });
    }

    lineItems.push({
      merchandiseId: selectedVariant.id,
      quantity: 1,
      attributes,
    });
    (upsells?.products || []).forEach((item) => {
      lineItems.push({
        merchandiseId: item.id,
        quantity: 1,
        attributes: [
          { key: '_source', value: 'Rebuy' },
          {
            key: '_attribution',
            value: `data source ID: ${upsells?.rulesetid || ''}`,
          },
        ],
      });
    });
    cartAddItems(lineItems);
    setUpsells(null);
    setIsAdding(false);
    openCart();
    pushRebuyEvent({
      subject: 'user',
      verb: 'added',
      noun: 'product',
      shopifyProductHandle: selectedVariant?.product?.handle || '',
    });
  }, [isAdding, isLoading, selectedVariant?.id, upsells?.products]);

  const handleAddToCart = useCallback(async () => {
    if (!selectedVariant?.id || isLoading || isAdding) return;
    setIsAdding(true);
    const attributes = [];
    if (isPdp && router?.query?._source === 'rebuy') {
      attributes.push({ key: '_source', value: 'Rebuy' });
      attributes.push({
        key: '_attribution',
        value: `data source ID: ${router?.query?.rulesetid || ''}`,
      });
    } else if (rulesetid) {
      attributes.push({ key: '_source', value: 'Rebuy' });
      attributes.push({
        key: '_attribution',
        value: `data source ID: ${rulesetid || ''}`,
      });
    }

    if (recipient?.send_on) {
      attributes.push(
        {
          key: '__shopify_send_gift_card_to_recipient',
          value: `on`,
        },
        {
          key: 'Recipient email',
          value: recipient?.recipient_email,
        },
        {
          key: '__shopify_offset',
          value: recipient?.shopify_offset,
        }
      );
      if (recipient?.recipient_name) {
        attributes.push({
          key: 'Recipient name',
          value: recipient?.recipient_name,
        });
      }
      if (recipient?.message) {
        attributes.push({
          key: 'Message',
          value: recipient?.message,
        });
      }
      if (recipient?.send_on !== 'Now') {
        const dateArr = recipient?.send_on.split('-');
        const sendOn = `${dateArr[2]}-${dateArr[0]}-${dateArr[1]}`; // yyyy-mm-dd
        attributes.push({
          key: 'Send on',
          value: sendOn,
        });
      }
    }

    await cartAddItem({
      merchandiseId: selectedVariant.id,
      quantity: 1,
      attributes,
    });
    setIsAdding(false);
    if (typeof recipientFormReset === 'function') {
      recipientFormReset();
    }
    openCart();
    pushRebuyEvent({
      subject: 'user',
      verb: 'added',
      noun: 'product',
      shopifyProductHandle: selectedVariant?.product?.handle || '',
    });
  }, [isAdding, isLoading, selectedVariant?.id, recipient]);

  const handleNotifyMe = useCallback(
    async (component) => {
      if (!selectedVariant?.id || isLoading) return;
      openModal(component);
    },
    [isLoading, selectedVariant?.id]
  );

  return {
    state: {
      buttonText,
      isAdding,
      isLoading,
      isNotifyMe: variantIsSoldOut && enabledNotifyMe,
      isSoldOut: variantIsSoldOut,
      isUnavailable: variantIsUnavailable,
      subtext: settings?.product?.addToCart?.subtext,
    },
    actions: {
      handleAddToCart,
      handleAddToCartWithUpsells,
      handleNotifyMe,
    },
  };
}
