import { useCallback, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function EmailSignup() {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const settings = useSettings();

  const {
    enabled,
    heading,
    subtext,
    listId,
    placeholder,
    buttonText,
    successMessage,
    submittedMessage,
  } = {
    ...settings?.footer?.email,
  };
  const emailListId = listId || 'PTvJdH';

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (isLoading) return;

      if (!email) {
        setMessage('Email is required');
        setTimeout(() => {
          setMessage('');
        }, 3000);
        return;
      }

      const isEmailValid = validateEmail(email);
      if (!isEmailValid) {
        setMessage('Email is invalid');
        setTimeout(() => {
          setMessage('');
        }, 3000);
        return;
      }

      setIsLoading(true);

      const endpoint = '/api/klaviyo';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'emailSignup',
          emailListId,
          email,
        }),
      };
      const response = await fetch(endpoint, options);
      const body = await response.json();
      if (body?.success) {
        setIsLoading(false);
        setMessage('Email submitted!');
        PubSub.publish('SUBSCRIBE_EMAIL', email);
        setEmail('');
        setTimeout(() => {
          setMessage('');
        }, 4000);
      } else {
        setIsLoading(false);
        setEmail('');
        setMessage('Please try again later');
      }
    },
    [email, isLoading, message]
  );

  return enabled ? (
    <form
      className="border-b border-b-grey5 px-4 pb-8 text-center md:border-none md:p-0 md:text-left"
      onSubmit={handleSubmit}
    >
      <h2 className="h6 mb-4 uppercase">{heading}</h2>

      {subtext && <p className="mb-4 text-xs text-gray">{subtext}</p>}

      <div className="relative">
        <input
          aria-label='Email Address'
          name="email-address"
          id="email-address"
          type="email"
          value={email}
          placeholder={placeholder}
          required
          aria-required="true"
          className="form-field-input mt-0 border-grey5 bg-grey5 py-3.5 pr-[130px] text-base text-grey3 placeholder:text-grey3 md:pr-[155px]"
          onChange={(e) => setEmail(e.target.value)}
        />
        {message && (
          <p className="absolute top-[calc(100%+0.3rem)] w-full px-2 text-2xs text-white">
            {message}
          </p>
        )}
        {buttonText && (
          <button
            aria-label={buttonText}
            className="button button--light-icon absolute right-1 top-1/2 -translate-y-1/2 transform max-md:px-3"
            type="submit"
          >
            {buttonText}
            <span className="inner-arrow" role='none' />
          </button>
        )}
      </div>
    </form>
  ) : null;
}

EmailSignup.displayName = 'EmailSignup';
