const COLORS = [
  { label: 'Dark Loden', value: 'var(--dark-loden)' },
  { label: 'Dark Orion', value: 'var(--dark-orion)' },
  { label: 'Orion', value: 'var(--orion)' },
  { label: 'Light Desert', value: 'var(--light-desert)' },
];

export function Schema() {
  return {
    category: 'Text',
    label: 'Icon Row',
    key: 'icon-row',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/icon-text-screenshot.jpg?v=1680714239',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
        defaultValue: 'Icon Row Heading',
      },
      {
        label: 'Subtext',
        name: 'subtext',
        component: 'html',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        label: 'Icons',
        name: 'icons',
        component: 'group-list',
        itemProps: {
          label: '{{item.label}}',
        },
        validate: {
          maxItems: 6,
        },
        fields: [
          {
            label: 'Icon',
            name: 'icon',
            component: 'image',
          },
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
        ],
        defaultItem: { label: 'New Icon', description: 'Description' },
      },
      {
        label: 'Button',
        name: 'buttonLink',
        component: 'link',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Background color, spacing bottom (mobile and desktop)',
        fields: [
          {
            label: 'Background Color',
            name: 'bgColor',
            component: 'select',
            options: COLORS,
          },
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          bgColor: 'var(--light-desert)',
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
}
