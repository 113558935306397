import { ShopByDesignContent } from './ShopByDesignContent';
import { ShopByDesignMedia } from './ShopByDesignMedia';
import { ShopByDesignThumbs } from './ShopByDesignThumbs';
import { Schema } from './ShopByDesign.schema';

export function ShopByDesign({ cms }) {
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;
  const { section, content, media, grid } = cms;
  const {
    mediaOrderDesktop,
    mediaOrderMobile,
  } = {
    ...media,
  };

  const mediaOrderClasses = `${
    mediaOrderMobile === '2' ? 'order-2' : 'order-1'
  } ${mediaOrderDesktop === '2' ? 'md:order-2' : 'md:order-1'}`;
  const contentOrderClasses = `${
    mediaOrderMobile === '2' ? 'order-1' : 'order-2'
  } ${mediaOrderDesktop === '2' ? 'md:order-1' : 'md:order-2'}`;

  return (
    <div id={moduleID} className="md-py-contained md-px-contained">
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>

      <div className="relative mx-auto grid max-w-[var(--content-max-width)] grid-cols-1 items-center md:grid-cols-2">
        <div
          className={`relative h-full w-full ${mediaOrderClasses}`}
        >
          <ShopByDesignMedia media={media} />
        </div>

        <div
          className={`flex w-full h-full items-center ${contentOrderClasses}`}
          style={{backgroundColor: content.bgColor}}
        >
          <ShopByDesignContent content={content} />
        </div>

        <ShopByDesignThumbs grid={grid} />
      </div>
    </div>
  );
}

ShopByDesign.displayName = 'ShopByDesign';
ShopByDesign.Schema = Schema;
