import { Image } from '../../Image';

export function ColorVariantOption({
  color,
  enabledColorNameOnHover,
  onClick,
  selectedVariant,
  swatchesMap,
}) {
  const isActive = color === selectedVariant?.selectedOptionsMap?.Color;
  const swatch = swatchesMap?.[color?.toLowerCase().trim()];
  const hasImage = swatch?.startsWith('http');
  const backgroundColor =
    isActive && swatch === '#FFFFFF'
      ? 'var(--off-white)'
      : swatch || 'var(--off-white)';

  return (
    <div className="group/color relative">
      <button
        aria-label={`Select ${color} color variant`}
        className={`relative flex h-5 w-5 items-center justify-center overflow-hidden rounded-full border bg-transparent transition md:h-6 md:w-6 md:hover:border-darkOrion ${
          isActive ? 'border-darkOrion' : 'border-grey2'
        }`}
        onClick={onClick}
        type="button"
      >
        {hasImage && (
          <Image
            alt={`${selectedVariant?.product?.title} - ${color}`}
            className="absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 object-cover"
            height="24"
            src={swatch}
            width="24"
          />
        )}

        <div
          className={`absolute left-1/2 top-1/2 h-full w-full -translate-x-1/2 -translate-y-1/2 rounded-full border-[3px] border-white transition-[border-width] duration-100 ${
            isActive ? '' : ''
          }`}
          style={hasImage ? {} : { backgroundColor }}
        />
      </button>

      {enabledColorNameOnHover && (
        <p className="pointer-events-none absolute bottom-[calc(100%+2px)] left-1/2 mb-1 hidden -translate-x-1/2 whitespace-nowrap rounded bg-grey2 px-2 text-2xs text-grey4 opacity-0 transition duration-75 md:block group-hover/color:md:opacity-100">
          {color}
        </p>
      )}
    </div>
  );
}

ColorVariantOption.displayName = 'ColorVariantOption';
