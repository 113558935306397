export function Schema() {
  return {
    category: 'Text',
    label: 'Accordions',
    key: 'accordions',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1616/9825/files/accordions-screenshot.jpg?v=1680541250',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'html',
        defaultValue: 'Accordions Heading',
      },
      {
        label: 'Description',
        name: 'description',
        component: 'markdown',
        defaultValue:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      },
      {
        label: 'Accordions',
        name: 'accordions',
        component: 'group-list',
        itemProps: {
          label: '{{item.header}}',
        },
        fields: [
          {
            label: 'Header',
            name: 'header',
            component: 'text',
          },
          {
            label: 'Body',
            name: 'body',
            component: 'markdown',
          },
          {
            label: 'Default Open',
            name: 'defaultOpen',
            component: 'toggle',
            description: 'Sets accordion to be open by default',
            toggleLabels: {
              true: 'On',
              false: 'Off',
            },
          },
        ],
        defaultValue: [
          {
            header: 'Excepteur sint occaecat cupidatat non proident?',
            body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
            defaultOpen: false,
          },
        ],
        defaultItem: {
          header: 'Excepteur sint occaecat cupidatat non proident?',
          body: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
          defaultOpen: false,
        },
      },
      {
        name: 'enableFaq',
        label: 'Enable FAQ',
        component: 'toggle',
        toggleLabels: {
          true: 'Yes',
          false: 'No',
        },
        defaultValue: true,
        description:
          'Enables FAQ Schema for crawlers to recognize the frenquently asked questions, should be used on FAQ template',
      },
      {
        label: 'Button',
        name: 'buttonLink',
        component: 'link',
      },
      {
        label: 'Button Style',
        name: 'buttonStyle',
        component: 'select',
        options: [
          { label: 'Orion Fill', value: 'button--orion' },
          { label: 'Black Fill', value: 'button--black' },
          { label: 'Dark Outline', value: 'button--dark-outline' },
        ],
        defaultValue: 'button--orion',
      },
      {
        label: 'Section Settings',
        name: 'section',
        component: 'group',
        description: 'Spacing bottom (mobile and desktop)',
        fields: [
          {
            component: 'number',
            name: 'mobilePadding',
            label: 'Section Bottom Margin - Mobile',
          },
          {
            component: 'number',
            name: 'desktopPadding',
            label: 'Section Bottom Margin - Desktop',
          },
        ],
        defaultValue: {
          mobilePadding: 24,
          desktopPadding: 48,
        },
      },
    ],
  };
}
