import { useEffect, useState } from 'react';
import { useLoadScript } from './useLoadScript';

const checkYotpoInitialized = () => {
  setTimeout(() => {
    if (typeof window !== 'undefined' && window.yotpo) {
      window.yotpoAPI = new Yotpo.API(yotpo);
      yotpoAPI.refreshWidgets();
    } else {
      checkYotpoInitialized();
    }
  }, 1000);
};

export const useYotpoLoyaltyScript = () => {
  const [yotpoLoaded, setYotpoLoaded] = useState(null);
  const scriptStatus = useLoadScript(
    'https://cdn-loyalty.yotpo.com/loader/0lzdHVAuHKHmTf4oB_6NHw.js?shop=www.brandedbills.com'
  );

  useEffect(() => {
    if (scriptStatus === 'done') {
      setYotpoLoaded(true);
      checkYotpoInitialized();
    }
  }, [scriptStatus]);

  return { yotpoLoaded };
};
