import { useMemo, useEffect, useRef, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import PropTypes from 'prop-types';

import { CollectionFilters } from './CollectionFilters';
import { CollectionGrid } from './CollectionGrid';
import { CollectionSort } from './CollectionSort';
import { useCollectionFilters } from './useCollectionFilters';
import { useCollectionProducts } from './useCollectionProducts';
import { useColorSwatches } from '../../hooks';

export function Collection({
  handle,
  products,
  productsReady,
  hideFilters,
  hasVisibleHeroSection,
  setCollectionProductsData,
  setProductLength,
}) {
  const { swatchesMap } = useColorSwatches();
  const settings = useSettings();
  const {
    filtering,
    sort: sortSettings,
    pagination,
    promotion,
  } = { ...settings?.collection };

  const enabledFilters = filtering?.enabled;
  const enabledSort = sortSettings?.enabled;
  const isSearchPage = handle === 'search';

  const promoTiles = useMemo(() => {
    if (!promotion?.campaigns?.length) return null;
    const campaign = promotion.campaigns.find(({ collections }) => {
      return collections?.some((colHandle) => colHandle.trim() === handle);
    });
    return campaign?.promoTiles || null;
  }, [handle, promotion?.campaigns]);

  const collectionFiltersData = useCollectionFilters({
    enabledFilters,
    handle,
    products,
    productsReady,
  });

  const [resultsPerPage, setResultsPerPage] = useState(
    pagination?.resultsPerPage || 24
  );
  const [scrollY, setScrollY] = useState(null);

  const collectionProductsData = useCollectionProducts({
    activeFilters: collectionFiltersData.state.activeFilters,
    filtersMap: collectionFiltersData.state.filtersMap,
    handle,
    pagination: {
      ...pagination,
      resultsPerPage,
    },
    products,
    productsReady,
    promoTiles,
  });
  const totalProductsRevealed =
    collectionProductsData.state.productsLimit +
    (collectionProductsData.state.numOfPromoTilesInLoad || 0);

  let saveScrollTimeout;
  const saveScroll = () => {
    if (saveScrollTimeout) clearTimeout(saveScrollTimeout);

    saveScrollTimeout = setTimeout(() => {
      window.history.replaceState(
        {
          ...window.history.state,
          options: {
            ...window.history.state.options,
            scrollY: window.scrollY,
            totalProducts: totalProductsRevealed,
          },
        },
        ''
      );
    }, 50);
  };

  const toolbarRef = useRef(null);
  const [hideToolbar, setHiddenToolbar] = useState(false);
  const [stickyToolbar, setStickyToolbar] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (collectionProductsData?.state) {
      setCollectionProductsData(collectionProductsData);
    }
  }, [
    collectionProductsData?.state?.selectedSort,
    collectionProductsData?.actions?.selectSort,
  ]);

  useEffect(() => {
    setProductLength(
      collectionProductsData?.state?.filteredProducts?.length || 0
    );
  }, [collectionProductsData?.state?.filteredProducts?.length]);

  useEffect(() => {
    const headerHeight = document.querySelector('header').offsetHeight;
    let threshold = 0;
    const rect = toolbarRef.current.parentElement.getBoundingClientRect();
    threshold =
      window.pageYOffset + rect.top + toolbarRef.current.clientHeight + 8;

    const setToolbarVisibility = () => {
      if (window.innerWidth >= 1024) {
        setHiddenToolbar(false);
        setStickyToolbar(false);
      } else {
        const currentScroll = window.pageYOffset;

        if (currentScroll > lastScroll) {
          // On scroll Down
          if (currentScroll > threshold - headerHeight) {
            setStickyToolbar(true);
            setHiddenToolbar(true);
          } else {
            setStickyToolbar(false);
          }
        } else if (currentScroll < lastScroll) {
          // On scroll Up
          if (currentScroll < threshold - headerHeight) {
            setStickyToolbar(false);
          } else {
            setStickyToolbar(true);
            setHiddenToolbar(false);
          }
        }

        setLastScroll(currentScroll);
      }
    };

    window.addEventListener('scroll', setToolbarVisibility);
    return () => {
      window.removeEventListener('scroll', setToolbarVisibility);
    };
  }, [lastScroll]);

  useEffect(() => {
    const { options } = window.history.state;
    if (options?.totalProducts) {
      setResultsPerPage(options?.totalProducts);
    }
    if (options?.scrollY) {
      setScrollY(options.scrollY);
    }
  }, []);

  useEffect(() => {
    if (scrollY) {
      setTimeout(() => {
        window.scrollTo(0, scrollY);
      }, 10);
    }
  }, [productsReady, scrollY]);

  useEffect(() => {
    window.addEventListener('scroll', saveScroll);

    return () => {
      window.removeEventListener('scroll', saveScroll);
    };
  }, [totalProductsRevealed]);

  return (
    <div
      className={`relative mx-auto grid grid-cols-2 ${
        hasVisibleHeroSection ? 'md:pt-16' : ''
      } ${
        enabledFilters && !hideFilters
          ? 'gap-x-4 md:grid-cols-[18.5rem_1fr] md:gap-x-6 lg:gap-x-8'
          : 'md:grid-cols-1'
      }`}
    >
      <div
        className={`-m-4 bg-white p-4 transition-[transform] duration-300 ease-out max-md:col-span-2 max-md:grid max-md:grid-cols-2 max-md:gap-x-4 max-md:overflow-hidden md:m-0 md:p-0
          ${stickyToolbar ? 'sticky z-10' : ''}
          ${
            stickyToolbar && hideToolbar
              ? 'top-0 translate-y-[-100%] opacity-0 '
              : 'top-[var(--header-height-mobile)] transform-none opacity-100'
          }
        `}
        ref={toolbarRef}
      >
        {enabledSort && !hideFilters && (
          <div
            className={`max-md:order-2 md:absolute md:right-0 md:top-0 md:w-full
            ${enabledFilters && !hideFilters ? '' : 'max-md:col-span-2'} ${
              hasVisibleHeroSection ? '' : 'md:hidden'
            }`}
          >
            <CollectionSort
              selectedSort={collectionProductsData.state.selectedSort}
              selectSort={collectionProductsData.actions.selectSort}
            />
          </div>
        )}

        {enabledFilters && !hideFilters && (
          <div
            className={`md:h-full ${
              enabledSort
                ? 'max-md:order-1 max-md:border-r max-md:border-border'
                : 'max-md:col-span-2'
            }`}
          >
            <CollectionFilters
              collectionFiltersData={collectionFiltersData}
              collectionCount={products?.length}
              swatchesMap={swatchesMap}
              filteredProducts={filteredProducts}
              enabledFilters={enabledFilters}
              handle={handle}
              productsReady={productsReady}
            />
          </div>
        )}
      </div>

      <div className="max-md:order-3 max-md:col-span-2 max-md:mt-6">
        <CollectionGrid
          activeFilters={collectionFiltersData.state.activeFilters}
          collectionProductsData={collectionProductsData}
          enabledFilters={enabledFilters}
          isSearchPage={isSearchPage}
          promoTiles={promoTiles}
          swatchesMap={swatchesMap}
          collectionFiltersData={collectionFiltersData}
          hideFilters={hideFilters}
          setFilteredProducts={setFilteredProducts}
        />
      </div>
    </div>
  );
}

Collection.displayName = 'Collection';
Collection.defaultProps = {
  handle: undefined,
  products: [],
  productsReady: true,
};
Collection.propTypes = {
  handle: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  productsReady: PropTypes.bool,
};
