import { useMemo, useState } from 'react';

import { CollectionDropdownFilter } from './CollectionDropdownFilter';
import { CollectionFiltersSummary } from './CollectionFiltersSummary';
import { CollectionMenuSidebar } from '../CollectionMenuSidebar';
import { Svg } from '../../Svg';

export function CollectionMobileFilters({
  activeFilters,
  addFilter,
  clearFilters,
  collectionCount,
  filters,
  filtersMap,
  removeFilter,
  showCount,
  swatchesMap,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const totalFilters = useMemo(() => {
    return Object.values(activeFilters || {}).reduce((acc, filterValues) => {
      return acc + filterValues.length;
    }, 0);
  }, [activeFilters]);

  return (
    <>
      <button
        aria-label="Open filters sidebar"
        className="relative flex gap-2.5 h-10 w-full items-center justify-center rounded-md- border border-black px-2 text-left"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <Svg
          className="w-5 text-black"
          src="/svgs/filter.svg#filter"
          title="Arrow Right"
          viewBox="0 0 25 24"
        />
        
        <div className="flex items-center gap-2.5 overflow-hidden">
          <h3 className="m-0 text-sh-upper uppercase leading-normal font-bold">Filters</h3>

          {totalFilters > 0 && (
            <p className="m-0 uppercase text-sh-upper leading-normal font-bold hidden">
              {totalFilters} Selected
            </p>
          )}
        </div>

      </button>

      <CollectionMenuSidebar
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Filters"
        clearFilters={clearFilters}
        activeFilters={activeFilters}
      >
        <div className="sticky top-0 z-[1] p-4 pt-5 hidden">
          <div className="mb-4 flex justify-between items-center gap-2 hidden">
            <h3 className="m-0 uppercase">
              Filters Summary{' '}
              <span className="text-xs">
                {totalFilters ? `(${totalFilters})` : ''}
              </span>
            </h3>

            {totalFilters > 0 && (
              <button
                className="button--underline text-black whitespace-nowrap"
                onClick={clearFilters}
                type="button"
              >
                Clear All
              </button>
            )}
          </div>

          <div className="scrollbar-hide max-h-[4.5rem] overflow-y-auto">
            {totalFilters ? (
              <CollectionFiltersSummary
                activeFilters={activeFilters}
                filtersMap={filtersMap}
                removeFilter={removeFilter}
              />
            ) : (
              <p className="text-base m-0">
                No filters selected yet
              </p>
            )}
          </div>
        </div>

        <ul className="list-none m-0 p-0 pt-1 pb-4 [&_li]:m-0">
          {filters.map((filter, index) => {
            if (
              !filter.values.length ||
              (filter.values.length === 1 &&
                filter.values[0].count === collectionCount)
            )
              return null;

            return (
              <li key={index} className={`${index > 0 ? 'border-t' : ''} border-grey2 `}>
                <CollectionDropdownFilter
                  activeFilters={activeFilters}
                  addFilter={addFilter}
                  defaultOpen
                  filter={filter}
                  removeFilter={removeFilter}
                  showCount={showCount}
                  swatchesMap={swatchesMap}
                />
              </li>
            );
          })}
        </ul>
      </CollectionMenuSidebar>
    </>
  );
}

CollectionMobileFilters.displayName = 'CollectionMobileFilters';
