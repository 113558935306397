import { useEffect } from 'react';
import { useCustomerAuth } from '@backpackjs/storefront';
import Script from 'next/script';
import { Schema } from './YotpoRewards.schema';

export function YotpoRewards({ cms }) {
  // loaded state necessary to avoid hydration errors due to ssr and yotpo replacing the html
  const { customerInit, customer } = useCustomerAuth();
  const { section } = cms;
  const moduleID = `${Schema().key}-${cms.id || cms.cmsId || cms.tinaId}`;

  useEffect(() => {
    if (customerInit) {
      if (
        typeof yotpoWidgetsContainer !== 'undefined' &&
        typeof yotpoWidgetsContainer?.initWidgets !== 'undefined'
      ) {
        window.yotpoWidgetsContainer.initWidgets();
      }
    }
  }, []);

  return (
    <div id={moduleID} style={{ minHeight: '100vh', background: '#F9F9F9' }}>
      <style>{`
        #${moduleID} { margin-bottom: ${section?.mobilePadding}px; }
        @media(min-width: 1024px) {
          #${moduleID} { margin-bottom: ${section?.desktopPadding}px; }
        }
      `}</style>
      <div
        id="swell-customer-identification"
        data-authenticated="true"
        data-email={customer?.email}
        data-id={customer?.id?.replace('gid://shopify/Customer/', '')}
        data-tags='"[]"'
        style={{ display: 'none' }}
      />
      {customerInit && (
        <>
          <Script src="https://cdn-widgetsrepository.yotpo.com/v1/loader/0lzdHVAuHKHmTf4oB_6NHw" />
          <div
            className="yotpo-widget-instance"
            data-yotpo-instance-id={cms?.yotpoInstanceId || '208474'}
          />
        </>
      )}
    </div>
  );
}

YotpoRewards.displayName = Schema().label;
YotpoRewards.Schema = Schema;
