import { Image } from '../Image';
import { Link } from '../Link';
import { Spinner } from '../Spinner';
import { Svg } from '../Svg';
import { QuantitySelector } from './QuantitySelector';
import { useCartItem } from './useCartItem';
import { useCartItemPrices } from './useCartItemPrices';

export function CartItem({ closeCart, item }) {
  const { id, variant, quantity } = item;

  const {
    isUpdatingItem,
    isRemovingItem,
    handleDecrement,
    handleIncrement,
    handleRemove,
  } = useCartItem({ id, quantity });
  const { price, compareAtPrice } = useCartItemPrices({ item });
  const isFreeItem = item.attributes.some((attr) =>
    ['_gwp'].includes(attr.key)
  );
  const mczrAttr = (item?.attributes || []).reduce((acc, attr) => {
    if (attr?.key === '_mczr_designId' || attr?.key === '_mczr_image') {
      return { ...acc, [attr.key.replace('_mczr_', '')]: attr.value };
    }
    if (attr?.key?.toLowerCase().indexOf('quantity') !== -1) {
      return { ...acc, quantity: attr.value };
    }
    if (attr?.key?.toLowerCase().indexOf('select a color') !== -1) {
      return { ...acc, color: attr.value };
    }
    if (attr?.key?.toLowerCase().indexOf('select a size') !== -1) {
      return { ...acc, size: attr.value };
    }
    return acc;
  }, {});
  const mczrDesignId = mczrAttr?.designId;
  const mczrImage = mczrAttr?.image;
  const url = `/products/${
    variant.product.handle
  }?variant=${variant?.id?.replace('gid://shopify/ProductVariant/', '')}${
    mczrDesignId ? `&designId=${mczrDesignId}` : ''
  }`;

  const mczrQuantity = mczrAttr?.quantity;
  const mczrColor = mczrAttr?.color;
  const mczrSize = mczrAttr?.size;

  if (isFreeItem) return null;

  return (
    <div className="relative grid grid-cols-[auto_1fr] gap-4 px-4 py-6 md:gap-6 md:px-0">
      <Link
        aria-label={`View ${variant.product.title}`}
        href={url}
        onClick={closeCart}
        tabIndex="-1"
      >
        <Image
          alt={variant.product.title}
          className="bg-grey1 p-2 md:hidden"
          height={Math.floor(80 / (variant.image.width / variant.image.height))}
          src={mczrDesignId ? mczrImage : variant.image.src}
          width="80"
        />
        <Image
          alt={variant.product.title}
          className="hidden bg-grey1 p-2 md:block"
          height={Math.floor(
            120 / (variant.image.width / variant.image.height)
          )}
          src={mczrDesignId ? mczrImage : variant.image.src}
          width="120"
        />
      </Link>

      <div className="flex flex-col justify-between gap-4 md:gap-5">
        <div className="relative pr-6">
          <Link
            aria-label={`View ${variant.product.title}`}
            className="text-black no-underline"
            href={url}
            onClick={closeCart}
          >
            <h4 className="mb-2 text-xs uppercase md:text-sh-lg">
              {variant.product.title}
            </h4>
          </Link>

          {variant.selectedOptions.length > 0 && (
            <div className="flex flex-col gap-2">
              {variant.selectedOptions.map((option, index) => {
                return (
                  option?.value !== 'Default Title' &&
                  option?.value?.indexOf('mczr_') !== 0 && (
                    <p
                      className="break-anywhere mb-0 text-xs md:text-sm"
                      key={`variant-option-${variant?.id}-${index}`}
                    >
                      {option.name}: {option.value}
                    </p>
                  )
                );
              })}
            </div>
          )}

          {item?.attributes?.length > 0 && (
            <div className="mt-2 flex flex-col gap-2">
              {mczrColor && (
                <p className="break-anywhere mb-0 text-xs md:text-sm">{`Color: ${mczrColor}`}</p>
              )}
              {mczrSize && (
                <p className="break-anywhere mb-0 text-xs md:text-sm">{`Size: ${mczrSize}`}</p>
              )}
              {item.attributes.map((attribute, index) => {
                if (mczrDesignId) {
                  return null;
                }
                const sendOnArr =
                  attribute.key === 'Send on' ? attribute.value.split('-') : [];
                const sendOnFormat = sendOnArr.length
                  ? `${sendOnArr[1]}/${sendOnArr[2]}/${sendOnArr[0]}`
                  : '';
                return (
                  attribute.key.indexOf('_') !== 0 && (
                    <p
                      className="break-anywhere mb-0 text-xs md:text-sm"
                      key={`item-attribute-${variant?.id}-${index}`}
                    >
                      {attribute.key}:{' '}
                      {attribute.key === 'Send on'
                        ? sendOnFormat
                        : attribute.value}
                    </p>
                  )
                );
              })}
            </div>
          )}

          <button
            aria-label={`Remove ${variant.product.title} from cart`}
            className="absolute right-0 top-0 w-4"
            onClick={handleRemove}
            type="button"
          >
            <Svg
              className="w-[16px] hover:text-lava"
              src="/svgs/icon-trash.svg#icon-trash"
              viewBox="0 0 18 19"
            />
          </button>
        </div>

        <div className="flex items-center justify-between gap-4">
          <QuantitySelector
            handleDecrement={handleDecrement}
            handleIncrement={handleIncrement}
            isUpdatingItem={isUpdatingItem}
            item={item}
            isCustomizedItem={mczrDesignId}
            mczrQuantity={mczrQuantity}
          />

          <div className="flex flex-col items-center gap-0.5">
            <p
              className={`mb-0 text-xs md:text-sm ${
                compareAtPrice ? 'font-bold text-lava' : ''
              }`}
            >
              <span
                aria-label={
                  compareAtPrice
                    ? `Sale price ${price}`
                    : `Regular price ${price}`
                }
              >
                {price}
              </span>
            </p>
            {compareAtPrice && (
              <p className="mb-0 text-xs text-mediumDarkGray md:text-sm">
                <span
                  className="line-through"
                  aria-label={`Regular price ${compareAtPrice}`}
                >
                  {compareAtPrice}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>

      {isRemovingItem && (
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.6)]">
          <Spinner width="20" />
        </div>
      )}
    </div>
  );
}

CartItem.displayName = 'CartItem';
